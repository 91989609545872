import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  styled,
  Card as MuiCard,
  TableHead,
  TableSortLabel,
  Toolbar,
  Typography,
  TablePagination,
  Grid,
  Box,
  CardContent,
  Divider,
} from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getComparator, stableSort } from "../util";
import ReportInterface from "../../../integration/internal/ReportInterface";
import { TEST_REPORT } from "../../../constants/envConstant";
import moment from "moment";
import Loading from "../../components/Loading";
import { spacing } from "@material-ui/system";
import NoData from "../../invoices/NoReports";
import AutoPdfGenerationContainer from "../../../common/pdf/AutoPdfGenerationContainer";
import Space from "../../../components/Space";
import { UNIT_CONVERSION_SCALE } from "../../../constants/values";
import CustomHr from "../../../components/CustomHr";
import { MAIN } from "../../../constants/colors";
import RadonReportChart from "../../charts/Chartjs/RadonReportChart";
import { RadonUtil } from "../../components/RadonUtil";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Shadow = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled(Box)`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled(Box)`
  min-width: 150px;
`;

const styles = {
  report_client_bold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
  },
  report_label: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "18px",
  },
  report_title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#FFFFFF",
    textAlign: "center",
    verticalAlign: "middle",
  },
  report_title_box: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: MAIN,
    justifyContent: "center",
    alignItems: "center",
  },
  report_footer: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "48px",
    color: "#585858",
  },
};

export function GeneratedReportsTable() {
  const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)}px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding: ${(props) => props.theme.spacing(10)}px;
    }
  `;

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("generated_date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reports, setReports] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openViewClicked, setOpenViewClicked] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [reportDuration, setReportDuration] = useState(null);
  const location = useLocation();
  const reportType = "30 days";

  let res;
  location.state.email = localStorage.getItem("0xaccountId");
  location.state.name = localStorage.getItem("0xuserId");

  const fixedRow = {
    measurement_start_d: "2021-02-15T12:00:00",
    types: "hr_btn",
    created: "2021-08-19T20:56:19.341685",
    sn_report_start: "EG23EQ000012-072021-081921",
    client_name: "User_12",
    device_name: "EG12_In_SanRamon",
    email: "choi2incommon@gmail.com",
    serial_number: "EG23EQ000012",
    filename: "choi2incommon@gmail.com_EG23EQ000012_User_12_072021_081921",
    measurement_start: "072021",
    date: "2021-08-19",
    measurement_end: "081921",
    updated: "2021-08-19T21:56:19.341685",
    generated_date: "08-19-2021",
    measurement_end_d: "2021-08-19T12:00:00",
  };

  const headCells = [
    { id: "device_name", alignment: "left", label: "Device Name" },
    { id: "serial_number", alignment: "left", label: "S/N" },
    { id: "client_name", alignment: "left", label: "Client" },
    { id: "measurement_start", alignment: "left", label: "Started" },
    { id: "measurement_end", alignment: "left", label: "Ended" },
    { id: "generated_date", alignment: "left", label: "Generated" },
    { id: "req_type", alignment: "left", label: "Type" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignment}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const getGeneratedReportsList = async () => {
    if (!location.state) {
      return;
    }

    try {
      setLoaded(false);
      setNoData(false);
      res = await ReportInterface.retrieveReportsList(location.state.email);

      if (res && (!res.data || (res.data && res.data.length === 0)))
        res.data = [];
      else if (
        !_.isEqual(
          reports,
          changeDate(TEST_REPORT ? [...res.data, fixedRow] : res.data)
        )
      ) {
        setReports(
          changeDate(TEST_REPORT ? [...res.data, fixedRow] : res.data)
        );
      }
    } catch (e) {
      alert("getGeneratedReportsList@ ==> " + e);
    }

    setLoaded(true);
    if (res && res.resultcode === 404) {
      setNoData(true);
    }
  };

  useEffect(() => {
    getGeneratedReportsList();
  }, [reportDetails, reportDuration]);

  const getDate = (moment, type) => {
    /*
     * type1 : MMDDYY
     * type2 : MM/DD/YYYY
     * type3 : MM-DD-YYYY
     * */

    if (type === 1) {
      let month =
        moment.month() < 9 ? "0" + (moment.month() + 1) : moment.month() + 1;
      return month + moment.date() + (moment.year() - 2000);
    } else if (type === 2) {
      let month =
        moment.month() < 9 ? "0" + (moment.month() + 1) : moment.month() + 1;
      return month + "/" + moment.date() + "/" + moment.year();
    } else if (type === 3) {
      let month =
        moment.month() < 9 ? "0" + (moment.month() + 1) : moment.month() + 1;
      return month + "-" + moment.date() + "-" + moment.year();
    }
  };
  let created = getDate(moment(), 3);

  let EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar>
        <ToolbarTitle>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {/*{numSelected} selected*/}
            </Typography>
          ) : (
            <Typography variant="h5" id="tableTitle">
              Radon Reports
            </Typography>
          )}
        </ToolbarTitle>
        <Spacer />
      </Toolbar>
    );
  };

  const changeDate = (data) => {
    let tmp = data.map((r) => {
      return { ...r, generated_date: r.generated_date.replace(/-/g, "/") };
    });
    return tmp;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertDate = (d) => {
    return d.substring(0, 2) + "/" + d.substring(2, 4) + "/20" + d.substring(4);
  };

  const history = useHistory();

  const updatePDFUrl = (url) => {
    getGeneratedReportsList();
  };

  const getRptType = (req) => {
    if(!req) {
      return "N/A"
    }

    if(req.toUpperCase()=="RPT_48H") {
      return "48 Hours"
    }
    if(req.toUpperCase()=="RPT_7D") {
      return "7 Days"
    }
    if(req.toUpperCase()=="RPT_30D") {
      return "30 Days"
    }
    if(req.toUpperCase()=="RPT_1M") {
      return "1 Month"
    }
    if(req.toUpperCase()=="RPT_3M") {
      return "3 Months"
    }
    if(req.toUpperCase()=="RPT_6M") {
      return "6 Months"
    }
    if(req.toUpperCase()=="RPT_1Y") {
      return "1 Year"
    }
    return req
  };

  const openRealtimeView = (row) => {
    console.log(`row in openRealtimeView=${JSON.stringify(row)}`);
    let duration;
    let btnType;
    if(!row.measurement_duration) {
      const date1 = new Date(row.measurement_start.substring(0,2)+"/"+row.measurement_start.substring(2,4)+"/"+row.measurement_start.substring(4));
      const date2 = new Date(row.measurement_end.substring(0,2)+"/"+row.measurement_end.substring(2,4)+"/"+row.measurement_end.substring(4));
      // const date1 = new Date('7/13/2010');
      // const date2 = new Date('12/15/2010'); //13395600000
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      console.log(`diffTime=${diffTime}, diffDays = ${diffDays}`);
      if( diffDays>=365 ) {
        duration = "YEAR";
        btnType = "wk_btn";
      } else if( diffDays>=170 ) {
        duration = "HALF_YEAR";
        btnType = "dy_btn";
      } else if( diffDays>=80 ) {
        duration = "QUARTER";
        btnType = "dy_btn";
      } else if( diffDays>=28 ) {
        duration = "MONTH";
        btnType = "dy_btn";
      } else if( diffDays>=6 ) {
        duration = "WEEK";
        btnType = "hr_btn";
      } else {
        duration = "48HR";
        btnType = "hr_btn";
      }
    } else {
      duration = RadonUtil.getDurationType(row.measurement_duration);
      btnType = RadonUtil.getBtnType(row.measurement_duration);
    }
    // btnType = RadonUtil.getBtnType(row.measurement_duration);
    console.log(`duration=${duration}, btnType=${btnType}`);
    let generated = new Date(row.generated_date);
    let cut = new Date("05/04/2024");
    let path = "/reports/details";
    let skipFlag = true;
    if(generated > cut) {
      path = "/reports/history/details";
    }
    history.push({
      pathname: path,
      state: {
        email: localStorage.getItem("0xaccountId"),
        serial_number: [row.serial_number],
        actionType: "VIEW",
        duration: {
          start: row.measurement_start,
          end: row.measurement_end,
          type: duration,
          btnType: btnType,
        },
        originalGenerated: row.generated_date,
        skipFlag: skipFlag,
      },
    });
  };

  const useStyles = makeStyles((theme) => ({
    hover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {!loaded ? (
        <Loading></Loading>
      ) : noData ? (
        <NoData></NoData>
      ) : (
        <Wrapper>
          <EnhancedTableToolbar />
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={reports.length}
                />
                <TableBody>
                  {stableSort(reports, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const date = new Date(row.created);

                      return (
                        <TableRow
                          hover
                          classes={{ hover: classes.hover }}
                          onClick={(event) => openRealtimeView(row)}
                          role="checkbox"
                          tabIndex={-1}
                          key={`${row.id}-${index}`}
                        >
                          <TableCell align="left">{row.device_name}</TableCell>
                          <TableCell align="left">
                            {row.serial_number}
                          </TableCell>
                          <TableCell align="left">{row.client_name}</TableCell>
                          <TableCell align="left">
                            {convertDate(row.measurement_start)}
                          </TableCell>
                          <TableCell align="left">
                            {convertDate(row.measurement_end)}
                          </TableCell>
                          <TableCell align="left">
                            {moment
                              .parseZone(row.generated_date)
                              .utc()
                              .format("MM/DD/yyyy")}
                          </TableCell>
                          <TableCell align="left">{getRptType(row.req_type)}</TableCell>
                          {/*<TableCell padding="none" align="right">*/}
                          {/*  <Box mr={2}>*/}
                          {/*    <IconButton aria-label="details" onClick={() => openRealtimeView(row)}>*/}
                          {/*      <RemoveRedEyeIcon/>*/}
                          {/*    </IconButton>*/}
                          {/*    /!*<IconButton aria-label="download" href={row.pdf_url}>*!/*/}
                          {/*    /!*  <ArchiveIcon/>*!/*/}
                          {/*    /!*</IconButton>*!/*/}
                          {/*  </Box>*/}
                          {/*</TableCell>*/}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={reports.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <div
            style={{
              position: "absolute",
              left: "-2000px",
              top: -500,
            }}
          >
            {/*<div>*/}
            {reportDetails &&
            reportDetails.companyName &&
            reportDuration &&
            reportDuration.measurement_start ? (
              <AutoPdfGenerationContainer
                run={openViewClicked}
                details={reportDetails}
                duration={reportDuration}
                generated={created}
                callback={updatePDFUrl}
              >
                <>
                  <Grid container justify="center">
                    <Grid item xs={12} lg={10}>
                      <Box style={styles.report_title_box}>
                        <Typography
                          align={"center"}
                          style={styles.report_title}
                        >
                          RADON REPORT
                        </Typography>
                      </Box>
                      <Shadow>
                        <Card px={6} pt={6}>
                          <CardContent>
                            <Grid container spacing={6}>
                              <Grid item xs={6} md={6}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.companyName}
                                </Typography>
                                <Typography
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.email}
                                </Typography>
                                <Space height={"10px"} />
                                <Typography
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.address.street}
                                </Typography>
                                <Typography
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.address.city}
                                  {", "}
                                  {reportDetails.address.state}{" "}
                                  {reportDetails.address.zipcode}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography
                                  align={"right"}
                                  variant="body2"
                                  gutterBottom
                                >
                                  Client Name
                                </Typography>
                                <Typography
                                  align={"right"}
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.client_name}
                                </Typography>
                                <Space height={"10px"} />
                                <Typography
                                  align={"right"}
                                  variant={"body2"}
                                  gutterBottom
                                >
                                  Client Email Address
                                </Typography>
                                <Typography
                                  align={"right"}
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.client_email}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Space height={"29px"} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Space height={"29px"} />
                            </Grid>
                            <Grid container xs={12} md={12}>
                              <Grid item xs={6} md={6}>
                                <Typography variant="body2" gutterBottom>
                                  Serial Number
                                </Typography>
                                <Typography
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDetails.serial_number}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography
                                  align={"right"}
                                  variant={"body2"}
                                  gutterBottom
                                >
                                  Measurement Duration
                                </Typography>
                                <Typography
                                  align={"right"}
                                  variant={"body2"}
                                  gutterBottom
                                  style={styles.report_client_bold}
                                >
                                  {reportDuration.measurement_start.substring(
                                    0,
                                    2
                                  ) +
                                    "/" +
                                    reportDuration.measurement_start.substring(
                                      2,
                                      4
                                    ) +
                                    "/20" +
                                    reportDuration.measurement_start.substring(
                                      4
                                    )}{" "}
                                  ~{" "}
                                  {reportDuration.measurement_end.substring(
                                    0,
                                    2
                                  ) +
                                    "/" +
                                    reportDuration.measurement_end.substring(
                                      2,
                                      4
                                    ) +
                                    "/20" +
                                    reportDuration.measurement_end.substring(4)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Space height={"32px"} />
                            <Grid item xs={6} md={6}>
                              <Typography variant="body2" gutterBottom>
                                {reportType} of Radon Data
                              </Typography>
                              <Grid container spacing={6}>
                                <Grid item md={4}>
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_client_bold}
                                  >
                                    {(
                                      reportDetails.lowest_radon /
                                      UNIT_CONVERSION_SCALE
                                    ).toFixed(1)}{" "}
                                    pCi/L
                                  </Typography>
                                  <CustomHr color={MAIN} height={1} />
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_label}
                                  >
                                    Lowest
                                  </Typography>
                                </Grid>
                                <Grid item md={4}>
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_client_bold}
                                  >
                                    {(
                                      reportDetails.avg_radon /
                                      UNIT_CONVERSION_SCALE
                                    ).toFixed(1)}{" "}
                                    pCi/L
                                  </Typography>
                                  <CustomHr color={MAIN} height={1} />
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_label}
                                  >
                                    Average
                                  </Typography>
                                </Grid>
                                <Grid item md={4}>
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_client_bold}
                                  >
                                    {(
                                      reportDetails.highest_radon /
                                      UNIT_CONVERSION_SCALE
                                    ).toFixed(1)}{" "}
                                    pCi/L
                                  </Typography>
                                  <CustomHr color={MAIN} height={1} />
                                  <Typography
                                    variant={"body2"}
                                    gutterBottom
                                    style={styles.report_label}
                                  >
                                    Highest
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Space height={"32px"} />
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Space height={"21px"} />
                            {/*<Grid container>*/}
                            <Grid item xs={12} md={12}>
                              <RadonReportChart
                                radon={reportDetails.radondata}
                                type={"MONTH"}
                              />
                            </Grid>
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant={"body2"}
                                  align={"left"}
                                  gutterBottom
                                  style={styles.report_footer}
                                >
                                  EcoGuard Radon Report
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant={"body2"}
                                  align={"right"}
                                  gutterBottom
                                  style={styles.report_footer}
                                >
                                  Report Generated on {created}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Shadow>
                    </Grid>
                  </Grid>
                </>
              </AutoPdfGenerationContainer>
            ) : (
              <></>
            )}
          </div>
        </Wrapper>
      )}
    </>
  );
}
