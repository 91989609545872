import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

const RequestReport = () => {
  const Mailto = ({
    email,
    subject = "",
    body = "",
    detail = "",
    children,
  }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body)
      params += `${subject ? "&" : ""}body=${
        encodeURIComponent(body) + detail
      }`;

    return (
      <Button
        variant="contained"
        color="primary"
        mt={3}
        classes={{ root: "button", label: "button-label" }}
        href={`mailto:${email}${params}`}
      >
        {children}
      </Button>
    );
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">
              48-hour Clearance Radon Report
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px", marginBottom: "20px" }}>
              We provide two complimentary 48-hour clearance radon reports per quarter for certified EcoGuard devices with serial numbers with EQQG.<br/>
              To generate the report, the device must be activated and connected to a Wi-Fi for at least 72 hours.
            </Typography>
            <Typography variant="body1" style={{ fontSize: "14px", marginBottom: "20px" }}>
              Please confirm that you have entered all the required information for your radon report:
              <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
                <li>Device information</li>
                <li>Client information</li>
                <li>Location information</li>
              </ul>
            </Typography>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Please contact us at{" "}
              <Link href="mailto:lab@ecosense.io">lab@ecosense.io</Link>{" "}
              if you have any question regarding radon report.
            </Typography>
          </Grid>
          {/*<Grid item>*/}
          {/*  <Mailto*/}
          {/*    email="lab@ecosense.io"*/}
          {/*    subject="Request 48-hour clearance radon report"*/}
          {/*    body="To receive the 48-hour clearance radon report, ensure that the device has been activated and remains connected to a Wi-Fi network for a minimum of 72 hours. Please provide your device and account details below. We will review your data and send you a report within 1-2 business days."*/}
          {/*    detail="%0D%0A%0D%0A- Device S/N:%0D%0A- Account email address:"*/}
          {/*  >*/}
          {/*    Request report*/}
          {/*  </Mailto>*/}
          {/*</Grid>*/}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestReport;
