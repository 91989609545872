import React, { Component } from "react";
import moment from "moment";
import { UNIT_CONVERSION_SCALE } from "../../constants/values";

const RadonUtil = {
  changeDateFormat: function (dateString) {
    /*
     * Input : YYYYMMDD
     * Output : MM/DD/YY
     */
    return (
      dateString.substring(4, 6) +
      "/" +
      dateString.substring(6) +
      "/" +
      dateString.substring(2, 4)
    );
  },

  getDate: function (moment, type) {
    /*
     * type 1 : MMDDYY
     * type 2 : MM/DD/YYYY
     * type 3 : MM-DD-YYYY
     * type 4 : YYYYMMDD
     * type 5 : MM/DD/YY
     * type 6 : MM/DD/YY HH:MM
     * */
    let month =
      moment.month() < 9 ? "0" + (moment.month() + 1) : moment.month() + 1;
    let date = moment.date() < 9 ? "0" + moment.date() : moment.date();
    if (type === 1) {
      return moment.format("MMDDYY");
    } else if (type === 2) {
      return moment.format("MM/DD/YY");
    } else if (type === 3) {
      return moment.format("MM-DD-YYYY");
    } else if (type === 4) {
      return moment.format("YYYYMMDD");
    } else if (type === 5) {
      return moment.format("MM/DD/YY");
    } else if (type === 6) {
      return moment.format("MM/DD/YY HH:MM");
    } else if (type === 7) {
      return moment.format("MM/DD/YY hh:mm A");
    }
  },

  getDurationType: function (type) {
    if (type.toLowerCase() === "48 hours") {
      return "48HR";
    } else if (type.toLowerCase() === "7 days") {
      return "WEEK";
    } else if (type.toLowerCase() === "30 days") {
      return "MONTH";
    } else if (type.toLowerCase() === "3 months") {
      return "QUARTER";
    } else if (type.toLowerCase() === "6 months") {
      return "HALF_YEAR";
    } else if (type.toLowerCase() === "1 year") {
      return "YEAR";
    } else {  // monthly report could be 28 ~ 31 days
      return "MONTH";
    }
  },

  getBtnType: function (type) {
    if (type.toLowerCase() === "48 hours") {
      return "hr_btn";
    } else if (type.toLowerCase() === "7 days") {
      return "hr_btn";
    } else if (type.toLowerCase() === "30 days") {
      return "dy_btn";
    } else if (type.toLowerCase() === "3 months") {
      return "dy_btn";
    } else if (type.toLowerCase() === "6 months") {
      return "dy_btn";
    } else if (type.toLowerCase() === "1 year") {
      return "wk_btn";
    } else if (type === "48HR") {
      return "hr_btn";
    } else if (type === "DAY") {
      return "hr_btn";
    } else if (type === "WEEK") {
      return "hr_btn";
    } else if (type === "MONTH") {
      return "dy_btn";
    } else if (type === "QUARTER") {
      return "dy_btn";
    } else if (type === "HALF_YEAR") {
      return "dy_btn";
    } else if (type === "YEAR") {
      return "wk_btn";
    } else {  // monthly report
      return "dy_btn";
    }
  },

  convertToPCi: function (bqValue) {
    const radonLevelForPciText = (bqValue / UNIT_CONVERSION_SCALE).toFixed(1);
    const radonLevelForPciNumber = Math.round(radonLevelForPciText * 100) / 100;
    return radonLevelForPciNumber;
  },

  getDurationMomentByType: function (type) {
    let endMoment = moment();
    let startMoment = endMoment.clone();
    if (type === "48HR") {
      startMoment = startMoment.clone().add(-48, "hours");
    } else if (type === "DAY") {
      startMoment = startMoment.add(-1, "days");
    } else if (type === "WEEK") {
      startMoment = startMoment.add(-7, "days");
    } else if (type === "MONTH") {
      startMoment = startMoment.add(-30, "days");
    } else if (type === "QUARTER") {
      startMoment = startMoment.add(-3, "months");
    } else if (type === "HALF_YEAR") {
      startMoment = startMoment.add(-6, "months");
    } else if (type === "YEAR") {
      startMoment = startMoment.add(-1, "years");
    } else {
      startMoment = startMoment.add(-30, "days");
    }

    return {
      startMoment: startMoment,
      endMoment: endMoment,
    };
  },

  // Input: '08/01/21', Output: '08/01/2021'
  getFormattedDate: (dateString) => {
    return (
      dateString.substring(0, 2) +
      "/" +
      dateString.substring(2, 4) +
      "/20" +
      dateString.substring(4)
    );
  },

  // Input: '08/01/21', Output: '08/01/2021'
  getFormattedDateWithoutYear: (dateString) => {
    return dateString.substring(0, 2) + "/" + dateString.substring(2, 4);
  },

  // startDate: '08/01/21', endDate: '09/01/21'
  getDurationMomentByDate: function (startDate, endDate) {
    let startMoment = moment(new Date(startDate));
    let endMoment = moment(new Date(endDate));
    return {
      startMoment: startMoment,
      endMoment: endMoment,
    };
  },

  convert2DurationSet: function (duration) {
    let current = moment();
    let startMillis;
    if (duration.type === "48HR") {
      startMillis = current.clone().add(-48, "hours");
    } else if (duration.type === "DAY") {
      startMillis = current.clone().add(-1, "days");
    } else if (duration.type === "WEEK") {
      startMillis = current.clone().add(-7, "days");
    } else if (duration.type === "MONTH") {
      startMillis = current.clone().add(-30, "days");
    } else if (duration.type === "QUARTER") {
      startMillis = current.clone().add(-3, "months");
    } else if (duration.type === "HALF_YEAR") {
      startMillis = current.clone().add(-6, "months");
    } else if (duration.type === "YEAR") {
      startMillis = current.clone().add(-1, "years");
    } else {
      startMillis = current.clone().add(-30, "days");
    }
    let startTime;
    let endTime;
    startTime = this.getDate(startMillis, 1);
    endTime = this.getDate(current, 1);

    if (duration.type === "48HR") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "hr_btn",
      };
    } else if (duration.type === "DAY") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "hr_btn",
      };
    } else if (duration.type === "WEEK") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "hr_btn",
      };
    } else if (duration.type === "MONTH") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "dy_btn",
      };
    } else if (duration.type === "QUARTER") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "dy_btn",
      };
    } else if (duration.type === "HALF_YEAR") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "dy_btn",
      };
    } else if (duration.type === "YEAR") {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: "wk_btn",
      };
    }
  },

  getReportTypeText: function (type) {
    if (type === "48HR") return "48 hours";
    else if (type === "DAY") return "24 hours";
    else if (type === "WEEK") return "7 days";
    else if (type === "MONTH") return "30 days";
    else if (type === "QUARTER") return "3 months";
    else if (type === "HALF_YEAR") return "6 months";
    else if (type === "YEAR") return "1 year";
  },
};

export { RadonUtil };
