import React from 'react';
import {Auth} from 'aws-amplify';

const AuthUtil = {
  getAuthToken: async function() {
    let authToken;
    try {
      const accessSource = localStorage.getItem('accessSource');
      const accessToken = localStorage.getItem('accessToken');
      if (accessSource === 'app' && accessToken) {
        authToken = accessToken;
      } else {
        authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      }
    } catch(e) {
      throw e;
    }
    return authToken;
  },

}

export {AuthUtil};
