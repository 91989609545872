import {Grid, IconButton, Typography} from "@material-ui/core";
import dallas_sign from "../../../assets/img/dallas_sign.png";
import james_sign from "../../../assets/img/james_sign.png";
import {ArrowBackIos} from "@material-ui/icons";
import Space from "../../../components/Space";
import React from "react";

const Details = ({ reportDetails }) => {
    const email = reportDetails.ctrl_state_detail && reportDetails.ctrl_state_detail.length > 0 ? reportDetails.ctrl_state_detail[0].contact_email : null;
    const phone = reportDetails.ctrl_state_detail && reportDetails.ctrl_state_detail.length > 0 ? reportDetails.ctrl_state_detail[0].contact_phone : null;
    const state = reportDetails.ctrl_state_detail && reportDetails.ctrl_state_detail.length > 0 ? reportDetails.ctrl_state_detail[0].state : null;
    const state_full_name = reportDetails.ctrl_state_detail && reportDetails.ctrl_state_detail.length > 0 ? reportDetails.ctrl_state_detail[0].state_full_name : null;
    const url = reportDetails.ctrl_state_detail && reportDetails.ctrl_state_detail.length > 0 ? reportDetails.ctrl_state_detail[0].dep_web : null;

    const needContact = () => {
        if (state) {
            if (state === 'NJ' || state === 'PA' || state === 'NY' || state === 'ME' || state === 'RI' || state === 'FL') return false;
        }
        return true;
    }

  return (
    <Grid container>
      {state && (
          <Grid item style={{ marginBottom: "5px" }}>
              <Typography
                  style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontStyle: "italic",
                  }}
              >
                  State Radon Information
              </Typography>

              <Typography
                  style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      marginTop: 10,
                      marginBottom: 10,
                  }}
              >
                  {state_full_name.toUpperCase()}
              </Typography>

              {state === 'NJ' && (
                  <Typography style={{ fontSize: "11px" }}>
                      This notice is provided to you by a business certified by the New Jersey Department of Environmental
                      Protection to perform radon measurements. N.J.S.A. 26:2D-73 requires that no person shall disclose to any
                      individual, except the Department of Environmental Protection or the Department of Health the address or
                      owner of a nonpublic building that the person has tested or treated for the presence of radon, unless the owner
                      of the building waives, in writing, this right of confidentiality. In the case of a prospective sale of a building which
                      has been tested for radon, the seller shall provide the buyer, at the time the contract of sale is entered into, with
                      a copy of the results of that test and evidence of any subsequent mitigation or treatment, and any prospective
                      buyer who contracts for the testing shall have the right to receive the results of that testing.
                  </Typography>
              )}

              {reportDetails.ctrl_state_detail.state === 'PA' && (
                  <Typography style={{ fontSize: "11px" }}>
                      Radon is the second leading cause of lung cancer, after smoking.
                      The U.S. Environmental Protection Agency (EPA) and the Surgeon General strongly recommend taking further action when the home's radon test results are 4.0 pCi/L or greater.
                      The national average indoor radon level is about 1.3 pCi/L.
                      The higher the home's radon level the greater the health risk to you and your family.
                      Reducing your radon levels can be done easily, effectively, and fairly inexpensively.
                      Even homes with very high radon levels can be reduced below 4.0 pCi/L.
                      For further information about reducing elevated radon levels please refer to the "Pennsylvania's Consumer's Guide to Radon Reduction."
                      THE RADON CERTIFICATION ACT REQUIRES THAT ANYONE WHO PERFORMS RADON TESTING, MITIGATION, OR LABORATORY ANALYSIS ACTIVITIES MUST BE CERTIFIED BY THE PENNSYLVANIA DEPARTMENT OF ENVIRONMENTAL PROTECTION.
                      ANY PERSON PROVIDING THESE RADON SERVICES SHALL PRESENT TO THE CLIENT A CURRENT DEP-ISSUED PHOTO IDENTIFICATION CARD UPON REQUEST.
                      IF YOU HAVE QUESTIONS, YOU MAY CONTACT DEP AT THE BUREAU OF RADIATION PROTECTION, DEPARTMENT OF ENVIRONMENTAL PROTECTION, P.O. BOX 8469, HARRISBURG, PA., 17105 8469, (717) 783 3594
                  </Typography>
              )}

              {state === 'NY' && (
                  <Typography style={{ fontSize: "11px" }}>
                      Anyone who analyzes and provides the results of those tests must hold New York State Department of Health Environmental Laboratory Approval Program certification.
                      ELAP certification ensures that the results obtained from an individual or firm are accurate and reliable.
                      This means that, while charcoal canisters, alpha track detectors and/or continuous radon monitors may be deployed by radon testers certified by AARST, NRSB, NJ, or PA, the actual test must be analyzed and the results reported by an ELAP-certified lab.
                  </Typography>
              )}

              {state === 'ME' && (
                  <Typography style={{ fontSize: "11px" }}>
                      As per 22 MRSA, Sec. 771, results of this test will be reported to the Maine Dept. of Health and Human Services.
                      Any questions, comments, or complaints concerning individuals or firms providing radon related services in Maine should be directed to:
                      Radiation Control Program 11 State House Station Augusta, ME 04333-0010  Maine Dept. of Health contact: 207-287-5743
                  </Typography>
              )}

              {state === 'RI' && (
                  <Typography style={{ fontSize: "11px" }}>
                      This notice is provided to you by an organization or individual licenses and/or certified by the Rhode Island Dept. of Health to perform radon measurements.
                      Any questions, comments, or complaints regarding the person performing these measurements may be directed to the RI Dept. of Health,
                      Radon Control Program, 3 Capitol Hill Room 206, Providence RI 02908-5097  Rhode Island Dept. of Health contact: 401-222-7796
                  </Typography>
              )}

              {state === 'FL' && (
                  <Typography style={{ fontSize: "11px" }}>
                      An organization or individual certified by the Florida Dept. of Health to perform radon or radon progeny measurements or radon mitigation services provides this Notice to you.
                      Any questions, comments, or complaints regarding the persons performing these measurement or mitigation services may be directed to the Florida Dept. of Health,
                      Bureau of Facility Programs, Radon Indoor Air Quality, 4052 Bald Cypress Way, Bin #A08, Tallahassee, Florida 32399-1710.  Florida Dept. of Health contact: 800-543-8279
                  </Typography>
              )}

              {needContact() && (
                  <Grid container spacing={2} direction="column">
                      <Grid item>
                          <Typography>
                              More information about radon is available by contacting the Department of Health at:
                          </Typography>
                      </Grid>
                      <Grid item container style={{ marginBottom: "10px" }}>
                          <Grid item xs={4}>
                              <Typography
                                  style={{ fontSize: "12px" }}
                              >{`• Phone: ${phone}`}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                              <Typography
                                  style={{ fontSize: "12px" }}
                              >{`• Website: ${url}`}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                              <Typography
                                  style={{ fontSize: "12px" }}
                              >{`• Email: ${email}`}</Typography>
                          </Grid>
                      </Grid>
                      <Grid item style={{ marginBottom: "20px" }}>
                          <Typography style={{ fontSize: "12px" }}>
                              Radon levels in a home can be influenced by many factors including
                              weather, season, living conditions, and occupancy patterns. Temporary
                              conditions observed during the testing period may cause the test to
                              not reflect the client’s risk from radon. The levels stated for this
                              time period had the following situations present.
                          </Typography>
                      </Grid>
                  </Grid>
              )}
          </Grid>
      )}

      <Grid container>
          <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography
                  style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontStyle: "italic",
                  }}
              >
                  Report Reviewed and Certified by:
              </Typography>
          </Grid>

          <Grid item xs={4} style={{ marginTop: 18 }}>
              <Typography
                  style={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "13px",
                      }}
              >
                  Ecosense, Inc. <br />
                  Laboratory <br />
              </Typography>
              <Typography
                  style={{
                      marginLeft: 10,
                      fontWeight: 300,
                      color: "black",
                      fontSize: "12px",
                  }}
              >
                  NRPP ID: 113936-AL <br />
                  NYSDOH ELAP ID: 12177 <br />
              </Typography>
              <Typography
                  style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "13px",
                  }}
              >
                  Business <br />
              </Typography>
              <Typography
                  style={{
                      marginLeft: 10,
                      fontWeight: 300,
                      color: "black",
                      fontSize: "12px",
                  }}
              >
                  NJ License: MEB95797 <br />
                  IL License: RNI20232169
              </Typography>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 18 }}>
              <img
                  alt="dallas-sign"
                  src={dallas_sign}
                  style={{
                      height: "30px",
                      backgroundColor: "transparent",
                  }}
              />
              <hr
                  style={{
                      marginTop: "0px",
                      marginRight: "40px",
                      backgroundColor: "black",
                      border: "none",
                      height: 0.5,
                  }}
              />
              <Typography
                  style={{
                      fontWeight: 300,
                      color: "black",
                      fontSize: "12px",
                  }}
              >
                  QA Officer <br />
                  Dallas Jones <br />
                  NRPP ID 114061-RMP
              </Typography>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 18 }}>
              <img
                  alt="james-sign"
                  src={james_sign}
                  style={{
                      width: "82px",
                      height: "30px",
                      backgroundColor: "transparent",
                  }}
              />
              <hr
                  style={{
                      marginTop: "0px",
                      marginRight: "40px",
                      backgroundColor: "black",
                      border: "none",
                      height: 0.5,
                  }}
              />
              <Typography
                  style={{
                      fontWeight: 300,
                      color: "black",
                      fontSize: "12px",
                  }}
              >
                  Technical Director <br />
                  James Lee <br />
                  NRPP ID 114033-RMP
              </Typography>
          </Grid>
      </Grid>
      {needContact() && <Space height={"100px"} />}
    </Grid>
  );
};
export default Details;
