import { Auth } from "aws-amplify";
import { apiEndPoint, awsXApiKey } from "../../../src/env";
import { CRITICAL_ACTION_TIMEOUT } from "../../constants/values";
import { AuthUtil } from "../../views/auth/AuthUtil";

// AWS.config.region = 'us-west-2';
// const apig = new AWS.APIGateway({apiVersion: '2015/07/09'});

// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-api-gateway/index.html
// import { APIGatewayClient, CreateApiKeyCommand } from "@aws-sdk/client-api-gateway";
// const client = new APIGatewayClient({ region: "us-west-2" });

const headers = {
  "Content-Type": "application/json",
  "x-api-key": awsXApiKey,
};

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    Auth.signIn(credentials.email, credentials.password)
      .then((response) => {
        if (response.username) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUpAsMitigator(credentials) {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: credentials.email,
      password: credentials.password,
      attributes: {
        name: credentials.name,
        "custom:group": "mitigator",
      },
    })
      .then((response) => {
        if (response.user.username) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePassword(credentials) {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          credentials.currentPassword,
          credentials.newPassword
        );
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserAttribute(key, value) {
  const obj = {};
  obj[key] = value;
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, obj);
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserAttributes(keys, values) {
  const obj = {};
  for(let i=0; i<keys.length; i++) {
    obj[keys[i]] = values[i];
  }
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, obj);
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resendSignUp(email) {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyCode(code) {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(code.email, code.passcode, {
      forceAliasCreation: true,
    })
      .then((response) => {
        if (response === "SUCCESS") {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(email, passcode, password) {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, passcode, password)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
  Direct call using AWS SDK
  git: https://www.npmjs.com/package/aws-api-gateway-client
  aws: https://docs.aws.amazon.com/apigateway/latest/developerguide/how-to-generate-sdk-javascript.html
  check CORS: curl -v -X OPTIONS https://api.cloud.ecosense.io/api/v1/mitigator
  CORS: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
 */
export async function createCompanyInfo2(info) {
  const apigClientFactory = require("aws-api-gateway-client").default;
  var apigClient = apigClientFactory.newClient({
    invokeUrl: apiEndPoint,
    apiKey: awsXApiKey,
    region: "us-west-2",
  });

  var pathParams = {};
  var pathTemplate = "/mitigator";
  var method = "POST";
  var additionalParams = {
    headers: headers,
    method: "POST",
    //mode: 'no-cors',
    credentials: "include",
    queryParams: {},
  };

  const body = {
    email: info.email,
    name: info.name,
    companyName: info.companyName,
    phoneNumber: info.phoneNumber,
    address: info.address,
    city: info.city,
    state: info.state,
    zipCode: info.zipCode,
  };

  return new Promise((resolve, reject) => {
    apigClient
      .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then(function(response) {
        alert("success!");
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function(error) {
        alert("error!" + error);
        reject(error);
      });
  });
}

export async function createCompanyInfo(info) {
  const data = {
    email: info.email,
    name: info.name,
    companyName: info.companyName,
    phoneNumber: info.phoneNumber,
    address: info.address,
    city: info.city,
    state: info.state,
    zipcode: info.zipcode,
  };

  const POST_options = {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(data),
  };

  const options = POST_options;

  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/mitigator", options)
      .then((response) => {
        //alert('Success!' + JSON.stringify(response));
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        alert("Fail!" + JSON.stringify(error));
        console.log("response:" + JSON.stringify(error));
        reject(error);
      });
  });
}

export async function updateCompanyInfo(info) {
  const data = {
    email: info.email,
    mitigator_info: {
      companyName: info.companyName,
      phoneNumber: info.phoneNumber,
      address: {
        detail: info.detail,
        street: info.address,
        city: info.city,
        state: info.state,
        zipcode: info.zipcode,
      },
      companyEmail: info.companyEmail,
    },
  };

  const PUT_options = {
    method: "PUT",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(data),
  };
  // const options = GET_options;
  const options = PUT_options;

  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/mitigator", options)
      .then((response) => {
        //alert('Success!' + JSON.stringify(response));
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        alert("Fail!" + JSON.stringify(error));
        console.log("response:" + JSON.stringify(error));
        reject(error);
      });
  });
}

export async function getCompanyInfo(email) {
  console.log("[authService] getCompanyInfo is called:" + email);
  const controller = new AbortController();
  const GET_options = {
    method: "GET",
    headers: headers,
    credentials: "include",
  };

  const options = GET_options;
  const timeoutId = setTimeout(
    () => controller.abort(),
    CRITICAL_ACTION_TIMEOUT
  );
  return await fetch(
    apiEndPoint + "/mitigator" + "?email=" + email,
    options
  ).then((res) => {
    return res.text().then((text) => {
      const data = text && JSON.parse(text);
      return data;
    });
  });
}

export async function getUserPhoto(email) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    signal: controller.signal,
    credentials: "include",
  };

  try {
    const request = apiEndPoint + "/image?email=" + email;
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    const response = await fetch(request, options);
    if (response.status == 404) {
      return null;
    } else if (response.status >= 400 && response.status < 600) {
      throw "[Settings] getUserPhoto API failed. email: " + email;
    }
    const json = await response.json();
    if (!json || json.message) {
      return null;
    }
    return json.url;
  } catch (e) {
    console.log("ERROR getUserPhoto response " + JSON.stringify(e), true);
  }
}

export async function updateUserPhoto(request) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    signal: controller.signal,
    body: JSON.stringify(request),
  };

  try {
    let requestUrl = apiEndPoint + "/image";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let response = await fetch(requestUrl, options);
    if (response.status >= 400 && response.status < 600) {
      throw (
        "[Settings] updateUserPhoto API failed. email: " +
        request.email +
        ", file_name: " +
        request.file_name +
        ", image: " +
        request.image
      );
    }
    return response;
  } catch (e) {
    throw "[Settings] updateUserPhoto API failed. error: " + e;
  }
}

export async function getCompanyLogo(email) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    signal: controller.signal,
    credentials: "include",
  };

  try {
    const request = apiEndPoint + "/company/logo?email=" + email;
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    const response = await fetch(request, options);
    if (response.status == 404) {
      return null;
    } else if (response.status >= 400 && response.status < 600) {
      throw "[Settings] getUserPhoto API failed. email: " + email;
    }
    const json = await response.json();
    if (json.resultcode !== 200) {
      return null;
    }

    return json.data.company_logo_url;
  } catch (e) {
    console.log("ERROR getUserPhoto response " + JSON.stringify(e), true);
  }
}

export async function updateCompanyLogo(request) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    signal: controller.signal,
    body: JSON.stringify(request),
  };

  try {
    let requestUrl = apiEndPoint + "/company/logo";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let response = await fetch(requestUrl, options);
    if (response.status >= 400 && response.status < 600) {
      throw (
        "[Settings] updateCompanyLogo API failed. email: " +
        request.email +
        ", file_name: " +
        request.file_name +
        ", image: " +
        request.image
      );
    }
    return response;
  } catch (e) {
    throw "[Settings] updateCompanyLogo API failed. error: " + e;
  }
}
