import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const NoDevicesErrorDialog = ({
  openNoDataMsg,
  handleNoDataClose,
  handleClose,
}) => {
  return (
    <Dialog
      titleStyle={{ textAlign: "center" }}
      maxWidth="md"
      maxHeight={"sm"}
      open={openNoDataMsg}
      onClose={handleNoDataClose}
      aria-labelledby="alert-dialog-title"
    >
      <div className="s5"></div>
      <DialogTitle id="alert-dialog-title">Information Message</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          There is no devices to generate reports
        </DialogContentText>
      </DialogContent>
      <div className="s10"></div>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button
          style={{
            background: "#E0E0E0",
            color: "#000000",
            width: "100px",
            height: "36px",
          }}
          onClick={handleClose}
        >
          OK
        </Button>
      </DialogActions>
      <div className="s10"></div>
    </Dialog>
  );
};

export default NoDevicesErrorDialog;
