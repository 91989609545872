import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";

import {Helmet} from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Box,
  Paper as MuiPaper,
} from "@material-ui/core";

import {spacing, display} from "@material-ui/system";
import {useDispatch} from "react-redux";
import {getSubscriptionDetailAction} from "../../models/actions/subscriptionActions";
import _ from "lodash";
import {format} from "date-fns";
import Doc from './SubscriptionDocService';
import PdfContainer from '../../common/pdf/PdfContainer';
import {validEmailRegex, validZipcodeRegex} from "../../utils/AuthValidation";
import {Formik} from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Elements} from "@stripe/react-stripe-js";
import confirmationLogo from "../../assets/img/confirmation.png";

import {loadStripe} from "@stripe/stripe-js";
import CheckoutFormForSubscription from "./CheckoutFormForSubscription";
import {Alert as MuiAlert} from "@material-ui/lab";
import {monthlySubStatusProperties, orderStatusProperties} from "../../constants/status";
import Loading from "../components/Loading";
import {stripeKey} from '../../env';
// const stripePromise = loadStripe("pk_test_51IZMIZI37b7asQ3bVAMOHfxBlFPhUxoBPwlAk4wIsTwpJJO8cAvhsJsA3vq5gYjbWOIJlIxmLmb5dwAXoVteHkRg007UDFsqvQ");
const stripePromise = loadStripe(stripeKey)
const Alert = styled(MuiAlert)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

let originHTML;
const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Chip = styled(MuiChip)`
  ${spacing};
  width: ${(props) => '100px'};
  background: ${(props) => monthlySubStatusProperties[props.status].color};
  color: ${(props) => props.theme.palette.common.white};
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function SubscriptionDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  if (!location || !location.search) {
    history.push('/auth/sign-in');
  } else if (location.search.indexOf('?') === 0) {
    if (!localStorage.getItem('0xaccountId')) {
      history.push({
        pathname: '/auth/sign-in',
        invoiceNo: location.search.substr(1),
      })
    }
  }

  const name = localStorage.getItem('0xuserId');
  const invoiceNo = location.search.replace('?', '');
  const [subscription, setSubscription] = useState({
    address: {
      street: '',
      detail: '',
      city: '',
      state: '',
      zipcode: '',
    },
    paymentTo: {
      street: '',
      detail: '',
      city: '',
      state: '',
      zipcode: '',
    },
  });
  const [createdDate, setCreatedDate] = useState('');
  const [devices, setDevices] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = React.useState(false);
  const [openCardForm, setOpenCardForm] = React.useState(false);
  const [openInvoiceConfirmation, setOpenInvoiceConfirmation] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  location.state = {
    email: localStorage.getItem('0xaccountId'),
    name: localStorage.getItem('0xuserId')
  }

  const getSubscriptionDetail = async () => {
    let res = await dispatch(
      getSubscriptionDetailAction(invoiceNo)
    );
    if (res && (!res.data || (res.data && res.data.length === 0))) res.data = [];
    else if (!_.isEqual(subscription, res.data[0])) {
      res.data[0].invoiceNo = invoiceNo;
      setSubscription(res.data[0]);
      setCreatedDate(format(new Date(res.data[0].created), "MMMM dd, yyyy"));
      const devices = res.data[0].devices.map((device) =>
        <TableRow>
          <TableCell>{device.description ? device.description[0].toUpperCase() + device.description.substring(1) : ''}</TableCell>
          <TableCell component="th" scope="row">
            {device.serialNumber}
          </TableCell>
          <TableCell>{device.clientName ? device.clientName : 'N/A' }</TableCell>
          <TableCell>{device.servicePeriod}</TableCell>
          <TableCell align="right">{toFixed(device.unitPrice)}</TableCell>
        </TableRow>
      );
      setDevices(devices);
    }
  };

  // Credit Card
  ////////////////////////////////////////////////////////////////////////////////
  const [payload, setPayload] = useState();
  const [checked, setChecked] = React.useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  const refreshPopup = () => {
    setRefresh(!refresh);
  }

  const validateBillingInformation = (values) => {
    const errors = {};

    if (!values.bill_firstname) {
      errors.bill_firstname = 'Required';
    }

    if (!values.bill_lastname) {
      errors.bill_lastname = 'Required';
    }

    if (!values.bill_email) {
      errors.bill_email = 'Required';
    } else if (!validEmailRegex.test(values.bill_email)) {
      errors.bill_email = 'Please enter a valid email address.';
    }

    if (!values.bill_phone) {
      errors.bill_phone = 'Required';
    }

    if (!values.bill_street) {
      errors.bill_street = 'Required';
    }

    if (!values.bill_city) {
      errors.bill_city = 'Required';
    }

    if (values.bill_state == 'Select') {
      errors.bill_state = 'Required';
    }

    if (!values.bill_zipcode) {
      errors.bill_zipcode = 'Required';
    } else if (!validZipcodeRegex.test(values.bill_zipcode)) {
      errors.bill_zipcode = 'Incorrect Zipcode.';
    }

    return errors;
  };

  const handleOpenCardForm = async (values) => {
    setOpenCardForm(true);
  };

  const handleCloseCardForm = () => {
    setOpenCardForm(false);
  };

  const handleCloseInvoiceConfirmation = () => {
    setOpenInvoiceConfirmation(false);
    refreshSubscriptionPage();
  };

  const refreshSubscriptionPage = () => {
    history.go(0);
  };

  const showSubscriptionConfirmation = (orderId, payload) => {
    setPayload(payload);
    setOpenCardForm(false);
    setOpenInvoiceConfirmation(true);
  };

  const onChangeBillStateValue = (values) => (event) => {
    values.bill_state = event.target.value;
    validateBillingInformation(values);
    refreshPopup();
  };

  const toFixed = (number) => {
    if (number ===0 ) {
      return '$0.00';
    } else if (number) {
      return '$' + number.toFixed(2);
    }
    return '';
  }
  useEffect(() => {
    getSubscriptionDetail();
  }, []);

  const createPdf = (html) => {
    Doc.createPdf(html, invoiceNo);
  }

  originHTML = (
    <React.Fragment>
      <Helmet title="Invoice Details"/>

      <Typography variant="h3" gutterBottom display="inline">
        Invoice #{subscription.invoiceNo}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/subscriptions">
          Subscriptions
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      {
        !subscription.invoiceNo ? (
          <Loading></Loading>
        ) : (
          <>
            <Divider my={6}/>
            <PdfContainer createPdf={createPdf} handleOpenCardForm={handleOpenCardForm} isSubsription={true} isPaidStatus={(subscription.status && monthlySubStatusProperties[subscription.status].msg === 'Paid') ? true : false}>
              <React.Fragment>
                <Grid container justify="center">
                  <Grid item xs={12} lg={10}>
                    <Shadow>
                      <Card px={6} pt={6}>
                        <CardContent>
                          <Grid container spacing={6}>
                            <Grid item xs={12}>
                              <Typography variant="body2" gutterBottom>
                                {
                                  name ? (
                                    <span>Hello {name},</span>
                                  ) : (
                                    <span>Hello,</span>
                                  )
                                }
                                <br/>
                                <br/>
                                This is an invoice for a monthly subscription fee of {toFixed(subscription.totalDue)} (USD)
                                on {subscription.product}.
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="caption">Invoice No.</Typography>
                              <Typography variant="body2">{subscription.invoiceNo}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {/*<Typography variant="caption" align="right" display="block">*/}
                              {/*  {subscription.status != undefined && subscription.status!=0 && <Chip*/}
                              {/*    size="small"*/}
                              {/*    mr={1}*/}
                              {/*    mb={1}*/}
                              {/*    label={monthlySubStatusProperties[subscription.status].msg}*/}
                              {/*    status={subscription.status}*/}
                              {/*  />}*/}
                              {/*</Typography>*/}
                              <Typography variant="body2" align="right">
                                {createdDate}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="caption">Billing information</Typography>
                              <Typography variant="body2">
                                {subscription.name}
                                <br/>
                                {subscription.address.street}
                                <br/>
                                {subscription.address.city}
                                <br/>
                                {subscription.address.state}
                                <br/>
                                {subscription.address.zipcode}
                                <br/>
                                <Link href={"mailto:" + subscription.email}>{subscription.email}</Link>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="caption" align="right" display="block">
                                Payment To
                              </Typography>
                              <Typography variant="body2" align="right">
                                Econsense Inc.
                                <br/>
                                {subscription.paymentTo.street}
                                <br/>
                                {subscription.paymentTo.city}
                                <br/>
                                {subscription.paymentTo.state}
                                <br/>
                                {subscription.paymentTo.zipcode}
                                <br/>
                                <Link href="mailto:support@ecosense.io">support@ecosense.io</Link>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Description</TableCell>
                              <TableCell>Serial Number</TableCell>
                              <TableCell>Client Name</TableCell>
                              <TableCell>Service period (Days)</TableCell>
                              <TableCell align="right">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {devices}
                            <TableRow>
                              <TableCell/>
                              <TableCell/>
                              <TableCell/>
                              <TableCell>Subtotal</TableCell>
                              <TableCell align="right">{toFixed(subscription.subtotal)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell/>
                              <TableCell/>
                              <TableCell/>
                              <TableCell>Total</TableCell>
                              <TableCell align="right">{toFixed(subscription.totalDue)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Card>
                    </Shadow>
                  </Grid>
                </Grid>
              </React.Fragment>
            </PdfContainer>
            <Grid item>
              <div>
                <Dialog
                  fullWidth={fullWidth}
                  titleStyle={{textAlign: "center"}}
                  maxWidth="sm"
                  open={openCardForm}
                  onClose={handleCloseCardForm}
                  aria-labelledby="max-width-dialog-title"
                  disableBackdropClick="true"
                >
                  <div className="s20"></div>
                  <DialogTitle id="max-width-dialog-title">
                    <Typography variant="h5" align="center">
                      EcoGuard Subscription</Typography>
                  </DialogTitle>
                  <DialogContent>
                    {
                      !loadingInvoice ?
                        <Formik
                          initialValues={{
                            email: location.state.email,
                            name: location.state.name,
                            bill_firstname: "",
                            bill_lastname: "",
                            bill_email: "",
                            bill_phone: "",
                            bill_street: "",
                            bill_detail: "",
                            bill_city: "",
                            bill_state: "Select",
                            bill_zipcode: "",
                            bill_country: "US",
                            submit: false,
                          }}
                          // initialValues={{
                          //   email: location.state.email,
                          //   name: location.state.name,
                          //   bill_firstname: "Kevin",
                          //   bill_lastname: "Jung",
                          //   bill_email: "seungjae.jung@ecosense.io",
                          //   bill_phone: "425-979-8090",
                          //   bill_street: "419 Pocono Manor Place",
                          //   bill_detail: "",
                          //   bill_city: "San Ramon",
                          //   bill_state: "CA",
                          //   bill_zipcode: "94583",
                          //   bill_country: "US",
                          //   submit: false,
                          // }}
                          validate={validateBillingInformation}
                          onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                          }}
                        >
                          {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values,
                            }) => (
                            <form noValidate onSubmit={handleSubmit}>
                              {errors.submit && (
                                <Alert mt={2} mb={1} severity="warning">
                                  {errors.submit}
                                </Alert>
                              )}
                              <div className="s20"></div>
                              <Typography variant="h7" gutterBottom>
                                <b>Invoice #{invoiceNo}</b>
                              </Typography>
                              <Table options={{rowStyle: {height: 5}}}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Activated devices</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Subscription Fee
                                    </TableCell>
                                    <TableCell align="left">{devices.length}</TableCell>
                                    <TableCell align="right">{toFixed(subscription.totalDue)}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              <div className="s20"></div>
                              <div className={'show'}>
                                <div className="s10"></div>
                                <Typography variant="h7" gutterBottom>
                                  <b>Billing information</b>
                                </Typography>
                                {/*<div className="s10"></div>*/}
                                <Grid container spacing={3}>
                                  <Grid item md={6}>
                                    <TextField
                                      name="bill_firstname"
                                      label="First name"
                                      value={values.bill_firstname}
                                      fullWidth
                                      error={Boolean(touched.bill_firstname && errors.bill_firstname)}
                                      helperText={touched.bill_firstname && errors.bill_firstname}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={6}>
                                    <TextField
                                      name="bill_lastname"
                                      label="Last name"
                                      value={values.bill_lastname}
                                      error={Boolean(touched.bill_lastname && errors.bill_lastname)}
                                      fullWidth
                                      helperText={touched.bill_lastname && errors.bill_lastname}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item md={6}>
                                    <TextField
                                      name="bill_email"
                                      label="Email"
                                      value={values.bill_email}
                                      fullWidth
                                      error={Boolean(touched.bill_email && errors.bill_email)}
                                      helperText={touched.bill_email && errors.bill_email}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={6}>
                                    <TextField
                                      name="bill_phone"
                                      label="Phone"
                                      value={values.bill_phone}
                                      error={Boolean(touched.bill_phone && errors.bill_phone)}
                                      fullWidth
                                      helperText={touched.bill_phone && errors.bill_phone}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item md={12}>
                                    <TextField
                                      name="bill_street"
                                      label="Address 1"
                                      value={values.bill_street}
                                      error={Boolean(touched.bill_street && errors.bill_street)}
                                      fullWidth
                                      helperText={touched.bill_street && errors.bill_street}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item md={12}>
                                    <TextField
                                      name="bill_detail"
                                      label="Address 2"
                                      value={values.bill_detail}
                                      error={Boolean(touched.bill_detail && errors.bill_detail)}
                                      fullWidth
                                      helperText={touched.bill_detail && errors.bill_detail}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item md={6}>
                                    <TextField
                                      name="bill_city"
                                      label="City"
                                      value={values.bill_city}
                                      error={Boolean(touched.bill_city && errors.bill_city)}
                                      fullWidth
                                      helperText={touched.bill_city && errors.bill_city}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={3}>
                                    <FormControl
                                      my={2}
                                      fullWidth
                                      sx={{ m: 1, minWidth: 120 }}
                                      helperText={touched.bill_state && errors.bill_state}
                                      error={Boolean(touched.bill_state && errors.bill_state)}>
                                      <InputLabel id="ship-state-select-label">State</InputLabel>
                                      <Select
                                        labelId="ship-state-select-label"
                                        id="ship-state-select"
                                        value={values.bill_state}
                                        label="State"
                                        onBlur={handleBlur}
                                        onChange={onChangeBillStateValue(values)}
                                        MenuProps={{
                                          style: {
                                            maxHeight: 220,
                                          },
                                        }}
                                      >
                                        <MenuItem value="Select">Select</MenuItem>
                                        <MenuItem value="AL">AL</MenuItem>
                                        <MenuItem value="AK">AK</MenuItem>
                                        <MenuItem value="AZ">AZ</MenuItem>
                                        <MenuItem value="AR">AR</MenuItem>
                                        <MenuItem value="CA">CA</MenuItem>
                                        <MenuItem value="CO">CO</MenuItem>
                                        <MenuItem value="CT">CT</MenuItem>
                                        <MenuItem value="DE">DE</MenuItem>
                                        <MenuItem value="FL">FL</MenuItem>
                                        <MenuItem value="GA">GA</MenuItem>
                                        <MenuItem value="HI">HI</MenuItem>
                                        <MenuItem value="ID">ID</MenuItem>
                                        <MenuItem value="IL">IL</MenuItem>
                                        <MenuItem value="IN">IN</MenuItem>
                                        <MenuItem value="IA">IA</MenuItem>
                                        <MenuItem value="KS">KS</MenuItem>
                                        <MenuItem value="KY">KY</MenuItem>
                                        <MenuItem value="LA">LA</MenuItem>
                                        <MenuItem value="ME">ME</MenuItem>
                                        <MenuItem value="MD">MD</MenuItem>
                                        <MenuItem value="MA">MA</MenuItem>
                                        <MenuItem value="MI">MI</MenuItem>
                                        <MenuItem value="MN">MN</MenuItem>
                                        <MenuItem value="MS">MS</MenuItem>
                                        <MenuItem value="MO">MO</MenuItem>
                                        <MenuItem value="MT">MT</MenuItem>
                                        <MenuItem value="NE">NE</MenuItem>
                                        <MenuItem value="NV">NV</MenuItem>
                                        <MenuItem value="NH">NH</MenuItem>
                                        <MenuItem value="NJ">NJ</MenuItem>
                                        <MenuItem value="NM">NM</MenuItem>
                                        <MenuItem value="NY">NY</MenuItem>
                                        <MenuItem value="NC">NC</MenuItem>
                                        <MenuItem value="ND">ND</MenuItem>
                                        <MenuItem value="OH">OH</MenuItem>
                                        <MenuItem value="OK">OK</MenuItem>
                                        <MenuItem value="OR">OR</MenuItem>
                                        <MenuItem value="PA">PA</MenuItem>
                                        <MenuItem value="RI">RI</MenuItem>
                                        <MenuItem value="SC">SC</MenuItem>
                                        <MenuItem value="SD">SD</MenuItem>
                                        <MenuItem value="TN">TN</MenuItem>
                                        <MenuItem value="TX">TX</MenuItem>
                                        <MenuItem value="UT">UT</MenuItem>
                                        <MenuItem value="VT">VT</MenuItem>
                                        <MenuItem value="VA">VA</MenuItem>
                                        <MenuItem value="WA">WA</MenuItem>
                                        <MenuItem value="WV">WV</MenuItem>
                                        <MenuItem value="WI">WI</MenuItem>
                                        <MenuItem value="WY">WY</MenuItem>
                                      </Select>
                                      {
                                        errors.bill_state && (
                                          <FormHelperText>Required</FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={3}>
                                    <TextField
                                      type="number"
                                      name="bill_zipcode"
                                      label="Zipcode"
                                      value={values.bill_zipcode}
                                      error={Boolean(touched.bill_zipcode && errors.bill_zipcode)}
                                      fullWidth
                                      helperText={touched.bill_zipcode && errors.bill_zipcode}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="s30"></div>
                              <Elements stripe={stripePromise}>
                                <CheckoutFormForSubscription
                                  subscription={subscription}
                                  values={values}
                                  showSubscriptionConfirmation={showSubscriptionConfirmation}
                                  validateBillingInformation={validateBillingInformation}
                                  refreshPopup={refreshPopup}/>
                              </Elements>
                            </form>
                          )}
                        </Formik>
                        :
                        <div className='get-order-detail-loading'>
                          <CircularProgress size={20}/>
                        </div>
                    }
                  </DialogContent>
                  <div className="s10"></div>
                  <DialogActions>
                    <Button onClick={handleCloseCardForm} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullWidth={fullWidth}
                  titleStyle={{textAlign: "center"}}
                  maxWidth="sm"
                  open={openInvoiceConfirmation}
                  onClose={handleCloseInvoiceConfirmation}
                  aria-labelledby="max-width-dialog-title"
                >
                  <Wrapper>
                    <div className="s10"></div>
                    <div align='center'>
                      <img src={confirmationLogo} style={{width: '70px', height: '70px'}}/>

                      <div className="s20"></div>
                      <DialogTitle id="max-width-dialog-title">
                        <Typography variant="h4" align="center">Thank you for your order!</Typography>
                      </DialogTitle>
                      <div className="s20"></div>
                      <span className='order-id-text'>
                            Invoice No.: {invoiceNo}
                          </span>
                      <div className="s20"></div>
                      <DialogContent>
                        <DialogContentText>
                          Thanks for paying your subscription.<br/>
                          If you have any questions, please contact us at
                          support@ecosense.io<br/>
                        </DialogContentText>
                        <div className="s40"></div>
                        <div align="center">
                            <span className='learn-more-text'>
                              Learn more about EcoQube? <a className='learn-more-link' href='https://ecosense.io/' target='_blank'>Click here</a>
                            </span>
                        </div>
                      </DialogContent>
                      <div className="s20"></div>
                      <DialogActions>
                        <Box textAlign='center'>
                          {/*<Button variant='contained'>*/}
                          {/*  My button*/}
                          {/*</Button>*/}
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleCloseInvoiceConfirmation}
                            classes={{root: 'button', label: 'button-label'}}
                          >
                            Close
                          </Button>
                        </Box>

                      </DialogActions>
                    </div>
                    {/*<div className="s20"></div>*/}
                  </Wrapper>
                </Dialog>
              </div>
            </Grid>
          </>
        )
      }
    </React.Fragment>
  );

  return originHTML;
}

export default SubscriptionDetails;
