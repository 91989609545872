import React, {useState} from "react";
import { useDispatch } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {forgotPassword, resendSignUp, resetPassword} from "../../models/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  FormControl as MuiFormControl, Grid, CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validNameRegex, validPwdRegex} from "../../utils/AuthValidation";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";

const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;


function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [passcode, setPasscode] = useState("");

  const [pwdValues, setPwdValues] = React.useState({
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false
  });

  const handlePasscodeChange = (event) => {
    const value = event.target.value;
    setPasscode(value);
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
  };

  const handleClickShowNewPassword = () => {
    setPwdValues({ ...pwdValues, showNewPassword: !pwdValues.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setPwdValues({ ...pwdValues, showConfirmPassword: !pwdValues.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!passcode) {
      errors.passcode = 'Required';
    } else if (passcode.length !== 6 || isNaN(passcode)) {
      errors.passcode =  'Passcode must be 6 digits';
    }

    if (!pwdValues.newPassword) {
      errors.newPassword = 'Required';
    } else if (!validPwdRegex.test(pwdValues.newPassword)) {
      errors.newPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    }

    if (!pwdValues.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (!validPwdRegex.test(pwdValues.confirmPassword)) {
      errors.confirmPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    } else if (!errors.newPassword && (pwdValues.newPassword !== pwdValues.confirmPassword)) {
      errors.confirmPassword = 'Your new password and confirmation password do not match.';
    }

    return errors;
  };

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <div>
        <div align="center">
          <img width={120} src="/static/img/logo/ecosense-logo3.jpg"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset your password
        </Typography>
        <div className='s20'></div>
        <Typography component="h2" variant="body1" align="center">
          We have sent a verification code to<br/>
          <b>{location.state.email}</b><br/>
          Check your spam or junk folder if you don't see it
        </Typography>
      </div>
      <Formik
        initialValues={{
          email: location.state.email,
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(
              resetPassword(values.email.trim().toLowerCase(), passcode, pwdValues.newPassword)
            );
            history.push("/auth/reset-pwd-confirm");
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === 'InvalidParameterException') {
              message = 'Trailing spaces are not allowed in your password \n or one of parameters is invalid';
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <div className="s10"></div>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              </>
            )}
            <div className="s30"></div>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              disabled
              helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="passcode"
              label="Verification code"
              value={passcode}
              error={Boolean(errors.passcode)}
              fullWidth
              helperText={errors.passcode}
              // onBlur={handleBlur}
              onChange={handlePasscodeChange}
              my={3}
            />
            <FormControl
              fullWidth
              my={2}
            >
              <InputLabel htmlFor="standard-new-password" error={(errors.newPassword) ? true : false}>New Password</InputLabel>
              <Input
                id="standard-new-password"
                autoComplete="new-password"
                aria-describedby="new-password-helper"
                type={pwdValues.showNewPassword ? "text" : "password"}
                value={pwdValues.newPassword}
                onChange={handlePwdChange("newPassword")}
                // onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {pwdValues.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.newPassword && <FormHelperText id="new-password-helper" error={true}>{errors.newPassword}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              my={2}
            >
              <InputLabel htmlFor="standard-confirm-password" error={(errors.confirmPassword) ? true : false}>Confirm New Password</InputLabel>
              <Input
                id="standard-confirm-password"
                autoComplete="new-password"
                aria-describedby="confirm-password-helper"
                type={pwdValues.showConfirmPassword ? "text" : "password"}
                value={pwdValues.confirmPassword}
                onChange={handlePwdChange("confirmPassword")}
                // onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {pwdValues.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && <FormHelperText id="confirm-password-helper" error={true}>{errors.confirmPassword}</FormHelperText>}
            </FormControl>
            <div className="s30"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              RESET YOUR PASSWORD
            </Button>
          </form>
        )}
      </Formik>
      <Formik
        initialValues={{
          email: location.state.email,
          submit: false,
        }}
        onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
          try {
            const trimmedEmail = location.state.email.trim().toLowerCase();
            await dispatch(
              forgotPassword(trimmedEmail)
            );
            history.push({
              pathname: '/auth/reset-password',
              state: {email: trimmedEmail}
            });
          } catch (error) {
            let message = error.message || "Something went wrong";
            setStatus({success: false});
            setErrors({submit: message});
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="s10"></div>
            <div align="center">
              <span className='button-left-text'>
                  Didn't receive a code?
              </span>
              <Button
                style={{paddingTop: "4px"}}
                type="submit"
                color="primary"
                classes={{label: 'link-text'}}
              >
                Resend
              </Button>
              {
                isSubmitting ?
                  <div className='resend-spinner'>
                    <CircularProgress size={13}/>
                  </div> : <></>
              }
            </div>
          </form>
        )}
      </Formik>
      <div className='s15'></div>
      {/*<div align="center">*/}
      {/*  <span className='reset-goback-text'>*/}
      {/*    Didn't receive a code? <a className='reset-login-link' onclick='return resendVerificationCode()' target='_self'>Resend</a>*/}
      {/*  </span>*/}
      {/*</div>*/}
      {/*<div align="center">*/}
      {/*    <span className='reset-goback-text'>*/}
      {/*      Go back to <a className='reset-login-link' href='/auth/sign-in' target='_self'>login</a>*/}
      {/*    </span>*/}
      {/*</div>*/}
    </Wrapper>
  );
}

export default ResetPassword;
