// Mitigation Installation
export const MITIGATION_SYSTEM = {
  INSTALLED: {
    label: "Yes",
    value: 1,
  },
  NOT_INSTALLED: {
    label: "No",
    value: 0,
  },
};

export const MITIGATION_SYSTEM_KEY = {
  0: "Not Installed",
  1: "Installed",
  undefined: "N/A",
};

// Building Type
export const BUILDING_TYPE = {
  SINGLE_FAMILY: {
    label: "Single-family home",
    value: "SF",
  },
  COMMERCIAL: {
    label: "Commercial",
    value: "C1",
  },
  EDUCATIONAL: {
    label: "Educational",
    value: "E1",
  },
  HEALTHCARE: {
    label: "Healthcare",
    value: "H1",
  },
  GOVERNMENT: {
    label: "Government",
    value: "G1",
  },
  MULTI_FAMILY: {
    label: "Multi-family home",
    value: "MF",
  },
  PUBLIC_BUILDINGS: {
    label: "Public buildings",
    value: "P1",
  },
};

export const BUILDING_TYPE_KEY = {
  SF: "Single-family home",
  C1: "Commercial",
  E1: "Educational",
  H1: "Healthcare",
  G1: "Government",
  MF: "Multi-family home",
  P1: "Public buildings",
};

// Structure Type
export const STRUCTURE_TYPE = {
  BASEMENT: {
    label: "Basement",
    value: "BS",
  },
  SLAB: {
    label: "Slab",
    value: "SL",
  },
  CRAWLSPACE: {
    label: "Crawlspace",
    value: "CR",
  },
  MULTI_STORY: {
    label: "Multi-story",
    value: "MS",
  },
};

export const STRUCTURE_TYPE_KEY = {
  BS: "Basement",
  SL: "Slab",
  CR: "Crawlspace",
  MS: "Multi-story",
};

// Test Floor
export const TEST_FLOOR = {
  BASEMENT: {
    label: "Basement",
    value: "BS",
  },
  FLOOR_1ST: {
    label: "1st Floor",
    value: "FL1",
  },
  FLOOR_2ND: {
    label: "2nd Floor",
    value: "FL2",
  },
  FLOOR_3RD_OR_ABOVE: {
    label: "3rd Floor or above",
    value: "FL3+",
  },
};

export const TEST_FLOOR_KEY = {
  BS: "Basement",
  FL1: "1st Floor",
  FL2: "2nd Floor",
  "FL3+": "3rd Floor or above",
  undefined: "N/A",
};

// Room Type
export const ROOM_TYPE = {
  LIVING_ROOM: {
    label: "Living room",
    value: "LV",
  },
  DINING_ROOM: {
    label: "Dining room",
    value: "DN",
  },
  BEDROOM: {
    label: "Bedroom",
    value: "BD",
  },
  PLAYROOM: {
    label: "Playroom",
    value: "PL",
  },
  BASEMENT: {
    label: "Basement",
    value: "BS",
  },
  OFFICE: {
    label: "Office",
    value: "OF",
  },
  CLASSROOM: {
    label: "Classroom",
    value: "CS",
  },
  OTHERS: {
    label: "Others",
    value: "OT",
  },
};

export const ROOM_TYPE_KEY = {
  LV: "Living room",
  DN: "Dining room",
  BD: "Bedroom",
  PL: "Playroom",
  BS: "Basement",
  OF: "Office",
  CS: "Classroom",
  OT: "Others",
};

export const MEASUREMENT_UNIT = {
  0: "pCi/L",
  1: "Bq/m\u00B3",
};

export const REPORT_RESULT_SU = {
  0: "EPA Guidelines",
  1: "EPA Guidelines",
};

export const REPORT_RESULT_COLOR = {
  0: "#DE7548",
  1: "#4CAF50",
};

// Monitoring mode
export const _48H_AVG = 48;
export const _24H_AVG = 24;
export const REALTIME = 1;

// RADON LEVEL RELATED
export const EPA_CONSIDER_FIXING_HOME = 2.7;
export const EPA_RECOMMEND_FIXING_HOME = 4.0;
export const EPA_CONSIDER_FIXING_HOME_BQ = 100;
export const EPA_RECOMMEND_FIXING_HOME_BQ = 150;
export const UNIT_CONVERSION_SCALE = 37;
export const NORMAL_STATUS = "normal";
export const CONSIDER_FIXING_STATUS = "consider_fixing";
export const RECOMMEND_FIXING_STATUS = "recommend_fixing";
export const ECOQUBE_PRICE = 129;

// NETWORK RELATED
export const CRITICAL_ACTION_TIMEOUT = 30000;

// EVENT RELATED
export const ACTIVE = "ACTIVATE";
export const INACTIVE = "DEACTIVATE";
export const OVER4 = "Over 4pCi/L";
export const BELOW4 = "Below 4pCi/L";
export const ON = "on";
export const OFF = "off";
export const PCI = "pCi/L";
export const BQ = "Bq/m3";
export const UNIT_EVENT = "UNIT";
export const THRESHOLD_EVENT = "THRESHOLD";
export const ALARM_EVENT = "ALARM";
export const enableAlert = false;

export const THRESHOLD_TO_SHOW = 24 * 60; // 24 hours in minutes

// Cognito attributes
export const CUSTOM_COMPANY_EMAIL = "custom:companyEmail";

export const ISS_WIFI = "Wi-Fi network connection failure";
export const ISS_OTHERS = "Others";

// states in the US
export const states = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MD: "MD",
  ME: "ME",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MS: "MS",
  MT: "MT",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
};

export const STATES_FULL_NAME = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "Wyoming",
  WY: "West Virginia",
};
