import { Grid } from "@material-ui/core";
import Details from "./Details";
import StateRadonInfo from "./StateRadonInfo";
import Footer from "../PageMain/Footer";
import Data from "./Data";
import Space from "../../../components/Space";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const PageTwo = ({ reportDetails, reportChartRadonValue }) => {
  const location = useLocation();
  const reportType = location.state.duration.type;

  return (
    <Grid
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
      container
    >
      {reportType === "48HR" && (
        <Data
          reportDetails={reportDetails}
          reportChartRadonValue={reportChartRadonValue}
        />
      )}
      <Details reportDetails={reportDetails} />
      {/*{reportDetails.ctrl_state_detail? <StateRadonInfo reportDetails={reportDetails} /> : <Space height={"200px"} />}*/}
      {/*{reportType !== "48HR" && <Space height={"430px"} />}*/}
      <Footer />
    </Grid>
  );
};
