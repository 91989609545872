import { PDFExport } from "@progress/kendo-react-pdf";
import PageMain from "./PageMain";
import { PageTwo } from "./PageTwo";
import {IconButton} from "@material-ui/core";
import {ArrowForwardIos} from "@material-ui/icons";
import React from "react";
import PageAdditional from "./Page48h/PageAdditional";
import PageRecommendations from "./Page48h/PageRecommendations";
import PageUncertainty from "./Page48h/PageUncertainty";

const ForPdf = ({
  pdfExportComponent,
  reportDetails,
  reportChartRadonValue,
  pageTwo,
  pageAdditional,
  pageRecommendations,
  pageUncertainty,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "-9999px",
        left: "-9999px"
      }}
    >
      <PDFExport paperSize="Letter" ref={pdfExportComponent}>
        <div id="pdfRoot">
          <div
            className="pdf-body"
            style={{
              height: "100%",
              padding: "25px 25px 5px",
              border: "1px solid #E6E8F0",
              backgroundColor: "white",
            }}
          >
            <PageMain
              reportDetails={reportDetails}
              reportChartRadonValue={reportChartRadonValue}
              isForPDF={true}
            />
          </div>
            {pageTwo && (
                <div
                    className="pdf-body"
                    style={{
                        height: "100%",
                        padding: "25px 25px 5px",
                        border: "1px solid #E6E8F0",
                        backgroundColor: "white",
                    }}
                >
                    <PageTwo
                        reportDetails={reportDetails}
                        reportChartRadonValue={reportChartRadonValue}
                    />
                </div>
            )}
            {pageAdditional && (
                <div
                    className="pdf-body"
                    style={{
                        height: "100%",
                        padding: "25px 25px 5px",
                        border: "1px solid #E6E8F0",
                        backgroundColor: "white",
                    }}
                >
                    <PageAdditional
                        reportDetails={reportDetails}
                    />
                </div>
            )}
            {pageRecommendations && (
                <div
                    className="pdf-body"
                    style={{
                        height: "100%",
                        padding: "25px 25px 5px",
                        border: "1px solid #E6E8F0",
                        backgroundColor: "white",
                    }}
                >
                    <PageRecommendations />
                </div>
            )}
            {pageUncertainty && (
                <div
                    className="pdf-body"
                    style={{
                        height: "100%",
                        padding: "25px 25px 5px",
                        border: "1px solid #E6E8F0",
                        backgroundColor: "white",
                    }}
                >
                    <PageUncertainty />
                </div>
            )}
        </div>
      </PDFExport>
    </div>
  );
};

export default ForPdf;
