import styled from "styled-components/macro";
import {
    Button,
    Card as MuiCard,
    CardContent as MuiCardContent,
    CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Grid, IconButton,
    Typography as MuiTypography
} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {customAlert} from "../../../utils/AlertWrapper";
import React, {useCallback, useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {labelForTitle} from "./CardLayout";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Box from "@material-ui/core/Box";
import {VisuallyHiddenInput} from "../../../utils/visuallyHiddenInput";
import FileSavedIcon from "../../../assets/img/file-saved.png";
import {formatBytes} from "../../../utils/formatBytes";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import Space from "../../../components/Space";
import DefaultCheckbox from "../../components/DefaultCheckbox";
import { useFormik } from "formik";
import {toBase64} from "../../../utils/toBase64";
import {MAX_IMAGE_SIZE} from "./DeviceDetail";
import {validEmailRegex} from "../../../utils/AuthValidation";
import SubscriptionInterface from "../../../integration/internal/SubscriptionInterface";
import {fromBase64} from "../../../utils/fromBase64";

const Typography = styled(MuiTypography)(spacing);

const AdditionInfoCard = ({data, initUnit, callback}) => {
    customAlert('test:' + initUnit);

    const [showSpinner, setShowSpinner] = useState(false);
    const [ansiAarst, setAnsiAarst] = useState(null);
    const [comments, setComments] = useState(null);
    const [values, setValues] = useState(null);
    const [changed, setChanged] = useState(false);

    const initializeValues = useCallback(async () => {
        setValues({
            envFiles: await fromBase64(data.aux_images ?? []),
            base64EnvFiles: data.aux_images ?? [],
        });
        setAnsiAarst(data["ansi-aarst"]);
        setComments(data.comments);
    }, [data]);

    // const initialValues= {
    //     formType: "standard",
    //     envFiles: [],
    //     base64EnvFiles: [],
    // };

    useEffect(() => {
        initializeValues();
    }, [initializeValues, data]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: values,
    });

    const checkChange = () => {
        setChanged(ansiAarst !== data["ansi-aarst"] || comments !== data.comments);
    };

    const onCommentsChange = (event) => {
        let value = event.target.value;
        if (!value) {
            value = '';
        }

        setComments(value);
        if (value !== data.comments) setChanged(true);
    };

    const handleDeleteFile = (e) => {
        const filteredArray = formik.values.envFiles.filter(
            (file) => file.name !== e.currentTarget.id
        );
        const filteredBase64Array = formik.values.base64EnvFiles.filter(
            (file) => file.name !== e.currentTarget.id
        );

        formik.setFieldValue("envFiles", filteredArray);
        formik.setFieldValue("base64EnvFiles", filteredBase64Array);

        setChanged(true);
    };

    const handleFileChange = (e) => {
        const filesToUpload = Array.from(e.currentTarget.files);
        const currentFiles = formik.values.envFiles;

        let isValid = [];

        filesToUpload.forEach((file) => {
            isValid.push(file.size <= MAX_IMAGE_SIZE);
        });

        if (isValid.some((bool) => bool === false)) {
            formik.setFieldError(
                "envFiles",
                "Image files cannot exceed 25MB per file"
            );
        } else if (currentFiles.length + filesToUpload.length > 2) {
            formik.setFieldError("envFiles", "Cannot upload more than 2 files");
        } else if (filesToUpload.length > 0) {
            toBase64(e.currentTarget.files, formik, "base64EnvFiles");

            formik.setFieldValue("envFiles", [
                ...formik.values.envFiles,
                ...e.currentTarget.files,
            ]);
        }

        setChanged(true);
    };

    const onAnsiAarst = (event) => {
        let eventValue = Number(event.target.checked);
        setAnsiAarst(eventValue);
        if (eventValue !== data["ansi-aarst"]) setChanged(true);
    };

    const checkAdditionalInfo = () => {
        let counter = 0;
        if (data.aux_images && data.aux_images.length > 0) counter++;
        if (data.comments && data.comments !== "") counter++;
        if (data["ansi-aarst"] && data["ansi-aarst"] === 1) counter++;

        if (counter > 0) return "("+counter+")";
        else return null;
    }

    const applyChange = async () => {
        setShowSpinner(true);
        let result = await SubscriptionInterface.changeAdditionInformation(
            localStorage.getItem("0xaccountId"),
            data.serial_number,
            formik.values.base64EnvFiles,
            ansiAarst,
            comments
        );

        if (result) {
            setChanged(false);
            callback(data.serial_number);
        } else {
            customAlert("Fail to apply changes");
        }
        setShowSpinner(false);
    };

    return (
        !data.serial_number ? (
            <CircularProgress/>
        ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Additional Information For 48-hour Clearance Radon Report
                        <Typography
                            style={{
                                color: "#3DB5E6",
                                marginLeft: 6,
                            }}
                        >
                            {checkAdditionalInfo()}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl>
                                <Typography style={labelForTitle}>Measurement Environments</Typography>
                                <Container
                                    style={{
                                        height: "80px",
                                        backgroundColor: "#f8fbff",
                                        borderRadius: "6px",
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        style={{
                                            height: "100%",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CloudUploadIcon style={{ color: "#8f95b2" }} fontSize="large" />
                                        </Grid>
                                        <Grid item xs={8} style={{ paddingLeft: "10px" }}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    height: "100%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="subtitle2">Upload a site photo (displayed in report)</Typography>
                                                <Typography variant="caption" style={{ marginTop: 1 }}>
                                                    Up to 2 files, limit 25MB per file, in png, jpeg
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    background: formik.values.envFiles.length === 2 ? "#E0E0E0" : "#3DB5E6",
                                                    color: "#FFFFFF",
                                                    fontSize: '11px',
                                                }}
                                                variant="contained"
                                                disabled={formik.values.envFiles.length === 2}
                                                component="label"
                                                disableElevation={true}
                                            >
                                                Browse files
                                                <VisuallyHiddenInput
                                                    disabled={formik.values.envFiles.length === 2}
                                                    name="file"
                                                    type="file"
                                                    multiple
                                                    accept="image/png,image/jpeg"
                                                    onClick={(e) => {
                                                        e.target.value = null;
                                                    }}
                                                    onChange={(e) => handleFileChange(e)}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Grid item>
                                    {formik.errors["envFiles"] && (
                                        <FormControl variant="standard">
                                            <FormHelperText style={{ color: "red" }}>
                                                {formik.errors["envFiles"]}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    {!!formik.values.envFiles.length && (
                                        <Container
                                            style={{
                                                borderRadius: "6px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Grid
                                                container
                                                item
                                                style={{
                                                    height: "100%",
                                                }}
                                            >
                                                {formik.values.envFiles.map((file) => {
                                                    return (
                                                        <React.Fragment key={file.name}>
                                                            <Grid
                                                                key={file.name}
                                                                item
                                                                xs={1}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "40%",
                                                                    }}
                                                                    src={FileSavedIcon}
                                                                    alt="file saved icon"
                                                                ></img>
                                                            </Grid>
                                                            <Grid item xs={8} style={{ paddingLeft: "10px" }}>
                                                                <Box
                                                                    style={{
                                                                        display: "flex",
                                                                        height: "100%",
                                                                        flexDirection: "column",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle2">
                                                                        {file.name}{" "}
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{ color: "grey" }}
                                                                        >
                                                                            {formatBytes(file.size)}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "right",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    id={file.name}
                                                                    onClick={(e) => handleDeleteFile(e)}
                                                                >
                                                                    <HighlightOffIcon id={file.name} />
                                                                </IconButton>
                                                            </Grid>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Grid>
                                        </Container>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl>
                                <Typography style={labelForTitle}>Comments</Typography>
                                <TextField
                                    id="comments"
                                    // label="Comments"
                                    type="text"
                                    multiline
                                    variant="outlined"
                                    defaultValue={comments}
                                    helperText={
                                        <Box
                                            component="span"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <span>{`${(comments && comments.length > 0) ? comments.length : 0} / 300`}</span>
                                        </Box>
                                    }
                                    style={{ marginTop: 15 }}
                                    onChange={onCommentsChange}
                                    inputProps={{
                                        maxLength: 300,
                                        style: {
                                            height: "110px",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl>
                                <Typography style={labelForTitle}>Radon Level Guides Template</Typography>
                                <Space height={'10px'} />
                                <FormControlLabel control={
                                    <DefaultCheckbox
                                        checked={ansiAarst !== null ? !!ansiAarst:false}
                                        onChange={onAnsiAarst}
                                    />
                                } label="ANSI-AARST MAH Recommendations" />
                                <Space height={'90px'} />
                                <Grid container justify="flex-end" alignItems="flex-end">
                                    {changed ? (
                                        <Button
                                            style={{
                                                background: changed ? "#3DB5E6" : "#E0E0E0",
                                                color: "#FFFFFF",
                                            }}
                                            onClick={applyChange}
                                        >
                                            Save
                                        </Button>
                                    ) : (
                                        <Button
                                            style={{ background: "#E0E0E0", color: "#FFFFFF" }}
                                            disabled
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid >
        )
    );
}

export default AdditionInfoCard;
