import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import imageToBase64 from "image-to-base64/browser";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert } from "@material-ui/lab";
import Space from "../../components/Space";
import { updateCompanyInfo } from "../../models/actions/authActions";
import {
  getCompanyInfo,
  getCompanyLogo,
  updateCompanyLogo,
} from "../../models/services/authService";
import DefaultCompanyLogo from "../../assets/img/ic_company.png";

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

function CompanyInfo() {
  const [originData, setOriginData] = useState({});
  const [image, setImage] = useState();
  const [errorUpload, setErrorUpload] = useState();

  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [detail, setDetail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [changed, setChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  location.state = {
    email: localStorage.getItem("0xaccountId"),
    name: localStorage.getItem("0xuserId"),
  };
  location.state.email = localStorage.getItem("0xaccountId");
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }

  useEffect(() => {
    const getData = async () => {
      const trimmedEmail = location.state.email.trim().toLowerCase();
      const companyInfo = (await getCompanyInfo(trimmedEmail)).data;
      console.log("companyInfo:" + JSON.stringify(companyInfo));
      if (companyInfo) {
        setCompanyName(companyInfo.companyName);
        setPhoneNumber(companyInfo.phoneNumber);
        setAddress(companyInfo.address.street);
        setDetail(companyInfo.address.detail);
        setCity(companyInfo.address.city);
        setState(companyInfo.address.state);
        setZipcode(companyInfo.address.zipcode);
        setOriginData({
          companyName: companyInfo.companyName,
          phoneNumber: companyInfo.phoneNumber,
          address: {
            street: companyInfo.address.street,
            city: companyInfo.address.city,
            state: companyInfo.address.state,
            zipcode: companyInfo.address.zipcode,
          },
        });
      }
    };
    getData();

    // Load Image
    const updateImage = async () => {
      const imgURL =
        (await getCompanyLogo(location.state.email)) + "?a=" + Math.random();

      if (imgURL.split("?a=")[0] === null) {
        setImage(imgURL);
      } else {
        setImage(DefaultCompanyLogo);
      }
    };
    updateImage();
  }, [location.state.email]);

  const validateCompanyInfo = (values) => {
    const errors = {};

    if (!companyName) {
      errors.companyName = "Required";
    }

    if (!phoneNumber) {
      errors.phoneNumber = "Required";
    }

    // if (!address) {
    //   errors.address = "Required";
    // }
    //
    // if (!city) {
    //   errors.city = "Required";
    // }
    //
    // if (!state) {
    //   errors.state = "Required";
    // }
    //
    // if (!zipcode) {
    //   errors.zipcode = "Required";
    // }

    return errors;
  };

  const handleCompanyNameChanged = (event) => {
    const value = event.target.value;
    setCompanyName(value);
    if (value != originData.companyName) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handlePhoneNumberChanged = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    if (value != originData.phoneNumber) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleAddressChanged = (event) => {
    const value = event.target.value;
    setAddress(value);
    if (value != originData.address.street) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleDetailChanged = (event) => {
    const value = event.target.value;
    setDetail(value);
    if (value != originData.address.detail) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleCityChanged = (event) => {
    const value = event.target.value;
    setCity(value);
    if (value != originData.address.city) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleStateChanged = (event) => {
    const value = event.target.value;
    setState(value);
    if (value != originData.address.state) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleZipcodeChanged = (event) => {
    const value = event.target.value;
    setZipcode(value);
    if (value != originData.address.zipcode) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const createPutImageRequest = (email, fileName, base64EncodedImageData) => {
    /* /api/v1/company/logo */
    const request = {
      email: email,
      file_name: fileName,
      image: base64EncodedImageData,
    };
    return request;
  };

  const handleImageChanged = async (e) => {
    setShowAlert(false);
    setShowSpinnerUpload(true);
    setErrorUpload("");
    try {
      const email = location.state.email;
      const file = e.target.files[0];
      const imageURL = URL.createObjectURL(e.target.files[0]);
      const base64EncodedImageData = await toBase64(file).catch((e) =>
        Error(e)
      );
      if (base64EncodedImageData instanceof Error) {
        console.log("Error: ", base64EncodedImageData.message);
        setErrorUpload(base64EncodedImageData.message);
        return;
      }

      // Upload image to cloud
      const fileName = email + ".jpg";
      imageToBase64(imageURL)
        .then(async (response) => {
          const putImageRequest = createPutImageRequest(
            email,
            fileName,
            base64EncodedImageData
          );
          let updateCompanyLogoResponse = await updateCompanyLogo(
            putImageRequest
          );
          setImage(imageURL);
          if (updateCompanyLogoResponse.status === 200) {
            setShowSpinnerUpload(false);
            setShowAlert(true);
          } else {
            setErrorUpload("Uploading image failed.");
          }
          setShowAlert(true);
        })
        .catch((error) => {
          setShowSpinnerUpload(false);
          throw error;
        });
    } catch (error) {
      setErrorUpload(error.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          companyName: "",
          phoneNumber: "",
          address: "",
          detail: "",
          city: "",
          state: "",
          zipcode: "",
          submit: false,
        }}
        validate={validateCompanyInfo}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setShowAlert(false);
          setShowSpinner(true);
          const trimmedEmail = location.state.email.trim().toLowerCase();
          try {
            // Register Subscription Information for Mitigator
            await dispatch(
              updateCompanyInfo({
                email: trimmedEmail,
                companyName: companyName,
                phoneNumber: phoneNumber,
                address: address,
                detail: detail,
                city: city,
                state: state,
                zipcode: zipcode,
              })
            );

            setOriginData({
              companyName: companyName,
              phoneNumber: phoneNumber,
              address: {
                street: address,
                city: city,
                state: state,
                zipcode: zipcode,
              },
            });

            setChanged(false);
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === "UserNotConfirmedException") {
              message = "Account not verified yet";
            } else if (error.code === "PasswordResetRequiredException") {
              message = "Existing user found. Please reset your password";
            } else if (error.code === "NotAuthorizedException") {
              message = "Forgot Password?";
            } else if (error.code === "UserNotFoundException") {
              message = "User does not exist!";
            } else if (error.code === "UsernameExistsException") {
              message = "User already exists!";
            } else if (error.code === "InvalidParameterException") {
              message =
                "Trailing spaces are not allowed in your password \n or one of parameters is invalid";
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
          setShowAlert(true);
          setShowSpinner(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {showAlert && (
              <Alert
                mt={2}
                mb={1}
                severity={errors.submit ? "error" : "success"}
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                {errors.submit ? errors.submit : "Successfully saved."}
              </Alert>
            )}
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={8}>
                    <Typography variant="h6" gutterBottom>
                      Company information
                    </Typography>
                    <Grid item container spacing={4}>
                      <Grid item md={6}>
                        <TextField
                          name="companyName"
                          label="Company name *"
                          value={companyName}
                          fullWidth
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={handleCompanyNameChanged}
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="phoneNumber"
                          label="Phone number *"
                          value={phoneNumber}
                          error={Boolean(
                            touched.phoneNumber && errors.phoneNumber
                          )}
                          fullWidth
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          onBlur={handleBlur}
                          onChange={handlePhoneNumberChanged}
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      name="address"
                      label="Address"
                      value={address}
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      onBlur={handleBlur}
                      onChange={handleAddressChanged}
                      my={2}
                    />
                    <Grid item container spacing={4}>
                      <Grid item md={6}>
                        <TextField
                          name="address2"
                          label="Address 2"
                          value={detail}
                          error={Boolean(touched.detail && errors.detail)}
                          fullWidth
                          helperText={touched.detail && errors.detail}
                          onBlur={handleBlur}
                          onChange={handleDetailChanged}
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="city"
                          label="City"
                          value={city}
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helperText={touched.city && errors.city}
                          onBlur={handleBlur}
                          onChange={handleCityChanged}
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={4}>
                      <Grid item md={6}>
                        <TextField
                          name="state"
                          label="State"
                          value={state}
                          error={Boolean(touched.state && errors.state)}
                          fullWidth
                          helperText={touched.state && errors.state}
                          onBlur={handleBlur}
                          onChange={handleStateChanged}
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="zipcode"
                          label="Zipcode"
                          value={zipcode}
                          error={Boolean(touched.zipcode && errors.zipcode)}
                          fullWidth
                          helperText={touched.zipcode && errors.zipcode}
                          onBlur={handleBlur}
                          onChange={handleZipcodeChanged}
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <div className="s30"></div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      {
                        <>
                          {changed ? (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              classes={{
                                root: "button",
                                label: "button-label",
                              }}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              classes={{
                                root: "button",
                                label: "button-label",
                              }}
                              style={{
                                background: "#E0E0E0",
                                color: "#FFFFFF",
                              }}
                              disabled
                            >
                              Save
                            </Button>
                          )}
                        </>
                      }
                      {showSpinner ? (
                        <div className="save-changes-spinner">
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    container
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <CenteredContent>
                      <Space height={"20px"} />
                      <img
                        alt="company-logo"
                        style={{
                          objectFit: "scale-down",
                          width: "160px",
                          height: "60px",
                        }}
                        src={image}
                      />
                      <div className="s10"></div>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="company-logo-raised-button-file"
                        type="file"
                        onChange={(e) => handleImageChanged(e)}
                      />
                      <label htmlFor="company-logo-raised-button-file">
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                          >
                            {
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  classes={{
                                    root: "button",
                                    label: "button-label",
                                  }}
                                >
                                  <CloudUpload mr={2} /> Upload
                                </Button>
                              </>
                            }
                            {showSpinnerUpload ? (
                              <div className="settings-changes-spinner">
                                <CircularProgress size={20} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                        <div className="s10"></div>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          For best results, use an image at least 160px by 64px
                          in .jpg format
                        </Typography>
                        {/*<div className="s20"></div>*/}
                      </label>
                    </CenteredContent>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CompanyInfo;
