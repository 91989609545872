import React from 'react';
import Button from "@material-ui/core/Button";
import "../../assets/css/order.css";
import {isMobile} from 'react-device-detect';

export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);
  return (
      <section className="pdf-container">
        <section className="pdf-body" ref={bodyRef}>
          {props.children}
        </section>
        <section className="download-pdf">
          {
            !isMobile ? (
            <Button onClick={createPdf} variant="contained" color="primary" mt={2} classes={{ root: 'download-button', label: 'download-button-label' }}>
              Download
            </Button>
            ) : (
            <></>
            )
          }
          {/*{*/}
          {/*  !isMobile && props.isSubsription && !props.isPaidStatus ? (*/}
          {/*    <Button onClick={props.handleOpenCardForm} variant="contained" color="primary" mt={2} classes={{ root: 'paynow-button', label: 'paynow-button-label'}} style={{marginLeft: '20px'}} >*/}
          {/*      Pay Now*/}
          {/*    </Button>*/}
          {/*  ) : (*/}
          {/*    <></>*/}
          {/*  )*/}
          {/*}*/}
        </section>
      </section>
  )
}
