import { savePDF } from '@progress/kendo-react-pdf';

class ReportDocService {
  createPdf = (html, orderId, callback) => {
    alert('ReportDocService createPdf is called: ' + callback);
    // savePDF(html, {
    //   scale: 0.55,
    //   paperSize: 'A4',
    //   fileName: 'report-' + orderId+ '.pdf',
    //   margin: 0
    // }, callback);
  }
}

const Doc = new ReportDocService();
export default Doc;
