import { Grid, Typography } from "@material-ui/core";

const Comments = ({ reportDetails }) => {
  return (
    <Grid
      container
      style={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid item>
        <Typography
          style={{
            marginTop: 6,
            color: "black",
            fontSize: "12px",
            fontWeight: 500,
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Comments:
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            wordBreak: "break-word",
            marginTop: 5,
            color: "black",
            textAlign: "left",
            fontSize: "11px",
            fontWeight: 300,
            height: "75px",
          }}
        >
          {reportDetails.device_placement? reportDetails.device_placement.comments : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Comments;
