import React from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { changePassword } from "../../models/actions/authActions";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  validEmailRegex,
  validNameRegex,
  validPwdRegex,
} from "../../utils/AuthValidation";
import { Alert as MuiAlert } from "@material-ui/lab";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import AccountInfo from "./AccountInfo";
import ChangePassword from "./ChangePassword";
import CompanyInfo from "./CompanyInfo";
import QRInfo from "./QRInfo";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

function Setting() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3">Settings</Typography>

      {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
      {/*  <Link component={NavLink} exact to="/">*/}
      {/*    Dashboard*/}
      {/*  </Link>*/}
      {/*  <Link component={NavLink} exact to="/">*/}
      {/*    Pages*/}
      {/*  </Link>*/}
      {/*  <Typography>Settings</Typography>*/}
      {/*</Breadcrumbs>*/}

      <Divider my={6} />
      <AccountInfo />
      <CompanyInfo />
      <ChangePassword />
      <QRInfo />
    </React.Fragment>
  );
}

export default Setting;
