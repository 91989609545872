import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { useState } from "react";
import { getComparator, stableSort } from "../util";

export function DurationTable(props) {
  const { handleDurationSelect, isEligible } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("serial_number");
  const [selected, setSelected] = useState([]);

  const durations = [
    { duration: "48-hour clearance radon report", type: "48HR" },
    { duration: "Last 7 days", type: "WEEK" },
    { duration: "Last 30 days", type: "MONTH" },
    { duration: "Last 3 months", type: "QUARTER" },
    { duration: "Last 6 months", type: "HALF_YEAR" },
    { duration: "Last 1 year", type: "YEAR" },
  ];

  const handleClick = (event, duration) => {
    const selectedIndex = selected.indexOf(duration.duration);
    let newSelected = [];
    if (selectedIndex >= 0) return;
    else {
      newSelected = [duration.duration];
    }

    setSelected(newSelected);
    handleDurationSelect(true, duration);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size={"medium"}>
            <TableBody>
              {stableSort(durations, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.duration);

                  return (
                    <TableRow
                      hover={row.type === "48HR" && !isEligible ? false : true}
                      onClick={(event) => {
                        if (row.type === "48HR" && !isEligible) {
                          return null;
                        } else {
                          handleClick(event, {
                            duration: row.duration,
                            type: row.type,
                          });
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.duration}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">
                        <FormControl component="fieldset">
                          <RadioGroup aria-label="gender" name="gender1">
                            <FormControlLabel
                              disabled={row.type === "48HR" && !isEligible}
                              value="selected"
                              control={<Radio />}
                              checked={isItemSelected}
                            />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color:
                            row.type === "48HR" && !isEligible ? "grey" : "",
                        }}
                      >
                        {row.duration}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
