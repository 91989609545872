import { Container, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RadonUtil } from "../../components/RadonUtil";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {getRadonValue} from "../util";

const HistoricalTrend = ({ reportDetails, reportChartRadonValue, isForPDF }) => {
  const location = useLocation();
  const reportType = location.state.duration.type;
  const durationType = RadonUtil.getBtnType(reportType);
  let prevDate;
  let render;

  const yAxisMax = () => {
    let dMax = Number(getRadonValue(
        reportDetails.measurement_unit,
        reportDetails.highest_radon
    ));
    return dMax < 1 ? (dMax * 1.2).toFixed(1) : Math.ceil(dMax);
  }

  function formatTicks(tick) {
    let formattedDate;
    switch (reportType) {
      case "48HR":
        if (tick === reportChartRadonValue[0].datetime || tick % 2 === 0) {
          return tick;
        } else {
          return "";
        }
      case "WEEK":
        render = false;

        if (prevDate !== tick) {
          render = true;
          prevDate = tick;
        }

        if (tick === reportChartRadonValue[0].datetime || render) {
          return tick;
        } else {
          return "";
        }

      default:
        formattedDate = moment(tick).format("MMM DD");

        if (tick === reportChartRadonValue[0].datetime || tick % 2 === 0) {
          return formattedDate;
        } else {
          return "";
        }
    }
  }

  function CustomizedXAxis(props) {
    const { x, y, payload } = props;
    let label;

    switch (reportType) {
      case "48HR":
        render = false;
        const splitDatetime = moment(payload.value)
          .format("MM/DD hh:mm A")
          .split(" ");

        const date = splitDatetime[0];
        const time = splitDatetime[1] + " " + splitDatetime[2];

        if (prevDate !== date) {
          render = true;
          prevDate = date;
        }

        return (
          <g transform={`translate(${x},${y})`}>
            <text fontSize={10} dy={10} textAnchor="middle" fill="#666">
              {time}
            </text>
            {render && (
              <text fontSize={10} dy={22} textAnchor="middle" fill="#666">
                {date}
              </text>
            )}
          </g>
        );
      case "WEEK":
        label = moment(payload.value)
          .format("dddd")
          .substring(0, 3)
          .toUpperCase();

        return (
          <g transform={`translate(${x},${y})`}>
            <text fontSize={10} dy={10} textAnchor="middle" fill="#666">
              {label}
            </text>
          </g>
        );
      default:
        label = moment(payload.value).format("MMM DD");

        return (
          <g transform={`translate(${x},${y})`}>
            <text fontSize={10} dy={10} textAnchor="middle" fill="#666">
              {label}
            </text>
          </g>
        );
    }
  }

  return (
    <>
      <Typography
        style={{
          marginTop: "30px",
          color: "black",
          fontSize: "12px",
          fontWeight: 500,
          textAlign: "left",
          fontStyle: "italic",
        }}
      >
        Radon Historical Trend
      </Typography>

      <Grid
        container
        style={{
          paddingTop: "10px",
        }}
      >
        <Grid item xs={12}>
          <Container>
            <ResponsiveContainer width="99%" height={isForPDF ? 220 : 400} aspect={2.5}>
              <LineChart
                data={reportChartRadonValue}
                margin={{
                  right: 10,
                  left: -30,
                  bottom: 5,
                }}
              >
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <XAxis
                  allowDataOverflow
                  scale="time"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  dataKey="datetime"
                  tick={CustomizedXAxis}
                  tickFormatter={(tick) => formatTicks(tick)}
                />
                <YAxis
                  axisLine={false}
                  style={{ fontSize: "10px" }}
                  domain={[0, yAxisMax]}
                />
                {reportDetails.avg_radon && (
                  <Tooltip
                    formatter={function (value, name) {
                      return `${value}`;
                    }}
                    labelFormatter={function (value) {
                      switch (durationType) {
                        case "hr_btn":
                          return `${moment(value).format("MM/DD/YY hh:mm A")}`;

                        default:
                          // day_btn
                          return `${moment(value).format("MM/DD/YYYY")}`;
                      }
                    }}
                  />
                )}
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="radon_level"
                  stroke="#3DB5E6"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default HistoricalTrend;
