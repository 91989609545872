import { BIG_NUMBER_BLACK, BLACK, RED, UNIT_BLACK } from '../../../constants/colors';

export const textForComment = {
  fontSize: '11px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: BLACK,
  opacity: 0.6,
};

export const labelForTitle = {
  fontSize: '11px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: BLACK,
  opacity: 0.6,
};

export const valueForText = {
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BLACK,
  minWidth: '120px'
};

export const alertMessageForActivation = {
  fontSize: '12px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: '#D14343',
  minWidth: '120px'
};

export const valueForLocationText = {
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BLACK,
  minWidth: '120px',
  whiteSpace: 'nowrap',
};

export const valueForOutOfSyncText = {
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BLACK,
  minWidth: '120px'
};

export const unitText = {
  paddingLeft: '10px',
  fontSize: '12px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BLACK,
};

export const valueForThresholdInput = {
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BLACK,
  width: '100%'
};

export const radonBigNumber = {
  fontSize: '70px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  lineHeight: '30px',
  color: BIG_NUMBER_BLACK
};

export const workingStatusMessage = {
  fontSize: '16px',
  fontWeight: '500',
  fontStyle: 'normal',
  lineHeight: '24px',
  paddingTop: '10px',
  color: BLACK
};

export const radonUnit1 = {
  fontSize: '16px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '15px',
  color: UNIT_BLACK
};

export const radonUnit2ForPci = {
  fontSize: '16px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: UNIT_BLACK,
  paddingLeft: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center'
};

export const radonUnit2ForBq = {
  fontSize: '16px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: UNIT_BLACK,
  paddingLeft: '0px',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center'
};

export const radonMessage = {
  fontSize: '16px',
  fontWeight: '500',
  fontStyle: 'normal',
  lineHeight: '24px',
  paddingTop: '10px',
};

export const labelForMessage = {
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: BLACK,
};
