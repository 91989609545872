export const toBase64 = (files, formik, type) => {
  const promises = Array.from(files).map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        resolve({ name: file.name, image: base64String });
      };

      reader.readAsDataURL(file);
    });
  });

  Promise.all(promises).then((fileData) => {
    formik.setFieldValue(type, [...formik.values[type], ...fileData]);
  });
};
