import moment from "moment";
import { UNIT_CONVERSION_SCALE } from "../../../constants/values";
import { orderBy } from "lodash";

export const convertToPicocuries = (bqm) => {
  const pCiL = bqm / UNIT_CONVERSION_SCALE;

  const fixed = Math.round(pCiL * 10) / 10;
  const parsedValue = parseFloat(fixed.toFixed(1)).toFixed(1);

  return parsedValue;
};

export const getRadonValue = (measurementUnit, BqmValue) => {
  if (BqmValue) {
    switch (measurementUnit) {
      case 0:
        return convertToPicocuries(BqmValue);
      case 1:
        if (BqmValue === undefined || BqmValue === "") {
          return BqmValue;
        } else {
          return parseFloat(BqmValue.toFixed(0));
        }
      default:
        return null;
    }
  } else if (BqmValue === 0) {
    return 0;
  }

  return null;
};

function insertStartValue(reportDetails, reportType) {
  let start, dataPointExists;

  switch (reportType) {
    case "WEEK":
      start =
        moment(reportDetails.measurement_end, "YYYY-MM-DD HH:mm:ss")
          .subtract(168, "hours")
          .valueOf() / 1000;

      dataPointExists = reportDetails.radondata.some(
        (datapoint) => datapoint.datetime === start
      );

      if (!dataPointExists) {
        reportDetails.radondata.push({ datetime: start, radon_level: null });
      }

      return reportDetails.radondata;
    case "MONTH":
      start = moment(
        moment(reportDetails.measurement_end, "YYYY-MM-DD HH:mm:ss").subtract(
          1,
          "month"
        )
      ).format("YYYYMMDD");

      dataPointExists = reportDetails.radondata.some(
        (datapoint) => datapoint.datetime === start
      );

      if (!dataPointExists) {
        reportDetails.radondata.push({ datetime: start, radon_level: null });
      }

      return reportDetails.radondata;
    case "QUARTER":
      start = moment(
        moment(reportDetails.measurement_end, "YYYY-MM-DD HH:mm:ss").subtract(
          3,
          "months"
        )
      ).format("YYYYMMDD");

      dataPointExists = reportDetails.radondata.some(
        (datapoint) => datapoint.datetime === start
      );

      if (!dataPointExists) {
        reportDetails.radondata.push({ datetime: start, radon_level: null });
      }

      return reportDetails.radondata;
    case "HALF_YEAR":
      start = moment(
        moment(reportDetails.measurement_end, "YYYY-MM-DD HH:mm:ss").subtract(
          6,
          "months"
        )
      ).format("YYYYMMDD");

      dataPointExists = reportDetails.radondata.some(
        (datapoint) => datapoint.datetime === start
      );

      if (!dataPointExists) {
        reportDetails.radondata.push({ datetime: start, radon_level: null });
      }

      return reportDetails.radondata;
    case "YEAR":
      start = moment(
        moment(reportDetails.measurement_end, "YYYY-MM-DD HH:mm:ss").subtract(
          1,
          "year"
        )
      ).format("YYYYMMDD");

      dataPointExists = reportDetails.radondata.some(
        (datapoint) => datapoint.datetime === start
      );

      if (!dataPointExists) {
        reportDetails.radondata.push({ datetime: start, radon_level: null });
      }

      return reportDetails.radondata;
    default:
      return reportDetails.radondata;
  }
}

export function reformatRadonChartData(reportDetails, btn_type, reportType) {
  if (reportDetails.radondata === "") {
    return [];
  } else {
    let withStartValue, sortedData;

    switch (btn_type) {
      case "hr_btn":
        withStartValue = insertStartValue(reportDetails, reportType);

        sortedData = orderBy(withStartValue, ["datetime"], ["asc"]);

        return sortedData.map((data, idx) => {
          let radonValue = getRadonValue(
            reportDetails.measurement_unit,
            data.radon_level
          );

          return {
            datetime: Number(moment.unix(data.datetime).valueOf()),
            radon_level: radonValue,
          };
        });

      default:
        // day_btn
        withStartValue = insertStartValue(reportDetails, reportType);

        sortedData = orderBy(withStartValue, ["datetime"], ["asc"]);

        return sortedData.map((data, idx) => {
          let radonValue = getRadonValue(
            reportDetails.measurement_unit,
            data.radon_level
          );

          return {
            datetime: Number(moment(data.datetime, "YYYYMMDD").valueOf()),
            radon_level: radonValue,
          };
        });
    }
  }
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === "generated_date") {
    let d1 = moment(a[orderBy], "MM/DD/YYYY");
    let d2 = moment(b[orderBy], "MM/DD/YYYY");
    return d2.utc() - d1.utc();
  } else if (orderBy === "measurement_start" || orderBy === "measurement_end") {
    let d1 = moment(a[orderBy], "MMDDYY");
    let d2 = moment(b[orderBy], "MMDDYY");
    return d2.utc() - d1.utc();
  }

  if(!a[orderBy]) return 1
  if(!b[orderBy]) return -1

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  if (!array || array.length === undefined) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
