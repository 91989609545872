import React from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";
import {Formik} from "formik";
import {resendSignUp, verifyCode} from "../../models/actions/authActions";

import {Box, Button, CircularProgress, Paper, TextField as MuiTextField, Typography,} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {Alert as MuiAlert} from "@material-ui/lab";
import "../../assets/css/common.css";
import "../../assets/css/auth.css";
import InputBase from "@material-ui/core/InputBase";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.passcode) {
    errors.passcode = 'Required';
  } else if (values.passcode.length !== 6 || isNaN(values.passcode)) {
    errors.passcode = 'Passcode must be 6 digits';
  }

  return errors;
};

function VerifyPasscode() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const resendVerificationCode = async () => {
    const email = location.state.email;
    try {
      await dispatch(
        resendSignUp(email)
      );
      history.push({
        pathname: '/auth/verify-code',
        state: {email: email}
      });
    } catch (error) {
      // let message = error.message || "Something went wrong";
      // setStatus({ success: false });
      // setErrors({ submit: message });
      // setSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <Helmet title="Verify Passcode"/>
      <div>
        <div align="center">
          <img width="110px" src="/static/img/auth/img-verification.png"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Verify your email address
        </Typography>
        <div className='s20'></div>
        <Typography component="h2" variant="body1" align="center">
          We have sent verification code to <br/><b>{location.state.email}</b>
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Check your spam or just folder<br/> if you don't see it
        </Typography>
      </div>

      <Formik
        initialValues={{
          passcode: "",
          email: location.state.email,
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
          try {
            await dispatch(
              verifyCode({
                email: location.state.email,
                passcode: values.passcode.trim(),
              })
            );
            history.push("/auth/sign-in");
          } catch (error) {
            let message;
            if (error.message === 'Custom auth lambda trigger is not configured for the user pool.') {
              //TODO: Need to add new page
              history.push("/auth/confirm-sign-up");
            } else {
              message = error.message || "Something went wrong";
            }

            setStatus({success: false});
            setErrors({submit: message});
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <div className="s10"></div>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              </>
            )}
            {/*<TextField*/}
            {/*  type="email"*/}
            {/*  name="email"*/}
            {/*  label="Email Address"*/}
            {/*  value={values.email}*/}
            {/*  error={Boolean(touched.email && errors.email)}*/}
            {/*  fullWidth*/}
            {/*  helperText={touched.email && errors.email}*/}
            {/*  onBlur={handleBlur}*/}
            {/*  onChange={handleChange}*/}
            {/*  my={3}*/}
            {/*/>*/}
            <InputBase disabled inputProps={{ 'aria-label': 'naked' }}/>
            <TextField
              type="text"
              name="passcode"
              label="Verification code"
              value={values.passcode}
              error={Boolean(touched.passcode && errors.passcode)}
              fullWidth
              helperText={touched.passcode && errors.passcode}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <InputBase disabled inputProps={{ 'aria-label': 'naked' }}/>
            <div className="s10"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{root: 'button', label: 'button-label'}}
            >
              Click to verify
            </Button>

            {/*<div className="auth-padding"></div>*/}
            {/*<div className="auth-padding"></div>*/}

          </form>
        )}
      </Formik>
      <Formik
        initialValues={{
          email: location.state.email,
          submit: false,
        }}
        onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
          try {
            const trimmedEmail = location.state.email.trim().toLowerCase();
            await dispatch(
              resendSignUp(trimmedEmail)
            );
            history.push({
              pathname: '/auth/verify-code',
              state: {email: trimmedEmail}
            });
          } catch (error) {
            let message = error.message || "Something went wrong";
            setStatus({success: false});
            setErrors({submit: message});
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="s10"></div>
            <div align="center">
              <span className='button-left-text'>
                  Didn't receive a code?
              </span>
              <Button
                style={{paddingTop: "4px"}}
                type="submit"
                color="primary"
                classes={{label: 'link-text'}}
              >
                Resend
              </Button>
              {
                isSubmitting ?
                  <div className='resend-spinner'>
                    <CircularProgress size={13}/>
                  </div> : <></>
              }
            </div>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default VerifyPasscode;
