export const GREEN = '#00C853';
export const ORANGE = '#F8B814';
export const RED = '#B71C1C';
export const RED_BRIGHT = '#B00020';
export const MAIN = '#3DB5E6';
export const WHITE = '#FFFFFF';
export const LIGHTLINE = '#F7F9FC';
export const GREY = '#9E9E9E';
export const BUTTON_DISABLED = '#E0E0E0';
export const BLACK = '#000000';
export const UNIT_BLACK = '#353D4C';
export const BIG_NUMBER_BLACK = '#1F2738';
export const DEACTIVATED_RED = '#B00020';

