import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { DurationTable } from "../Tables/DurationTable";
import Loading from "../../components/Loading";

const SelectDurationDialog = ({
  isLoading,
  openDurationForm,
  handleDurationSelect,
  isEligible,
  handleNextClose,
  handleClose,
  handleGoPrevious,
  durationSelected,
  handleSecondEligibilityCheck,
  handleFinal,
}) => {
  return (
    <Dialog
      titleStyle={{ textAlign: "center" }}
      maxWidth="md"
      maxHeight={"sm"}
      open={openDurationForm}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title2"
    >
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="s20"></div>
        <DialogTitle id="max-width-dialog-title2">
          <Typography variant="h5" align="center">
            Select measurement time
          </Typography>
        </DialogTitle>
        {isLoading ? (
          <Loading top={"100px"} bottom={"100px"} />
        ) : (
          <>
            <DialogContent>
              <TableContainer>
                <DurationTable
                  handleDurationSelect={handleDurationSelect}
                  isEligible={isEligible}
                />
              </TableContainer>
            </DialogContent>
            <div className="s20"></div>
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    color: "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handleClose}
                >
                  <u>Cancel</u>
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    background: "#3DB5E6",
                    color: "#FFFFFF",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handleGoPrevious}
                  color="primary"
                >
                  Previous
                </Button>
                <Box style={{ width: "10px" }} />
                <Button
                  style={{
                    background: durationSelected.isSelected
                      ? "#3DB5E6"
                      : "#E0E0E0",
                    color: durationSelected.isSelected ? "#FFFFFF" : "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={
                    durationSelected.duration &&
                    durationSelected.duration.type === "48HR"
                      ? handleSecondEligibilityCheck
                      : handleFinal
                  }
                  color="primary"
                  disabled={!durationSelected.isSelected}
                >
                  Preview
                </Button>
              </Box>
            </DialogActions>
            <div className="s20"></div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default SelectDurationDialog;
