import React from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory} from "react-router-dom";

import { Helmet } from "react-helmet-async";

import "../../../assets/css/common.css";
import "../../../assets/css/dashboard.css";

import { spacing } from "@material-ui/system";
import {useDispatch} from "react-redux";

import {
  Grid,
  Paper as MuiPaper,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

function NoDevices(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const {size} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const titleBox = {
    top: 10,
    height: 39,
    width: 318,
  };

  const titleStyle = {
    color: 'black',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: 38,
  };

  const subtitleStyle = {
    color: 'black',
    height: 28,
    width: 472,
    fontFamily: 'normal',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 28,
  };

  return (
    <React.Fragment>
      <Typography mb={'228px'}/>
      {size===0?
      <div className='no-data-container'>
        <Typography variant={'h6'} align={'center'} mb={'19px'}>
          No data available now
        </Typography>
        <span className='no-data-text'>
          Register your EcoGuard with Mobile app or <NavLink to='/orders'> Order</NavLink> new EcoQube
        </span>
      </div>: <Typography variant={'h6'} align={'center'} mb={'19px'}>
          No data found matched
        </Typography>}
    </React.Fragment>
  );
}

export default NoDevices;
