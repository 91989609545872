import { Divider, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

const Header = () => {
  return (
    <>
      <Helmet title="Support" />
      <Grid container>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Support
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 20, marginTop: 10 }} />
    </>
  );
};

export default Header;
