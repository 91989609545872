import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { resetPassword, signIn } from "../../models/actions/authActions";
import "../../assets/css/auth.css";
import "../../assets/css/common.css";
import { validEmailRegex, validPwdRegex } from "../../utils/AuthValidation";
import { Auth } from "aws-amplify";
import InputBase from "@material-ui/core/InputBase";
import * as types from "../../constants";
import {
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { customAlert } from "../../utils/AlertWrapper";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import {encodeTx, decodeRx} from "../../utils/codec";

const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const TEST_EMAIL = null;

function SignInHidden() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [requesting, setRequesting] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pwdValues, setPwdValues] = React.useState({
    currentPassword: "",
    showCurrentPassword: false,
  });
  const [userEmail, setUserEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
  };

  const handleClickShowCurrentPassword = () => {
    setPwdValues({
      ...pwdValues,
      showCurrentPassword: !pwdValues.showCurrentPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};

    if (!email) {
      errors.email = "Required";
    } else if (!validEmailRegex.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!userEmail) {
      errors.userEmail = "Required";
    } else if (!validEmailRegex.test(userEmail)) {
      errors.userEmail = "Please enter a valid userEmail address.";
    }

    if (!pwdValues.currentPassword) {
      errors.currentPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.currentPassword)) {
      errors.currentPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    }

    return errors;
  };

  return requesting ? (
    <>
      <Box display="flex" flexDirection={"column"}>
        <div className="progress">
          <CircularProgress />
        </div>
        <div className="s20"></div>
        <Typography variant={"body1"}>Signing In ..</Typography>
      </Box>
    </>
  ) : (
    <Wrapper>
      <Helmet title="Sign In" />
      <div>
        <div className="s15"></div>
        <div align="center">
          <img width={120} src="/static/img/logo/ecosense-logo3.png"></img>
        </div>
        <div className="s30"></div>
        <div align="center">
          <span className="signin-title-text">Sign in to EcoGuard</span>
        </div>
        <div className="s20"></div>
        <div align="center">
          <span className="account-check-text">
            Don't have an account?{" "}
            <Link className="account-check-link" to="/auth/sign-up">
              SIGN UP
            </Link>
          </span>
        </div>
        <div className="s30"></div>
      </div>

      <Formik
        initialValues={{
          email: "",
          userEmail: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let existingUser = localStorage.getItem("0xXRurEm");
          if(existingUser) {
            localStorage.removeItem("0xXRurEm");
          }
          setErrorMsg("");
          const trimmedEmail = email.trim().toLowerCase();
          const trimmedUserEmail = userEmail.trim().toLowerCase();
          try {
            setRequesting(true);
            // await dispatch(
            //   signIn({ email: trimmedEmail, password: pwdValues.currentPassword })
            // );

            const user = await Auth.signIn(email, pwdValues.currentPassword);
            localStorage.setItem("0xuserId", user.attributes.name);
            const certNo = _.get(user, "attributes.custom:certNo");
            localStorage.setItem("0xcertNo", certNo || "");
            if (TEST_EMAIL) {
              localStorage.setItem("0xaccountId", TEST_EMAIL);
            } else {
              localStorage.setItem("0xaccountId", email);
              if(trimmedUserEmail&&trimmedUserEmail.length>0&&
                trimmedEmail=="choiincommon@gmail.com"||trimmedEmail=="choi2incommon@gmail.com"||trimmedEmail=="jlee@ecosense.io"||trimmedEmail=="djones@ecosense.io"||trimmedEmail=="jjeong@ecosense.io") {
                localStorage.setItem("0xXRurEm", encodeTx(trimmedUserEmail));
              }
            }
            localStorage.setItem("accessSource", "web");

            const group = _.get(user, "attributes.custom:group");
            if (group != "mitigator") {
              throw new Error("NotMitigatorUserException");
            }

            if (location && location.invoiceNo) {
              const invoiceNo = location.invoiceNo;
              delete location.invoiceNo;
              history.push("/subscriptions/detail?" + invoiceNo);
            } else {
              const queryParams = new URLSearchParams(location.search);
              const prevLocation = queryParams.get("redirect_to");

              if (prevLocation === null) {
                history.push({
                  pathname: "/devices",
                  state: {
                    email: trimmedEmail,
                    name: localStorage.getItem("0xuserId"),
                    certNo: localStorage.getItem("0xcertNo"),
                  },
                });
              } else {
                history.push(prevLocation, {
                  state: {
                    email: trimmedEmail,
                    name: localStorage.getItem("0xuserId"),
                    certNo: localStorage.getItem("0xcertNo"),
                  },
                });
              }
            }
          } catch (error) {
            alert('eerror');
            let message = error.message || "Something went wrong";
            if (error.code === "UserNotConfirmedException") {
              message = "Account not verified yet";
              history.push({
                pathname: "/auth/passcode-required",
                state: {
                  from: "/auth/sign-in",
                  email: trimmedEmail,
                },
              });
            } else if (error.code === "PasswordResetRequiredException") {
              message = "Existing user found. Please reset your password";
            } else if (error.code === "NotAuthorizedException") {
              message = "User and password don't match or user doesn't exist";
            } else if (error.code === "UserNotFoundException") {
              message = "Email and password don't match or user doesn't exist";
            } else if (error.message === "NotMitigatorUserException") {
              message = "This is not business account. Please contact us.";
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setErrorMsg(message);
            setSubmitting(false);
          }
          setRequesting(false);
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            {/*<Alert mt={3} mb={1} severity="info">*/}
            {/*  Use <strong>demo@bootlab.io</strong> and{" "}*/}
            {/*  <strong>unsafepassword</strong> to sign in*/}
            {/*</Alert>*/}
            {errorMsg && (
              <Alert mt={2} mb={1} severity="warning">
                {errorMsg}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={handleEmailChange}
              // variant="outlined"
              my={3}
            />
            <FormControl fullWidth my={0}>
              <InputLabel
                htmlFor="standard-current-password"
                error={errors.currentPassword ? true : false}
              >
                Password
              </InputLabel>
              <Input
                id="standard-current-password"
                autoComplete="new-password"
                aria-describedby="current-password-helper"
                type={pwdValues.showCurrentPassword ? "text" : "password"}
                value={pwdValues.currentPassword}
                onChange={handlePwdChange("currentPassword")}
                // onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {pwdValues.showCurrentPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.currentPassword && (
                <FormHelperText id="current-password-helper" error={true}>
                  {errors.currentPassword}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              type="email"
              name="userEmail"
              label="User Email Address"
              value={userEmail}
              error={Boolean(touched.userEmail && errors.userEmail)}
              fullWidth
              helperText={touched.userEmail && errors.userEmail}
              // onBlur={handleBlur}
              onChange={handleUserEmailChange}
              // variant="outlined"
              my={3}
            />
            <InputBase disabled inputProps={{ "aria-label": "naked" }} />
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox value="remember" color="primary" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <div className="s40"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: "button", label: "button-label" }}
            >
              SIGN IN
            </Button>
            <div className="s10"></div>
            <Button
              component={Link}
              to="/auth/forgot-password"
              fullWidth
              color="primary"
              classes={{ label: "link-text" }}
            >
              Forgot password?
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default SignInHidden;
