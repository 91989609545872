import * as types from "../../constants";
import {
  getSubscriptionList,
  createSubscription,
  getSubscriptionDetail,
} from "../services/subscriptionService";

export function getSubscriptionListAction(email) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_LIST_REQUEST });

    return getSubscriptionList(email)
      .then((response) => {
        dispatch({
          type: types.ORDER_LIST_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_LIST_FAILURE });
        throw error;
      });
  };
}

export function getSubscriptionDetailAction(invoiceNo) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_DETAIL_REQUEST });

    return getSubscriptionDetail(invoiceNo)
      .then((response) => {
        dispatch({
          type: types.ORDER_DETAIL_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_DETAIL_FAILURE });
        throw error;
      });
  };
}

export function createSubscriptionAction(email, data) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_CREATE_REQUEST });

    return createSubscription(email, data)
      .then((response) => {
        dispatch({
          type: types.ORDER_CREATE_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_CREATE_FAILURE });
        throw error;
      });
  };
}
