import React from "react";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import {customAlert} from "../../utils/AlertWrapper";

function RedirectToRoot() {
  const history = useHistory();
  const checkUser = async () => {
    try {
      let authResult = await Auth.currentAuthenticatedUser();
      customAlert('auth result: ' + JSON.stringify(authResult));
      if(authResult) {
        history.replace({
          pathname: "/devices",
          state: {
            email: localStorage.getItem('0xaccountId'),
            name: localStorage.getItem('0xuserId'),
          }
        });
      } else {
        history.push("/auth/sign-in");
      }
    } catch(e) {
      history.push("/auth/sign-in");
    }
  };

  checkUser();

  return (
    <>
    </>
  );
}

export default RedirectToRoot;
