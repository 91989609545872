import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  validCertNoRegex,
  validEmailRegex,
  validNameRegex,
} from "../../utils/AuthValidation";
import { Alert as MuiAlert } from "@material-ui/lab";
import Space from "../../components/Space";
import { customAlert } from "../../utils/AlertWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { classes } from "istanbul-lib-coverage";
import { updateUserAttribute, updateUserAttributes } from "../../models/actions/authActions";
import { Auth } from "aws-amplify";
import {
  getUserPhoto,
  updateUserPhoto,
} from "../../models/services/authService";
import imageToBase64 from "image-to-base64/browser";
import "../../assets/css/settings.css";
import _ from "lodash";

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

function AccountInfo() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [errorUpload, setErrorUpload] = useState();
  const [image, setImage] = useState();
  const [changed, setChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinnerSaveChanges, setShowSpinnerSaveChanges] = useState(false);
  const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  location.state = {
    email: localStorage.getItem("0xaccountId"),
    name: localStorage.getItem("0xuserId"),
    certNo: localStorage.getItem("0xcertNo"),
    ownerDisplay: localStorage.getItem("0xownerDisplay")||''
  };

  location.state.email = localStorage.getItem("0xaccountId");
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }
  const [name, setName] = useState(location.state.name);
  const [certNo, setCertNo] = useState(location.state.certNo);
  const [originCertNo, setOriginSetCertNo] = useState(location.state.certNo);
  const [ownerDisplay, setOwnerDisplay] = useState(location.state.ownerDisplay);
  const originalSettings = {
    name: location.state.name,
    certNo: location.state.certNo,
    ownerDisplay: location.state.ownerDisplay,
  }

  useEffect(() => {
    // Update Full Name
    const updateAttribute = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const name = user.attributes.name;
      const certNo = _.get(user, "attributes.custom:certNo")?? "";
      const ownerDisplay = _.get(user, "attributes.custom:ownerDisplay")?? "";
      setName(name);
      setCertNo(certNo);
      setOriginSetCertNo(certNo);
      setOwnerDisplay(ownerDisplay);
    };
    updateAttribute();

    // Load Image
    const updateImage = async () => {
      const imgURL =
        (await getUserPhoto(location.state.email)) + "?a=" + Math.random();
      if (imgURL) {
        setImage(imgURL);
      } else {
        setImage(null);
      }
    };
    updateImage();
  }, [location.state.email]);

  const validateAccountInfo = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!name) {
      errors.name = "Required";
    } else if (!validNameRegex.test(name)) {
      errors.name = "It must not contain numbers or special characters.";
    }

    if (!ownerDisplay) {
      errors.ownerDisplay = "Required";
    } else if (!validEmailRegex.test(ownerDisplay)) {
      errors.ownerDisplay = "Please enter a valid email address.";
    }

    // if (!certNo) {
    //   errors.certNo = "Required";
    // } else if (!validCertNoRegex.test(values.certNo)) {
    //   errors.certNo = "Please enter a valid certification number.";
    // }

    return errors;
  };

  const handleNameChanged = (event) => {
    const value = event.target.value;
    setName(value);
    customAlert("changed to value:" + value);
    if (value != originalSettings.name) {
      setChanged(true);
    } else {
      if(originalSettings.certNo==certNo && originalSettings.ownerDisplay==ownerDisplay) setChanged(false);
    }
  };

  const handleOwnerDisplayChange = (event) => {
    const value = event.target.value;
    setOwnerDisplay(value);
    if (value != originalSettings.ownerDisplay) {
      setChanged(true);
    } else {
      if(originalSettings.certNo==certNo && originalSettings.name==name) setChanged(false);
    }
  };

  const handleCertNoChanged = (event) => {
    const value = event.target.value;
    setCertNo(value);
    if (value != originalSettings.certNo) {
      setChanged(true);
    } else {
      if(originalSettings.ownerDisplay==ownerDisplay && originalSettings.name==name) setChanged(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const createPutImageRequest = (email, fileName, base64EncodedImageData) => {
    /* /api/v1/image */
    const request = {
      email: email,
      file_name: fileName,
      image: base64EncodedImageData,
    };
    return request;
  };

  const handleImageChanged = async (e) => {
    setShowAlert(false);
    setShowSpinnerUpload(true);
    setErrorUpload("");
    try {
      const email = location.state.email;
      const file = e.target.files[0];
      const imageURL = URL.createObjectURL(e.target.files[0]);
      const base64EncodedImageData = await toBase64(file).catch((e) =>
        Error(e)
      );
      if (base64EncodedImageData instanceof Error) {
        console.log("Error: ", base64EncodedImageData.message);
        setErrorUpload(base64EncodedImageData.message);
        return;
      }

      // Upload image to cloud
      const fileName = email + ".jpg";
      imageToBase64(imageURL)
        .then(async (response) => {
          const putImageRequest = createPutImageRequest(
            email,
            fileName,
            base64EncodedImageData
          );
          let updateUserPhotoResponse = await updateUserPhoto(putImageRequest);
          setImage(imageURL);
          if (updateUserPhotoResponse.status === 201) {
            setShowSpinnerUpload(false);
            setShowAlert(true);
          } else {
            setErrorUpload("Uploading image failed.");
          }
          setShowAlert(true);
        })
        .catch((error) => {
          setShowSpinnerUpload(false);
          throw error;
        });
    } catch (error) {
      setErrorUpload(error.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: location.state.email,
          name: name,
          certNo: certNo,
          ownerDisplay: ownerDisplay,
          submit: false,
        }}
        validate={validateAccountInfo}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setShowAlert(false);
          setShowSpinnerSaveChanges(true);

          try {
            // await dispatch(updateUserAttribute("name", name));
            // await dispatch(updateUserAttribute("custom:certNo", certNo));
            if(certNo === undefined|| certNo === null) {
              await dispatch(updateUserAttributes(["name", "custom:ownerDisplay"], [name, ownerDisplay]));
            } else {
              await dispatch(updateUserAttributes(["name", "custom:certNo", "custom:ownerDisplay"], [name, certNo, ownerDisplay]));
            }

            setChanged(false);
          } catch (error) {
            alert(JSON.stringify(error))
            let message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
          setShowAlert(true);
          setShowSpinnerSaveChanges(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {showAlert && (
              <Alert
                mt={2}
                mb={1}
                severity={errors.submit || errorUpload ? "error" : "success"}
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                {errors.submit
                  ? errors.submit
                  : errorUpload
                  ? errorUpload
                  : "Successfully saved."}
              </Alert>
            )}
            <Card mb={6} style={{ minHeight: "290px" }}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={8}>
                    <Typography variant="h6" gutterBottom>
                      Account information
                    </Typography>
                    <div className="s20"></div>
                    <TextField
                      type="email"
                      disabled
                      name="email"
                      label="Email Address *"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      my={2}
                    />
                    <TextField
                      type="email"
                      name="ownerDisplay"
                      label="Email Address that will display in report"
                      value={ownerDisplay}
                      error={Boolean(touched.ownerDisplay && errors.ownerDisplay)}
                      fullWidth
                      helperText={touched.ownerDisplay && errors.ownerDisplay}
                      onBlur={handleBlur}
                      onChange={handleOwnerDisplayChange}
                      my={2}
                    />
                    <TextField
                      type="text"
                      name="name"
                      label="Full name *"
                      value={name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleNameChanged}
                      my={2}
                    />
                    <TextField
                      type="text"
                      name="certNo"
                      label="Certification number"
                      value={certNo}
                      error={Boolean(touched.certNo && errors.certNo)}
                      fullWidth
                      helperText={touched.certNo && errors.certNo}
                      onBlur={handleBlur}
                      onChange={handleCertNoChanged}
                      my={2}
                    />
                    <div className="s40"></div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      {
                        <>
                          {changed ? (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              classes={{
                                root: "button",
                                label: "button-label",
                              }}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              classes={{
                                root: "button",
                                label: "button-label",
                              }}
                              style={{
                                background: "#E0E0E0",
                                color: "#FFFFFF",
                              }}
                              disabled
                            >
                              Save
                            </Button>
                          )}
                        </>
                      }
                      {showSpinnerSaveChanges ? (
                        <div className="settings-changes-spinner">
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    container
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <CenteredContent>
                      <Space height={"20px"} />
                      <BigAvatar alt="Remy Sharp" src={image} />
                      <div className="s10"></div>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="user-photo-raised-button-file"
                        multiple
                        type="file"
                        onChange={handleImageChanged}
                      />
                      <label htmlFor="user-photo-raised-button-file">
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                          >
                            {
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  classes={{
                                    root: "button",
                                    label: "button-label",
                                  }}
                                >
                                  <CloudUpload mr={2} /> Upload
                                </Button>
                              </>
                            }
                            {showSpinnerUpload ? (
                              <div className="settings-changes-spinner">
                                <CircularProgress size={20} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                        <div className="s10"></div>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          For best results, use an image at least 128px by 128px
                          in .jpg format
                        </Typography>
                        {/*<div className="s20"></div>*/}
                      </label>
                    </CenteredContent>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
}

export default AccountInfo;
