export const section1 = {
  title: 'Introduction',
  p1: 'From [START_DATE] to [END_DATE], we had collected [DATA_POINTS_NUMBER] data points to be measured from EcoQube device. The average of [DURATION] is [RADON_AVG_VALUE] pCi/L and Min value is [RADON_MIN_VALUE] pCi/L and Max value is [RADON_MAX_VALUE] pCi/L during your selected days. EPA testing guidelines, radon measurement equipment is required to be located 20 inches above from the floor. It is highly recommended to place the radon measurement equipment on a chair or table made of a material other than stone or soil and to keep it away from walls or windows, high heat, and high humidity. The test result reflects the amount of radon measured for the time period and location that the CRM was placed. If all EPA testing protocols were carefully followed, it is reasonable to assume that this is an accurate assessment of the average level of radon to which this continuous monitor was exposed.'
}

export const section2 = {
  title: 'Radon fluctuation and trends',
  p1: 'Radon levels tend to fluctuate (within the day, over the season, due to changes in weather conditions, wind from open windows, humidity, etc.), so the recommended course of action is to run the test with the EcoQube continuously for at least 90 days. If you see below ‘Radon Trends’ graph, there were [NUM_HIGHTER_4] times spikes higher than 4.0 pCi/L and [NUM_HIGHTER_2_7] times higher than 2.7 pCi/L during selected days.',
  p2: 'Weather is one of the most common factors affecting radon levels. Changes in weather – particularly extreme weather events – can change the pressure differences and therefore change how radon enters your home. Some weather events, like storms, typically cause radon levels in a home to rise. Other events, like heavy winds, can cause radon levels to either rise or fall. Essentially, weather events can cause your radon levels levels to either rise or fall. All kinds of weather affect your test – and there’s no easy way to know exactly which way your test was affected. Typically, light weather events – like a light rainfall, low winds, mild snowfall, etc., do not dramatically affect radon levels. But, heavy weather events – severe storms, high winds, etc., often do affect radon levels tremendously.'
}

export const section3 = {
  title: 'Radon status percentage for Action Level',
  p1: 'According to the EPA, the maximum “acceptable” level of radon is 4.0 pCi/L, but even that level is not “safe”, per se. The EPA strongly recommends you consider radon mitigation between levels 2.7 and 4.0.',
  p2: 'The ideal number of radon measurement would be zero but, unfortunately, this level is nonexistent. Radon is a naturally-occurring radioactive gas that is found everywhere.'
}
