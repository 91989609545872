import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";

const Error48HrDialog = ({
  isEligible,
  open48HrErrorPopup,
  handleFinal,
  handleClose,
  handle48HrErrorPrevious,
  response
}) => {
  const setTitle = () => {
      if (!response) return "";

      switch (response.resultcode) {
          case 200:
              return "Confirm Report Generation";
          case 202:
              return "Insufficient Data for Report Generation";
          case 270:
              return "Confirm Report Generation";
          default:
              return "Insufficient Data for Report Generation";
      }
  }

  return (
    <Dialog
      // fullWidth={fullWidth}
      titleStyle={{ textAlign: "center" }}
      maxWidth="md"
      maxHeight={"sm"}
      open={open48HrErrorPopup}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title2"
    >
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="s20"></div>
        <DialogTitle id="max-width-dialog-title2">
          <Typography variant="h5" align="center">
            { setTitle() }
          </Typography>
        </DialogTitle>
          { isEligible ? (
              <DialogContent>
                  <Container
                      style={{
                          width: "700px",
                          height: "400px",
                      }}
                  >
                      <Grid
                          container
                          style={{
                              paddingTop: "20px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                          }}
                      >
                          <Grid item>
                              <Typography
                                  style={{
                                      color: "#B00020",
                                      marginBottom: "20px",
                                  }}
                              >
                                  Are you sure you want to generate the 48-hour clearance radon report?
                                  Once generated, this report cannot be regenerated,
                                  so please ensure all information is accurate and up-to-date.
                              </Typography>
                              <Typography
                                  style={{ marginBottom: "20px" }}
                              >
                                  We provide two complimentary 48-hour clearance radon reports per quarter.
                                  To generate the report,
                                  the device must be activated and connected to a Wi-Fi network for at least 72 hours.
                              </Typography>
                              <Typography style={{ marginBottom: "20px" }}>
                                  Please confirm that you have entered all the required information for your radon report:
                                  <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
                                      <li>Device information</li>
                                      <li>Client information</li>
                                      <li>Location information</li>
                                  </ul>
                              </Typography>
                              <Typography>
                                  If the information is not complete, close this window, go to your dashboard,
                                  select the device, update all necessary information, and then try generating the report again.
                              </Typography>
                          </Grid>
                      </Grid>
                  </Container>
              </DialogContent>
          ) : (
              <DialogContent>
                  <Container
                      style={{
                          width: "700px",
                          height: "400px",
                      }}
                  >
                      <Grid
                          container
                          style={{
                              paddingTop: "20px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                          }}
                      >
                          <Grid item>
                              <Typography
                                  style={{
                                      color: "#B00020",
                                      marginBottom: "20px",
                                  }}
                              >
                                  The selected device currently lacks sufficient data to generate a 48-hour clearance radon report.
                                  Please ensure the device is activated and connected to Wi-Fi network.
                              </Typography>
                          </Grid>
                      </Grid>
                  </Container>
              </DialogContent>
          )}
        <div className="s20"></div>
        <DialogActions>
          <Box
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
            }}
          >
            <Button
              style={{
                color: "#000000",
                width: "150px",
                height: "36px",
              }}
              onClick={handleClose}
            >
              <u>Cancel</u>
            </Button>
          </Box>
          <Box
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                background: "#E0E0E0",
                color: "#000000",
                width: "150px",
                height: "36px",
              }}
              onClick={handle48HrErrorPrevious}
              color="primary"
            >
              Previous
            </Button>
            <Box style={{ width: "10px" }} />
            <Button
              style={{
                  background: isEligible
                      ? "#3DB5E6"
                      : "#E0E0E0",
                  color: "#FFFFFF",
                  width: "150px",
                  height: "36px",
              }}
              onClick={handleFinal}
              color="primary"
              disabled={!isEligible}
            >
              Preview
            </Button>
          </Box>
        </DialogActions>
        <div className="s20"></div>
      </div>
    </Dialog>
  );
};

export default Error48HrDialog;
