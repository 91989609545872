import * as types from "../../constants";
import {
  getOrderList,
  createOrder,
  getOrderDetail, getOrderDetailByQty,
} from "../services/orderService";

export function getOrderListAction(email) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_LIST_REQUEST });

    return getOrderList(email)
      .then((response) => {
        dispatch({
          type: types.ORDER_LIST_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_LIST_FAILURE });
        throw error;
      });
  };
}

export function getOrderDetailAction(orderId) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_DETAIL_REQUEST });

    return getOrderDetail(orderId)
      .then((response) => {
        dispatch({
          type: types.ORDER_DETAIL_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_DETAIL_FAILURE });
        throw error;
      });
  };
}

export function getOrderDetailByQtyAction(product, state, quantity) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_DETAIL_BY_QTY_REQUEST });

    return getOrderDetailByQty(product, state, quantity)
      .then((response) => {
        dispatch({
          type: types.ORDER_DETAIL_BY_QTY_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_DETAIL_BY_QTY_FAILURE });
        throw error;
      });
  };
}

export function createOrderAction(email, data) {
  return async (dispatch) => {
    dispatch({ type: types.ORDER_CREATE_REQUEST });

    return createOrder(email, data)
      .then((response) => {
        dispatch({
          type: types.ORDER_CREATE_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({ type: types.ORDER_CREATE_FAILURE });
        throw error;
      });
  };
}
