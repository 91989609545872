import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

const Contact = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">General Support</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Please email us at{" "}
              <Link href="mailto:support@ecosense.io">support@ecosense.io</Link>{" "}
              or fill out the support form below. <br />
              Tell us about the challenge you’re experiencing and we’ll get back
              to you within 24 hours.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="https://ecosense.io/pages/contact-us"
              variant="contained"
              color="primary"
              mt={3}
              classes={{ root: "button", label: "button-label" }}
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Contact;
