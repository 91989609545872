import {Typography} from "@material-ui/core";
import React from "react";
import Footer from "../PageMain/Footer";

const PageUncertainty= () => {
  const styles = {
    reportTable: {
      border: "solid 0.5px black",
      borderCollapse: "collapse",
      fontSize: "10px",
    },
    reportTableBlue: {
      border: "solid 0.5px black",
      borderCollapse: "collapse",
      fontSize: "10px",
      background: "lightcyan",
    },
  };

  return(
    <>
      <div
        style={{
          marginTop: 30,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Typography
          style={{
            textAlign: "start",
            fontWeight: 500,
            color: "black",
            fontStyle: "italic",
            fontSize: "10px"
          }}
        >
          % Uncertainty of our EcoQube Pro
        </Typography>

        <div style={{ marginTop: 10 }} />

        <table
          style={{
            width: "100%",
            border: "solid 0.5px black",
            borderCollapse: "collapse",
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr bgcolor={"lightgray"} height={"36px"}>
              <th style={styles.reportTable}>Radon Level<br />(pCi/L)</th>
              <th style={styles.reportTable}>%<br />Uncertainty</th>
              <th style={styles.reportTable}>Radon Level<br />(pCi/L)</th>
              <th style={styles.reportTable}>%<br />Uncertainty</th>
              <th style={styles.reportTable}>Radon Level<br />(pCi/L)</th>
              <th style={styles.reportTable}>%<br />Uncertainty</th>
              <th style={styles.reportTable}>Radon Level<br />(pCi/L)</th>
              <th style={styles.reportTable}>%<br />Uncertainty</th>
            </tr>
          </thead>
          <tbody>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.1</td>
              <td style={styles.reportTable}>-</td>
              <td style={styles.reportTableBlue}>3.1</td>
              <td style={styles.reportTable}>1.5%</td>
              <td style={styles.reportTableBlue}>6.1</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>9.1</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.2</td>
              <td style={styles.reportTable}>5.9%</td>
              <td style={styles.reportTableBlue}>3.2</td>
              <td style={styles.reportTable}>1.5%</td>
              <td style={styles.reportTableBlue}>6.2</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>9.2</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.3</td>
              <td style={styles.reportTable}>4.8%</td>
              <td style={styles.reportTableBlue}>3.3</td>
              <td style={styles.reportTable}>1.5%</td>
              <td style={styles.reportTableBlue}>6.3</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.3</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.4</td>
              <td style={styles.reportTable}>4.2%</td>
              <td style={styles.reportTableBlue}>3.4</td>
              <td style={styles.reportTable}>1.4%</td>
              <td style={styles.reportTableBlue}>6.4</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.4</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.5</td>
              <td style={styles.reportTable}>3.7%</td>
              <td style={styles.reportTableBlue}>3.5</td>
              <td style={styles.reportTable}>1.4%</td>
              <td style={styles.reportTableBlue}>6.5</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.5</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.6</td>
              <td style={styles.reportTable}>3.4%</td>
              <td style={styles.reportTableBlue}>3.6</td>
              <td style={styles.reportTable}>1.4%</td>
              <td style={styles.reportTableBlue}>6.6</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.6</td>
              <td style={styles.reportTable}>0.9%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.7</td>
              <td style={styles.reportTable}>3.1%</td>
              <td style={styles.reportTableBlue}>3.7</td>
              <td style={styles.reportTable}>1.4%</td>
              <td style={styles.reportTableBlue}>6.7</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.7</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.8</td>
              <td style={styles.reportTable}>2.9%</td>
              <td style={styles.reportTableBlue}>3.8</td>
              <td style={styles.reportTable}>1.4%</td>
              <td style={styles.reportTableBlue}>6.8</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.8</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>0.9</td>
              <td style={styles.reportTable}>2.8%</td>
              <td style={styles.reportTableBlue}>3.9</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>6.9</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>9.9</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.0</td>
              <td style={styles.reportTable}>2.6%</td>
              <td style={styles.reportTableBlue}>4.0</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>7.0</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.0</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.1</td>
              <td style={styles.reportTable}>2.5%</td>
              <td style={styles.reportTableBlue}>4.1</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>7.1</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.1</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.2</td>
              <td style={styles.reportTable}>2.4%</td>
              <td style={styles.reportTableBlue}>4.2</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>7.2</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.2</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.3</td>
              <td style={styles.reportTable}>2.3%</td>
              <td style={styles.reportTableBlue}>4.3</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>7.3</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.3</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.4</td>
              <td style={styles.reportTable}>2.2%</td>
              <td style={styles.reportTableBlue}>4.4</td>
              <td style={styles.reportTable}>1.3%</td>
              <td style={styles.reportTableBlue}>7.4</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.4</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.5</td>
              <td style={styles.reportTable}>2.2%</td>
              <td style={styles.reportTableBlue}>4.5</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>7.5</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.5</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.6</td>
              <td style={styles.reportTable}>2.1%</td>
              <td style={styles.reportTableBlue}>4.6</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>7.6</td>
              <td style={styles.reportTable}>1.0%</td>
              <td style={styles.reportTableBlue}>10.6</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.7</td>
              <td style={styles.reportTable}>2.0%</td>
              <td style={styles.reportTableBlue}>4.7</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>7.7</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>10.7</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.8</td>
              <td style={styles.reportTable}>2.0%</td>
              <td style={styles.reportTableBlue}>4.8</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>7.8</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>10.8</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>1.9</td>
              <td style={styles.reportTable}>1.9%</td>
              <td style={styles.reportTableBlue}>4.9</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>7.9</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>10.9</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.0</td>
              <td style={styles.reportTable}>1.9%</td>
              <td style={styles.reportTableBlue}>5.0</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>8.0</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.0</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.1</td>
              <td style={styles.reportTable}>1.8%</td>
              <td style={styles.reportTableBlue}>5.1</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>8.1</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.1</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.2</td>
              <td style={styles.reportTable}>1.8%</td>
              <td style={styles.reportTableBlue}>5.2</td>
              <td style={styles.reportTable}>1.2%</td>
              <td style={styles.reportTableBlue}>8.2</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.2</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.3</td>
              <td style={styles.reportTable}>1.7%</td>
              <td style={styles.reportTableBlue}>5.3</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.3</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.3</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.4</td>
              <td style={styles.reportTable}>1.7%</td>
              <td style={styles.reportTableBlue}>5.4</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.4</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.4</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.5</td>
              <td style={styles.reportTable}>1.7%</td>
              <td style={styles.reportTableBlue}>5.5</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.5</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.5</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.6</td>
              <td style={styles.reportTable}>1.6%</td>
              <td style={styles.reportTableBlue}>5.6</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.6</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.6</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.7</td>
              <td style={styles.reportTable}>1.6%</td>
              <td style={styles.reportTableBlue}>5.7</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.7</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.7</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.8</td>
              <td style={styles.reportTable}>1.6%</td>
              <td style={styles.reportTableBlue}>5.8</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.8</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.8</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>2.9</td>
              <td style={styles.reportTable}>1.5%</td>
              <td style={styles.reportTableBlue}>5.9</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>8.9</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>11.9</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
            <tr align={"center"} height={"21px"}>
              <td style={styles.reportTableBlue}>3.0</td>
              <td style={styles.reportTable}>1.5%</td>
              <td style={styles.reportTableBlue}>6.0</td>
              <td style={styles.reportTable}>1.1%</td>
              <td style={styles.reportTableBlue}>9.0</td>
              <td style={styles.reportTable}>0.9%</td>
              <td style={styles.reportTableBlue}>12.0</td>
              <td style={styles.reportTable}>0.8%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default PageUncertainty;