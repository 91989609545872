import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import "../../assets/css/common.css";
import "../../assets/css/order.css";

import { Add as AddIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import SubscriptionInterface from "../../integration/internal/SubscriptionInterface";
import ReportInterface from "../../integration/internal/ReportInterface";
import Confirm48HrDialog from "./Dialogs/Confirm48HrDialog";
import NoDevicesErrorDialog from "./Dialogs/NoDevicesErrorDialog";
import SelectDurationDialog from "./Dialogs/SelectDurationDialog";
import SelectDeviceDialog from "./Dialogs/SelectDeviceDialog";
import { GeneratedReportsTable } from "./Tables/GeneratedReportsTable";
import Error48HrDialog from "./Dialogs/Error48HrDialog";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ReportList() {
  const history = useHistory();
  const location = useLocation();
  const [deviceSelected, setDeviceSelected] = useState({
    isSelected: false,
    serial: null,
  });
  const [durationSelected, setDurationSelected] = useState({
    isSelected: false,
    duration: null,
  });
  const [isEligible, setIsEligible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeviceForm, setOpenDeviceForm] = useState(false);
  const [openDurationForm, setOpenDurationForm] = useState(false);
  const [open48HrConfirmPopup, setOpen48HrConfirmPopup] = useState(false);
  const [open48HrErrorPopup, setOpen48HrErrorPopup] = useState(false);
  const [openNoDataMsg, setOpenNoDataMsg] = useState(false);
  const [devices, setDevices] = useState([]);
  const [response, setResponse] = useState(null);

  location.state = {
    ...location.state,
    email: localStorage.getItem("0xaccountId"),
    name: localStorage.getItem("0xuserId"),
  };

  const checkAvailableDevices = async () => {
    let res = await SubscriptionInterface.retrieveDevicesWithoutSummary(
      location.state.email
    );
    if (res && res.data && res.data.devices && res.data.devices.length > 0) {
      setDevices(res.data);
      return true;
    } else return false;
  };

  const handleClickOpen = async () => {
    let haveDevices = await checkAvailableDevices();
    if (haveDevices) setOpenDeviceForm(true);
    else {
      setOpenNoDataMsg(true);
    }
  };

  const handleClose = () => {
    setOpenDurationForm(false);
    setOpenDeviceForm(false);
    setOpen48HrConfirmPopup(false);
    setOpen48HrErrorPopup(false);
  };

  const eligibilityCheck = async (email, serial) => {
    let ret = false;
    try {
      let res = await ReportInterface.retrieve48hrEligibility(email, serial);

      if (res) {
        setResponse(res);
        switch (res.resultcode) {
          case 270: // is eligible
            setIsEligible(true);
            ret = true;
            break;
          default:
            // something went wrong
            setIsEligible(false);
            ret = false;
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
    return ret;
  }

  const eligibilityConfirm = async (email, serial) => {
    let ret = false;
    try {
      let res = await ReportInterface.retrieve48HrReportDetails(email, serial);

      if (res) {
        setResponse(res);
        switch (res.resultcode) {
          case 200:
            setIsEligible(true);
            ret = true;
            break;
          case 202:
            setIsEligible(true);
            ret = false;
            break;
          default:
            setIsEligible(false);
            ret = false;
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
    return ret;
  }

  const handleFirstEligibilityCheck = async () => {
    setIsLoading(true);
    await eligibilityCheck(location.state.email, deviceSelected.serial[0]);
    handleClose();
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    setOpenDurationForm(true);
  };

  const handleSecondEligibilityCheck = async () => {
    setIsLoading(true);

    if (await eligibilityCheck(location.state.email, deviceSelected.serial[0]) && await eligibilityConfirm(location.state.email, deviceSelected.serial[0])) {
      setOpen48HrErrorPopup(false);
      setOpen48HrConfirmPopup(true);
    }
    else {
      setOpen48HrErrorPopup(true);
      setOpen48HrConfirmPopup(false);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    setOpenDurationForm(false);
  };

  const handleNextClose = () => {
    setOpenDurationForm(false);
  };

  const handle48HrConfirm = async () => {
    setIsLoading(true);

    if (await eligibilityConfirm(location.state.email, deviceSelected.serial[0])) {
      setOpen48HrErrorPopup(false);
      setOpen48HrConfirmPopup(true);

      history.push({
        pathname: "/reports/details",
        state: {
          email: localStorage.getItem("0xaccountId"),
          serial_number: deviceSelected.serial,
          duration: durationSelected.duration,
        },
      });
    }
    else {
      setOpen48HrErrorPopup(true);
      setOpen48HrConfirmPopup(false);
    }

    setIsLoading(false);
  };

  const handleFinal = () => {
    console.log("handleFinal :  " + JSON.stringify(durationSelected.duration));
    handleNextClose();
    setOpen48HrErrorPopup(false);

    history.push({
      pathname: "/reports/details",
      state: {
        email: localStorage.getItem("0xaccountId"),
        serial_number: deviceSelected.serial,
        duration: durationSelected.duration,
      },
    });
  };

  const handleDeviceSelect = (isSelected, serial) => {
    setDeviceSelected({ isSelected: isSelected, serial: serial });
  };

  const handleDurationSelect = (isSelected, duration) => {
    setDurationSelected({ isSelected: isSelected, duration: duration });
  };

  const handle48HrPrevious = () => {
    setOpenDurationForm(true);
    setOpen48HrConfirmPopup(false);
  };

  const handle48HrErrorPrevious = () => {
    setOpenDurationForm(true);
    setOpen48HrErrorPopup(false);
  };

  const handleGoPrevious = () => {
    setOpenDurationForm(false);
    setOpenDeviceForm(true);
  };

  const handleNoDataClose = () => {
    setOpenNoDataMsg(false);
  };

  useEffect(() => {
    if (location.state && location.state.sn) {
      setDeviceSelected({ isSelected: true, serial: [location.state.sn] });
      setOpenDurationForm(true);
      eligibilityCheck(location.state.email, location.state.sn);
    }
  }, []);

  return (
    // <React.Fragment>
    <>
      <Helmet title="Reports" />
      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Radon Reports
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/reports/list">
              Radon Reports
            </Link>
            <Typography>Overview</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              classes={{ root: "button", label: "button-label" }}
            >
              <AddIcon />
              New Report
            </Button>
            <SelectDeviceDialog
              isLoading={isLoading}
              openDeviceForm={openDeviceForm}
              handleClose={handleClose}
              devices={devices}
              handleDeviceSelect={handleDeviceSelect}
              deviceSelected={deviceSelected}
              handleFirstEligibilityCheck={handleFirstEligibilityCheck}
            />
            <SelectDurationDialog
              isLoading={isLoading}
              openDurationForm={openDurationForm}
              handleDurationSelect={handleDurationSelect}
              isEligible={isEligible}
              handleNextClose={handleNextClose}
              handleClose={handleClose}
              handleGoPrevious={handleGoPrevious}
              durationSelected={durationSelected}
              handleSecondEligibilityCheck={handleSecondEligibilityCheck}
              handleFinal={handleFinal}
            />
            <Confirm48HrDialog
              isLoading={isLoading}
              open48HrConfirmPopup={open48HrConfirmPopup}
              handle48HrConfirm={handle48HrConfirm}
              handleClose={handleClose}
              handle48HrPrevious={handle48HrPrevious}
            />
            <Error48HrDialog
              isEligible={isEligible}
              open48HrErrorPopup={open48HrErrorPopup}
              handleFinal={handleFinal}
              handleClose={handleClose}
              handle48HrErrorPrevious={handle48HrErrorPrevious}
              response={response}
            />
            <NoDevicesErrorDialog
              openNoDataMsg={openNoDataMsg}
              handleNoDataClose={handleNoDataClose}
              handleClose={handleClose}
            />
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GeneratedReportsTable />
        </Grid>
      </Grid>
    </>
  );
}

export default ReportList;
