import React, {useState} from "react";
import styled, {withTheme} from "styled-components/macro";

import {CardContent, Card as MuiCard, Typography} from "@material-ui/core";
import {spacing} from "@material-ui/system";

import {Line} from "react-chartjs-2";
import {UNIT_CONVERSION_SCALE} from '../../../constants/values';
import moment from 'moment';
import Box from '@material-ui/core/Box';

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px,
  width:100%;
`;

function RadonReportChart({theme, radon, type}) {
  let data, chartType;
  let sortedRadon = sortDate(radon, getComparator('asc', 'datetime'));
  if (type === 'MONTH') {
    chartType = '30 days ';
    data = {
      labels: sortedRadon.map((r) => moment(r.datetime * 1000).format('MM/DD HH:mm')),
      datasets: [
        {
          label: "Level (pCi/L)",
          fill: true,
          backgroundColor: "transparent",
          borderColor: theme.palette.secondary.main,
          data: sortedRadon.map((r) => (r.radon_level / UNIT_CONVERSION_SCALE).toFixed(1)),
        },
      ],
    };
  } else if (type === 'QUARTER') {
    chartType = '3 months ';
    data = {
      labels: sortedRadon.map((r) => r.datetime.substring(4, 6) + '/' + r.datetime.substring(6) + '/' + r.datetime.substring(0, 4)),
      datasets: [
        {
          label: "Level (pCi/L)",
          fill: true,
          backgroundColor: "transparent",
          borderColor: theme.palette.secondary.main,
          data: sortedRadon.map((r) => (r.radon_level / UNIT_CONVERSION_SCALE).toFixed(1)),
        },
      ],
    };
  } else if (type === 'HALF_YEAR') {
    chartType = '6 months ';
    data = {
      labels: sortedRadon.map((r) => r.datetime.substring(4, 6) + '/' + r.datetime.substring(6) + '/' + r.datetime.substring(0, 4)),
      datasets: [
        {
          label: "Level (pCi/L)",
          fill: true,
          backgroundColor: "transparent",
          borderColor: theme.palette.secondary.main,
          data: sortedRadon.map((r) => (r.radon_level / UNIT_CONVERSION_SCALE).toFixed(1)),
        },
      ],
    };
  } else if (type === 'YEAR') {
    chartType = '1 year ';
    data = {
      labels: sortedRadon.map((r) => r.datetime.substring(4, 6) + '/' + r.datetime.substring(6) + '/' + r.datetime.substring(0, 4)),
      datasets: [
        {
          label: "Level (pCi/L)",
          fill: true,
          backgroundColor: "transparent",
          borderColor: theme.palette.secondary.main,
          data: sortedRadon.map((r) => (r.radon_level / UNIT_CONVERSION_SCALE).toFixed(1)),
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  function sortDate(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Spacer mb={6}/>
        <Box style={{width:'800px', height:'300px', alignItems:'center', justifyContent:'center'}}>
          <Line data={data} options={options}/>
        </Box>
        <Typography variant="body2" gutterBottom align={'center'}>Time</Typography>
        <Typography variant="body2" align={'center'} gutterBottom style={{fontWeight: 300, fontSize: 14}}>
          [{chartType}{' of Radon Trend'}]
        </Typography>
      </CardContent>
    </Card>
  );
}

export default withTheme(RadonReportChart);
