import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useFormik } from "formik";
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent, CircularProgress,
  Grid, MenuItem,
  Typography as MuiTypography,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DefaultRadio from '../../../views/components/DefaultRadio'
import {
  ACTIVE,
  ALARM_EVENT,
  BQ, BUILDING_TYPE,
  INACTIVE, MITIGATION_SYSTEM, OFF, ON,
  PCI, ROOM_TYPE, STRUCTURE_TYPE, TEST_FLOOR,
  THRESHOLD_EVENT,
  UNIT_CONVERSION_SCALE,
  UNIT_EVENT
} from '../../../constants/values';
import SubscriptionInterface from '../../../integration/internal/SubscriptionInterface';
import Space from '../../../components/Space';
import {customAlert} from '../../../utils/AlertWrapper';
import {labelForTitle, textForComment, valueForText, valueForThresholdInput} from "./CardLayout";
import Select from "@material-ui/core/Select";
import {KeyboardDatePicker} from "@material-ui/pickers";
import CustomTextField from "../../../components/CustomTextField";
import CustomTextArea from "../../../components/CustomTextArea";
import _ from "lodash";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const StyledTypography = styled(Typography)({
  padding: 10,
});

const LocationInfoCard = ({data, callback}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [mitigationInstallation, setMitigationInstallation] = useState(null);
  const [mitigationDate, setMitigationDate] = useState(null);
  const [yearBuilt, setYearBuilt] = useState(null);
  const [buildingType, setBuildingType] = useState(null);
  const [structureType, setStructureType] = useState(null);
  const [testFloor, setTestFloor] = useState(null);
  const [roomType, setRoomType] = useState(null);
  const [comments, setComments] = useState(null);

  const [mitigationInstallationChanged, setMitigationInstallationChanged] = useState(false);
  const [mitigationDateChanged, setMitigationDateChanged] = useState(false);
  const [yearBuiltChanged, setYearBuiltChanged] = useState(false);
  const [buildingTypeChanged, setBuildingTypeChanged] = useState(false);
  const [structureTypeChanged, setStructureTypeChanged] = useState(false);
  const [testFloorChanged, setTestFloorChanged] = useState(false);
  const [roomTypeChanged, setRoomTypeChanged] = useState(false);
  const [commentsChanged, setCommentsChanged] = useState(false);

  const isValueChanged = () => {
    return mitigationInstallationChanged || mitigationDateChanged || yearBuiltChanged || buildingTypeChanged || structureTypeChanged || testFloorChanged || roomTypeChanged || commentsChanged;
  };

  useEffect(() => {
    const devicePlacement = data.device_placement;
    if (devicePlacement) {
      if (mitigationInstallation == null || mitigationInstallation == undefined) {
        const mitigationInstalledValue = (devicePlacement.mitigation_installed) ? Number(devicePlacement.mitigation_installed) : null;
        setMitigationInstallation(mitigationInstalledValue);

        if (!mitigationInstalledValue || mitigationInstalledValue === MITIGATION_SYSTEM.NOT_INSTALLED.value) {
          setMitigationDate(null);
        } else {
          const originalMitiInstalledDateValue = _.get(data, 'device_placement.mitigation_installed_date');
          const originalMitiInstalledDate = (originalMitiInstalledDateValue) ? getFormattedDate(new Date(originalMitiInstalledDateValue)) : null;
          setMitigationDate(originalMitiInstalledDate);
        }
      }

      if (yearBuilt == null || yearBuilt == undefined) {
        setYearBuilt(devicePlacement.year_built);
      }

      if (!buildingType) {
        setBuildingType(devicePlacement.bld_type);
      }

      if (!structureType) {
        setStructureType(devicePlacement.str_type);
      }

      if (!testFloor) {
        setTestFloor(devicePlacement.location_floor);
      }

      if (!roomType) {
        setRoomType(devicePlacement.room_type);
      }

      if (!comments) {
        setComments(devicePlacement.comments);
      }
    }

  });

  const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const onMitigationInstallationChange = (event) => {
    const originalMitigationInstallationValue = Number(_.get(data, 'device_placement.mitigation_installed'));
    const changedMitigationInstallationValue = Number(event.target.value);

    const originalMitiInstalledDateValue = _.get(data, 'device_placement.mitigation_installed_date');
    const originalMitiInstalledDateObj = (originalMitiInstalledDateValue) ? new Date(originalMitiInstalledDateValue) : null;

    setMitigationInstallation(changedMitigationInstallationValue);
    if (changedMitigationInstallationValue === MITIGATION_SYSTEM.NOT_INSTALLED.value) {
      setMitigationDate(null);
    }

    if (changedMitigationInstallationValue !== originalMitigationInstallationValue) {
      setMitigationInstallationChanged(true);
    } else {
      setMitigationInstallationChanged(false);
    }

    if (originalMitiInstalledDateValue !== mitigationDate) {
      setMitigationDateChanged(true);
    } else {
      setMitigationDateChanged(false);
    }
  };

  const onMitigationDateChange = (value) => {
    const originalMitiInstalledDateValue = _.get(data, 'device_placement.mitigation_installed_date');
    const originDate = (originalMitiInstalledDateValue) ? getFormattedDate(new Date(originalMitiInstalledDateValue)) : null;
    const changedDate = (value) ? getFormattedDate(new Date(value)) : null;
    setMitigationDate(changedDate);

    if (originDate !== changedDate) {
      setMitigationDateChanged(true);
    } else {
      setMitigationDateChanged(false);
    }
  };

  const onYearBuiltChange = (event) => {
    let value = event.target.value;
    if( !value || ( value[value.length-1].match('[0-9]') && value[0].match('[1-9]')) ) {
      if (value.length <= 4) {
        setYearBuilt(value);
      }
    }
    const originalValue = Number(_.get(data, 'device_placement.year_built'));
    if (value !== originalValue) {
      setYearBuiltChanged(true);
    } else {
      setYearBuiltChanged(false);
    }
  };

  const onBuildingTypeChanged = (event) => {
    const value = event.target.value;
    setBuildingType(value);

    const originalValue = _.get(data, 'device_placement.bld_type');
    if (value !== originalValue) {
      setBuildingTypeChanged(true);
    } else {
      setBuildingTypeChanged(false);
    }
  };

  const onStructureTypeChanged = (event) => {
    const value = event.target.value;
    setStructureType(value);

    const originalValue = _.get(data, 'device_placement.str_type');
    if (value !== originalValue) {
      setStructureTypeChanged(true);
    } else {
      setStructureTypeChanged(false);
    }
  };

  const onTestFloorChanged = (event) => {
    const value = event.target.value;
    setTestFloor(value);

    const originalValue = _.get(data, 'device_placement.location_floor');
    if (value !== originalValue) {
      setTestFloorChanged(true);
    } else {
      setTestFloorChanged(false);
    }
  };

  const onRoomTypeChanged = (event) => {
    const value = event.target.value;
    setRoomType(value);

    const originalValue = _.get(data, 'device_placement.room_type');
    if (value !== originalValue) {
      setRoomTypeChanged(true);
    } else {
      setRoomTypeChanged(false);
    }
  };

  const onCommentsChange = (event) => {
    let value = event.target.value;
    if (!value) {
      value = '';
    }

    let originalValue = _.get(data, 'device_placement.comments');
    if (!originalValue) {
      originalValue = '';
    }

    setComments(value);

    if (value !== originalValue) {
      setCommentsChanged(true);
    } else {
      setCommentsChanged(false);
    }
  };


  const onSave = async () => {
    // alert('mitigationDate === ' + mitigationDate);
    setShowSpinner(true);
    let id = localStorage.getItem('0xaccountId');
    let result = await SubscriptionInterface.changeLocationInformation(
      id,
      data.serial_number,
      mitigationInstallation,
      mitigationDate,
      yearBuilt,
      buildingType,
      structureType,
      testFloor,
      roomType,
      comments
    );

    if (result) {
      setMitigationInstallationChanged(false);
      setMitigationDateChanged(false);
      setYearBuiltChanged(false);
      setBuildingTypeChanged(false);
      setStructureTypeChanged(false);
      setTestFloorChanged(false);
      setRoomTypeChanged(false);
      setCommentsChanged(false);

      await callback(data.serial_number);
    } else {
      customAlert('Fail to apply changes');
    }
    setShowSpinner(false);
  };


  const styles = theme => ({
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "yellow !important"
    }
  });

  return (
    !data.serial_number ? (
      <Card mb={3} className='loading-card'>
        <CircularProgress/>
      </Card>
    ) : (
    <Card mb={3} className='common-card'>
      <CardContent>
        <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
          <Box style={{width: '100%'}}>
            <Typography variant={'subtitle1'} mb={3}>
              <Box fontWeight="fontWeightBold">Location Information</Box>
            </Typography>
          </Box>
          <Box style={{width:'60px'}}></Box>
          <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
            {isValueChanged() ?
              <Button style={{background: isValueChanged() ? '#3DB5E6' : '#E0E0E0', color: '#FFFFFF'}} onClick={onSave}>Save</Button> :
              <Button style={{background: '#E0E0E0', color: '#FFFFFF'}} disabled>Save</Button>}
            {
              showSpinner ?
                <div className='save-changes-spinner'>
                  <CircularProgress size={20}/>
                </div> : <></>
            }
          </Box>
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Mitigation Installation</Typography>
        <FormControl component="fieldset">
          <RadioGroup defaultValue={mitigationInstallation} value={mitigationInstallation} aria-label="mitigationInstallation" name="customized-radios" onChange={onMitigationInstallationChange}>
            <Box display='flex' flexDirection={'row'}>
              <FormControlLabel value={MITIGATION_SYSTEM.INSTALLED.value} control={<DefaultRadio/>} label={MITIGATION_SYSTEM.INSTALLED.label}/>
              <Box style={{width: '107px'}}/>
              <FormControlLabel value={MITIGATION_SYSTEM.NOT_INSTALLED.value} control={<DefaultRadio/>} label={MITIGATION_SYSTEM.NOT_INSTALLED.label}/>
            </Box>
          </RadioGroup>
        </FormControl>
        <Typography style={textForComment}>If mitigation installed, enter a date(mm/yy/year) or type unknown</Typography>
        <Space height={'5px'}/>
        <Grid item>
          <KeyboardDatePicker
            autoOk={true}
            fullWidth
            disableToolbar
            PopoverProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }}
            variant="inline"
            format="MM/dd/yyyy"
            margin="dense"
            // error={
            //   formik.touched.startDate && Boolean(formik.errors.startDate)
            // }
            // helperText={formik.touched.startDate && formik.errors.startDate}
            id="startDate"
            // label="Project start date"
            value={mitigationDate}
            onChange={onMitigationDateChange}
            KeyboardButtonProps={{
              "aria-label": "change start date",
            }}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Year Built</Typography>
        <Box display='flex' flexDirection='row'>
          <CustomTextField
            style={valueForThresholdInput}
            name="threshold"
            value={yearBuilt}
            onChange={onYearBuiltChange}
            inputProps={{
              style: {textAlign: 'left'},
              max: 4
            }}
            maxLength={4}
            my={3}
          />
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Building Type</Typography>
        <Box display='flex' flexDirection='row'>
          <Select
            className="dropdown-select"
            id="buidling-type-select"
            value={buildingType}
            onChange={onBuildingTypeChanged}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.SINGLE_FAMILY.value}>{BUILDING_TYPE.SINGLE_FAMILY.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.COMMERCIAL.value}>{BUILDING_TYPE.COMMERCIAL.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.EDUCATIONAL.value}>{BUILDING_TYPE.EDUCATIONAL.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.HEALTHCARE.value}>{BUILDING_TYPE.HEALTHCARE.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.GOVERNMENT.value}>{BUILDING_TYPE.GOVERNMENT.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.MULTI_FAMILY.value}>{BUILDING_TYPE.MULTI_FAMILY.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={BUILDING_TYPE.PUBLIC_BUILDINGS.value}>{BUILDING_TYPE.PUBLIC_BUILDINGS.label}</MenuItem>
          </Select>
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Structure Type</Typography>
        <Box display='flex' flexDirection='row'>
          <Select
            className="dropdown-select"
            id="structure-type-select"
            value={structureType}
            onChange={onStructureTypeChanged}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem className="dropdown-menuitem" value={STRUCTURE_TYPE.BASEMENT.value}>{STRUCTURE_TYPE.BASEMENT.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={STRUCTURE_TYPE.SLAB.value}>{STRUCTURE_TYPE.SLAB.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={STRUCTURE_TYPE.CRAWLSPACE.value}>{STRUCTURE_TYPE.CRAWLSPACE.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={STRUCTURE_TYPE.MULTI_STORY.value}>{STRUCTURE_TYPE.MULTI_STORY.label}</MenuItem>
          </Select>
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Test Floor</Typography>
        <Box display='flex' flexDirection='row'>
          <Select
            className="dropdown-select"
            id="test-floor-select"
            value={testFloor}
            onChange={onTestFloorChanged}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem className="dropdown-menuitem" value={TEST_FLOOR.BASEMENT.value}>{TEST_FLOOR.BASEMENT.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={TEST_FLOOR.FLOOR_1ST.value}>{TEST_FLOOR.FLOOR_1ST.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={TEST_FLOOR.FLOOR_2ND.value}>{TEST_FLOOR.FLOOR_2ND.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={TEST_FLOOR.FLOOR_3RD_OR_ABOVE.value}>{TEST_FLOOR.FLOOR_3RD_OR_ABOVE.label}</MenuItem>
          </Select>
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Room Type</Typography>
        <Box display='flex' flexDirection='row'>
          <Select
            className="dropdown-select"
            id="room-type-select"
            value={roomType}
            onChange={onRoomTypeChanged}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.LIVING_ROOM.value}>{ROOM_TYPE.LIVING_ROOM.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.DINING_ROOM.value}>{ROOM_TYPE.DINING_ROOM.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.BEDROOM.value}>{ROOM_TYPE.BEDROOM.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.PLAYROOM.value}>{ROOM_TYPE.PLAYROOM.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.BASEMENT.value}>{ROOM_TYPE.BASEMENT.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.OFFICE.value}>{ROOM_TYPE.OFFICE.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.CLASSROOM.value}>{ROOM_TYPE.CLASSROOM.label}</MenuItem>
            <MenuItem className="dropdown-menuitem" value={ROOM_TYPE.OTHERS.value}>{ROOM_TYPE.OTHERS.label}</MenuItem>
          </Select>
        </Box>
        <Space height={'20px'}/>
        <Typography style={labelForTitle}>Comments</Typography>
        <TextField
          id="comments"
          // label="Comments"
          type="text"
          multiline
          variant="outlined"
          defaultValue={comments}
          helperText={
            <Box
              component="span"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <span>{`${(comments && comments.length > 0) ? comments.length : 0} / 300`}</span>
            </Box>
          }
          style={{ marginTop: 15 }}
          onChange={onCommentsChange}
          inputProps={{
            maxLength: 300,
            style: {
              height: "110px",
            },
          }}
          fullWidth
        />
      </CardContent>
    </Card>
    )
  );
};


export default LocationInfoCard;
