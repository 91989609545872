import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {format} from "date-fns";
import {Helmet} from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip, CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {green, orange, red, grey} from "@material-ui/core/colors";
import "../../assets/css/common.css";
import "../../assets/css/subscription.css";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import {spacing} from "@material-ui/system";
import {useDispatch} from "react-redux";
import {getSubscriptionListAction, getSubscriptionDetailAction} from "../../models/actions/subscriptionActions";
import _ from "lodash";
import NoSubscriptions from "./NoSubscriptions";
import NoOrders from "./NoOrders";
import Loading from "../components/Loading";
import {customAlert} from "../../utils/AlertWrapper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {monthlySubStatusProperties} from '../../constants/status';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;



const Chip = styled(MuiChip)`
  ${spacing};
  width: ${(props) => '120px'};
  background: ${(props) => monthlySubStatusProperties[props.status].color};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {id: "invoiceNo", alignment: "left", label: "Invoice No."},
  {id: "product", alignment: "left", label: "Product"},
  {id: "description", alignment: "left", label: "Description"},
  {id: "date", alignment: "left", label: "Issue date"},
  {id: "totalDue", alignment: "left", label: "Total"},
  // {id: "status", alignment: "center", label: "Status"},
  // {id: "actions", alignment: "right", label: "Actions"},
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{ "aria-label": "select all" }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const {numSelected} = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Invoices
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer/>
      {/*<div>*/}
      {/*  {numSelected > 0 ? (*/}
      {/*    <Tooltip title="Delete">*/}
      {/*      <IconButton aria-label="Delete">*/}
      {/*        <ArchiveIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  ) : (*/}
      {/*    <Tooltip title="Filter list">*/}
      {/*      <IconButton aria-label="Filter list">*/}
      {/*        <FilterListIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  )}*/}
      {/*</div>*/}
    </Toolbar>
  );
};

function EnhancedTable() {
  const [loaded, setLoaded] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [subscriptions, setSubscriptions] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  location.state.email = localStorage.getItem('0xaccountId');
  location.state.name = localStorage.getItem('0xuserId');
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }

  const getSubscriptionList = async () => {
    let res = await dispatch(
      getSubscriptionListAction(location.state.email)
    );
    if (res && (!res.data || (res.data && res.data.length === 0))) res.data = [];
    else if (!_.isEqual(subscriptions, res.data)) {
      setSubscriptions(res.data);
    }
    setLoaded(true);
  };

  useEffect(() => {
    getSubscriptionList();
  }, [subscriptions]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subscriptions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, invoiceNo) => {
    history.push("/subscriptions/detail?" + invoiceNo);
    // history.push({
    //   pathname: "/subscriptions/detail?" + invoiceNo,
    //   state: {
    //     invoiceNo: invoiceNo,
    //   }
    // });
  };

  const toFixed = (number) => {
    if (number ===0 ) {
      return '$0.00';
    } else if (number) {
      return '$' + number.toFixed(2);
    }
    return '';
  }
  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, subscriptions.length - page * rowsPerPage);

  const useStyles = makeStyles((theme) => ({
    hover: {
      "&:hover": {
        // backgroundColor: "#f0fcff !important",
        cursor: 'pointer',
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {!loaded ? (
        <Loading></Loading>
      ) : (
        subscriptions.length <= 0 ? (
            <NoSubscriptions></NoSubscriptions>
          ) :
          <Wrapper>
            <Paper>
              <EnhancedTableToolbar numSelected={selected.length}/>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={subscriptions.length}
                  />
                  <TableBody>
                    {stableSort(subscriptions, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let date;
                        let formattedDate;
                        if(row.created) {
                          date = new Date(row.created);
                          formattedDate = format(date, "MM/dd/yyyy");
                        } else {
                          formattedDate = 'N/A';
                        }
                        return (
                          <TableRow
                            hover
                            classes={{hover: classes.hover,}}
                            onClick={(event) => handleClick(event, row.invoiceNo)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                            selected={isItemSelected}
                          >
                            {/*<TableCell padding="checkbox">*/}
                            {/*  <Checkbox*/}
                            {/*    checked={isItemSelected}*/}
                            {/*    inputProps={{ "aria-labelledby": labelId }}*/}
                            {/*    onClick={(event) => handleClick(event, row.id)}*/}
                            {/*  />*/}
                            {/*</TableCell>*/}

                            <TableCell align="left" className='cell-invoice-id'>#{row.invoiceNo}</TableCell>
                            <TableCell align="left">{row.product}</TableCell>
                            <TableCell align="left" className='cell-description'>{row.description}</TableCell>
                            <TableCell align="left" className='cell-issue-date'>{formattedDate}</TableCell>
                            <TableCell align="left">{toFixed(row.totalDue)}</TableCell>
                            {/*<TableCell align="center">*/}
                            {/*  {row.status != undefined && (*/}
                            {/*    <Chip*/}
                            {/*      size="small"*/}
                            {/*      mr={1}*/}
                            {/*      mb={1}*/}
                            {/*      label={monthlySubStatusProperties[row.status].msg}*/}
                            {/*      status={row.status}*/}
                            {/*    />*/}
                            {/*  )}*/}
                            {/*</TableCell>*/}

                            {/*<TableCell align="left">{row.method}</TableCell>*/}
                            {/*<TableCell padding="none" align="right">*/}
                            {/*  <Box mr={2}>*/}
                            {/*    /!*<IconButton aria-label="download">*!/*/}
                            {/*    /!*  <ArchiveIcon />*!/*/}
                            {/*    /!*</IconButton>*!/*/}
                            {/*    <IconButton aria-label="details"*/}
                            {/*                onClick={() => history.push("/subscriptions/detail?" + row.invoiceNo)}>*/}
                            {/*      <RemoveRedEyeIcon/>*/}
                            {/*    </IconButton>*/}
                            {/*  </Box>*/}
                            {/*</TableCell>*/}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={subscriptions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Wrapper>
      )}
    </>
  );
}

function SubscriptionList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  location.state = {
    email: localStorage.getItem('0xaccountId'),
    name: localStorage.getItem('0xuserId')
  }

  return (
    <>
      <Helmet title="Subscription"/>
      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Subscriptions
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {/*<Typography>Subscriptions</Typography>*/}
            <Link component={NavLink} exact to="/subscriptions">
              Subscriptions
            </Link>
            <Typography>Status</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6}/>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable/>
        </Grid>
      </Grid>
    </>
  );
}

export default SubscriptionList;
