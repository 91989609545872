import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, InputLabel, MenuItem,
  Typography as MuiTypography,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";
import Button from '@material-ui/core/Button';
import "../../../assets/css/dashboard.css";
import STATUS_GREEN from '../../../assets/img/status_green.png';
import STATUS_GREY from '../../../assets/img/status_gray.png';
import STATUS_ORANGE from '../../../assets/img/status_orange.png';
import STATUS_RED from '../../../assets/img/status_red.png';
import LINE_UNIT from '../../../assets/img/Line_Unit.png';
import WAITING from '../../../assets/img/waiting.png';
import {
  BLACK,
  BUTTON_DISABLED,
  DEACTIVATED_RED,
  GREEN,
  GREY,
  MAIN,
  ORANGE,
  RED,
  WHITE
} from '../../../constants/colors';
import DialogContentText from '@material-ui/core/DialogContentText';
import SubscriptionInterface from '../../../integration/internal/SubscriptionInterface';
import {
  _24H_AVG, _48H_AVG,
  ACTIVE,
  BQ,
  EPA_CONSIDER_FIXING_HOME, EPA_CONSIDER_FIXING_HOME_BQ,
  EPA_RECOMMEND_FIXING_HOME, EPA_RECOMMEND_FIXING_HOME_BQ, INACTIVE, OFF, ON,
  PCI, REALTIME, THRESHOLD_TO_SHOW,
  UNIT_CONVERSION_SCALE
} from '../../../constants/values';
import {customAlert} from '../../../utils/AlertWrapper';
import moment from 'moment';
import Space from '../../../components/Space';
import {
  labelForTitle,
  valueForText,
  activationStatusStyle,
  valueForThresholdInput
} from '../../../views/dashboards/Devices/CardLayout';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {
  alertMessageForActivation,
  radonBigNumber,
  radonUnit,
  radonUnit1,
  radonUnit2ForBq,
  radonUnit2ForPci,
  valueForOutOfSyncText, workingStatusMessage
} from "./CardLayout";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DefaultRadio from "../../components/DefaultRadio";
import CustomTextField from "../../../components/CustomTextField";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const DeviceInfoCard = ({data, initUnit, callback}) => {
  customAlert('test:' + initUnit);

  const [monitorMode, setMonitorMode] = useState(null);
  const [activateDeviceConfirmPopup, setActivateDeviceConfirmPopup] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [currentRadonLevel, setCurrentRadonLevel] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [notiThreshold, setNotiThreshold] = useState(null);
  const [pushNotiStatus, setPushNotiStatus] = useState(null);
  const [emailNotiStatus, setEmailNotiStatus] = useState(null);
  const [calibrationDate, setCalibrationDate] = useState(null);
  const [unit, setUnit] = useState(null);

  const [monitorModeChanged, setMonitorModeChanged] = useState(false);
  const [subsStatusChanged, setSubsStatusChanged] = useState(false);
  const [notiThresholdChanged, setNotiThresholdChanged] = useState(false);
  const [pushNotiStatusChanged, setPushNotiStatusChanged] = useState(false);
  const [emailNotiStatusChanged, setEmailNotiStatusChanged] = useState(false);
  const [unitChanged, setUnitChanged] = useState(false);

  const onCurrentRadonLevelChange = (event) => {
    setCurrentRadonLevel(event.target.value);
  };

  const getColor = (reading) => {
    if(isDeactivated()||!reading) return GREY;
    if (data.unit === 0) {
      let pCiValue = reading / UNIT_CONVERSION_SCALE;
      if (pCiValue < EPA_CONSIDER_FIXING_HOME) {
        return GREEN;
      } else if (pCiValue < EPA_RECOMMEND_FIXING_HOME) {
        return ORANGE;
      } else {
        return RED;
      }
    } else {
      if (reading < EPA_CONSIDER_FIXING_HOME_BQ) {
        return GREEN;
      } else if (reading < EPA_RECOMMEND_FIXING_HOME_BQ) {
        return ORANGE;
      } else {
        return RED;
      }
    }
  };

  useEffect(() => {
    if (data && data.serial_number) {
      // Radon Monitoring Mode
      if (!monitorMode) {
        setMonitorMode(data.mv_mode || null);
      }

      // Subscription Status
      if (!subscriptionStatus) {
        setSubscriptionStatus(data.subs_status ? data.subs_status.toUpperCase() : null);
      }

      // Push Notification
      if (!pushNotiStatus) {
        const pushNotiStatus = data.push_notification;
        setPushNotiStatus(pushNotiStatus);
      }

      // Email Notification
      if (!emailNotiStatus) {
        const emailNotiStatus = data.email_notification;
        setEmailNotiStatus(emailNotiStatus);
      }

      // Unt Setting
      if (!unit) {
        setUnit(data.unit === 0 ? PCI : BQ);
      }

      // Notification Threshold
      if (!notiThreshold && notiThreshold != '') {
        setNotiThreshold(data.unit === 0 ? (data.alarm_value / UNIT_CONVERSION_SCALE).toFixed(2) : Number(data.alarm_value).toFixed(0));
      }

      // Calibration Date
      if (!calibrationDate) {
        const calibrationDate = data.calibration_date || 'N/A';
        setCalibrationDate(calibrationDate);
      }
    }
  });

  const checkSyncStatus = () => {
    if(isDeactivated()) return {isSync: false, syncMsg: 'N/A'};
    let msDiff = moment.utc() - moment.utc(data.last_radon_update_time, 'YYYY-MM-DDTHH:mm:ss').utc();
    let minDiff = parseInt((msDiff / 60 / 1000).toFixed(0));
    let bootup = data.p_time<10? true: false;

    let synced = false;
    let message;
    if (minDiff < 20) synced = true;
    if(bootup) {
      synced = false;
      message = 'Measuring in progress';
    } else if (minDiff < 60) {
      message = 'Synced ' + minDiff + ' minutes ago';
    } else if (minDiff < 24 * 60) {
      message = 'Synced ' + (minDiff / 60).toFixed(0) + ' hour(s) ago';
    } else {
      message = 'Synced ' + (minDiff / 60 / 24).toFixed(0) + ' day(s) ago';
    }
    return {isSync: synced, syncMsg: message};
  };

  const runningModeChanged = (event) => {
    const monitorMode = event.target.value;
    setMonitorMode(monitorMode);
    if (!data.mv_mode || monitorMode != data.mv_mode) {
      setMonitorModeChanged(true);
    } else {
      setMonitorModeChanged(false);
    }
  };

  const subscriptionStatusChanged = (event) => {
    const value = event.target.value;
    setSubscriptionStatus(value);
    if (!data.subs_status || value != data.subs_status.toUpperCase()) {
      setSubsStatusChanged(true);
    } else {
      setSubsStatusChanged(false);
    }
  };

  const pushNotificationChanged = (event) => {
    const value = event.target.value;
    setPushNotiStatus(value);
    if (!data.push_notification || value != data.push_notification) {
      setPushNotiStatusChanged(true);
    } else {
      setPushNotiStatusChanged(false);
    }
  };

  const emailNotificationChanged = (event) => {
    const value = event.target.value;
    setEmailNotiStatus(value);

    if (!data.email_notification || value != data.email_notification) {
      setEmailNotiStatusChanged(true);
    } else {
      setEmailNotiStatusChanged(false);
    }
  };

  const changeUnit = (event) => {
    const selectedUnit = event.target.value;
    setUnit(selectedUnit);
    if (event.target.value != unit) {
      setNotiThreshold(event.target.value === PCI ? (notiThreshold / UNIT_CONVERSION_SCALE).toFixed(2) : (notiThreshold * UNIT_CONVERSION_SCALE).toFixed(0));
    }

    if ( data.unit != (selectedUnit ? (selectedUnit === PCI ? 0 : 1) : null) ) {
      setUnitChanged(true);
    } else {
      setUnitChanged(false);
    }
  };

  const onNotiThresholdChange = (event) => {
    if (!isNaN(event.target.value)) {
      const changedNotiThreshold = event.target.value;
      setNotiThreshold(event.target.value);
      const originalNotiThreshold = data.unit === 0 ? (data.alarm_value / UNIT_CONVERSION_SCALE).toFixed(2) : Number(data.alarm_value).toFixed(0);
      const formattedOriginalSettingValue = unit === PCI ? Number(originalNotiThreshold).toFixed(2) : Number(originalNotiThreshold).toFixed(0);
      const formattedChangedValue = unit == PCI ? Number(changedNotiThreshold).toFixed(2) : Number(changedNotiThreshold).toFixed(0);
      if (formattedOriginalSettingValue != formattedChangedValue) {
        setNotiThresholdChanged(true);
      } else {
        setNotiThresholdChanged(false);
      }
    }
  };

  const handleClose = () => {
    // Do nothing
    setActivateDeviceConfirmPopup(false);
  };

  const handleActivation = async () => {
    setShowSpinner(true);
    //TODO: trigger deactivating
    await onSave();
    setActivateDeviceConfirmPopup(false);
  };

  const saveChanges = async () => {
    if(subsStatusChanged) {
      setActivateDeviceConfirmPopup(true);
    } else if (isValueChanged()) {
      setShowSpinner(true);
      await onSave();
    }
  };

  const showPopup = () => {
    setActivateDeviceConfirmPopup(true);
  };

  const onSave = async () => {
    const isSubsStatusChanged = subsStatusChanged;
    let id = localStorage.getItem('0xaccountId');
    let result = await SubscriptionInterface.changeDeviceInformation(
      id,
      data.serial_number,
      monitorMode,
      subscriptionStatus,
      isSubsStatusChanged,
      notiThreshold,
      pushNotiStatus,
      emailNotiStatus,
      unit
    );

    if (result) {
      setMonitorModeChanged(false);
      setSubsStatusChanged(false);
      setNotiThresholdChanged(false);
      setPushNotiStatusChanged(false);
      setEmailNotiStatusChanged(false);
      setUnitChanged(false);

      callback(data.serial_number);
    } else {
      customAlert('Fail to apply changes');
    }
    setShowSpinner(false);
  };

  const isDeactivated = () => {
    return data&&data.subs_status&&data.subs_status.toLowerCase()==='DEACTIVATE'.toLowerCase();
  };

  const getDeactivatedDaysMessage = () => {
    let activated = data.activated;
    let deactivated = data.deactivated;
    if(activated==='N'&&deactivated==='N') {
      return 'Not Activated';
    }
    let updatedUtc = new Date(deactivated).getTime() + moment().utcOffset() * 60 * 1000;  // TODO : is this the best way to get utc time from date?
    let current = new Date().getTime();
    let diff = (current - updatedUtc) / 60 / 1000;
    let days = Math.ceil(diff/60/24);
    if(days<=1) {
      return 'Deactivated within a day';
    } else return 'Deactivated ' + days + ' days ago';
  };

  const isSubscribed = () => {
    // return true;
    return data&&data.subscription&&data.subscription.toLowerCase()==='Y'.toLowerCase();
  };

  const showData = () => {
    // console.log('showData === ' + JSON.stringify(data));
    if(!data) return false;
    if(getRunningMode()==='24h_avg') {
      let date = new Date(data.last_radon_update_time_24);
      if (date.getFullYear() <= 1970) {
        return false;
      } else return true;
    } else if(getRunningMode()==='48h_avg') {
      let date = new Date(data.last_radon_update_time_48);
      if (date.getFullYear() <= 1970) {
        return false;
      } else return true;
    } else if(getRunningMode()==='rt') {
      return true;
    }
  };

  const getRunningMode = () => {
    // return 'rt';
    if(data&&data.mv_mode&&data.mv_mode==24) {
      return '24h_avg';
    } else if(data&&data.mv_mode&&data.mv_mode==48) {
      return '48h_avg';
    } else if(data&&data.mv_mode&&data.mv_mode==1) {
      return 'rt';
    } else {
      return 'rt';
    }
  };

  const isReady = () => {
    return true;
  };

  const getLevelByMode = () => {
    let runningMode = getRunningMode();
    console.log('getLevelByMode === ' + runningMode);
    if(runningMode==='24h_avg') {
      return data.radon_level_24;
    } else if(runningMode==='48h_avg') {
      return data.radon_level_48;
    } else if(runningMode==='rt') {
      return data.radon_level;
    }
  };

  const isValueChanged = () => {
    return subsStatusChanged || monitorModeChanged || notiThresholdChanged || pushNotiStatusChanged || emailNotiStatusChanged || unitChanged;
  };

  const getWorkingStatusMessage = () => {
    return isDeactivated()? getDeactivatedDaysMessage():
      isSubscribed()? (getRunningMode()==='24h_avg'? getRunningMode()==='48h_avg'? '48 Hrs Avg Monitoring Mode' : '24 Hrs Avg Monitoring Mode': 'Real-time Monitoring Mode') :
        getColor(getLevelByMode())===GREEN? 'No Action Required':
          getColor(getLevelByMode())===ORANGE? 'Consider Fixing Home': 'Recommend Fixing Home'
  };

  const getLEDLight = () => {
    return isDeactivated()? STATUS_GREY :
        getColor(getLevelByMode())===GREY? STATUS_GREY:
          getColor(getLevelByMode())===GREEN? STATUS_GREEN:
          getColor(getLevelByMode())===ORANGE? STATUS_ORANGE: STATUS_RED
  };

  const getStyle = () => {
    return {color: isDeactivated()? DEACTIVATED_RED : isSubscribed()? GREY : getColor(getLevelByMode())};
  };

  const get30DaysAvg = () => {
    let value = isDeactivated()? 'N/A': isNaN(data.avg_30_days)? 'N/A' : unit === PCI ? (data.avg_30_days / UNIT_CONVERSION_SCALE).toFixed(1) : data.avg_30_days;
    if (!isDeactivated()) {
      value = value + ' ' + unit;
    }
    return value;
  };

  const getCurrentRadonLevel = () => {
    let value = unit === PCI ? getLevelByMode() >=0 ? (getLevelByMode() / UNIT_CONVERSION_SCALE).toFixed(1) : 'N/A' : getLevelByMode();

    if (isDeactivated()) {
      value = 'N/A';
    } else {
      if (value >= 0) {
        value = value + ' ' + unit;
      }
    }
    return value;
  };

  return (
    !data.serial_number ? (
      <Card mb={3} className='loading-card'>
        <CircularProgress/>
      </Card>
    ) : (
      <Card mb={3} className='common-card'>
        <CardContent>
          <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
            <Box style={{width: '100%'}}>
              <Typography variant={'subtitle1'} mb={3}>
                <Box fontWeight="fontWeightBold">Device Information</Box>
              </Typography>
            </Box>
            <Box style={{width:'60px'}}></Box>
            <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
              {isValueChanged() ?
                <Button style={{background: isValueChanged()? MAIN : BUTTON_DISABLED, color: WHITE}} onClick={saveChanges}>Save</Button> :
                <Button style={{background: BUTTON_DISABLED, color: WHITE}} disabled>Save</Button>}
              {
                showSpinner ?
                  <div className='save-changes-spinner'>
                    <CircularProgress size={20}/>
                  </div> : <></>
              }
            </Box>
          </Box>

          {/*<Box style={{height: '32px', width: '100%'}}/>*/}
          {/*<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>*/}
          {/*  {!showData()? <img src={WAITING} style={{width: '62px', height: '53px'}}/>:*/}
          {/*    <Typography*/}
          {/*    style={radonBigNumber}>{unit === PCI ? getLevelByMode()>=0? (getLevelByMode() / UNIT_CONVERSION_SCALE).toFixed(1) : '--' : getLevelByMode()}</Typography>}*/}
          {/*  <Box style={{width: '15px'}}/>*/}
          {/*  {!showData()? null:<Box>*/}
          {/*    <Typography style={radonUnit1}>{unit === PCI ? 'pCi' : 'Bq'}</Typography>*/}
          {/*    <img src={LINE_UNIT} style={{width: '28px', height: '1px'}}/>*/}
          {/*    <Typography*/}
          {/*      style={unit === PCI ? radonUnit2ForPci : radonUnit2ForBq}>{unit === PCI ? 'L' : 'm3'}</Typography>*/}
          {/*  </Box>}*/}
          {/*</Box>*/}
          {/*<Box style={{width: '100%', height: '12px'}}/>*/}
          {/*<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={{height:'24px'}}>*/}
          {/*  <Typography style={{*/}
          {/*    fontSize: '16px',*/}
          {/*    fontWeight: '500',*/}
          {/*    fontStyle: 'normal',*/}
          {/*    lineHeight: '24px',*/}
          {/*    paddingTop: '10px',*/}
          {/*    color: isDeactivated()? DEACTIVATED_RED : isSubscribed()? GREY : getColor(getLevelByMode())}}>*/}
          {/*  {getWorkingStatusMessage()}</Typography>*/}
          {/*</Box>*/}
          {/*<Box style={{width: '100%', height: '15px'}}/>*/}
          {/*<Box display={'flex'} justifyContent={'flex-end'} mr={'36px'}>*/}
          {/*  {!showData()||isDeactivated()?*/}
          {/*    <Box style={{width: '32px', height: '9px'}}></Box>:*/}
          {/*    <img src={getLEDLight()} style={{width: '32px', height: '9px'}}/>*/}
          {/*  }*/}
          {/*</Box>*/}
          <Space height={'20px'}/>

          <Typography style={labelForTitle}>Current Radon Level</Typography>
          <CustomTextField
            style={valueForText}
            name="currentRadonLevel"
            placeholder={''}
            value={getCurrentRadonLevel()}
            fullWidth
            onChange={onCurrentRadonLevelChange}
            disabled
            InputProps={true ? { disableUnderline: true } : {}}
            my={3}
          />
          {
            !isDeactivated() ?
            <Space height={'10px'}/>
            :
            <Typography style={alertMessageForActivation}>The device is deactivated and not in service.</Typography>
          }
          <Space height={'10px'}/>
          {/*{*/}
          {/*  isDeactivated() &&*/}
          {/*  <Typography style={alertMessageForActivation}>The device is deactivated and not in service.</Typography>*/}
          {/*  <Space height={'10px'}/>*/}
          {/*}*/}

          <Typography style={labelForTitle}>Radon Monitoring Mode</Typography>
          <Box display='flex' flexDirection='row'>
            <Select
              className="running-mode-select"
              id="running-mode-select"
              value={monitorMode}
              onChange={runningModeChanged}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className="running-mode-menuitem" value={_48H_AVG}>48 Hours Average</MenuItem>
              <MenuItem className="running-mode-menuitem" value={_24H_AVG}>24 Hours Average</MenuItem>
              <MenuItem className="running-mode-menuitem" value={REALTIME}>Real-time</MenuItem>
            </Select>
          </Box>
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>
            Subscription Status
          </Typography>
          <Box display='flex' flexDirection='row' >
            <Select
              className="activation-select"
              id="subs-activation-select"
              value={subscriptionStatus}
              onChange={subscriptionStatusChanged}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className="activation-menuitem" value={ACTIVE}>Activated</MenuItem>
              <MenuItem className="activation-menuitem" value={INACTIVE}>Deactivated</MenuItem>
            </Select>
          </Box>
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>30 Days Avg</Typography>
          <CustomTextField
            style={valueForText}
            name="30DaysAvg"
            placeholder={''}
            value={get30DaysAvg()}
            fullWidth
            disabled
            InputProps={true ? { disableUnderline: true } : {}}
            my={3}
          />
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>
            Device Sync
          </Typography>
          <CustomTextField
            style={valueForText}
            name="deviceSync"
            placeholder={''}
            value={checkSyncStatus().syncMsg}
            fullWidth
            disabled
            InputProps={true ? { disableUnderline: true } : {}}
            my={3}
          />
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>Notification Threshold</Typography>
          <Box display='flex' flexDirection='row'>
            <CustomTextField
              style={valueForThresholdInput}
              name="threshold"
              value={notiThreshold}
              onChange={onNotiThresholdChange}
              inputProps={{style: {textAlign: 'left'}}}
              my={3}
            />
          </Box>
          <Space height={'20px'}/>
          <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
            <Box style={{width: '100%'}}>
              <Typography style={labelForTitle}>Push Notification</Typography>
              <Box display='flex' flexDirection='row'>
                <Select
                  className="dropdown-select"
                  id="push-notification-select"
                  value={pushNotiStatus}
                  onChange={pushNotificationChanged}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem className="dropdown-menuitem" value={ON}>On</MenuItem>
                  <MenuItem className="dropdown-menuitem" value={OFF}>Off</MenuItem>
                </Select>
              </Box>
            </Box>
            <Box style={{width:'60px'}}></Box>
            <Box style={{width: '100%'}}>
              <Typography style={labelForTitle}>Email Notification</Typography>
              <Box display='flex' flexDirection='row'>
                <Select
                  className="dropdown-select"
                  id="email-notification-select"
                  value={emailNotiStatus}
                  onChange={emailNotificationChanged}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem className="dropdown-menuitem" value={ON}>On</MenuItem>
                  <MenuItem className="dropdown-menuitem" value={OFF}>Off</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>Calibrated</Typography>
          <CustomTextField
            style={valueForText}
            name="calibrationDate"
            placeholder={''}
            value={calibrationDate}
            fullWidth
            disabled
            InputProps={true ? { disableUnderline: true } : {}}
            my={3}
          />
          <Space height={'20px'}/>
          <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
            <Box style={{width: '100%'}}>
              <Typography style={labelForTitle}>Serial Number</Typography>
              <CustomTextField
                style={valueForText}
                name="serialNumber"
                placeholder={''}
                value={data.serial_number}
                fullWidth
                disabled
                InputProps={true ? { disableUnderline: true } : {}}
                my={3}
              />
            </Box>
            <Box style={{width:'60px'}}></Box>
            <Box style={{width: '100%'}}>
              <Typography style={labelForTitle}>Firmware Version</Typography>
              <CustomTextField
                style={valueForText}
                name="fwVersion"
                placeholder={''}
                value={data.fw_version}
                fullWidth
                disabled
                InputProps={true ? { disableUnderline: true } : {}}
                my={3}
              />
            </Box>
          </Box>
          <Space height={'20px'}/>
          <Typography style={labelForTitle}>Unit Setting</Typography>
          <FormControl component="fieldset">
            <RadioGroup defaultValue={unit} value={unit} aria-label="unit" name="customized-radios" onChange={changeUnit}>
              <Box display='flex' flexDirection={'row'}>
                <FormControlLabel value={PCI} control={<DefaultRadio/>} label={PCI}/>
                <Box style={{width: '107px'}}/>
                <FormControlLabel value={BQ} control={<DefaultRadio/>} label={BQ}/>
              </Box>
            </RadioGroup>
          </FormControl>
          <Dialog
            fullWidth={'519px'}
            maxWidth="xs"
            open={activateDeviceConfirmPopup}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            PaperProps={{style: {alignItems: 'center', justifyContent: 'center'}}}
          >
            <div className="s15"></div>
            <DialogTitle id="max-width-dialog-title">
              {subscriptionStatus === INACTIVE ? 'Deactivate Device' : 'Activate Device'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText props={{style: {alignItems: 'center', justifyContent: 'center'}}}>
                {subscriptionStatus === INACTIVE ? 'You are about to deactivate this device.\nData no longer will upload to the server.' :
                  'You are about to activate this device.\nData will start uploading to the server and you will be charged a subscription fee.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleActivation} color="primary" classes={{root: 'button', label: 'button-label'}}>
                {subscriptionStatus === INACTIVE ? 'Deactivate' : 'Activate'}
              </Button>
            </DialogActions>
            <div className="s20"></div>
          </Dialog>
        </CardContent>
      </Card>
    )
  );
};

export default DeviceInfoCard;
