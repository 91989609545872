import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Grid } from "@material-ui/core";
import "../../../assets/css/common.css";
import "../../../assets/css/dashboard.css";
import DeviceInfoCard from "./DeviceInfoCard";
import LocationInfoCard from "./LocationInfoCard";
import ClientInfoCard from "./ClientInfoCard";
import AdditionInfoCard from "./AdditionInfoCard";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { Button, FormControlLabel } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import _ from "lodash";
import SubscriptionInterface from "../../../integration/internal/SubscriptionInterface";
import { customAlert } from "../../../utils/AlertWrapper";
import { RadonUtil } from "../../components/RadonUtil";
import ReportInterface from "../../../integration/internal/ReportInterface";
import RadonLineChart from "../Analytics/RadonLineChart";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import RadonLevelChart from "../Analytics/RadonLevelChart";
import Space from "../../../components/Space";
import RadonNumberTable from "../Analytics/RadonNumberTable";
import {MITIGATION_SYSTEM} from "../../../constants/values";
export const MAX_IMAGE_SIZE = 1024 * 1024 * 25; // 25 MB
export const MAX_PDF_SIZE = 1024 * 1024 * 50; // 50 MB

export const RADON_REF_PCI_LEVEL = {
  LEVEL2: 2.7,
  LEVEL3: 4.0,
};

export const RADON_REF_BQ_LEVEL = {
  LEVEL2: 100,
  LEVEL3: 148,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#ffffff",
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function scrollProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function DeviceDetail() {
  const location = useLocation();
  const history = useHistory();
  const [chartType, setChartType] = useState("DAY");
  const [deviceInfo, setDeviceInfo] = useState({ serial_number: null });
  const [chartInfo, setChartInfo] = useState(null);
  const [unit, setUnit] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabEnabled, setTabEnabled] = useState(true);
  const [startMoment, setStartMoment] = useState();
  const [originMoment, setOriginMoment] = useState();
  const [endMoment, setEndMoment] = useState();
  const [dataArray, setDataArray] = useState();
  const defaultRadonLevel = {
    total: 0, // Total Data Count
    green: 0, // No Action Required (0 <= x < 2.7)
    orange: 0, // Consider Fixing Home (2.7 <= x < 4)
    red: 0, // Recommend Fixing Home (4 < x)
  };
  const [radonLevel, setRadonLevel] = useState(defaultRadonLevel);

  let res;
  const [widthLineChart, setWidthLineChart] = useState();
  const lineChartGridRef = useRef(null);
  const lineChartRef = useRef(null);
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const successLoadedChartData = (data) => {
    if (data && data.length > 0) {
      setDataArray(data);
      setRadonLevel(defaultRadonLevel);

      let green = 0;
      let orange = 0;
      let red = 0;
      let total = 0;

      const RADON_LEVEL2 =
        deviceInfo.unit == 0
          ? RADON_REF_PCI_LEVEL.LEVEL2
          : RADON_REF_BQ_LEVEL.LEVEL2;
      const RADON_LEVEL3 =
        deviceInfo.unit == 0
          ? RADON_REF_PCI_LEVEL.LEVEL3
          : RADON_REF_BQ_LEVEL.LEVEL3;

      for (let i = 0; i < data.length; i++) {
        // const radonLevel = RadonUtil.convertToPCi(data[i].radon_level);
        const radonLevel = data[i].radon_level;
        if (radonLevel >= 0 && radonLevel < RADON_LEVEL2) {
          green++;
        } else if (radonLevel >= RADON_LEVEL2 && radonLevel < RADON_LEVEL3) {
          orange++;
        } else if (radonLevel >= RADON_LEVEL3) {
          red++;
        }
      }

      setRadonLevel({
        total: green + orange + red, // Total Data Count
        green: green, // No Action Required (0 <= x < 2.7)
        orange: orange, // Consider Fixing Home (2.7 <= x < 4)
        red: red, // Recommend Fixing Home (4 < x)
      });
    }
  };

  const handleChangeDuration = async (event, newTab) => {
    setTabEnabled(false);
    // await setChartInfo(null);
    setChartInfo(null);
    // if (currentTab === newTab) {
    //   return;
    // }

    setTab(newTab);
    let type;
    if (newTab == 0) {
      type = "DAY";
    } else if (newTab == 1) {
      type = "WEEK";
    } else if (newTab == 2) {
      type = "MONTH";
    } else if (newTab == 3) {
      type = "YEAR";
    } else {
      type = "DAY";
    }
    setCurrentTab(newTab);
    setChartType(type);

    getChartInfo(type);
    setTimeout(() => setTabEnabled(true), 2000);
  };

  const getDeviceInfo = async () => {
    if (!location.state || !location.state.sn) {
      history.replace("/devices");
      return;
    }
    res = await SubscriptionInterface.retrieveDeviceDetails(
      localStorage.getItem("0xaccountId"),
      location.state.sn
    );
    // console.log('from getDeviceInfo' + JSON.stringify(res))
    if (res && !res.data) {
      return;
    } else if (!_.isEqual(deviceInfo, res.data)) {
      setDeviceInfo(res.data);
    }
  };

  const getChartInfo = async (type = "DAY") => {
    if (!location.state) return;
    const email = localStorage.getItem("0xaccountId");
    const serial_number = location.state.sn;

    let durationMoment = RadonUtil.getDurationMomentByType(type);
    setOriginMoment({
      startMoment: durationMoment.startMoment,
      endMoment: durationMoment.endMoment,
    });
    setStartMoment(durationMoment.startMoment);
    setEndMoment(durationMoment.endMoment);

    const reportDetail = await ReportInterface.retrieveRadondata(
      email,
      serial_number,
      type
    );
    const radondata = _.get(reportDetail, "data.radondata");
    // console.log('[reportDetail] : ' + JSON.stringify(radondata) + '\n' + JSON.stringify(reportDetail.data.radondata));
    if (radondata && radondata.length > 0) {
      let dataSet = reportDetail.data.radondata;
      setChartInfo(dataSet);
    } else {
      setChartInfo([]);
    }
  };

  const convertDateTime = (datetime) => {
    return (
      datetime.substring(0, 4) +
      "-" +
      datetime.substring(4, 6) +
      "-" +
      datetime.substring(6)
    );
  };

  useEffect(() => {
    getDeviceInfo();
    getChartInfo(chartType);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartType]);

  const handleResize = () => {
    if (
      lineChartGridRef &&
      lineChartGridRef.current &&
      lineChartGridRef.current.offsetWidth
    ) {
      setWidthLineChart(lineChartGridRef.current.offsetWidth);
    }
  };

  const onChange = async (sn) => {
    customAlert("DeviceDetail onChange is called!!!!!");
    await getDeviceInfo(chartType);
    history.replace({
      pathname: "/device/detail",
      state: {
        sn: sn,
      },
    });
  };

  const onUnitChange = async (sn) => {
    let tabNumber = 0;
    if (chartType === "DAY") {
      tabNumber = 0;
    } else if (chartType === "WEEK") {
      tabNumber = 1;
    } else if (chartType === "MONTH") {
      tabNumber = 2;
    } else if (chartType === "YEAR") {
      tabNumber = 3;
    }
    await handleChangeDuration(null, tabNumber);
  };
  // Input: 090121, Output: 09/01/21
  const getFormattedDate = (dateString) => {
    //alert(dateString);
    if (!dateString) return "00/00/00";
    return (
      dateString.substring(0, 2) +
      "/" +
      dateString.substring(2, 4) +
      "/" +
      dateString.substring(4, 6)
    );
  };

  const goToReportGeneration = () => {
    history.push({
      pathname: "/reports/list",
      state: {
        sn: deviceInfo.serial_number,
      },
    });
  };

  const getRadonChart = () => {
    return (
      <>
        <RadonLineChart
          ref={lineChartRef}
          originMoment={originMoment}
          setStartMoment={setStartMoment}
          setEndMoment={setEndMoment}
          successLoadedChartData={successLoadedChartData}
          radon={chartInfo}
          type={chartType}
          line={"monotoneX"}
          width={widthLineChart}
          isForPDF={false}
          isForDashboard={true}
          unit={deviceInfo.unit}
        />
      </>
    );
  };

  const getEnabledTabs = () => {
    let startDateTime;
    let endDateTime;
    if (chartType == "WEEK" || chartType == "MONTH" || chartType == "YEAR") {
      startDateTime = RadonUtil.getDate(startMoment, 5);
      endDateTime = RadonUtil.getDate(endMoment, 5);
    } else {
      startDateTime = RadonUtil.getDate(startMoment, 6);
      endDateTime = RadonUtil.getDate(endMoment, 6);
    }

    let duration = startDateTime + " ~ " + endDateTime;
    // if (startMoment == originMoment.startMoment && endMoment == originMoment.endMoment) {
    //   if (chartType == 'DAY') {
    //     duration = "Last 24 hours: " + duration;
    //   } else if (chartType == 'WEEK') {
    //     duration = "Last 7 days: " + duration;
    //   } else if (chartType == 'MONTH') {
    //     duration = "Last 1 month: " + duration;
    //   } else if (chartType == 'YEAR') {
    //     duration = "Last 1 year: " + duration;
    //   }
    // }

    return (
      <Tabs
        value={tab}
        onChange={handleChangeDuration}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Day" {...scrollProps(0)} />
        <Tab label="Week" {...scrollProps(1)} />
        <Tab label="Month" {...scrollProps(2)} />
        <Tab label="Year" {...scrollProps(3)} />
        <Tab disabled label={duration} className={classes.rightAlign} />
      </Tabs>
    );
  };

  const getDisabledTabs = () => {
    return (
      <Tabs
        value={tab}
        onChange={handleChangeDuration}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab disabled label="Day" {...scrollProps(0)} />
        <Tab disabled label="Week" {...scrollProps(1)} />
        <Tab disabled label="Month" {...scrollProps(2)} />
        <Tab disabled label="Year" {...scrollProps(3)} />
      </Tabs>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, {localStorage.getItem("0xuserId")}!{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ background: "#3DB5E6", color: "#FFFFFF" }}
            onClick={goToReportGeneration}
          >
            Radon Report
          </Button>
        </Grid>
      </Grid>
      <Box style={{ width: "100%", height: "20px" }} />
      <Divider my={6} />
      <Box style={{ width: "100%", height: "46px" }} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <DeviceInfoCard
            data={deviceInfo}
            initUnit={unit}
            callback={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <ClientInfoCard data={deviceInfo} callback={onChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <LocationInfoCard data={deviceInfo} callback={onChange} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <AdditionInfoCard
            data={deviceInfo}
            initUnit={unit}
            callback={onChange}
        />
      </Grid >
      <Space height={"30px"} />
      <Grid container spacing={6} ref={lineChartGridRef}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          {chartInfo &&
          chartInfo.length > 0 &&
          deviceInfo.unit !== undefined &&
          deviceInfo.unit !== null ? (
            <>
              <Card mb={3}>
                <CardContent>
                  <Typography variant={"subtitle1"} mb={3}>
                    <Box fontWeight="fontWeightBold">Radon Trends</Box>
                    <Space height={"20px"} />
                  </Typography>
                  <div className={classes.root}>
                    <AppBar position="static" style={{ background: "#fcfcfc" }}>
                      {tabEnabled ? getEnabledTabs() : getDisabledTabs()}
                    </AppBar>
                    <TabPanel value={tab} index={tab}>
                      {getRadonChart()}
                    </TabPanel>
                  </div>
                </CardContent>
              </Card>
            </>
          ) : chartInfo && chartInfo.length == 0 ? null : (
            // <Card mb={3} className='dashboard-char-no-data'>
            //   <>
            //     <Box display="flex" p={1} >
            //       <Box p={1} flexGrow={1} >
            //         <Typography variant={'subtitle1'} mb={3}>
            //           <Box fontWeight="fontWeightBold">Radon Trends</Box>
            //         </Typography>
            //       </Box>
            //       <Box p={1}>
            //       </Box>
            //     </Box>
            //     <Space height={'20px'}/>
            //   </>
            //   <Space height={'20px'}/>
            //   <Typography align={'center'}>There is no radon data with associated devices {deviceInfo.serial_number}</Typography>
            // </Card>
            <Card mb={3} className="loading-card-dashboard-chart">
              <CircularProgress />
            </Card>
          )}
        </Grid>
      </Grid>
      {chartInfo &&
      chartInfo.length > 0 &&
      deviceInfo.unit !== undefined &&
      deviceInfo.unit !== null ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl>
            <RadonLevelChart
              radonLevel={radonLevel}
              unit={deviceInfo.unit}
            ></RadonLevelChart>
          </Grid>
          {/*<Grid item xs={12} sm={12} md={6} lg={6} xl>*/}
          {/*  <RadonNumberTable*/}
          {/*    radon={chartInfo}*/}
          {/*  >*/}
          {/*  </RadonNumberTable>*/}
          {/*</Grid>*/}
        </Grid>
      ) : null}
    </React.Fragment>
  );
}

export default DeviceDetail;
