export const decodeRx = (msg) => {
  if(msg == null) return null;
  const binString = atob(msg);
  return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0)));
};

export const encodeTx = (msg) => {
  if(msg == null) return null;
  const binString = Array.from(new TextEncoder().encode(msg), (byte) =>
    String.fromCodePoint(byte),
  ).join("");
  return btoa(binString);
};
