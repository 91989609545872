import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {useHistory, useLocation} from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import {CloudDownload as MuiCloudDownload} from "@material-ui/icons";
import {spacing} from "@material-ui/system";
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import {validCompanyEmailRegex} from "../../utils/AuthValidation";
import {Alert as MuiAlert} from "@material-ui/lab";
import Space from "../../components/Space";
import {getCompanyInfo, updateUserAttribute, updateCompanyInfo} from "../../models/actions/authActions";
import "../../assets/css/settings.css";
import QRCode from 'qrcode'
import DialogContentText from '@material-ui/core/DialogContentText';
import {CUSTOM_COMPANY_EMAIL, LS_EMAIL, LS_NAME} from '../../constants'
import {Auth} from 'aws-amplify';
import errorIcon from '../../assets/img/error_24px.png';
import sampleQrcode from '../../assets/img/sample_qrcode.png';
import {accessUrl} from '../../env';


const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudDownload = styled(MuiCloudDownload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;


function QRInfo() {
  const [changed, setChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [qr, setQr] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [originData, setOriginData] = useState({});
  const [valid, setValid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [proceedConfirmed, setProceedConfirmed] = useState(false);

  location.state = {
    email: localStorage.getItem(LS_EMAIL),
    name: localStorage.getItem(LS_NAME),
  }
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }
  const [email, setEmail] = useState(location.state.email);
  const [companyEmail, setCompanyEmail] = useState('');
  const defaultURL = accessUrl + '/qr?email=';

  useEffect(() => {
    const updateCompanyEmail = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const company = user.attributes[CUSTOM_COMPANY_EMAIL];
      console.log('company from cognito:' + company);
      setCompanyEmail(company? company: email);
      setOriginData({
        email: email,
        companyEmail: company,
      });
      console.log('company email in useEffect:' + companyEmail);
      generateQRCode(email);
    };

    updateCompanyEmail();

  }, []);

  const validateCompanyEmail = (values) => {
    const errors = {};
    if (!companyEmail) {
      errors.companyEmail = 'Required';
      setValid(false)
    } else if (!validCompanyEmailRegex.test(companyEmail)) {
      errors.companyEmail = 'Please enter a valid email address.';
      setValid(false)
    } else {
      setValid(true)
    }

    return errors;
  };

  const handleCompanyEmailChanged = (event) => {
    const value = event.target.value;
    setCompanyEmail(value);
    if (value != originData.companyEmail) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const openPopup = () => {
    // alert('openPopup is called:' + valid)
    if(valid) setOpen(true)
  };

  const generateQRCode = (source) => {
    console.log('source for generating qr code:' + source);
    if(!source) {
      setQr(sampleQrcode);
      setDisabled(true);
      return;
    }
    QRCode.toDataURL(defaultURL+source, {
      width: 72,
      margin: 2,
      color: {
        // dark: '#335383FF',
        // light: '#EEEEEEFF'
      }
    }, (err, url) => {
      if (err) {
        console.error(err);
        return
      }
      console.log(url)
      setQr(url)
      setDisabled(false);
    })
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = async () => {
    setProceedConfirmed(true);
    await updateUser();
    setChanged(false);
    setOpen(false);
    setProceedConfirmed(false);
  };

  const getCompany = async () => {
    const companyInfo = await dispatch(
      getCompanyInfo(email));
    return companyInfo.data;
  };

  const updateUser = async(setStatus, setErrors) => {
    setShowSpinner(true);
    try {
      await dispatch(
        updateUserAttribute(CUSTOM_COMPANY_EMAIL, companyEmail.toLowerCase())
      );

      let existingCompany = await getCompany();
      existingCompany = {...existingCompany, companyEmail: companyEmail,
        address: existingCompany.address.street, city: existingCompany.address.city,
      zipcode: existingCompany.address.zipcode, state: existingCompany.address.state };

      await dispatch(
        updateCompanyInfo(existingCompany)
      );

      setOriginData({
        email: email,
        companyEmail: companyEmail.toLowerCase(),
      });

    } catch (error) {
      let message = error.message || "Something went wrong";
      if (error.code === 'UserNotConfirmedException') {
        message = 'Account not verified yet';
      } else if (error.code === 'PasswordResetRequiredException') {
        message = 'Existing user found. Please reset your password';
      } else if (error.code === 'NotAuthorizedException') {
        message = 'Forgot Password?';
      } else if (error.code === 'UserNotFoundException') {
        message = 'User does not exist!';
      } else if (error.code === 'UsernameExistsException') {
        message = 'User already exists!';
      } else if (error.code === 'InvalidParameterException') {
        message = 'Trailing spaces are not allowed in your password \n or one of parameters is invalid';
      }

      // setStatus({success: false});
      // setErrors({submit: message});
    }
    setShowSpinner(false);
  };


  return (
    <>
      <Formik
        initialValues={{
          companyEmail: ''
        }}
        validate={validateCompanyEmail}
        // onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
        //   await updateUser(setStatus, setErrors);
        // }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
          <form noValidate onSubmit={handleSubmit}>
            {
              showAlert && (
                <Alert mt={2} mb={1} severity={(errors.submit) ? "error" : "success"} onClose={() => {
                  setShowAlert(false)
                }}>
                  {(errors.submit) ? errors.submit : 'Successfully saved.'}
                </Alert>
              )}
            <Card mb={6} style={{minHeight: '290px'}}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={8}>
                    <Typography variant="h6" gutterBottom>
                      QR code generator
                    </Typography>
                    <div className="s20"></div>
                    <Typography variant="h7" gutterBottom>
                      If a device fails to connect to the Wi-Fi network or has issues, customers can scan the QR code to
                      submit an inquiry and you will receive an email notification. If you change the inquiry
                      email address below, an email will be sent to the changed company email address.
                    </Typography>
                    <div className="s20"></div>
                    <TextField
                      type="email"
                      name="companyEmail"
                      label="Company email address"
                      value={companyEmail}
                      error={Boolean(touched.companyEmail && errors.companyEmail)}
                      fullWidth
                      helperText={touched.companyEmail && errors.companyEmail}
                      onBlur={handleBlur}
                      onChange={handleCompanyEmailChanged}
                      my={2}
                    />
                    <div className="s40"></div>
                    <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
                      {
                        <>
                          {
                            changed ?
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                classes={{root: 'button', label: 'button-label'}}
                                onClick={openPopup}
                              >
                                Save
                              </Button>
                              :
                              <Button classes={{root: 'button', label: 'button-label'}}
                                      style={{background: '#E0E0E0', color: '#FFFFFF'}} disabled>Save</Button>
                          }
                        </>
                      }
                    </Box>
                  </Grid>
                  <Grid item md={4}
                        container
                        align="center"
                        justify="center"
                        direction="column">
                    <CenteredContent>
                      <Space height={'20px'}/>
                      <img src={qr} style={{width: '90px', height: '90px', backgroundColor: 'transparent'}}/>
                      <div className="s10"></div>
                      <label htmlFor="raised-button-file">
                        <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems={'center'}>
                          <Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
                            {
                              <>
                                {/*<Button variant='contained' color='success' href={qr} download="qrcode.png">Download</Button>*/}
                                <Button
                                  variant="contained" color="primary" classes={{root: 'button', label: 'button-label'}} disabled={disabled}
                                  href={qr}
                                  download="qrcode.png">
                                  <CloudDownload mr={2} disabled={disabled}/> Download QR code
                                </Button>
                              </>
                            }
                          </Box>
                        </Box>
                        <div className="s10"></div>
                        {/*<Typography variant="caption" display="block" gutterBottom>*/}
                        {/*  For best results, use an image at least 128px by 128px in .jpg*/}
                        {/*  format*/}
                        {/*</Typography>*/}
                        {/*<div className="s20"></div>*/}
                      </label>
                    </CenteredContent>
                  </Grid>
                </Grid>
                  <Dialog
                    fullWidth={'519px'}
                    maxWidth="xs"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                    PaperProps={{style: {alignItems: 'center', justifyContent: 'center'}}}
                  >
                    <div className="s15"></div>
                    <DialogTitle id="max-width-dialog-title" style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                      <Box style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img src={errorIcon} style={{width: '24px', height: '24px', backgroundColor: 'transparent'}}/>
                        <Box style={{width:'5px'}}></Box>
                        <Box>{'Update Inquiry Email Address?'}</Box>
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText props={{style: {alignItems: 'center', justifyContent: 'center'}}}>
                        {'If you change the inquiry email address, ' +
                        'an email will be sent to the changed company email address.'}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant={'outlined'} onClick={handleClose} style={{background: '#FFFFFF', color: '#9E9E9E', borderColor:'#9E9E9E', borderWidth:'1px'}}>
                        Cancel
                      </Button>
                      {proceedConfirmed ?
                        <Button onClick={handleProceed} style={{background: '#E0E0E0', color: '#FFFFFF', disabled}}
                                classes={{root: 'button', label: 'button-label'}}>
                          {'Yes, Proceed'}
                        </Button>:
                        <Button onClick={handleProceed} style={{background: '#B00020', color: '#FFFFFF'}}
                                classes={{root: 'button', label: 'button-label'}}>
                          {'Yes, Proceed'}
                        </Button>
                      }
                      {
                        showSpinner ?
                          <div className='save-changes-spinner'>
                            <CircularProgress size={20}/>
                          </div> : <></>
                      }
                    </DialogActions>
                    <div className="s20"></div>
                  </Dialog>
                {/*}*/}
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
}

export default QRInfo;
