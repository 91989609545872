import React from "react";
import styled, { withTheme } from "styled-components/macro";


import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Doughnut } from "react-chartjs-2";

import {GREEN, ORANGE, RED} from "../../../constants/colors";


const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const OrangeText = styled.span`
  color: ${() => ORANGE};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const GreenText = styled.span`
  color: ${() => GREEN};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => RED};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

function RadonLevelChart({ radonLevel, unit, theme }) {
  const maxRadon = Math.max(radonLevel.green,radonLevel.orange,radonLevel.red);
  const data = {
    labels: ["No Action Required", "Consider Fixing Home", "Recommend Fixing Home"],
    datasets: [
      {
        data: [(radonLevel.green/radonLevel.total * 100).toFixed(1), (radonLevel.red/radonLevel.total * 100).toFixed(1), (radonLevel.orange/radonLevel.total * 100).toFixed(1)],
        backgroundColor: [GREEN, RED, ORANGE],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'bottom'
    },
    cutoutPercentage: 80,
  };

  return (
    <Card mb={3}>
      <CardHeader
        // action={
        //   // <IconButton aria-label="settings">
        //   //   {/*<MoreVertical />*/}
        //   // </IconButton>
        //   // <span style={{color: 'gray', paddingTop: '5px', alignItems: 'center'}}>08/26/21 ~ 09/02/21</span>
        // }
        title="Radon Level"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner variant="h4">
            {
              (radonLevel.green === maxRadon) &&
              <Typography style={styles.centerGreenText}>{(maxRadon * 100/radonLevel.total).toFixed(1)}%</Typography>
            }
            {
              (radonLevel.green !== maxRadon && radonLevel.red === maxRadon) &&
              <Typography style={styles.centerRedText}>{(maxRadon * 100/radonLevel.total).toFixed(1)}%</Typography>
            }
            {
              (radonLevel.green !== maxRadon && radonLevel.red !== maxRadon && radonLevel.orange === maxRadon) &&
              <Typography style={styles.centerOrangeText}>{(maxRadon * 100/radonLevel.total).toFixed(1)}%</Typography>
            }
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Radon Status</TableCell>
              <TableCell align="right">Range ({unit === 0 ? 'pCi/L' : 'Bq/m3'})</TableCell>
              <TableCell align="right">Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                No Action Required
              </TableCell>
              <TableCell align="right">{unit === 0 ? 'value < 2.7' : 'value < 100'}</TableCell>
              <TableCell align="right">
                <GreenText>{(radonLevel.green/radonLevel.total * 100).toFixed(1)}%</GreenText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Consider Fixing Home
              </TableCell>
              <TableCell align="right">{unit === 0 ? '2.7 <= value < 4.0' : '100 <= value < 148'}</TableCell>
              <TableCell align="right">
                <OrangeText>{(radonLevel.orange/radonLevel.total * 100).toFixed(1)}%</OrangeText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Recommend Fixing Home
              </TableCell>
              <TableCell align="right">{unit === 0 ? 'value >= 4.0' : 'value >= 148'}</TableCell>
              <TableCell align="right">
                <RedText>{(radonLevel.red/radonLevel.total * 100).toFixed(1)}%</RedText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

const styles = {
  centerGreenText: {
    fontSize: '18px',
    fontWeight: '800',
    fontStyle: 'normal',
    lineHeight: '24px',
    paddingTop: '10px',
    color: GREEN
  },
  centerRedText: {
    fontSize: '18px',
    fontWeight: '800',
    fontStyle: 'normal',
    lineHeight: '24px',
    paddingTop: '10px',
    color: RED
  },
  centerOrangeText: {
    fontSize: '18px',
    fontWeight: '800',
    fontStyle: 'normal',
    lineHeight: '24px',
    paddingTop: '10px',
    color: ORANGE
  },
}

export default withTheme(RadonLevelChart);
