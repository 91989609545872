import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import ecosenseLogo from '../../assets/img/ecosense-logo3.png';
import {display, spacing} from "@material-ui/system";
import _ from "lodash";
import "../../assets/css/order.css";
import Doc from '../reports/ReportDocService';
import {MAIN} from '../../constants/colors';
import Space from '../../components/Space';
import CustomHr from '../../components/CustomHr';
import ReportInterface from '../../integration/internal/ReportInterface';
import moment from 'moment';
import {UNIT_CONVERSION_SCALE} from '../../constants/values';
import ReportPdfContainer from '../../common/pdf/ReportPdfContainer';
import Loading from "../components/Loading";
import RadonLineChart from "../dashboards/Analytics/RadonLineChart";
import DialogContentText from '@material-ui/core/DialogContentText';
import {RadonUtil} from "../components/RadonUtil";
import ecoGuardLogo from "../../assets/img/EcoGuard_logo.png";
import {section1, section2, section3} from "../../report/template";
import {
  DATA_POINTS_NUMBER, DURATION,
  END_DATE,
  RADON_AVG_VALUE,
  RADON_MAX_VALUE,
  RADON_MIN_VALUE,
  START_DATE
} from "../../report/constant";
import {RADON_REF_BQ_LEVEL, RADON_REF_PCI_LEVEL} from "../dashboards/Devices/DeviceDetail";
import SubscriptionInterface from "../../integration/internal/SubscriptionInterface";
import RadonLevelChart from "../dashboards/Analytics/RadonLevelChart";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function AvReportDetails() {
  const location = useLocation();
  const [width, setWidth] = useState();
  const [reportDetails, setReportDetails] = useState({});
  const [reportDuration, setReportDuration] = useState({start: null, end: null});
  const [loaded, setLoaded] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  let res;
  const lineChartRef = useRef(null);
  const history = useHistory();
  const [startMoment, setStartMoment] = useState();
  const [originMoment, setOriginMoment] = useState();
  const [endMoment, setEndMoment] = useState();
  const [chartType, setChartType] = useState("DAY");
  const [chartInfo, setChartInfo] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState({serial_number: null});
  const [dataArray, setDataArray] = useState();
  const defaultRadonLevel = {
    total: 0,   // Total Data Count
    green: 0,   // No Action Required (0 <= x < 2.7)
    orange: 0,  // Consider Fixing Home (2.7 <= x < 4)
    red: 0,    // Recommend Fixing Home (4 < x)
  }
  const [radonLevel, setRadonLevel] = useState(defaultRadonLevel);

  useEffect(() => {
    init();
    setLoaded(true);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const init = async () => {
    await getDeviceInfo();
    await getChartInfo(chartType);
    await loadReportDetails();
  }

  const getChartInfo = async (type = 'DAY') => {
    if (!location.state) return;
    const email = localStorage.getItem('0xaccountId');
    const serial_number = location.state.sn;

    let durationMoment = RadonUtil.getDurationMomentByType(type);
    setOriginMoment({
        startMoment: durationMoment.startMoment,
        endMoment: durationMoment.endMoment,
      }
    );
    setStartMoment(durationMoment.startMoment);
    setEndMoment(durationMoment.endMoment);

    const reportDetail = await ReportInterface.retrieveRadondata(email, serial_number, type);
    const radondata = _.get(reportDetail, 'data.radondata');
    //console.log('reportDetail : ' + JSON.stringify(reportDetail.data.radondata));
    if (radondata && radondata.length > 0) {
      let dataSet = reportDetail.data.radondata;
      setChartInfo(dataSet);
    } else {
      setChartInfo([]);
    }
  };

  const getDeviceInfo = async () => {
    console.log('location.state.sn = ' + location.state.sn);
    if(!location.state||!location.state.sn) {
      history.replace('/devices');
      return;
    }
    res = await SubscriptionInterface.retrieveDeviceDetails(localStorage.getItem('0xaccountId'), location.state.sn);
    if (res && !res.data) {
      return;
    } else if (!_.isEqual(deviceInfo, res.data)) {
      setDeviceInfo(res.data);
    }
  };

  const successLoadedChartData = (data) => {
    if (data && data.length > 0) {
      setDataArray(data);
      setRadonLevel(defaultRadonLevel);

      let green = 0;
      let orange = 0;
      let red = 0;
      let total = 0;

      const RADON_LEVEL2 = (deviceInfo.unit == 0) ? RADON_REF_PCI_LEVEL.LEVEL2 : RADON_REF_BQ_LEVEL.LEVEL2;
      const RADON_LEVEL3 = (deviceInfo.unit == 0) ? RADON_REF_PCI_LEVEL.LEVEL3 : RADON_REF_BQ_LEVEL.LEVEL3;

      for (let i = 0; i < data.length; i++) {
        // const radonLevel = RadonUtil.convertToPCi(data[i].radon_level);
        const radonLevel = data[i].radon_level;
        if (radonLevel >= 0 && radonLevel < RADON_LEVEL2) {
          green++;
        } else if (radonLevel >= RADON_LEVEL2 && radonLevel < RADON_LEVEL3) {
          orange++;
        } else if (radonLevel >= RADON_LEVEL3) {
          red++;
        }
      }

      setRadonLevel ({
        total: green + orange + red,    // Total Data Count
        green: green,    // No Action Required (0 <= x < 2.7)
        orange: orange,  // Consider Fixing Home (2.7 <= x < 4)
        red: red,       // Recommend Fixing Home (4 < x)
      });
    }
  }

  const loadReportDetails = async () => {
    if (!location.state) return;
    let durationSet;
    if (location.state.actionType === 'VIEW') {
      durationSet = {
        measurement_start: location.state.duration.start,
        measurement_end: location.state.duration.end,
        types: location.state.duration.btnType,
      }
    } else {
      durationSet = RadonUtil.convert2DurationSet(location.state.duration);
    }
    setLoaded(false);

    // Input: start ('081221'), end ('091121')
    let durationMoment = RadonUtil.getDurationMomentByDate(
      RadonUtil.getFormattedDate(durationSet.measurement_start),
      RadonUtil.getFormattedDate(durationSet.measurement_end)
    );

    setOriginMoment({
        startMoment: durationMoment.startMoment,
        endMoment: durationMoment.endMoment,
      }
    );
    setStartMoment(durationMoment.startMoment);
    setEndMoment(durationMoment.endMoment);

    res = await ReportInterface.retrieveReportDetails(location.state.email, location.state.sn, durationSet);
    if ((res && !res.data)||!res) {
      setReportDetails(null);
      setReportDuration({
        start: durationSet.measurement_start,
        end: durationSet.measurement_end,
        type: location.state.duration.type
      });
      setShowNoData(true);
    } else if (res && !_.isEqual(reportDetails, res.data)) {
      const data = {
        ...res.data,
        start_date: RadonUtil.getFormattedDate(durationSet.measurement_start),
        end_date: RadonUtil.getFormattedDate(durationSet.measurement_end),
        data_points_number: res.data.radondata && res.data.radondata.length > 0 ? res.data.radondata.length : 0,
        duration: reportType,
        radon_avg_value: (res.data.avg_radon / UNIT_CONVERSION_SCALE).toFixed(1),
        radon_min_value: (res.data.lowest_radon / UNIT_CONVERSION_SCALE).toFixed(1),
        radon_max_value: (res.data.highest_radon / UNIT_CONVERSION_SCALE).toFixed(1)
      }
      setReportDetails(data);
      setReportDuration({
        start: durationSet.measurement_start,
        end: durationSet.measurement_end,
        type: location.state.duration.type
      });
    }
  };

  const handleResize = () => {
    if (lineChartRef && lineChartRef.current && lineChartRef.current.offsetWidth) {
      setWidth(lineChartRef.current.offsetWidth);
    }
  }

  const createPdf = (html, id, callback) => {
    // setWidth(800);
    Doc.createPdf(html, id, callback);
  };

  const getGeneratedDate = () => {
    let genDate = location.state.originalGenerated;
    return genDate;
  };

  let generated = RadonUtil.getDate(moment(getGeneratedDate()), 3);
  let reportType = RadonUtil.getReportTypeText(location.state.duration.type);
  const handleClose = () => {
    setShowNoData(false);
    history.push({pathname: '/reports/list'});
  };

  const getTitle = (isForPDF) => {
    return (
      <Box style={styles.report_title_box}>
        <Typography align={'center'} style={isForPDF ? styles.report_title_for_pdf : styles.report_title_for_html}>
          RADON REPORT
        </Typography>
      </Box>
    );
  }

  const getClientInfo = (isForPDF) => {
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={6} md={6}>
            <Typography gutterBottom style={styles.valueForText}>
              {reportDetails.companyName}</Typography>
            <Typography gutterBottom style={styles.valueForText}>
              {reportDetails.email}</Typography>
            <Space height={'10px'}/>
            <Typography gutterBottom style={styles.valueForText}>
              {reportDetails.address.street}</Typography>
            <Typography gutterBottom style={styles.valueForText}>
              {reportDetails.address.city}{reportDetails.address.city? ', ': ''}{reportDetails.address.state}{' '}{reportDetails.address.zipcode}</Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography align={'right'} gutterBottom style={styles.labelForTitle}>
              Client Name
            </Typography>
            <Typography align={'right'} gutterBottom style={styles.valueForText}>
              {reportDetails.client_name || 'N/A'}
            </Typography>
            <Space height={'10px'}/>
            <Typography align={'right'} gutterBottom style={styles.labelForTitle}>
              Client Email
            </Typography>
            <Typography align={'right'} gutterBottom style={styles.valueForText}>
              {reportDetails.client_email || 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Space height={'35px'}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12} md={12}>
          <Space height={'25px'}/>
        </Grid>
      </>
    )
  }

  const getHeaderInfo = (isForPDF) => {
    return (
      <>
        <Grid container>
          <Grid item xs={6} md={6}>
            <img src={ecosenseLogo} style={{width: '250px', backgroundColor:'transparent'}}/>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography align={'right'} gutterBottom style={styles.labelForTitleInHeader}>
              Duration</Typography>
            <Typography align={'right'} gutterBottom style={styles.valueForTextInHeader}>
              {RadonUtil.getFormattedDateWithoutYear(reportDuration.start)}{''}~{''}
              {RadonUtil.getFormattedDateWithoutYear(reportDuration.end)}</Typography>
            <Typography align={'right'} gutterBottom style={styles.labelForTitleInHeader}>
              Serial Number</Typography>
            <Typography align={'right'} gutterBottom style={styles.valueForTextInHeader}>
              {reportDetails.serial_number}</Typography>
          </Grid>
        </Grid>
        <Space height={'32px'}/>
        {/*<Grid item xs={6} md={6}>*/}
        {/*  <Typography gutterBottom style={styles.labelForTitle}>*/}
        {/*    {reportType} of Radon Data</Typography>*/}
        {/*  <Space height={'3px'}/>*/}
        {/*  <Grid container spacing={6}>*/}
        {/*    <Grid item md={4}>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.valueForText}>*/}
        {/*        {(reportDetails.lowest_radon / UNIT_CONVERSION_SCALE).toFixed(1)} pCi/L*/}
        {/*      </Typography>*/}
        {/*      <CustomHr color={MAIN} height={1}/>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.radon_number_label}>*/}
        {/*        Lowest*/}
        {/*      </Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item md={4}>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.valueForText}>*/}
        {/*        {(reportDetails.avg_radon / UNIT_CONVERSION_SCALE).toFixed(1)} pCi/L*/}
        {/*      </Typography>*/}
        {/*      <CustomHr color={MAIN} height={1}/>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.radon_number_label}>*/}
        {/*        Average*/}
        {/*      </Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item md={4}>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.valueForText}>*/}
        {/*        {(reportDetails.highest_radon / UNIT_CONVERSION_SCALE).toFixed(1)} pCi/L*/}
        {/*      </Typography>*/}
        {/*      <CustomHr color={MAIN} height={1}/>*/}
        {/*      <Typography variant={'body2'} gutterBottom style={styles.radon_number_label}>*/}
        {/*        Highest*/}
        {/*      </Typography>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Space height={'32px'}/>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <Divider/>*/}
        {/*</Grid>*/}
        {/*<Space height={'21px'}/>*/}
      </>
    )
  }

  const getSection1 = () => {

    // Retrieve value
    let title = section1["title"];
    let para1 = section1["p1"];

    // Replace actual value in template
    para1 = para1.replace(START_DATE, reportDetails.start_date);
    para1 = para1.replace(END_DATE, reportDetails.end_date);
    para1 = para1.replace(DATA_POINTS_NUMBER, reportDetails.data_points_number);
    para1 = para1.replace(DURATION, reportDetails.duration);
    para1 = para1.replace(RADON_AVG_VALUE, reportDetails.radon_avg_value);
    para1 = para1.replace(RADON_MIN_VALUE, reportDetails.radon_min_value);
    para1 = para1.replace(RADON_MAX_VALUE, reportDetails.radon_max_value);

    return (
      <>
        <Typography style={styles.titleForSection}>1. {title}</Typography>
        <Space height={'15px'}/>
        <Typography style={styles.contentForSection}>
          {para1}
        </Typography>
      </>
    );
  }

  const getSection2 = (isForPDF) => {
    // Retrieve value
    let title = section2["title"];
    let para1 = section2["p1"];
    let para2 = section2["p2"];

    return (
      <>
        <Typography style={styles.titleForSection}>2. {title}</Typography>
        <Space height={'15px'}/>
        <Typography style={styles.contentForSection}>
          {para1}
        </Typography>
        <Space height={'10px'}/>
        <Typography style={styles.contentForSection}>
          {para2}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} style={styles.center_container} ref={isForPDF ? null : lineChartRef}>
            <RadonLineChart
              originMoment={originMoment}
              setStartMoment={setStartMoment}
              setEndMoment={setEndMoment}
              radon={reportDetails.radondata}
              type={reportDuration.type}
              width={isForPDF ? 750 : width}
              isForPDF={isForPDF}
              isForDashboard={true}
              successLoadedChartData={successLoadedChartData}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const getSection3 = () => {
    // Retrieve value
    let title = section3["title"];
    let para1 = section3["p1"];
    let para2 = section3["p2"];

    return (
      <>
        <Typography style={styles.titleForSection}>3. {title}</Typography>
        <Space height={'15px'}/>
        <Typography style={styles.contentForSection}>
          {para1}
        </Typography>
        <Space height={'10px'}/>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} style={styles.center_container}>
            {
              (chartInfo && chartInfo.length > 0 && (deviceInfo.unit !== undefined && deviceInfo.unit !== null ) ) ?
                <>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl>
                      <RadonLevelChart
                        radonLevel={radonLevel}
                        unit={deviceInfo.unit}
                      >
                      </RadonLevelChart>
                    </Grid>
                    {/*<Grid item xs={12} sm={12} md={6} lg={6} xl>*/}
                    {/*  <RadonNumberTable*/}
                    {/*    radon={chartInfo}*/}
                    {/*  >*/}
                    {/*  </RadonNumberTable>*/}
                    {/*</Grid>*/}
                  </Grid>
                  {/*<Grid container spacing={6}>*/}
                  {/*  <Grid item xs={12} sm={12} md={12} lg={12} xl>*/}
                  {/*    <RadonHourlyLineChart>*/}
                  {/*    </RadonHourlyLineChart>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                </>
                :
                null
            }
          </Grid>
        </Grid>
        <Space height={'10px'}/>
        <Typography style={styles.contentForSection}>
          {para2}
        </Typography>
      </>
    )
  }

  const getFooter = () => {
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant={'body2'} align={'left'} gutterBottom style={styles.report_footer}>
              EcoGuard Radon Report
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'body2'} align={'right'} gutterBottom style={styles.report_footer}>
              Report Generated on {generated}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  const getNoDataDialog = () => {
    return (
      <>
        <Dialog
          titleStyle={{textAlign: "center"}}
          maxWidth="md"
          maxHeight={'sm'}
          open={showNoData}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <div className="s5"></div>
          <DialogTitle id="alert-dialog-title">
            Information Message
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">There is no radon data collected during the
              period.</DialogContentText>
          </DialogContent>
          <div className="s10"></div>
          <DialogActions style={{paddingRight: '20px'}}>
            <Button style={{background: '#E0E0E0', color: '#000000', width: '100px', height: '36px'}}
                    onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
          <div className="s10"></div>
        </Dialog>
      </>
    )
  }

  return (
    <React.Fragment>
      <Helmet title="Report Details"/>
      <Typography variant="h3" gutterBottom display="inline">
        Radon Analysis Report
      </Typography>
      {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
      {/*  <Link component={NavLink} exact to="/reports/list">*/}
      {/*    Radon Reports*/}
      {/*  </Link>*/}
      {/*  <Typography>Details</Typography>*/}
      {/*</Breadcrumbs>*/}
      <Divider my={6}/>
      <>
        {
          !reportDuration.start ? (
            <Loading></Loading>
          ) : (!reportDetails ?
              getNoDataDialog() :
              <>
                <Grid item xs={12} lg={10} >
                  {/*{getTitle(true)}*/}
                  <Shadow>
                    <Card px={6} pt={6}>
                      <CardContent>
                        {/*{getClientInfo()}*/}
                        {getHeaderInfo()}
                        {getSection1()}
                        <Space height={'40px'}/>
                        {getSection2(false)}
                        <Space height={'40px'}/>
                        {getSection3()}
                      </CardContent>
                      {getFooter()}
                    </Card>
                  </Shadow>
                </Grid>
              </>
          )
        }
      </>
    </React.Fragment>
  );
}

const styles = {
  titleForSection: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#000000',
    // opacity: 0.5,
  },
  contentForSection: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '25px',
    color: '#000000',
    paddingLeft: '5px',
    paddingRight: '5px',
    opacity: 0.8,
  },
  labelForTitleInHeader: {
    fontSize: '11px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.5,
  },
  valueForTextInHeader: {
    fontSize: '13px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.7,
    minWidth: '120px'
  },
  labelForTitle: {
    fontSize: '12px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.6,
  },
  valueForText: {
    fontSize: '15px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#000000',
    minWidth: '120px'
  },
  pdf_border: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#dee0e2',
  },
  line_border: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#dee0e2',
  },
  radon_number_label: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '18px',
  },
  report_title_for_html: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#FFFFFF',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  report_title_for_pdf: {
    fontStyle: 'bold',
    fontWeight: 600,
    fontSize: '15px',
    color: '#FFFFFF',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  report_title_box: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: MAIN,
    justifyContent: 'center',
    alignItems: 'center'
  },
  report_footer: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '11px',
    lineHeight: '48px',
    color: '#8a8a8a',
  },
  center_container: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  hidden: {
    position: "absolute",
    left: "-2000px",
    top: -500,
  }
};

export default AvReportDetails;
