import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CircularProgress,
  Typography as MuiTypography,
  Button, FormControlLabel,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import TextField from "@material-ui/core/TextField";
import SubscriptionInterface from "../../../integration/internal/SubscriptionInterface";
import Space from "../../../components/Space";
import { customAlert } from "../../../utils/AlertWrapper";
import {
  labelForTitle,
  valueForText,
  valueForInput,
  labelForMessage,
} from "../../../views/dashboards/Devices/CardLayout";
import { Add as AddIcon } from "@material-ui/icons";
import { valueForLocationText } from "./CardLayout";
import CustomTextField from "../../../components/CustomTextField";
import { BUTTON_DISABLED, MAIN, WHITE } from "../../../constants/colors";
import DefaultCheckbox from "../../components/DefaultCheckbox";
import {validEmailRegex} from "../../../utils/AuthValidation";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ClientInfoCard = ({ data, callback }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [name, setName] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [location, setLocation] = useState(null);
  const [phone, setPhone] = useState(null);
  const [notifyMonthly, setNotifyMonthly] = useState(null);
  const [changed, setChanged] = useState(0);
  let installed =
    !data.activated || data.activated === "N"
      ? "N/A"
      : new Date(
          data.activated.replaceAll("-", "/").split(".")[0].split("T")[0] +
            " " +
            data.activated.replaceAll("-", "/").split(".")[0].split("T")[1] +
            " UTC"
        );
  let deactivated =
    !data.deactivated || data.deactivated === "N"
      ? "N/A"
      : new Date(
          data.deactivated.replaceAll("-", "/").split(".")[0].split("T")[0] +
            " " +
            data.deactivated.replaceAll("-", "/").split(".")[0].split("T")[1] +
            " UTC"
        );

  // useEffect(() => {
  //   // console.log('client data=='+JSON.stringify(data));
  //   if (name == null || name == undefined) setName(data.device_name || null);
  //   if (clientName == null || clientName == undefined)
  //     setClientName(data.client_name || null);
  //   if (location == null || location == undefined)
  //     setLocation(formatLocation(data.device_location));
  //   if (phone == null || phone == undefined) setPhone(data.phone || null);
  //   if (notifyMonthly == null || notifyMonthly == undefined) setNotifyMonthly(data.notify_monthly || null);
  //   if (clientEmail == null || clientEmail == undefined) {
  //     if (data.client_display) setClientEmail(data.client_display);
  //     else {
  //       if (data.guest_email && data.guest_email.length) {
  //         setClientEmail(data.guest_email[0]);
  //         applyChange();
  //       }
  //       else setClientEmail(null);
  //     }
  //   }
  //   checkChange();
  // });

  useEffect(() => {
    // console.log('client data=='+JSON.stringify(data));
    if (name == null) setName(data.device_name);
    if (clientName == null) setClientName(data.client_name);
    if (clientEmail == null) setClientEmail(data.client_display);
    if (location == null) setLocation(formatLocation(data.device_location));
    if (phone == null) setPhone(data.phone);
    if (notifyMonthly == null) setNotifyMonthly(data.notify_monthly);

    console.log(`guest email = ${data.guest_email}, ${data.client_display}`);
    if(data.guest_email) console.log(`guest email length = ${data.guest_email.length}`);
    if (!data.client_display && (data.guest_email && data.guest_email.length)) {
      setClientEmail(data.guest_email[0]);
      let result = SubscriptionInterface.changeClientInformation(
        localStorage.getItem("0xaccountId"),
          data.serial_number,
          clientName,
          data.guest_email[0],
          name,
          location,
          phone,
          notifyMonthly
      );
      console.log(`result=${JSON.stringify(result)}`);
      if (result) {
        console.log(`set callback with ${data.serial_number}`);
        callback(data.serial_number);
      }
    }
    checkChange();
  });

  const formatLocation = (address) => {
    if (!address) return null;
    const { street, city, state, zipcode } = address;
    let formattedAddress = "";
    if (street != null && street != "") {
      formattedAddress = street;
    }
    if (city != null && city != "") {
      if (formattedAddress != "") formattedAddress += ", " + city;
      else formattedAddress = city;
    }
    if (state != null && state != "") {
      if (formattedAddress != "") formattedAddress += ", " + state;
      else formattedAddress = state;
    }
    if (zipcode != null && zipcode != "") {
      if (formattedAddress != "") formattedAddress += ", " + zipcode;
      else formattedAddress = zipcode;
    }
    return formattedAddress;
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const onClientEmailChange = (event) => {
    setClientEmail(event.target.value);
  };

  const onLocationChange = (event) => {
    // setLocation(event.target.value);
  };

  const onPhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const onNotifyMonthlyChange = (event) => {
    setNotifyMonthly(Number(event.target.checked));
  }

  const applyChange = async () => {
    setShowSpinner(true);
    let result = await SubscriptionInterface.changeClientInformation(
      localStorage.getItem("0xaccountId"),
      data.serial_number,
      clientName,
      clientEmail,
      name,
      location,
      phone,
      notifyMonthly
    );

    if (result) {
      setChanged(false);
      callback(data.serial_number);
    } else {
      customAlert("Fail to apply changes");
    }
    setShowSpinner(false);
  };

  const checkChange = () => {
    // console.log("name != data.device_name = " + name != data.device_name);
    // console.log("location != formatLocation(data.device_location) = " + location != formatLocation(data.device_location));
    // console.log("clientName != data.client_name = " + clientName != data.client_name);
    // console.log("phone != data.phone = " + phone != data.phone);
    console.log(`${name}==${data.device_name}, ${clientName}==${data.client_name}, ${clientEmail}==${data.client_display}, ${phone}==${data.phone},
    ${notifyMonthly}==${data.notify_monthly}`);
    if (
      // name != (data.device_name || null) ||
      // clientName != (data.client_name || null) ||
      // clientEmail != (data.client_display || null) ||
      // phone != (data.phone || null)
      name != data.device_name ||
      clientName != data.client_name ||
      clientEmail != data.client_display ||
      phone != data.phone ||
        (notifyMonthly !== null && data.notify_monthly !== notifyMonthly)
    ) {
      if (clientEmail && !validEmailRegex.test(clientEmail)) setChanged(false);
      else setChanged(true);
    } else setChanged(false);
  };

  return !data.serial_number ? (
    <Card mb={3} className="loading-card">
      <CircularProgress />
    </Card>
  ) : (
    <Card mb={3} className="common-card">
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box style={{ width: "100%" }}>
            <Typography variant={"subtitle1"} mb={3}>
              <Box fontWeight="fontWeightBold">Client Information</Box>
            </Typography>
          </Box>
          <Box style={{ width: "60px" }}></Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {changed ? (
              <Button
                style={{
                  background: changed ? "#3DB5E6" : "#E0E0E0",
                  color: "#FFFFFF",
                }}
                onClick={applyChange}
              >
                Save
              </Button>
            ) : (
              <Button
                style={{ background: "#E0E0E0", color: "#FFFFFF" }}
                disabled
              >
                Save
              </Button>
            )}
            {showSpinner ? (
              <div className="save-changes-spinner">
                <CircularProgress size={20} />
              </div>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Space height={"20px"} />
        <Typography style={labelForTitle}>
          Invited Guest (accessible to mobile app)
        </Typography>
        <CustomTextField
          style={valueForText}
          name="inviteGuestEmail"
          placeholder={""}
          value={
            data.guest_email && data.guest_email.length
              ? data.guest_email[0]
              : "N/A"
          }
          fullWidth
          disabled
          InputProps={true ? { disableUnderline: true } : {}}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>
          Client Email Address (displayed in the report)
        </Typography>
        <CustomTextField
          style={valueForText}
          name="clidentEmailAddress"
          placeholder={""}
          onChange={onClientEmailChange}
          value={clientEmail}
          fullWidth
          error={Boolean(clientEmail && !validEmailRegex.test(clientEmail))}
          helperText={clientEmail && !validEmailRegex.test(clientEmail) ? 'Please enter a valid email address.' : ''}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Client Name</Typography>
        <CustomTextField
          style={valueForText}
          name="clientName"
          placeholder={""}
          value={clientName}
          fullWidth
          onChange={onClientNameChange}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Activated Date</Typography>
        <CustomTextField
          style={valueForText}
          name="activatedDate"
          placeholder={""}
          value={
            installed === "N/A"
              ? installed
              : installed.getMonth() +
                1 +
                "/" +
                installed.getDate() +
                "/" +
                installed.getFullYear()
          }
          fullWidth
          disabled
          InputProps={true ? { disableUnderline: true } : {}}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Deactivated Date</Typography>
        <CustomTextField
          style={valueForText}
          name="deactivatedDate"
          placeholder={""}
          value={
            deactivated === "N/A"
              ? ""
              : deactivated.getMonth() +
                1 +
                "/" +
                deactivated.getDate() +
                "/" +
                deactivated.getFullYear()
          }
          fullWidth
          disabled
          InputProps={true ? { disableUnderline: true } : {}}
          my={3}
        />
        {/*<Box display='flex' flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>*/}
        {/*  <Box style={{width: '100%'}}>*/}
        {/*    <Typography style={labelForTitle}>Activated Date</Typography>*/}
        {/*    <CustomTextField*/}
        {/*      style={valueForText}*/}
        {/*      name="activatedDate"*/}
        {/*      placeholder={''}*/}
        {/*      value={installed==='N/A'? installed: (installed.getMonth() + 1) + '/' + installed.getDate() + '/' + installed.getFullYear()}*/}
        {/*      fullWidth*/}
        {/*      disabled*/}
        {/*      InputProps={true ? { disableUnderline: true } : {}}*/}
        {/*      my={3}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*  <Box style={{width:'60px'}}></Box>*/}
        {/*  <Box style={{width: '100%'}}>*/}
        {/*    <Typography style={labelForTitle}>Deactivated Date</Typography>*/}
        {/*    <CustomTextField*/}
        {/*      style={valueForText}*/}
        {/*      name="deactivatedDate"*/}
        {/*      placeholder={''}*/}
        {/*      value={deactivated==='N/A'? deactivated: (deactivated.getMonth() + 1) + '/' + deactivated.getDate() + '/' + deactivated.getFullYear()}*/}
        {/*      fullWidth*/}
        {/*      disabled*/}
        {/*      InputProps={true ? { disableUnderline: true } : {}}*/}
        {/*      my={3}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Device Name</Typography>
        <CustomTextField
          style={valueForText}
          name="deviceName"
          value={name}
          fullWidth
          onChange={onNameChange}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Device Location</Typography>
        <CustomTextField
          style={valueForText}
          name="deviceLocation"
          placeholder={""}
          value={formatLocation(data.device_location)}
          fullWidth
          disabled
          InputProps={true ? { disableUnderline: true } : {}}
          my={3}
        />
        <Space height={"20px"} />
        <Typography style={labelForTitle}>Client Phone Number</Typography>
        <CustomTextField
          style={valueForText}
          name="phone"
          fullWidth
          value={phone}
          onChange={onPhoneChange}
          my={3}
        />
        <Space height={'20px'} />
        <Typography style={labelForTitle}>Monthly Radon Report</Typography>
        <Space height={'10px'} />
        <FormControlLabel
          style={{display:'table'}}
          control={
            <div style={{display:'table-cell'}}>
              <DefaultCheckbox
                  onChange={onNotifyMonthlyChange}
                  checked={notifyMonthly !== null ? !!notifyMonthly:false}
              />
            </div>
          }
          label="Check this box if you would like the report to be emailed directly to your client during the first week of each month."
        />
      </CardContent>
    </Card>
  );
};

export default ClientInfoCard;
