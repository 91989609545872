import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const ProvideSupport = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">
              Provide Support Form For Your Customers
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              If a device fails to connect to the Wi-Fi network or has issues,
              customers can scan the QR code to submit an inquiry and you will
              <br />
              receive an email notification. Please download your company’s
              unique QR code and install it with EcoGuard. You can find your
              unique
              <br />
              QR code in the settings menu.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProvideSupport;
