import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {format} from "date-fns";
import {Helmet} from "react-helmet-async";
import {useDispatch} from "react-redux";
import _ from 'lodash';

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider as MuiDivider, FormControl, FormControlLabel,
  Grid,
  IconButton, InputLabel,
  Link, MenuItem,
  Paper as MuiPaper, Select, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  TextField, CircularProgress, CardContent,
} from "@material-ui/core";

import confirmationLogo from '../../assets/img/confirmation.png';
import {green, orange, red, grey} from "@material-ui/core/colors";
import "../../assets/css/common.css";
import "../../assets/css/order.css";
import {Alert as MuiAlert} from "@material-ui/lab";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import {spacing} from "@material-ui/system";
import {
  createOrderAction,
  getOrderDetailAction,
  getOrderDetailByQtyAction,
  getOrderListAction
} from "../../models/actions/orderActions";
import {Formik} from "formik";
import {
  validEmailRegex,
  validQuantityRegex,
  validNameRegex,
  validPwdRegex,
  validZipcodeRegex,
  validPhoneNumberRegex
} from "../../utils/AuthValidation";
import SubscriptionInterface from "../../integration/internal/SubscriptionInterface";
import NoOrders from "./NoOrders";
import ecosenseLogo from "../../assets/img/ecosense.png";
import Loading from "../components/Loading";
import makeStyles from "@material-ui/core/styles/makeStyles";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import {
  CardElement, Elements,
  PaymentElement,
  useElements, useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutFormForOrder from "./CheckoutFormForOrder";
import {getOrderDetailByQty} from "../../models/services/orderService";
import FormHelperText from "@material-ui/core/FormHelperText";
import {orderStatusProperties} from '../../constants/status';
import {ECOQUBE_PRICE} from '../../constants/values'
import {stripeKey} from '../../env'

// const stripePromise = loadStripe("pk_test_51IZMIZI37b7asQ3bVAMOHfxBlFPhUxoBPwlAk4wIsTwpJJO8cAvhsJsA3vq5gYjbWOIJlIxmLmb5dwAXoVteHkRg007UDFsqvQ");
const stripePromise = loadStripe(stripeKey)
const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Chip = styled(MuiChip)`
  ${spacing};
  width: ${(props) => '130px'};
  background: ${(props) => orderStatusProperties[props.status].color};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {id: "orderId", alignment: "left", label: "Order ID"},
  {id: "product", alignment: "left", label: "Product"},
  {id: "description", alignment: "left", label: "Description"},
  {id: "created", alignment: "left", label: "Created"},
  {id: "totalDue", alignment: "left", label: "Total"},
  {id: "status", alignment: "center", label: "Status"},
  // {id: "stripe_status", alignment: "center", label: "Details"},
  // {id: "actions", alignment: "right", label: "Actions"},
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{ "aria-label": "select all" }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const {numSelected} = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h5" id="tableTitle">
            Invoices
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer/>
      {/*<div>*/}
      {/*  {numSelected > 0 ? (*/}
      {/*    <Tooltip title="Delete">*/}
      {/*      <IconButton aria-label="Delete">*/}
      {/*        <ArchiveIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  ) : (*/}
      {/*    <Tooltip title="Filter list">*/}
      {/*      <IconButton aria-label="Filter list">*/}
      {/*        <FilterListIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  )}*/}
      {/*</div>*/}
    </Toolbar>
  );
};

function EnhancedTable(props) {
  const [loaded, setLoaded] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orders, setOrders] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  location.state.email = localStorage.getItem('0xaccountId');
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }

  location.state.name = localStorage.getItem('0xuserId');
  const getOrderList = async () => {
    let res = await dispatch(
      getOrderListAction(location.state.email)
    );
    if (res && (!res.data || (res.data && res.data.length === 0))) res.data = [];
    else if (!_.isEqual(orders, res.data)) {
      setOrders(res.data);
    }

    setLoaded(true);
  };

  useEffect(() => {
    getOrderList();
  }, [orders, props.openOrderConfirmation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDetail = (orderId) => {
    history.push("/orders/detail?orderId=" + orderId)
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);


  const useStyles = makeStyles((theme) => ({
    hover: {
      "&:hover": {
        // backgroundColor: "#f0fcff !important",
        cursor: 'pointer',
      },
    },
  }));

  const classes = useStyles();

  const handleClick = (event, orderId) => {
    history.push("/orders/detail?" + orderId);
    // history.push({
    //   pathname: "/subscriptions/detail?" + invoiceNo,
    //   state: {
    //     invoiceNo: invoiceNo,
    //   }
    // });
  };

  const toFixed = (number) => {
    if (number ===0 ) {
      return '$0.00';
    } else if (number) {
      return '$' + number.toFixed(2);
    }
    return '';
  }

  return (
    <>
      {
        !loaded ? (
          <Loading></Loading>
        ) : (
          orders.length <= 0 ? (
              <NoOrders></NoOrders>
            ) :
            <Wrapper>
              <Paper>
                <EnhancedTableToolbar numSelected={selected.length}/>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                    // style={{ width: "auto", tableLayout: "fixed" }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={orders.length}
                    />
                    <TableBody>
                      {stableSort(orders, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          let date;
                          let formattedDate;
                          if(row.created) {
                            date = new Date(row.created);
                            formattedDate = format(date, "MM/dd/yyyy");
                          } else {
                            formattedDate = 'N/A';
                          }

                          return (
                            <TableRow
                              hover
                              classes={{hover: classes.hover,}}
                              onClick={(event) => handleClick(event, row.orderId)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                              selected={isItemSelected}
                            >
                              {/*<TableCell padding="checkbox">*/}
                              {/*  <Checkbox*/}
                              {/*    checked={isItemSelected}*/}
                              {/*    inputProps={{ "aria-labelledby": labelId }}*/}
                              {/*    onClick={(event) => handleClick(event, row.id)}*/}
                              {/*  />*/}
                              {/*</TableCell>*/}

                              <TableCell align="left" className='cell-order-id'>#{row.orderId}</TableCell>
                              <TableCell align="left">{row.product}</TableCell>
                              <TableCell align="left">{row.description}</TableCell>
                              <TableCell align="left">{formattedDate}</TableCell>
                              <TableCell align="left">{toFixed(row.totalDue)}</TableCell>
                              <TableCell align="center">
                                {row.status!=undefined && (<Chip
                                    size="small"
                                    mr={1}
                                    mb={1}
                                    label={orderStatusProperties[row.status].msg}
                                    status={row.status}
                                  />)}
                              </TableCell>
                              {/*<TableCell align="left">{row.stripe_status}</TableCell>*/}


                              {/*<TableCell align="left">{row.method}</TableCell>*/}
                              {/*<TableCell padding="none" align="right">*/}
                              {/*  <Box mr={2}>*/}
                              {/*    /!*<IconButton aria-label="download">*!/*/}
                              {/*    /!*  <ArchiveIcon />*!/*/}
                              {/*    /!*</IconButton>*!/*/}
                              {/*    <IconButton aria-label="details"*/}
                              {/*                onClick={() => history.push("/orders/detail?" + row.orderId)}>*/}
                              {/*      <RemoveRedEyeIcon/>*/}
                              {/*    </IconButton>*/}
                              {/*  </Box>*/}
                              {/*</TableCell>*/}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={orders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </Wrapper>
        )}
    </>
  );
}

function OrderList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [loadingOrder, setLoadingOrder] = React.useState(false);
  const [openOrderForm, setOpenOrderForm] = React.useState(false);
  const [openCardForm, setOpenCardForm] = React.useState(false);
  const [openOrderConfirmation, setOpenOrderConfirmation] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  location.state = {
    email: localStorage.getItem('0xaccountId'),
    name: localStorage.getItem('0xuserId')
  }

  const [order, setOrder] = useState({
    bill_street: '',
    bill_detail: '',
    bill_email: '',
    bill_phone: '',
    bill_city: '',
    bill_state: '',
    bill_zipcode: '',
    bill_country: '',
    ship_street: '',
    ship_detail: '',
    ship_email: '',
    ship_phone: '',
    ship_city: '',
    ship_state: '',
    ship_zipcode: '',
    ship_country: '',
  });

  // Credit Card
  ////////////////////////////////////////////////////////////////////////////////
  const [successOrderId, setSuccessOrderId] = useState();
  const [payload, setPayload] = useState();
  const [checked, setChecked] = React.useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);

    if (!event.target.checked) {
      order.bill_firstname = order.ship_firstname;
      order.bill_lastname = order.ship_lastname;
      order.bill_street = order.ship_street;
      order.bill_detail = order.ship_detail;
      order.bill_email = order.ship_email;
      order.bill_phone = order.ship_phone;
      order.bill_city = order.ship_city;
      order.bill_state = order.ship_state;
      order.bill_zipcode = order.ship_zipcode;
      order.bill_country = order.ship_country;
    }
  };

  const refreshPopup = () => {
    setRefresh(!refresh);
  }

  const validateShippingInformation = (values) => {
    const errors = {};
    if (!values) return errors;

    if (!values.client_email) {
      errors.client_email = 'Required';
    } else if (!validEmailRegex.test(values.client_email)) {
      errors.client_email = 'Please enter a valid email address.';
    }

    if (!values.quantity) {
      errors.quantity = 'Required';
    } else if (values.quantity > 10) {
      errors.quantity = 'It must be equal or less than 10. Please contact sales@ecosense.io to order more.';
    }

    if (!values.ship_firstname) {
      errors.ship_firstname = 'Required';
    }

    if (!values.ship_lastname) {
      errors.ship_lastname = 'Required';
    }

    if (!values.ship_email) {
      errors.ship_email = 'Required';
    } else if (!validEmailRegex.test(values.ship_email)) {
      errors.ship_email = 'Please enter a valid email address.';
    }

    if (!values.ship_phone) {
      errors.ship_phone = 'Required';
    } else if(!validPhoneNumberRegex.test(values.ship_phone)) {
      errors.ship_phone = 'Please enter number, +, -, ., or space';
    }

    if (!values.ship_street) {
      errors.ship_street = 'Required';
    }

    if (!values.ship_city) {
      errors.ship_city = 'Required';
    }

    if (values.ship_state == 'Select') {
      errors.ship_state = 'Required';
    }

    if (!values.ship_zipcode) {
      errors.ship_zipcode = 'Required';
    } else if (!validZipcodeRegex.test(values.ship_zipcode)) {
      errors.ship_zipcode = 'Incorrect Zipcode';
    }

    return errors;
  };

  const validateBillingInformation = (values) => {
    const errors = {};

    if (!checked) {
      return errors;
    }

    const orderParam = {
      ...order,
      bill_firstname: values.bill_firstname,
      bill_lastname: values.bill_lastname,
      bill_email: values.bill_email,
      bill_phone: values.bill_phone,
      bill_street: values.bill_street,
      bill_detail: values.bill_detail,
      bill_city: values.bill_city,
      bill_state: values.bill_state,
      bill_zipcode: values.bill_zipcode,
      bill_country: values.bill_country,
    }
    setOrder(orderParam);

    if (!values.bill_firstname) {
      errors.bill_firstname = 'Required';
    }

    if (!values.bill_lastname) {
      errors.bill_lastname = 'Required';
    }

    if (!values.bill_email) {
      errors.bill_email = 'Required';
    } else if (!validEmailRegex.test(values.bill_email)) {
      errors.bill_email = 'Please enter a valid email address.';
    }

    if (!values.bill_phone) {
      errors.bill_phone = 'Required';
    }

    if (!values.bill_street) {
      errors.bill_street = 'Required';
    }

    if (!values.bill_city) {
      errors.bill_city = 'Required';
    }

    if (values.bill_state == 'Select') {
      errors.bill_state = 'Required';
    }

    if (!values.bill_zipcode) {
      errors.bill_zipcode = 'Required';
    } else if (!validZipcodeRegex.test(values.bill_zipcode)) {
      errors.bill_zipcode = 'Incorrect Zipcode.';
    }

    return errors;
  };

  const toFixed = (number) => {
    if (number ===0 ) {
      return '$0.00';
    } else if (number) {
      return '$' + number.toFixed(2);
    }
    return '';
  }

  const handleClickNewOrder = () => {
    setOpenOrderForm(true);
  };

  const handleCloseOrderForm = () => {
    setOpenOrderForm(false);
  };

  const handleCloseCardForm = () => {
    setOpenCardForm(false);
  };

  const handleCloseOrderConfirmation = () => {
    setOpenOrderConfirmation(false);
  };

  const handleClickNext = async (values) => {
    setChecked(false);
    setLoadingOrder(true);
    let res = await getOrderDetailByQty('EcoQube', values.ship_state, values.quantity)
    if (res && res.data && res.data.quantity > 0 && !_.isEqual(order, res.data)) {
      const orderParam = {
        ...res.data,
        refer_id : values.refer_id || null,
        order_type : values.refer_id ? 'refer_order' : null,
        client_fullname: values.client_fullname,
        client_email: values.client_email,
        ship_firstname: values.ship_firstname,
        ship_lastname: values.ship_lastname,
        ship_email: values.ship_email,
        ship_phone: values.ship_phone,
        ship_street: values.ship_street,
        ship_detail: values.ship_detail,
        ship_city: values.ship_city,
        ship_state: values.ship_state,
        ship_zipcode: values.ship_zipcode,
        ship_country: values.ship_country,
        bill_firstname: values.bill_firstname,
        bill_lastname: values.bill_lastname,
        bill_email: values.bill_email,
        bill_phone: values.bill_phone,
        bill_street: values.bill_street,
        bill_detail: values.bill_detail,
        bill_city: values.bill_city,
        bill_state: values.bill_state,
        bill_zipcode: values.bill_zipcode,
        bill_country: values.bill_country,
      }
      setOrder(orderParam);
    }
    setOpenCardForm(true);
    setOpenOrderForm(false);
    setLoadingOrder(false);
  }

  const showOrderConfirmation = (orderId, payload) => {
    setSuccessOrderId(orderId);
    setPayload(payload);
    setOpenCardForm(false);
    setOpenOrderConfirmation(true);
  };

  const onChangeShipStateValue = (values) => (event) => {
    values.ship_state = event.target.value;
    validateShippingInformation(values);
    refreshPopup();
  };

  const onChangeBillStateValue = (values) => (event) => {
    values.bill_state = event.target.value;
    validateBillingInformation(values);
    refreshPopup();
  };

  return (
    // <React.Fragment>
    <>
      <Helmet title="Orders"/>

      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Orders
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/orders">
              Orders
            </Link>
            <Typography>Status</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained"
                    color="primary"
                    onClick={handleClickNewOrder}
                    classes={{root: 'button', label: 'button-label'}}>
              <AddIcon/>
              New Order
            </Button>
            <Dialog
              fullWidth={fullWidth}
              titleStyle={{textAlign: "center"}}
              maxWidth="sm"
              open={openOrderForm}
              onClose={handleCloseOrderForm}
              disableBackdropClick="true"
              aria-labelledby="max-width-dialog-title"
            >
              <div className="s20"></div>
              <DialogTitle id="max-width-dialog-title">
                <Typography variant="h5" align="center">
                  Order your EcoGuard</Typography>
              </DialogTitle>
              <DialogContent>
                {/*<DialogContentText>*/}
                {/*  You can set my maximum width and whether to adapt or not.*/}
                {/*</DialogContentText>*/}
                <Formik
                  initialValues={{
                    client_email: location.state.email,
                    client_fullname: location.state.name,
                    ship_firstname: "",
                    ship_lastname: "",
                    ship_email: "",
                    ship_phone: "",
                    ship_street: "",
                    ship_detail: "",
                    ship_city: "",
                    ship_state: "Select",
                    ship_zipcode: "",
                    ship_country: "US",
                    bill_firstname: "",
                    bill_lastname: "",
                    bill_email: "",
                    bill_phone: "",
                    bill_street: "",
                    bill_detail: "",
                    bill_city: "",
                    bill_state: "Select",
                    bill_zipcode: "",
                    bill_country: "US",
                    submit: false,
                  }}
                  // initialValues={{
                  //   client_email: location.state.email,
                  //   client_fullname: location.state.name,
                  //   ship_firstname: "Kevin",
                  //   ship_lastname: "Jung",
                  //   ship_email: "seungjae.jung@ecosense.io",
                  //   ship_phone: "425-979-8090",
                  //   ship_street: "419 Pocono Manor Place",
                  //   ship_detail: "",
                  //   ship_city: "San Ramon",
                  //   ship_state: "Select",
                  //   ship_zipcode: "94583",
                  //   ship_country: "US",
                  //   bill_firstname: "",
                  //   bill_lastname: "",
                  //   bill_email: "",
                  //   bill_phone: "",
                  //   bill_street: "",
                  //   bill_detail: "",
                  //   bill_city: "",
                  //   bill_state: "Select",
                  //   bill_zipcode: "",
                  //   bill_country: "US",
                  //   submit: false,
                  // }}
                  validate={validateShippingInformation}
                  onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                    await handleClickNext(values);
                  }}
                >
                  {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                          {errors.submit}
                        </Alert>
                      )}
                      <div className="s20"></div>
                      <Typography variant="h7" gutterBottom>
                        <span className='order-popup-text-message'>
                          Please provide number of EcoGuard you want to order.<br></br>
                          EcoGuard acquisition fee: ${ECOQUBE_PRICE} per device
                        </span>
                      </Typography>
                      <div className="s20"></div>
                      <TextField
                        type="number"
                        name="quantity"
                        label="EcoGuard Quantity"
                        value={values.quantity}
                        error={Boolean(errors.quantity)}
                        fullWidth
                        helperText={errors.quantity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={2}
                      />
                      <div className="s10"></div>
                      <TextField
                        name="refer_id"
                        label="Refer by (Add refer ID if you have any)"
                        value={values.refer_id}
                        error={Boolean(errors.refer_id)}
                        fullWidth
                        helperText={errors.refer_id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={2}
                      />
                      <div className="s40"></div>

                      <Typography variant="h7" gutterBottom>
                        <b>Shipping information</b>
                      </Typography>
                      <div className="s10"></div>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <TextField
                            name="ship_firstname"
                            label="First name"
                            value={values.ship_firstname}
                            fullWidth
                            error={Boolean(touched.ship_firstname && errors.ship_firstname)}
                            helperText={touched.ship_firstname && errors.ship_firstname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            name="ship_lastname"
                            label="Last name"
                            value={values.ship_lastname}
                            error={Boolean(touched.ship_lastname && errors.ship_lastname)}
                            fullWidth
                            helperText={touched.ship_lastname && errors.ship_lastname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <TextField
                            name="ship_email"
                            label="Email"
                            value={values.ship_email}
                            fullWidth
                            error={Boolean(touched.ship_email && errors.ship_email)}
                            helperText={touched.ship_email && errors.ship_email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            name="ship_phone"
                            label="Phone"
                            value={values.ship_phone}
                            error={Boolean(touched.ship_phone && errors.ship_phone)}
                            fullWidth
                            helperText={touched.ship_phone && errors.ship_phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <TextField
                            name="ship_street"
                            label="Address 1"
                            value={values.ship_street}
                            error={Boolean(touched.ship_street && errors.ship_street)}
                            fullWidth
                            helperText={touched.ship_street && errors.ship_street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                        <TextField
                          name="ship_detail"
                          label="Address 2"
                          value={values.ship_detail}
                          error={Boolean(touched.ship_detail && errors.ship_detail)}
                          fullWidth
                          helperText={touched.ship_detail && errors.ship_detail}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={2}
                        />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <TextField
                            name="ship_city"
                            label="City"
                            value={values.ship_city}
                            error={Boolean(touched.ship_city && errors.ship_city)}
                            fullWidth
                            helperText={touched.ship_city && errors.ship_city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                        {/*<Grid item md={3}>*/}
                        {/*  <TextField*/}
                        {/*    name="ship_state"*/}
                        {/*    label="State"*/}
                        {/*    value={values.ship_state}*/}
                        {/*    error={Boolean(touched.ship_state && errors.ship_state)}*/}
                        {/*    fullWidth*/}
                        {/*    helperText={touched.ship_state && errors.ship_state}*/}
                        {/*    onBlur={handleBlur}*/}
                        {/*    onChange={handleChange}*/}
                        {/*    my={2}*/}
                        {/*  />*/}
                        {/*</Grid>*/}
                        <Grid item md={3}>
                          <FormControl
                            my={2}
                            fullWidth
                            sx={{ m: 1, minWidth: 120}}
                            helperText={touched.ship_state && errors.ship_state}
                            error={Boolean(touched.ship_state && errors.ship_state)}>
                            <InputLabel id="ship-state-select-label">State</InputLabel>
                            <Select
                              labelId="ship-state-select-label"
                              id="ship-state-select"
                              value={values.ship_state}
                              label="State"
                              onBlur={handleBlur}
                              onChange={onChangeShipStateValue(values)}
                              MenuProps={{
                                style: {
                                  maxHeight: 220,
                                },
                              }}
                            >
                              <MenuItem value="Select">Select</MenuItem>
                              <MenuItem value="AL">AL</MenuItem>
                              <MenuItem value="AK">AK</MenuItem>
                              <MenuItem value="AZ">AZ</MenuItem>
                              <MenuItem value="AR">AR</MenuItem>
                              <MenuItem value="CA">CA</MenuItem>
                              <MenuItem value="CO">CO</MenuItem>
                              <MenuItem value="CT">CT</MenuItem>
                              <MenuItem value="DE">DE</MenuItem>
                              <MenuItem value="FL">FL</MenuItem>
                              <MenuItem value="GA">GA</MenuItem>
                              <MenuItem value="HI">HI</MenuItem>
                              <MenuItem value="ID">ID</MenuItem>
                              <MenuItem value="IL">IL</MenuItem>
                              <MenuItem value="IN">IN</MenuItem>
                              <MenuItem value="IA">IA</MenuItem>
                              <MenuItem value="KS">KS</MenuItem>
                              <MenuItem value="KY">KY</MenuItem>
                              <MenuItem value="LA">LA</MenuItem>
                              <MenuItem value="ME">ME</MenuItem>
                              <MenuItem value="MD">MD</MenuItem>
                              <MenuItem value="MA">MA</MenuItem>
                              <MenuItem value="MI">MI</MenuItem>
                              <MenuItem value="MN">MN</MenuItem>
                              <MenuItem value="MS">MS</MenuItem>
                              <MenuItem value="MO">MO</MenuItem>
                              <MenuItem value="MT">MT</MenuItem>
                              <MenuItem value="NE">NE</MenuItem>
                              <MenuItem value="NV">NV</MenuItem>
                              <MenuItem value="NH">NH</MenuItem>
                              <MenuItem value="NJ">NJ</MenuItem>
                              <MenuItem value="NM">NM</MenuItem>
                              <MenuItem value="NY">NY</MenuItem>
                              <MenuItem value="NC">NC</MenuItem>
                              <MenuItem value="ND">ND</MenuItem>
                              <MenuItem value="OH">OH</MenuItem>
                              <MenuItem value="OK">OK</MenuItem>
                              <MenuItem value="OR">OR</MenuItem>
                              <MenuItem value="PA">PA</MenuItem>
                              <MenuItem value="RI">RI</MenuItem>
                              <MenuItem value="SC">SC</MenuItem>
                              <MenuItem value="SD">SD</MenuItem>
                              <MenuItem value="TN">TN</MenuItem>
                              <MenuItem value="TX">TX</MenuItem>
                              <MenuItem value="UT">UT</MenuItem>
                              <MenuItem value="VT">VT</MenuItem>
                              <MenuItem value="VA">VA</MenuItem>
                              <MenuItem value="WA">WA</MenuItem>
                              <MenuItem value="WV">WV</MenuItem>
                              <MenuItem value="WI">WI</MenuItem>
                              <MenuItem value="WY">WY</MenuItem>
                            </Select>
                            {
                              errors.ship_state && (
                                <FormHelperText>Required</FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            // type="number"
                            name="ship_zipcode"
                            label="Zipcode"
                            value={values.ship_zipcode}
                            error={Boolean(touched.ship_zipcode && errors.ship_zipcode)}
                            fullWidth
                            helperText={touched.ship_zipcode && errors.ship_zipcode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <div className="s50"></div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || Object.keys(validateShippingInformation()).length > 0}
                        // onClick={handleClickOrder}
                        classes={{root: 'button', label: 'button-label'}}
                      >
                        { loadingOrder ? <CircularProgress size={20}/> : <span>Next</span> }
                      </Button>
                    </form>
                  )}
                </Formik>
              </DialogContent>
              <div className="s20"></div>
              <DialogActions>
                <Button onClick={handleCloseOrderForm} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={fullWidth}
              titleStyle={{textAlign: "center"}}
              maxWidth="sm"
              open={openCardForm}
              onClose={handleCloseCardForm}
              disableBackdropClick="true"
              aria-labelledby="max-width-dialog-title"
            >
              <div className="s20"></div>
              <DialogTitle id="max-width-dialog-title">
                <Typography variant="h5" align="center">
                  Order your EcoGuard</Typography>
              </DialogTitle>
              <DialogContent>
              {
                !loadingOrder ?
                    <Formik
                      initialValues={{
                        email: location.state.email,
                        name: location.state.name,
                        ship_firstname: order.ship_firstname,
                        ship_lastname: order.ship_lastname,
                        ship_email: order.ship_email,
                        ship_phone: order.ship_phone,
                        ship_street: order.ship_street,
                        ship_detail: order.ship_detail,
                        ship_city: order.ship_city,
                        ship_state: order.ship_state,
                        ship_zipcode: order.ship_zipcode,
                        ship_country: order.ship_country,
                        bill_firstname: order.bill_firstname,
                        bill_lastname: order.bill_lastname,
                        bill_email: order.bill_email,
                        bill_phone: order.bill_phone,
                        bill_street: order.bill_street,
                        bill_detail: order.bill_detail,
                        bill_city: order.bill_city,
                        bill_state: order.bill_state,
                        bill_zipcode: order.bill_zipcode,
                        bill_country: order.bill_country,
                        submit: false,
                      }}
                      validate={validateBillingInformation}
                      onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {

                      }}
                    >
                      {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          {errors.submit && (
                            <Alert mt={2} mb={1} severity="warning">
                              {errors.submit}
                            </Alert>
                          )}
                          <div className="s20"></div>
                          <Typography variant="h7" gutterBottom>
                            <b>Order information</b>
                          </Typography>
                          <Table options={{rowStyle: {height: 5}}}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell align="right">Amount</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  EcoGuard Acquisition Fee
                                </TableCell>
                                <TableCell>{order.quantity}</TableCell>
                                <TableCell align="right">{toFixed(order.quantity * order.unitPrice)}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell/>
                                <TableCell>Subtotal</TableCell>
                                <TableCell align="right">{toFixed(order.subtotal)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell/>
                                <TableCell>Sales Tax</TableCell>
                                <TableCell align="right">{toFixed(order.salesTax)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell/>
                                <TableCell>Shipping</TableCell>
                                <TableCell align="right">{toFixed(order.shipping)}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell style={{borderBottom:"none"}}/>
                                <TableCell style={{borderBottom:"none"}}><b>Total</b></TableCell>
                                <TableCell style={{borderBottom:"none"}} align="right"><b>{toFixed(order.totalDue)}</b></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <div className="s10"></div>
                          <FormControlLabel
                            control={<Checkbox checked={checked} onChange={handleCheckBoxChange} />}
                            label="Please check if your billing address is different."
                          />
                          <div className={checked ? 'show' : 'hide'}>
                            <div className="s10"></div>
                            <Typography variant="h7" gutterBottom>
                              <b>Billing information</b>
                            </Typography>
                            {/*<div className="s10"></div>*/}
                            <Grid container spacing={3}>
                              <Grid item md={6}>
                                <TextField
                                  name="bill_firstname"
                                  label="First name"
                                  value={values.bill_firstname}
                                  fullWidth
                                  error={Boolean(touched.bill_firstname && errors.bill_firstname)}
                                  helperText={touched.bill_firstname && errors.bill_firstname}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={6}>
                                <TextField
                                  name="bill_lastname"
                                  label="Last name"
                                  value={values.bill_lastname}
                                  error={Boolean(touched.bill_lastname && errors.bill_lastname)}
                                  fullWidth
                                  helperText={touched.bill_lastname && errors.bill_lastname}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={6}>
                                <TextField
                                  name="bill_email"
                                  label="Email"
                                  value={values.bill_email}
                                  fullWidth
                                  error={Boolean(touched.bill_email && errors.bill_email)}
                                  helperText={touched.bill_email && errors.bill_email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={6}>
                                <TextField
                                  name="bill_phone"
                                  label="Phone"
                                  value={values.bill_phone}
                                  error={Boolean(touched.bill_phone && errors.bill_phone)}
                                  fullWidth
                                  helperText={touched.bill_phone && errors.bill_phone}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={12}>
                                <TextField
                                  name="bill_street"
                                  label="Address 1"
                                  value={values.bill_street}
                                  error={Boolean(touched.bill_street && errors.bill_street)}
                                  fullWidth
                                  helperText={touched.bill_street && errors.bill_street}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={12}>
                                <TextField
                                  name="bill_detail"
                                  label="Address 2"
                                  value={values.bill_detail}
                                  error={Boolean(touched.bill_detail && errors.bill_detail)}
                                  fullWidth
                                  helperText={touched.bill_detail && errors.bill_detail}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={6}>
                                <TextField
                                  name="bill_city"
                                  label="City"
                                  value={values.bill_city}
                                  error={Boolean(touched.bill_city && errors.bill_city)}
                                  fullWidth
                                  helperText={touched.bill_city && errors.bill_city}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={3}>
                                <FormControl
                                  my={2}
                                  fullWidth
                                  sx={{ m: 1, minWidth: 120 }}
                                  helperText={touched.bill_state && errors.bill_state}
                                  error={Boolean(touched.bill_state && errors.bill_state)}>
                                  <InputLabel id="ship-state-select-label">State</InputLabel>
                                  <Select
                                    labelId="ship-state-select-label"
                                    id="ship-state-select"
                                    value={values.bill_state}
                                    label="State"
                                    onBlur={handleBlur}
                                    onChange={onChangeBillStateValue(values)}
                                    MenuProps={{
                                      style: {
                                        maxHeight: 220,
                                      },
                                    }}
                                  >
                                    <MenuItem value="Select">Select</MenuItem>
                                    <MenuItem value="AL">AL</MenuItem>
                                    <MenuItem value="AK">AK</MenuItem>
                                    <MenuItem value="AZ">AZ</MenuItem>
                                    <MenuItem value="AR">AR</MenuItem>
                                    <MenuItem value="CA">CA</MenuItem>
                                    <MenuItem value="CO">CO</MenuItem>
                                    <MenuItem value="CT">CT</MenuItem>
                                    <MenuItem value="DE">DE</MenuItem>
                                    <MenuItem value="FL">FL</MenuItem>
                                    <MenuItem value="GA">GA</MenuItem>
                                    <MenuItem value="HI">HI</MenuItem>
                                    <MenuItem value="ID">ID</MenuItem>
                                    <MenuItem value="IL">IL</MenuItem>
                                    <MenuItem value="IN">IN</MenuItem>
                                    <MenuItem value="IA">IA</MenuItem>
                                    <MenuItem value="KS">KS</MenuItem>
                                    <MenuItem value="KY">KY</MenuItem>
                                    <MenuItem value="LA">LA</MenuItem>
                                    <MenuItem value="ME">ME</MenuItem>
                                    <MenuItem value="MD">MD</MenuItem>
                                    <MenuItem value="MA">MA</MenuItem>
                                    <MenuItem value="MI">MI</MenuItem>
                                    <MenuItem value="MN">MN</MenuItem>
                                    <MenuItem value="MS">MS</MenuItem>
                                    <MenuItem value="MO">MO</MenuItem>
                                    <MenuItem value="MT">MT</MenuItem>
                                    <MenuItem value="NE">NE</MenuItem>
                                    <MenuItem value="NV">NV</MenuItem>
                                    <MenuItem value="NH">NH</MenuItem>
                                    <MenuItem value="NJ">NJ</MenuItem>
                                    <MenuItem value="NM">NM</MenuItem>
                                    <MenuItem value="NY">NY</MenuItem>
                                    <MenuItem value="NC">NC</MenuItem>
                                    <MenuItem value="ND">ND</MenuItem>
                                    <MenuItem value="OH">OH</MenuItem>
                                    <MenuItem value="OK">OK</MenuItem>
                                    <MenuItem value="OR">OR</MenuItem>
                                    <MenuItem value="PA">PA</MenuItem>
                                    <MenuItem value="RI">RI</MenuItem>
                                    <MenuItem value="SC">SC</MenuItem>
                                    <MenuItem value="SD">SD</MenuItem>
                                    <MenuItem value="TN">TN</MenuItem>
                                    <MenuItem value="TX">TX</MenuItem>
                                    <MenuItem value="UT">UT</MenuItem>
                                    <MenuItem value="VT">VT</MenuItem>
                                    <MenuItem value="VA">VA</MenuItem>
                                    <MenuItem value="WA">WA</MenuItem>
                                    <MenuItem value="WV">WV</MenuItem>
                                    <MenuItem value="WI">WI</MenuItem>
                                    <MenuItem value="WY">WY</MenuItem>
                                  </Select>
                                  {
                                    errors.bill_state && (
                                      <FormHelperText>Required</FormHelperText>
                                    )
                                  }
                                </FormControl>
                              </Grid>
                              <Grid item md={3}>
                                <TextField
                                  // type="number"
                                  name="bill_zipcode"
                                  label="Zipcode"
                                  value={values.bill_zipcode}
                                  error={Boolean(touched.bill_zipcode && errors.bill_zipcode)}
                                  fullWidth
                                  helperText={touched.bill_zipcode && errors.bill_zipcode}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <div className="s30"></div>
                          <Elements stripe={stripePromise}>
                            <CheckoutFormForOrder
                              checked={checked}
                              order={order}
                              values={values}
                              showOrderConfirmation={showOrderConfirmation}
                              validateBillingInformation={validateBillingInformation}
                              refreshPopup={refreshPopup}/>
                          </Elements>
                        </form>
                      )}
                    </Formik>
                  :
                  <div className='get-order-detail-loading'>
                    <CircularProgress size={20}/>
                  </div>
              }
              </DialogContent>
              <div className="s10"></div>
              <DialogActions>
                <Button onClick={handleCloseCardForm} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={fullWidth}
              titleStyle={{textAlign: "center"}}
              maxWidth="sm"
              open={openOrderConfirmation}
              onClose={handleCloseOrderConfirmation}
              aria-labelledby="max-width-dialog-title"
            >
              <Wrapper>
                <div className="s10"></div>
                <div align='center'>
                  <img src={confirmationLogo} style={{width: '70px', height: '70px'}}/>

                  <div className="s20"></div>
                  <DialogTitle id="max-width-dialog-title">
                    <Typography variant="h4" align="center">Thank you for your order!</Typography>
                  </DialogTitle>
                  <div className="s20"></div>
                  <span className='order-id-text'>
                      Order ID: {successOrderId}
                    </span>
                  <div className="s20"></div>
                  <DialogContent>
                    <DialogContentText>
                      Thanks for placing your order.
                      Our team will send you invoice soon.<br/>
                      Please check your email.
                      If you have any questions, please contact us at
                      support@ecosense.io<br/>
                    </DialogContentText>
                    <div className="s40"></div>
                    <div align="center">
                      <span className='learn-more-text'>
                        Learn more about EcoQube? <a className='learn-more-link' href='https://ecosense.io/' target='_blank'>Click here</a>
                      </span>
                    </div>
                  </DialogContent>
                  <div className="s20"></div>
                  <DialogActions>
                    <Box textAlign='center'>
                      {/*<Button variant='contained'>*/}
                      {/*  My button*/}
                      {/*</Button>*/}
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleCloseOrderConfirmation}
                        classes={{root: 'button', label: 'button-label'}}
                      >
                        Close
                      </Button>
                    </Box>

                  </DialogActions>
                </div>
                {/*<div className="s20"></div>*/}
              </Wrapper>
            </Dialog>
          </div>
        </Grid>
      </Grid>

      <Divider my={6}/>

      {/*<Wrapper>*/}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable openOrderConfirmation={openOrderConfirmation}/>
        </Grid>
      </Grid>
      {/*</Wrapper>*/}
      {/*</React.Fragment>*/}
    </>
  );
}

export default OrderList;
