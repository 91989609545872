import { Auth } from "aws-amplify";
import { NETWORK_LOG } from "../../constants/envConstant";
import {
  CRITICAL_ACTION_TIMEOUT,
  PCI,
  UNIT_CONVERSION_SCALE,
} from "../../constants/values";
import { apiEndPoint, awsXApiKey } from "../../env";
import moment from "moment";
import _ from "lodash";
import { AuthUtil } from "../../views/auth/AuthUtil";
import {decodeRx} from '../../utils/codec';

const ReportInterface = {
  getTestEmail: function() {
    return decodeRx(localStorage.getItem('0xXRurEm'));
  },

  retrieveReportDetails: async function (email, serial, duration) {
    const userEmail = this.getTestEmail()||email;
    let res;
    let radon;
    let merge;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      console.log("ReportInterface duration: " + JSON.stringify(duration));
      let requestUrl = apiEndPoint + "/greport";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        requestUrl +
          "?email=" +
          userEmail +
          "&serial_number=" +
          serial +
          "&measurement_start=" +
          duration.measurement_start +
          "&measurement_end=" +
          duration.measurement_end +
          "&types=" +
          duration.types,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
      // radon = await this.retrieveRadondataByDay(email, serial, duration);
      // console.log('radon: ' + duration.types + ',' + JSON.stringify(radon));
      // console.log('original:' + JSON.stringify(res));
      // merge = {...res, data: {...res.data, radondata: radon.data.radondata.map(r => ({
      //       datetime: moment(new Date(r.datetime.substring(0, 4) + '-' + r.datetime.substring(4, 6) + '-' + r.datetime.substring(6))).format('YYYYMMDD'),
      //       radon_level: r.radon_level
      //     }))}};
      //
      // console.log('merge:' + JSON.stringify(merge));
    } catch (e) {}

    return res;
  },

  retrieveEUReportDetails: async function (email, serial, duration) {
    const userEmail = this.getTestEmail()||email;
    let res;
    try {
      const controller = new AbortController();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": awsXApiKey, },
        signal: controller.signal,
        credentials: "include",
      };
      console.log("EUReportInterface duration: " + JSON.stringify(duration));
      let requestUrl = apiEndPoint + "/eu/report";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        requestUrl +
        "?email=" +
        userEmail +
        "&serial_number=" +
        serial +
        "&measurement_start=" +
        duration.measurement_start +
        "&measurement_end=" +
        duration.measurement_end +
        "&types=" +
        duration.types,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {}

    return res;
  },


  // retrieveEndUserReportDetails: async function (email, keyId) {
  //   let res;
  //   try {
  //     const controller = new AbortController();
  //     const requestOptions = {
  //       // crossDomain: true,
  //       method: "GET",
  //       headers: { "Content-Type": "application/json", "x-api-key": awsXApiKey, },
  //       signal: controller.signal,
  //       credentials: "include",
  //     };
  //     let requestUrl = apiEndPoint + "/eu/report";
  //     const timeoutId = setTimeout(
  //       () => controller.abort(),
  //       CRITICAL_ACTION_TIMEOUT
  //     );
  //     res = await fetch(
  //       requestUrl +
  //       "?email=" +
  //       (testEmail || email) +
  //       "&sn_report_start=" +
  //       keyId,
  //       requestOptions
  //     ).then((res) => {
  //       return res.text().then((text) => {
  //         const data = text && JSON.parse(text);
  //         return data;
  //       });
  //     });
  //   } catch (e) {}
  //
  //   return res;
  // },

  retrieveRadondataByDay: async function (email, serial, duration) {
    const userEmail = this.getTestEmail()||email;
    let res;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let startUtc = moment.parseZone(duration.measurement_start_d).utc();
      let endUtc = moment.parseZone(duration.measurement_end_d).utc();
      let end = moment().utc();
      let start;
      let requestOption;
      let timeOffset = moment().utcOffset() / 60;
      switch (duration.type) {
        case "DAY":
          start = _.cloneDeep(end).subtract(1, "days");
          requestOption = "hr_btn";
          break;
        case "WEEK":
          start = _.cloneDeep(end).subtract(7, "days");
          requestOption = "hr_btn";
          break;
        case "MONTH":
          start = _.cloneDeep(end).subtract(4, "weeks");
          requestOption = "dy_btn&offset=" + timeOffset;
          break;
        case "YEAR":
          start = _.cloneDeep(end).subtract(52, "weeks");
          requestOption = "wk_btn&offset=" + timeOffset;
          break;
      }
      let baseUrl =
        apiEndPoint +
        "/radon?serial_number=" +
        serial +
        "&email=" +
        userEmail +
        "&s_date=" +
        start.unix() +
        "&e_date=" +
        end.unix() +
        "&types=" +
        requestOption;
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(baseUrl, requestOptions).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {}
    return {
      data: {
        radondata: res,
      },
    };
  },

  retrieveRadondata: async function (email, serial, type) {
    const userEmail = this.getTestEmail()||email;
    let res;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let end = moment().utc();
      let start;
      let requestOption;
      let timeOffset = moment().utcOffset() / 60;
      switch (type) {
        case "DAY":
          start = _.cloneDeep(end).subtract(1, "days");
          requestOption = "hr_btn";
          break;
        case "WEEK":
          start = _.cloneDeep(end).subtract(7, "days");
          requestOption = "hr_btn";
          break;
        case "MONTH":
          start = _.cloneDeep(end).subtract(4, "weeks");
          requestOption = "hr_btn";
          break;
        case "HALF_YEAR":
          start = _.cloneDeep(end).subtract(24, "weeks");
          requestOption = "dy_btn&offset=" + timeOffset;
          break;
        case "YEAR":
          start = _.cloneDeep(end).subtract(52, "weeks");
          requestOption = "wk_btn&offset=" + timeOffset;
          break;
      }
      let baseUrl =
        apiEndPoint +
        "/radon?serial_number=" +
        serial +
        "&email=" +
        userEmail +
        "&s_date=" +
        start.unix() +
        "&e_date=" +
        end.unix() +
        "&types=" +
        requestOption;
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(baseUrl, requestOptions).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {}
    return {
      data: {
        radondata: res,
      },
    };
  },

  retrieveReportsList: async function (email) {
    const userEmail = this.getTestEmail()||email;
    let res;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let requestUrl = apiEndPoint + "/report";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        requestUrl + "?email=" + userEmail,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {
      alert(e);
    }

    return res;
  },

  retrieveReportHistoryDetails: async function (email, sn, duration) {
    const userEmail = this.getTestEmail()||email;
    let res;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let requestUrl = apiEndPoint + "/report";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        requestUrl + "?email=" + userEmail + `&serial_number=${sn}&measurement_start=${duration.start}&measurement_end=${duration.end}&types=${duration.type}`,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {
      alert(e);
    }

    return res;
  },

  retrieve48HrReportDetails: async function (email, serial) {
    const userEmail = this.getTestEmail()||email;
    let res;
    let radon;
    let merge;
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let requestUrl = apiEndPoint + "/greport/48h";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        requestUrl +
          "?email=" +
          userEmail +
          "&serialNumber=" +
          serial,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {
      console.log(e);
    }

    return res;
  },

  retrieve48hrEligibility: async function (email, serial) {
    const userEmail = this.getTestEmail()||email;
    let res;

    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let requestUrl = apiEndPoint + "/greport/av/48h";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        `${requestUrl}?email=${userEmail}&serialNumber=${serial}`,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {}

    return res;
  },

  retrieve48hrSecondEligibility: async function (email, serial) {
    const userEmail = this.getTestEmail()||email;
    let res;

    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      const requestOptions = {
        // crossDomain: true,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
      };
      let requestUrl = apiEndPoint + "/greport/pv/48h";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      res = await fetch(
        `${requestUrl}?email=${userEmail}&serialNumber=${serial}`,
        requestOptions
      ).then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      });
    } catch (e) {}

    return res;
  },

  log: function (msg, flag) {
    if (NETWORK_LOG || flag) console.log(`[ReportInterface] ${msg}`);
  },
};

export default ReportInterface;
