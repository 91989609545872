import React from "react";
import { useDispatch } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { forgotPassword } from "../../models/actions/authActions";

import {
  Button, Checkbox, FormControlLabel,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validPwdRegex} from "../../utils/AuthValidation";
import InputBase from "@material-ui/core/InputBase";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validEmailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address.';
  }

  return errors;
};

function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <div>
        <div align="center">
          <img width={120} src="/static/img/logo/ecosense-logo3.jpg"></img>
        </div>
        <div className='s20'></div>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset your password
        </Typography>
        <div className='s20'></div>
        <Typography component="h2" variant="body1" align="center">
          Please enter the email address you'd like to your
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          password reset information sent to
        </Typography>
      </div>

      <Formik
        initialValues={{
          email: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const trimmedEmail = values.email.trim().toLowerCase();
          try {
            await dispatch(
              forgotPassword(trimmedEmail)
            );
            history.push({
              pathname: '/auth/reset-password',
              state: { email: trimmedEmail }
            });

          } catch (error) {
            let message = error.message || "Something went wrong";

            if (error.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
              message = error.message.replace(' or phone_number','');
              history.push({
                pathname: '/auth/passcode-required',
                state: {
                  from: '/auth/forgot-password',
                  email: trimmedEmail
                }
              });
            } else if (error.message==='Username/client id combination not found.') {
              message = "Couldn't find your Ecosense account.";
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <div className="s10"></div>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              </>
            )}
            <InputBase disabled inputProps={{ 'aria-label': 'naked'}}/>
            <InputBase disabled inputProps={{ 'aria-label': 'naked'}}/>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <InputBase disabled inputProps={{ 'aria-label': 'naked'}}/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              SEND
            </Button>
          </form>
        )}
      </Formik>
      <div className='s15'></div>
      <div align="center">
          <span className='reset-goback-text'>
            Go back to <Link className='reset-login-link' to='/auth/sign-in'>login</Link>
          </span>
      </div>
    </Wrapper>
  );
}

export default ForgotPassword;
