import { Auth } from 'aws-amplify';
import {apiEndPoint} from '../../env'
import {NETWORK_LOG} from '../../constants/envConstant';
import {CRITICAL_ACTION_TIMEOUT, ECOQUBE_PRICE} from '../../constants/values';
import * as types from "../../constants";
import {AuthUtil} from "../../views/auth/AuthUtil";
import {decodeRx} from '../../utils/codec';

export function getTestEmail() {
  return decodeRx(localStorage.getItem('0xXRurEm'));
};

export async function getOrderList(email) {
  const userEmail = getTestEmail()||email;
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
  };
  let requestUrl = apiEndPoint + '/subscription/order';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  return await fetch(requestUrl + '?email=' + userEmail, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    });
};

export async function getOrderDetail(orderId) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
  };
  let requestUrl = apiEndPoint + '/subscription/order';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  return await fetch(requestUrl + '?orderId=' + orderId, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    });
};

export async function getOrderDetailByQty(product, state, quantity) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
  };
  let requestUrl = apiEndPoint + '/subscription/order/confirm';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  return await fetch(requestUrl + '?product=' + product + '&state=' + state + '&quantity=' + quantity, options )
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    });
};


export async function createOrder(email, data) {
  const userEmail = this.getTestEmail()||email;
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
    body : JSON.stringify(data)
  };
  let requestUrl = apiEndPoint + '/subscription/order';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  return await fetch(requestUrl + '?email=' + userEmail, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    })
    .catch((error) => {
      throw error;
    });
};


