import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import "../../assets/css/order.css";
import {savePDF} from '@progress/kendo-react-pdf';
import {drawDOM, exportPDF, geometry} from '@progress/kendo-drawing';
import {saveAs} from '@progress/kendo-drawing/pdf';
import {Auth} from 'aws-amplify';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {apiEndPoint} from '../../env'
import {CRITICAL_ACTION_TIMEOUT} from '../../constants/values';
import {AuthUtil} from "../../views/auth/AuthUtil";

export default (props) => {
  const bodyRef = React.createRef();
  const history = useHistory();
  const {run, details, duration, created} = props;
  const [execute, setExecute] = useState(run);
  let result = null;
  const handlePDF = async () => {
    // alert('AutoPdfGenerationContinaer handlePDF is called');
    try {
      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      result = await convertPDF();
      const requestOptions = {
        // crossDomain: true,
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': token},
        signal: controller.signal,
        credentials: 'include',
        body: JSON.stringify({
          device_name: details.device_name,
          email: details.email,
          serial_number: details.serial_number,
          filename: details.email+'_'+details.serial_number+'_'+details.client_name+'_'+duration.measurement_start+'_'+duration.measurement_end,
          types: "hr_btn",
          client_name: details.client_name,
          generated_date: created,
          measurement_start: duration.measurement_start,
          measurement_end: duration.measurement_end,
          filebody: result
        }),
      };
      let requestUrl = apiEndPoint + '/report';
      const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
      let fetchRes = await fetch(requestUrl, requestOptions);
      let uploadResult = await fetchRes.json();

      if (uploadResult.resultcode >= 200 && uploadResult.resultcode < 300) {
        // alert('upload result===' + JSON.stringify(uploadResult));
        window.open(uploadResult.data.pdf_url);
        // props.callback(uploadResult.data.pdf_url);
      }
    } catch (e) {
      alert('failed loading the generated report, details: ' + e);
    }
  };

  const convertPDF = () => {
    // alert('AutoPdfGenerationContainer convertPDF is called: ' + bodyRef.current);
    let res;
    try {
      res = drawDOM(bodyRef.current, {
        scale: 0.45,
        paperSize: 'Letter',
        fileName: 'test.pdf',
        margin: 0
      }).then((group) => {
        return exportPDF(group);
      }).then((dataUri) => {
        let encoded = dataUri.split(";base64,")[1];
        return encoded;
      });
    } catch(e) {
      alert('converPDF exception:' + e);
    }
    return res;
  };

  useEffect(()=> {
    if(run) handlePDF();
  },[bodyRef]);

  return (
    <>
      <section className="pdf-container">
        <section className="pdf-body" ref={bodyRef}>
          {props.children}
        </section>
      </section>
    </>
  )
}
