export const orderStatusProperties = {
  0: {
    msg: 'Order Requested',
    color: '#4CAF50'
  },
  1: {
    msg: 'Invoice Sent',
    color: '#F8B814'
  },
  2: {
    msg: 'Paid',
    color: '#A3A3A3'
  },
  3: {
    msg: 'Canceled',
    color: '#D65B52'
  },
  301: {
    msg: 'Paid',
    color: '#A3A3A3'
  },
  302: {
    msg: 'Payment Failed',
    color: '#D65B52'
  },
  304: {
    msg: 'Refunded',
    color: '#A3A3A3'
  },
  305: {
    msg: 'Partially Refunded',
    color: '#A3A3A3'
  }
}

export const monthlySubStatusProperties = {
  1: {
    msg: 'Invoice Sent',
    color: '#F8B814'
  },
  2: {
    msg: 'Paid',
    color: '#A3A3A3'
  },
  3: {
    msg: 'Canceled',
    color: '#D65B52'
  },
  301: {
    msg: 'Paid',
    color: '#A3A3A3'
  },
  302: {
    msg: 'Payment Failed',
    color: '#D65B52'
  },
  304: {
    msg: 'Refunded',
    color: '#A3A3A3'
  },
  305: {
    msg: 'Partially Refunded',
    color: '#A3A3A3'
  }
}
