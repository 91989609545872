import { Divider, Grid, Typography } from "@material-ui/core";
import { styles } from "../PageMain";
import { RadonUtil } from "../../components/RadonUtil";
import moment from "moment";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  const getGeneratedDate = () => {
    let genDate = location.state.originalGenerated;
    return genDate;
  };

  let generated = RadonUtil.getDate(moment(getGeneratedDate()), 3);

  return (
    <div style={{ width: "100%", marginTop: "auto" }}>
      <Divider style={{ marginTop: "5px" }} />
      <Grid container>
        <Grid item xs={6} style={{ marginBottom: "5px" }}>
          <Typography variant={"body2"} style={styles.report_footer}>
            Prepared by Ecosense Lab
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant={"body2"}
            style={styles.report_footer}
            align={"right"}
          >
            Report Generated on {generated}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
