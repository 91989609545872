import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: '#979797',
      fontSize: '14px',
      fontWeight: '400',
      fontStyle: 'normal',
      // lineHeight: '30px',
      borderBottom: '0.5px solid',
      borderBottomColor: '#979797'
    }
  },
  underline: {
    "& .MuiInputBase-root.MuiInput-outlined": {
      borderBottom: 'none',
    }
  }
})(TextField);

export default CustomTextField;
