import { Box, Grid, Typography } from "@material-ui/core";
import { styles } from "../PageMain";
import {
  BUILDING_TYPE_KEY,
  MEASUREMENT_UNIT,
  MITIGATION_SYSTEM_KEY,
  REPORT_RESULT_COLOR,
  REPORT_RESULT_SU,
  ROOM_TYPE_KEY,
  STRUCTURE_TYPE_KEY,
  TEST_FLOOR_KEY,
  UNIT_CONVERSION_SCALE,
} from "../../../constants/values";
import moment from "moment";
import { convertToPicocuries, getRadonValue } from "../util";
import _ from "lodash";
import Space from "../../../components/Space";

const Details = ({ user, reportDetails }) => {
  const reformatRadonData = (data, unit, point) => {
    if (data === "" || data === undefined) {
      return "N/A";
    } else {
      if (unit === 0) {
        return (data / UNIT_CONVERSION_SCALE).toFixed(point) + " pCi/L";
      } else {
        return data.toFixed(0) + " Bq/m3";
      }
    }
  };

  const deviceLocationReturn = () => {
    if (reportDetails.device_location === undefined) {
      return <Typography style={styles.reportItemText}>N/A</Typography>;
    }

    if (reportDetails.device_location.street === undefined || reportDetails.device_location.street === "") {
      return <Typography style={styles.reportItemText}>N/A</Typography>;
    }

    return (
      <Typography style={styles.reportItemText}>
        {reportDetails.device_location.street +
          " " +
        (reportDetails.device_location.detail?? "") +
          "\n" +
        (reportDetails.device_location.city?? "") +
          " " +
        (reportDetails.device_location.state?? "") +
          " " +
        (reportDetails.device_location.zipcode?? "")}
      </Typography>
    );
  };

  const clientReturn = () => {
    const clientName = reportDetails.client? reportDetails.client.client_name : reportDetails.client_name ;
    const clientEmail = reportDetails.client? reportDetails.client.client_display : reportDetails.client_display? reportDetails.client_display : reportDetails.client_email;
    const clientPhone = reportDetails.client? reportDetails.client.client_phone : reportDetails.client_phone;
    const noValues = (!clientName||_.isEmpty(clientName)) && (!clientEmail||_.isEmpty(clientEmail)) && (!clientPhone||_.isEmpty(clientPhone));

    if (noValues) {
      return <><Typography style={styles.reportItemText}>N/A</Typography></>;
    }

    return (
      <>
        {clientName && (
          <Typography style={styles.reportItemText}>{_.isEmpty(clientName)? '': clientName}</Typography>
        )}
        {clientEmail && (
          <Typography style={styles.reportItemText}>{_.isEmpty(clientEmail)? '': clientEmail}</Typography>
        )}
        {clientPhone && (
          <Typography style={styles.reportItemText}>{clientPhone}</Typography>
        )}
      </>
    );
  };

  const testByReturn = () => {
    if (user === undefined) {
      if(!reportDetails.test_by&&!reportDetails.owner) return <Typography style={styles.reportItemText}>N/A</Typography>;
      let displayOwner = reportDetails.test_by||reportDetails.owner
      return (
        <>
          {displayOwner.name ? (<Typography style={styles.reportItemText}>{displayOwner.name}</Typography>) : (<Space height={"11px"} />)}
          {displayOwner.ownerDisplay ? (<Typography style={styles.reportItemText}>{displayOwner.ownerDisplay}</Typography>) : (<Space height={"11px"} />)}
          {displayOwner.certNo ? (<Typography style={styles.reportItemText}>{displayOwner.certNo}</Typography>) : (<Space height={"11px"} />)}
        </>
      );
    }

    return (
      <>
        {user.name ? (<Typography style={styles.reportItemText}>{user.name}</Typography>) : (<Space height={"11px"} />)}
        {user.email ? (<Typography style={styles.reportItemText}>{user.email}</Typography>) : (<Space height={"11px"} />)}
        {user.certNo ? (<Typography style={styles.reportItemText}>{user.certNo}</Typography>) : (<Space height={"11px"} />)}
      </>
    );
  };
  return (
    <Grid
      style={{
        flexDirection: "row",
      }}
      container
    >
      <Grid
        style={{
          flexDirection: "row",
        }}
        container
      >
        <Grid item xs={5}>
          <Typography style={styles.reportItemTitle}>Test By</Typography>
          <Box style={styles.reportItemContent}>{testByReturn()}</Box>
        </Grid>

        <Grid item xs={4}>
          <Typography style={styles.reportItemTitle}>Client Info</Typography>
          <Box style={styles.reportItemContent}>{clientReturn()}</Box>
        </Grid>

        <Grid item xs={3}>
          <Typography style={styles.reportItemTitle}>
            Device Location
          </Typography>
          <Box style={styles.reportItemContent}>{deviceLocationReturn()}</Box>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E6E8F0",
          borderRadius: 10,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Grid item>
          <Typography
            style={{
              marginTop: 10,
              color: "black",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Test Result:
            {convertToPicocuries(reportDetails.avg_radon) >= 4.0 ? (
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: REPORT_RESULT_COLOR[0],
                }}
              >
                <span display='flex' flexDirection='row'>
                  <svg fill={REPORT_RESULT_COLOR[0]} width="15px" height="15px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M 6 5 L 6 7.15625 L 22.53125 14 L 6 20.84375 L 6 23 L 26 14.78125 L 26 13.21875 Z M 6 25 L 6 27 L 26 27 L 26 25 Z"></path></g></svg>&nbsp;{REPORT_RESULT_SU[0]}
                </span>
            </span>
            ) : (
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: REPORT_RESULT_COLOR[1],
                }}
              >
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: "15px",
                    fontWeight: 500,
                    color: REPORT_RESULT_COLOR[1],
                  }}
                >
                  <span display='flex' flexDirection='row'>
                    <svg fill={REPORT_RESULT_COLOR[1]} width="15px" height="15px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M 26 6 L 6 15.21875 L 6 16.78125 L 26 26 L 26 23.84375 L 9.46875 16 L 26 8.15625 Z"></path></g></svg>&nbsp;{REPORT_RESULT_SU[0]}
                  </span>
                </span>
            </span>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            style={{
              marginBottom: 10,
              color: "black",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Overall Average:
            <span style={{ marginLeft: 5, fontSize: "17px", fontWeight: 500 }}>
              {reformatRadonData(
                reportDetails.avg_radon,
                reportDetails.measurement_unit,
                1
              )}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        style={{
          flexDirection: "row",
        }}
        container
      >
        <Grid item xs={5}>
          <Typography style={styles.reportItemTitle}>Test Summary:</Typography>

          <Typography style={styles.titleTopText}>
            Device Name:
            <span style={styles.contentText}>
              {reportDetails.device_name ? reportDetails.device_name : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Serial Number:
            <span style={styles.contentText}>
              {reportDetails.serial_number
                ? reportDetails.serial_number
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>Measurement Duration</Typography>
          <Typography style={styles.titleText}>
            From:
            <span style={styles.contentText}>
              {reportDetails.measurement_start_d
                ? moment(reportDetails.measurement_start_d).format(
                    "MM/DD/YYYY hh:mm A"
                  )
                : "N/A"}
            </span>
          </Typography>
          <Typography style={styles.titleText}>
            To:
            <span style={styles.contentText}>
              {reportDetails.measurement_end_d
                ? moment(reportDetails.measurement_end_d).format(
                    "MM/DD/YYYY hh:mm A"
                  )
                : "N/A"}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography style={styles.reportItemTitle}></Typography>

          <Typography
            style={{
              marginTop: 20,
              color: "black",
              fontSize: "11px",
              fontWeight: 300,
              textAlign: "left",
            }}
          >
            Test Floor:
            <span style={styles.contentText}>
              {reportDetails.device_placement? TEST_FLOOR_KEY[reportDetails.device_placement.location_floor]
                ? TEST_FLOOR_KEY[reportDetails.device_placement.location_floor] : "N/A"
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Mitigation system:
            <span style={styles.contentText}>
              {reportDetails.device_placement? MITIGATION_SYSTEM_KEY[
                reportDetails.device_placement.mitigation_installed
              ]
                ? MITIGATION_SYSTEM_KEY[
                    reportDetails.device_placement.mitigation_installed
                  ] : "N/A"
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Building Type:
            <span style={styles.contentText}>
              {reportDetails.device_placement? BUILDING_TYPE_KEY[reportDetails.device_placement.bld_type]
                ? BUILDING_TYPE_KEY[reportDetails.device_placement.bld_type] : "N/A"
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Room Type:
            <span style={styles.contentText}>
              {reportDetails.device_placement?ROOM_TYPE_KEY[reportDetails.device_placement.room_type]
                ? ROOM_TYPE_KEY[reportDetails.device_placement.room_type] : "N/A"
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Structure Type:
            <span style={styles.contentText}>
              {reportDetails.device_placement?STRUCTURE_TYPE_KEY[reportDetails.device_placement.str_type]
                ? STRUCTURE_TYPE_KEY[reportDetails.device_placement.str_type] : "N/A"
                : "N/A"}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Year Built:
            <span style={styles.contentText}>
              {reportDetails.device_placement? reportDetails.device_placement.year_built
                ? reportDetails.device_placement.year_built : "N/A"
                : "N/A"}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={3} style={{ flex: 1 }}>
          <Typography style={styles.reportItemTitle}>
            Radon Concentration (
            {MEASUREMENT_UNIT[reportDetails.measurement_unit]
              ? MEASUREMENT_UNIT[reportDetails.measurement_unit]
              : "N/A"}
            )
          </Typography>

          <Typography style={styles.titleTopText}>
            Min:
            <span style={styles.contentText}>
              {getRadonValue(
                reportDetails.measurement_unit,
                reportDetails.lowest_radon
              )}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Average:
            <span style={styles.contentText}>
              {getRadonValue(
                reportDetails.measurement_unit,
                reportDetails.avg_radon
              )}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Max:
            <span style={styles.contentText}>
              {getRadonValue(
                reportDetails.measurement_unit,
                reportDetails.highest_radon
              )}
            </span>
          </Typography>

          <Typography style={styles.titleText}>
            Duration:
            <span style={styles.contentText}>
              {reportDetails.measurement_duration
                ? reportDetails.measurement_duration
                : "N/A"}
            </span>
          </Typography>
          {reportDetails.measurement_duration === "48 Hours" && (
            <Typography style={styles.titleText}>
              Delay Time:
              <span style={styles.contentText}>24 hours</span>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Details;
