import React, {useEffect, useState, Component} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {format} from "date-fns";
import {Helmet} from "react-helmet-async";
import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography, Chip as MuiChip, FormControl,
} from "@material-ui/core";

import {spacing, display} from "@material-ui/system";
import {getOrderDetailAction} from "../../models/actions/orderActions";
import _ from "lodash";
import {useDispatch} from "react-redux";
import "../../assets/css/order.css";
import Doc from './OrderDocService';
import PdfContainer from '../../common/pdf/PdfContainer';
import FormHelperText from "@material-ui/core/FormHelperText";
import {orderStatusProperties} from '../../constants/status';
import Box from '@material-ui/core/Box';

let originHTML;

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function OrderDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const name = localStorage.getItem('0xuserId');
  const orderId = location.search.replace('?', '');
  const [order, setOrder] = useState();
  const [createdDate, setCreatedDate] = useState('');
  const getOrderDetail = async () => {
    let res = await dispatch(
      getOrderDetailAction(orderId)
    );
    if (res && (!res.data || (res.data && res.data.length === 0))) res.data = [];
    else if (!_.isEqual(order, res.data[0])) {
      const orderFromResult = res.data[0];
      setOrder(orderFromResult);
      setCreatedDate(format(new Date(orderFromResult.created), "MMMM dd, yyyy"));
    }
  };

  const toFixed = (number) => {
    if (number ===0 ) {
      return '$0.00';
    } else if (number) {
      return '$' + number.toFixed(2);
    }
    return '';
  }
  useEffect(() => {
    getOrderDetail();
  }, []);

  const createPdf = (html) => {
    Doc.createPdf(html, orderId);
  }

  const Chip = styled(MuiChip)`
  width: ${(props) => '130px'};
  background: ${(props) => orderStatusProperties[props.status].color};
  color: ${(props) => props.theme.palette.common.white};
`;

  originHTML = (
    (order && order.orderId) ?
      <React.Fragment>
        <Helmet title="Order Details"/>

        <Typography variant="h3" gutterBottom display="inline">
          Order #{ (order && order.orderId) ? order.orderId : ''}
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to="/orders">
            Orders
          </Link>
          <Typography>Details</Typography>
        </Breadcrumbs>

        <Divider my={6}/>
        <PdfContainer createPdf={createPdf}>
          <React.Fragment>
            <Grid container justify="center">
              <Grid item xs={12} lg={10}>
                <Shadow>
                  <Card px={6} pt={6}>
                    <CardContent>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom>
                            {
                              name ? (
                                <span>Hello {name},</span>
                              ) : (
                                <span>Hello,</span>
                              )
                            }
                            <br/>
                            <br/>
                            This is an invoice for a purchase order of {toFixed(order.totalDue)}(USD) on {order.product}.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption">Order ID</Typography>
                          <Typography variant="body2">{order.orderId}</Typography>
                          <br/>
                          <Typography variant="caption" align="left" display="block">
                            Payment To
                          </Typography>
                          <Typography variant="body2" align="left">
                            Econsense Inc.
                            <br/>
                            {order.paymentTo.street}, {order.paymentTo.city}, {order.paymentTo.state} {order.paymentTo.zipcode}<br/>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" align="right" display="block">
                              <span>Order Created</span>
                          </Typography>
                          <Typography variant="body2" align="right" display="block">
                            {order.status != undefined && (
                              <span>{format(new Date(order.created), "MMMM dd, yyyy")}</span>
                            )}
                          </Typography>
                          <br/>
                          <Typography variant="caption" align="right" display="block">
                          {order.status != undefined && order.status!=0 && <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={orderStatusProperties[order.status].msg}
                            status={order.status}
                          />}
                          </Typography>
                          <Typography variant="body2" align="right">
                            { order.status != undefined && order.status!=0 && format(new Date(order.updated), "MMMM dd, yyyy")}
                          </Typography>
                          <br/>
                          <Typography variant="caption" align="right" display="block">
                            {order.status != undefined && order.status!=0 && (
                              <span>{order.stripe_status}</span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption" align="left" >
                            Billing information
                          </Typography>
                          {
                            order.bill_address && <Typography variant="body2" align="left">
                              {order.bill_address.bill_firstname} {order.bill_address.bill_lastname}
                              <br/>
                              {order.bill_address.bill_street}
                              {
                                order.bill_address.bill_detail && (
                                  <br/>
                                )
                              }
                              {
                                order.bill_address.bill_detail && (
                                  order.bill_address.bill_detail
                                )
                              }
                              <br/>
                              {order.bill_address.bill_city}
                              <br/>
                              {order.bill_address.bill_state} {order.bill_address.bill_zipcode}
                              <br/>
                              <Link href={"mailto:" + order.bill_address.bill_email}>{order.bill_address.bill_email}</Link>
                              <br/>
                              {order.bill_address.bill_phone}
                              <br/>
                            </Typography>
                          }
                          {
                            !order.bill_address &&
                            <Typography variant="body2" align="left">
                              N/A
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption" align="right" display="block">Shipping Information</Typography>
                          {
                            order.address.ship_firstname &&
                            <Typography variant="body2" align="right" >
                              {order.address.ship_firstname} {order.address.ship_lastname}
                              <br/>
                              {order.address.ship_street}
                              {
                                order.address.ship_detail && (
                                  <br/>
                                )
                              }
                              {
                                order.address.ship_detail && (
                                  order.address.ship_detail
                                )
                              }
                              <br/>
                              {order.address.ship_city}
                              <br/>
                              {order.address.ship_state} {order.address.ship_zipcode}
                              <br/>
                              <Link href={"mailto:" + order.address.ship_email}>{order.address.ship_email}</Link>
                              <br/>
                              {order.address.ship_phone}
                              <br/>
                            </Typography>
                          }
                          {
                            !order.address.ship_firstname &&
                            <Typography variant="body2" align="right" >
                              {order.address.street}
                              {
                                order.address.detail && (
                                  <br/>
                                )
                              }
                              {
                                order.address.detail && (
                                  order.address.detail
                                )
                              }
                              <br/>
                              {order.address.city}
                              <br/>
                              {order.address.state} {order.address.zipcode}
                              <br/>
                              <Link href={"mailto:" + order.address.email}>{order.address.email}</Link>
                              <br/>
                              {order.address.phone}
                              <br/>
                            </Typography>
                          }
                        </Grid>

                      </Grid>
                    </CardContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            EcoGuard acquistion Fee
                          </TableCell>
                          <TableCell>{order.quantity}</TableCell>
                          <TableCell align="right">{toFixed(order.quantity * order.unitPrice)}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell/>
                          <TableCell>Subtotal</TableCell>
                          <TableCell align="right">{toFixed(order.subtotal)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell/>
                          <TableCell>Sales Tax</TableCell>
                          <TableCell align="right">{toFixed(order.salesTax)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell/>
                          <TableCell>Shipping</TableCell>
                          <TableCell align="right">{toFixed(order.shipping)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell/>
                          <TableCell><b>Total</b></TableCell>
                          <TableCell align="right"><b>{toFixed(order.totalDue)}</b></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Shadow>
              </Grid>
            </Grid>
          </React.Fragment>
        </PdfContainer>
      </React.Fragment>
    :
      <></>
  );

  return originHTML;
}


export default OrderDetails;
