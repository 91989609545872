import {Typography} from "@material-ui/core";
import React from "react";
import Footer from "../PageMain/Footer";
import Space from "../../../components/Space";

const PageRecommendations= () => {
    const styles = {
        titleRed: {
            color: "#E21111",
            fontSize: "14px",
            fontWeight: 800,
            textAlign: "left",
        },

        titleOrange: {
            color: "#FF8D39",
            fontSize: "14px",
            fontWeight: 800,
            textAlign: "left",
        },

        titleGreen: {
            color: "#3BA84B",
            fontSize: "14px",
            fontWeight: 800,
            textAlign: "left",
        },

        p: {
            textAlign: "start",
            marginTop: 20,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
        },

        ul: {
            marginTop: "0px",
            paddingLeft: "30px",
        },

        startContentTextBold: {
            color: "black",
            fontSize: "11px",
            fontWeight: 500,
        },

        startContentText: {
            color: "black",
            fontSize: "11px",
            fontWeight: 300,
            textAlign: "left",
        },

        contentTextBold: {
            color: "black",
            fontSize: "11px",
            fontWeight: 500,
        },

        contentText: {
            color: "black",
            fontSize: "11px",
            fontWeight: 300,
        },
    };

    return(
        <>
            <div
                style={{
                    marginTop: 30,
                    marginLeft: 20,
                    marginRight: 20,
                }}
            >
                <Typography
                    style={{
                        textAlign: "start",
                        fontWeight: 500,
                        color: "black",
                        fontStyle: "italic",
                        fontSize: "12px",
                    }}
                >
                    Recommendations: (as per ANSI-AARST MAH)
                </Typography>

                <p style={styles.p}>
                    <span style={styles.titleRed}>Test result is 4.0 pCi/L or greater:  YOUR RADON IS HIGH</span>
                    <ul
                        style={styles.ul}>
                        <li>
                            <span style={styles.contentTextBold}>FIX THE BUILDING.</span>
                            <span style={styles.contentText}>Test results indicate occupants may be exposed to radon concentrations that meet or exceed the EPA action level of 4 pCi/L or greater.</span>
                        </li>

                        <li>
                            <span style={styles.contentText}>Efforts to reduce radon concentrations are not complete until a retest provides evidence of mitigation effectiveness.</span>
                            <ul style={styles.ul}>
                                <li>
                                    <span style={styles.contentText}>Complete a short-term test between 24 hours and 30 days after installation of a mitigation system.</span>
                                </li>
                                <li>
                                    <span style={styles.contentTextBold}>Retest every 2 years or install a</span>
                                    <span style={styles.contentTextBold}> continuous radon monitor</span>
                                    <span style={styles.contentText}> to ensure the system remains effective.</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>


                <p style={styles.p}>
                    <span style={styles.titleOrange}>Test result is between 2.0 and 4.0 pCi/L:  YOUR RADON IS MODERATELY ELEVATED</span>
                    <ul
                        style={styles.ul}>
                        <li>
                            <span style={styles.contentTextBold}>CONSIDER FIXING THE BUILDING.</span>
                            <span style={styles.contentText}> Test results indicate radon levels greater than half the EPA action level.</span>
                        </li>

                        <li>
                            <span style={styles.contentText}>The World Health Organization (WHO) recommends mitigation if levels are 2.7 (100 Bq/m³) or higher.</span>
                        </li>

                        <li>
                            <span style={styles.contentTextBold}>Continuously monitoring</span>
                            <span style={styles.contentText}> the radon, especially when the heating system is active both day and night is more likely to provide a clear characterization of potential radon hazards. </span>
                        </li>
                    </ul>
                </p>

                <p style={styles.p}>
                    <span style={styles.titleGreen}>Test result is less than 2.0 pCi/L:  YOUR RADON IS LOW</span>
                    <ul
                        style={styles.ul}>
                        <li>
                            <span style={styles.contentTextBold}>NO FURTHER ACTION REQUIRED AT THIS TIME.</span>
                            <span style={styles.contentText}> Test results indicate radon levels greater than half the EPA action level.</span>
                        </li>

                        <li>
                            <span style={styles.contentText}>Radon levels in a building can fluctuate due to weather conditions and other factors, including renovations and alterations, or changes in the HVAC system.  For this reason, EPA recommends</span>
                            <span style={styles.contentTextBold}> retesting your home every 5 years </span>
                            <span style={styles.contentText}> and to remediate if levels become elevated.</span>
                        </li>
                    </ul>
                </p>

                <p style={styles.p}>
                    <span style={styles.titleRed}>Times to Retest:</span><br></br>
                    <span style={styles.contentText}>Retest in conjunction with any sale of new or existing buildings. In addition, be certain to test again or continuously monitor when any of the following circumstances occur:</span>
                    <ul
                        style={styles.ul}>
                        <li>
                            <span style={styles.contentText}>A ground contact area not previously tested is occupied, or a home is newly occupied.</span>
                        </li>


                        <li>
                            <span style={styles.contentText}>Ventilation is significantly altered by extensive weatherization, changes to mechanical systems or comparable procedures.</span>
                        </li>

                        <li>
                            <span style={styles.contentText}>A mitigation system is altered, modified, or repaired.</span>
                        </li>

                        <li>
                            <span style={styles.contentText}>Significant openings to soil occur due to:</span>
                            <ul style={styles.ul}>
                                <li>
                                    <span style={styles.contentText}>Complete a short-term test between 24 hours and 30 days after installation of a mitigation system.</span>
                                </li>
                                <li>
                                    <span style={styles.contentText}>Groundwater or slab surface water control systems that are altered or added (e.g., sumps, perimeter drain tile, shower/tub retrofits, etc.).</span>
                                    <span style={styles.contentText}>Natural settlement causing major cracks to develop.</span>
                                    <span style={styles.contentText}>Earthquakes, construction blasting, or formation of sinkholes nearby.</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>
            </div>
            <Space height={"50px"} />
            <Footer />
        </>
    )
}

export default PageRecommendations;