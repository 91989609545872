import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  IconButton,
} from "@material-ui/core";

import { display, spacing } from "@material-ui/system";
import _ from "lodash";
import "../../assets/css/order.css";
import ReportInterface from "../../integration/internal/ReportInterface";
import Loading from "../components/Loading";
import DialogContentText from "@material-ui/core/DialogContentText";
import { RadonUtil } from "../components/RadonUtil";
import { savePDF } from "@progress/kendo-react-pdf";
import { reformatRadonChartData } from "../reports/util";
import { AuthUtil } from "../auth/AuthUtil";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import {apiEndPoint, awsXApiKey} from "../../env";
import { CRITICAL_ACTION_TIMEOUT } from "../../constants/values";
import moment from "moment";
import ReportPreview from "../reports/ReportPreview";
import ForPdf from "../reports/ForPdf";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);
const encoded = false

function EUReport() {
  const location = useLocation();
  const [reportDetails, setReportDetails] = useState({});
  const [reportDuration, setReportDuration] = useState({
    start: null,
    end: null,
  });
  const [showNoData, setShowNoData] = useState(false);
  const [page, setPage] = useState(1);

  const pdfExportComponent = useRef(null);
  const history = useHistory();
  const [reportChartRadonValue, setReportChartRadonValue] = useState([]);

  let res;

  useEffect(() => {
    const getReportDetails = async () => {
      let queryParams = ''
      if(encoded) {
        queryParams = new URLSearchParams('?'+atob(location.search.slice(1)));
      }
      else {
        queryParams = new URLSearchParams(location.search);
      }
      const userEmail = queryParams.get('email')
      const serialNumber = queryParams.get("serial_number");
      const measurementStart = queryParams.get("measurement_start");
      const measurementEnd = queryParams.get("measurement_end");
      const durationType = queryParams.get("duration")
      const types = queryParams.get("types");

      if(serialNumber!=null) {
        history.replace(history.location.pathname, {
          email: userEmail,
          name: 'test name',
          certNo: 'cert no',
          actionType: "VIEW",
          serial_number: [serialNumber],
          duration: {
            start: measurementStart,
            end: measurementEnd,
            type: durationType,
          },
        });


        const duration = {
          measurement_start: measurementStart,
          measurement_end: measurementEnd,
          types,
        };

        // const durationSet = {
        //   measurement_start: location.state.duration.start,
        //   measurement_end: location.state.duration.end,
        //   types: types,
        // };

        res = await ReportInterface.retrieveEUReportDetails(
          userEmail,
          serialNumber,
          duration
        );

        if ((res && !res.data) || !res) {
          setReportDetails(null);
          setReportDuration({
            start: duration.measurement_start,
            end: duration.measurement_end,
            type: types,
          });
          setShowNoData(true);
        } else if (res && !_.isEqual(reportDetails, res.data)) {
          let retData = reformatRadonChartData(
            res.data,
            RadonUtil.getBtnType(durationType),
            durationType
          );
          setReportChartRadonValue(retData);
          setReportDetails(res.data);
          setReportDuration({
            start: duration.measurement_start,
            end: duration.measurement_end,
            type: durationType,
          });
        }
        else {
          // user redirect from report list
          res = await ReportInterface.retrieveEUReportDetails(
            userEmail,
            serialNumber,
            duration
          );

          if ((res && !res.data) || !res) {
            setReportDetails(null);
            setReportDuration({
              start: duration.measurement_start,
              end: duration.measurement_end,
              type: types,
              // type: location.state.duration.type,
            });
            setShowNoData(true);
          } else if (res && !_.isEqual(reportDetails, res.data)) {
            let retData = reformatRadonChartData(
              res.data,
              RadonUtil.getBtnType('WEEK'),
              location.state.duration.type
            );
            setReportChartRadonValue(retData);
            setReportDetails(res.data);
            setReportDuration({
              start: duration.measurement_start,
              end: duration.measurement_end,
              type: location.state.duration.type,
            });
          }
        }
      }
    };

    getReportDetails();
  }, []);

  const handleClose = () => {
    setShowNoData(false);
    // history.push({ pathname: "/reports/list" });
  };

  const convertPDF = (filename) => {
    let pdfRootHtmlElement = document.getElementById("pdfRoot");
    let res = drawDOM(pdfRootHtmlElement, {
      scale: 0.91,
      paperSize: "Letter",
      fileName: filename,
      margin: 0,
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        let encoded = dataUri.split(";base64,")[1];
        return encoded;
      });

    return res;
  };

  const handleSave = async () => {
    const fileName =
      reportDetails.serial_number +
      "_" +
      reportDetails.client_name +
      "_" +
      reportDuration.start +
      "_" +
      reportDuration.end +
      ".pdf";

    let pdfRootHtmlElement = document.getElementById("pdfRoot");

    try {
      const result = await convertPDF(fileName);

      const controller = new AbortController();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": awsXApiKey },
        signal: controller.signal,
        credentials: "include",
        body: JSON.stringify({
          device_name: reportDetails.device_name,
          email: reportDetails.email,
          serial_number: reportDetails.serial_number,
          filename: reportDetails.email + "_" + fileName,
          client_name: reportDetails.client_name,
          generated_date: moment(reportDetails.generated).format("MM/DD/YYYY"),
          measurement_start: reportDuration.start,
          measurement_end: reportDuration.end,
          filebody: result,
          radondata: reportDetails.radondata,
        }),
      };
      let requestUrl = apiEndPoint + "/eu/report";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let fetchRes = await fetch(requestUrl, requestOptions);
      let uploadResult = await fetchRes.json();

      if (uploadResult.resultcode >= 200 && uploadResult.resultcode < 300) {
        window.open(uploadResult.data.pdf_url);
        // history.push("/reports/list");
        savePDF(pdfRootHtmlElement, {
          paperSize: "Letter",
          fileName,
        });
      } else {
        throw Error("failed upload the generated report");
      }
    } catch (e) {
      alert(e);
    }
  };

  const getNoDataDialog = () => {
    return (
      <>
        <Dialog
          titleStyle={{ textAlign: "center" }}
          maxWidth="md"
          maxHeight={"sm"}
          open={showNoData}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <div className="s5"></div>
          <DialogTitle id="alert-dialog-title">Information Message</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There is no radon data collected during the period.
            </DialogContentText>
          </DialogContent>
          <div className="s10"></div>
          <DialogActions style={{ paddingRight: "20px" }}>
            <Button
              style={{
                background: "#E0E0E0",
                color: "#000000",
                width: "100px",
                height: "36px",
              }}
              onClick={handleClose}
            >
              OK
            </Button>
          </DialogActions>
          <div className="s10"></div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Helmet title="Report Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Radon Report
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/reports/list">
          Radon Reports
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <>
        {!reportDuration.start ? (
          <Loading></Loading>
        ) : !reportDetails ? (
          getNoDataDialog()
        ) : (
          <Container>
            <ForPdf
              pdfExportComponent={pdfExportComponent}
              reportDetails={reportDetails}
              reportChartRadonValue={reportChartRadonValue}
            />

            <Grid
              style={{
                marginTop: 20,
                flexDirection: "row",
              }}
              justify="center"
              container
            >
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justify="center"
                style={{
                  flex: 1,
                  visibility: page === 1 ? "hidden" : "",
                }}
              >
                <IconButton
                  disabled={page === 1}
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1);
                  }}
                >
                  <ArrowBackIos fontSize="small" style={{ color: "#8F95B2" }} />
                </IconButton>
              </Grid>
              <Grid item xs={7}>
                <ReportPreview
                  page={page}
                  reportDetails={reportDetails}
                  reportChartRadonValue={reportChartRadonValue}
                />
              </Grid>
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justify="center"
                style={{
                  flex: 1,
                  visibility: page === 2 ? "hidden" : "",
                }}
              >
                <IconButton
                  disabled={page === 2}
                  onClick={() => {
                    setPage((currentPage) => currentPage + 1);
                  }}
                >
                  <ArrowForwardIos
                    fontSize="small"
                    style={{ color: "#8F95B2" }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              style={{
                marginTop: 20,
                flexDirection: "row",
              }}
              justify="center"
              container
            >
              <Grid item>
                <Button
                  alignItems="center"
                  justify="center"
                  onClick={handleSave}
                  style={{ width: "300px", marginTop: 0, marginBottom: 30 }}
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Container>
        )}
      </>
    </>
  );
}

export default EUReport;
