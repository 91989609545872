import React, {Component} from 'react';
//import shouldPureComponentUpdate from 'react-pure-render/function';

import {markerRedStyle, markerGreenStyle, markerOrangeStyle} from './MarkerStyle.js';
import {RadonUtil} from "../components/RadonUtil";
import {RADON_REF_BQ_LEVEL, RADON_REF_PCI_LEVEL} from "../dashboards/Devices/DeviceDetail";

export default class Marker extends Component {
  static defaultProps = {};

  //shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);
  }

  // RADON_LEVEL2 = (deviceInfo.unit == 0) ? RADON_REF_PCI_LEVEL.LEVEL2 : RADON_REF_BQ_LEVEL.LEVEL2;
  // RADON_LEVEL3 = (deviceInfo.unit == 0) ? RADON_REF_PCI_LEVEL.LEVEL3 : RADON_REF_BQ_LEVEL.LEVEL3;


  render() {
    const RADON_LEVEL2 = RADON_REF_BQ_LEVEL.LEVEL2;
    const RADON_LEVEL3 = RADON_REF_BQ_LEVEL.LEVEL3;
    const radonNumber = this.props.radonNumber;
    let markerStyle;
    if (radonNumber >= 0 && radonNumber < RADON_LEVEL2) {
      markerStyle = markerGreenStyle;
    } else if (radonNumber >= RADON_LEVEL2 && radonNumber < RADON_LEVEL3) {
      markerStyle = markerOrangeStyle;
    } else if (radonNumber >= RADON_LEVEL3) {
      markerStyle = markerRedStyle;
    }

    return (
      <div style={markerStyle}>
        {RadonUtil.convertToPCi(radonNumber)}
      </div>
    );
  }
}
