import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../components/Loading";
import { getComparator, stableSort } from "../util";

export function DeviceTable(props) {
  const useStyles = makeStyles({
    customTableContainer: {
      overflow: "auto",
    },
  });

  const { handleDeviceSelect, devices } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("serial_number");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // const [devices, setDevices] = useState([]);
  const [skip, setSkip] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  let res;
  location.state.email = localStorage.getItem("0xaccountId");
  location.state.name = localStorage.getItem("0xuserId");
  const getDeviceList = async () => {
    if (!location.state) return;
    if (skip) return;
    setLoaded(true);
  };

  useEffect(() => {
    getDeviceList();
  }, []);

  function DeviceSelectionTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const deviceSelectionTableHeadCells = [
      { id: "radio_button", alignment: "center", label: "" },
      { id: "device_name", alignment: "left", label: "Device Name" },
      { id: "serial_number", alignment: "left", label: "S/N" },
      { id: "client_name", alignment: "left", label: "Client" },
      { id: "installed", alignment: "left", label: "Installed" },
    ];

    return (
      <TableHead>
        <TableRow>
          {deviceSelectionTableHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignment}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = devices.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    // alert('handleClick for ' + id);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex >= 0) return;
    else {
      newSelected = [id];
    }

    setSelected(newSelected);
    setSkip(!skip);
    handleDeviceSelect(true, newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, devices.length - page * rowsPerPage);
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {!loaded ? (
        <Loading top={"110px"} bottom={"110px"}></Loading>
      ) : devices.devices && devices.devices.length === 0 ? (
        <></>
      ) : (
        <div>
          <Paper>
            {/*  <TableContainer style={{maxHeight:'1300px'}}>*/}
            {/*TODO: stickyHeader does not work*/}
            <TableContainer style={{ maxHeight: "600px" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                // aria-labelledby="tableTitle"
                size={"medium"}
              >
                <DeviceSelectionTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={devices.length}
                />
                <TableBody>
                  {stableSort(devices.devices, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.serial_number);

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.serial_number)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.serial_number}-${index}`}
                          selected={isItemSelected}
                        >
                          <TableCell align="center">
                            <FormControl component="fieldset">
                              <RadioGroup aria-label="gender" name="gender1">
                                <FormControlLabel
                                  value="selected"
                                  control={<Radio />}
                                  checked={isItemSelected}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                          <TableCell align="left">{row.device_name}</TableCell>
                          <TableCell align="left">
                            {row.serial_number}
                          </TableCell>
                          <TableCell align="left">{row.client_name}</TableCell>
                          <TableCell align="left">
                            {row.installed.substring(0, 10)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {/*<Typography variant={'body2'} align={'center'} gutterBottom style={styles.report_client_bold}>*/}
                {/*Generating Report...*/}
                {/*</Typography></>}*/}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
}
