import Header from "./Header";
import Contact from "./Contact";
import { Grid } from "@material-ui/core";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import RequestReport from "./RequestReport";
import ProvideSupport from "./Support";
import Wifi from "./WiFi";

const SupportPage = () => {
  return (
    <div>
      <Header />
      <Grid container spacing={6}>
        <Grid item style={{ width: "100%" }}>
          <Contact />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Pricing />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <RequestReport />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <ProvideSupport />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Wifi />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <FAQ />
        </Grid>
      </Grid>
    </div>
  );
};

export default SupportPage;
