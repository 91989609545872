import React from "react";
import styled from "styled-components/macro";
import "../../assets/css/common.css";
import "../../assets/css/dashboard.css";
import { spacing } from "@material-ui/system";
import { Typography as MuiTypography } from "@material-ui/core";

const Typography = styled(MuiTypography)(spacing);

function NoData() {
  return (
    <React.Fragment>
      <Typography mb={"228px"} />
      <div className="no-data-container">
        <Typography variant={"h6"} align={"center"} mb={"19px"}>
          No generated reports now
        </Typography>
        <span className="no-data-text">
          Create new report by clicking 'New Report' button
        </span>
      </div>
    </React.Fragment>
  );
}

export default NoData;
