import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

const Pricing = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h5">EcoGuard Pricing</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              <ul style={{ paddingLeft: "2em" }}>
                <li>
                  Hardware regular price (EQ200EG):
                  <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                    <li>
                      <strong>$129.00/unit</strong>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul style={{ paddingLeft: "2em" }}>
                <li>
                  Please contact us (
                  <Link href="mailto:sale@ecosense.io">sale@ecosense.io</Link>)
                  for bulk purchases.
                </li>
              </ul>
              <ul style={{ paddingLeft: "2em" }}>
                <li>Monthly subscription fee per device</li>
                <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                  <li>
                    <strong>$3.99/month</strong>
                  </li>
                  <li>
                    <strong>$1.99/month</strong> (if your device is activated
                    less than 15 days)
                  </li>
                </ul>
              </ul>
              <ul style={{ paddingLeft: "2em" }}>
                <li>
                  On the 1st of every month, we will email you the previous
                  month's bill.
                </li>
              </ul>
              <ul style={{ paddingLeft: "2em" }}>
                <li>
                  If you have previously paid, you monthly subscription will
                  automatically be charged to the credit card information on
                  file.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Pricing;
