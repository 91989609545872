import { savePDF } from '@progress/kendo-react-pdf';

class OrderDocService {
  createPdf = (html, invoiceNo) => {
    savePDF(html, {
      scale: 0.75,
      paperSize: 'A4',
      fileName: 'subscription-' + invoiceNo+ '.pdf',
      margin: 0
    })
  }
}

const Doc = new OrderDocService();
export default Doc;
