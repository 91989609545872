import { Auth } from "aws-amplify";
import { apiEndPoint } from "../../env";
import { NETWORK_LOG } from "../../constants/envConstant";
import {
  CRITICAL_ACTION_TIMEOUT,
  PCI,
  UNIT_CONVERSION_SCALE,
} from "../../constants/values";
import { AuthUtil } from "../../views/auth/AuthUtil";
import {decodeRx} from '../../utils/codec';

const SubscriptionInterface = {
  getTestEmail: function() {
    return decodeRx(localStorage.getItem('0xXRurEm'));
  },

  retrieveDevices: async function (email) {
    // const testEmail = decodeRx(localStorage.getItem('0xXRurEm'));
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const requestOptions = {
      // crossDomain: true,
      method: "GET",
      // mode: 'no-cors',
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      // referrerPolicy: 'origin',
      credentials: "include",
      cache: "no-cache",
    };
    let requestUrl = apiEndPoint + "/subscription/device";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(
      requestUrl + "?email=" + userEmail + "&summary=yes",
      requestOptions
    )
      .then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          // if(data.data&&data.data.devices&&data.data.devices.length>0) {
          //   let filtered = data.data.devices.filter((d)=>d.subscription==='Y');
          //   data.data.devices = filtered;
          // }
          return data;
        });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return "fetch() request was aborted, hit CTRL-R to try again.";
        }
        console.log(err);
      });
    return res;
  },

  retrieveDevicesWithAccessToken: async function (email, accessToken) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    const requestOptions = {
      // crossDomain: true,
      method: "GET",
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      signal: controller.signal,
      // referrerPolicy: 'origin',
      credentials: "include",
      cache: "no-cache",
    };
    let requestUrl = apiEndPoint + "/subscription/device";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(
      requestUrl + "?email=" + userEmail + "&summary=yes",
      requestOptions
    )
      .then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          // if(data.data&&data.data.devices&&data.data.devices.length>0) {
          //   let filtered = data.data.devices.filter((d)=>d.subscription==='Y');
          //   data.data.devices = filtered;
          // }
          return data;
        });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return "fetch() request was aborted, hit CTRL-R to try again.";
        }
        console.log(err);
      });
    return res;
  },

  retrieveDevicesWithoutSummary: async function (email) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    // alert('email = ' + email);
    let token = await AuthUtil.getAuthToken();
    // alert('token = ' + token);
    const requestOptions = {
      // crossDomain: true,
      method: "GET",
      // mode: 'no-cors',
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      // referrerPolicy: 'origin',
      credentials: "include",
      cache: "no-cache",
    };

    let requestUrl = apiEndPoint + "/subscription/device";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(
      requestUrl + "?email=" + userEmail + "&stats=no",
      requestOptions
    ).then((res) => {
      // alert('res = ' + token);
      return res.text().then((text) => {
        // alert('text = ' + text);
        const data = text && JSON.parse(text);
        // alert('data = ' + data);
        return data;
      });
    });
    return res;
  },

  retrieveDevicesWithoutSummaryForApp: async function (email) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    // alert('email = ' + email);
    let token = await AuthUtil.getAuthToken();
    // alert('token = ' + token);
    const requestOptions = {
      // crossDomain: true,
      method: "GET",
      // mode: 'no-cors',
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      // referrerPolicy: 'origin',
      credentials: "include",
      cache: "no-cache",
    };

    let requestUrl = apiEndPoint + "/subscription/device";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(
      requestUrl + "?email=" + userEmail + "&stats=no",
      requestOptions
    )
      .then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      })
      .catch((err) => {
        alert("retrieveDevicesWithoutSummary@ err = " + err);
      });
    return res;
  },

  retrieveDeviceDetails: async function (email, sn) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      cache: "no-cache",
    };

    let requestUrl = apiEndPoint + "/subscription/device";
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(
      requestUrl + "?email=" + userEmail + "&serial_number=" + sn,
      requestOptions
    ).then((res) => {
      return res.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    });
    return res;
  },

  changeDeviceInformation: async function (
    email,
    sn,
    monitorMode,
    subscriptionStatus,
    isSubsStatusChanged,
    notiThreshold,
    pushNotiStatus,
    emailNotiStatus,
    unit
  ) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    const pushNotificationValue = pushNotiStatus
      ? pushNotiStatus.toLowerCase()
      : null;
    const emailNotificationValue = emailNotiStatus
      ? emailNotiStatus.toLowerCase()
      : null;
    let token = await AuthUtil.getAuthToken();
    let device_info = {
      mv_mode: monitorMode,
      alarm_value:
        unit === PCI
          ? (notiThreshold * UNIT_CONVERSION_SCALE).toFixed(1)
          : Number(notiThreshold).toFixed(1),
      push_notification: pushNotificationValue,
      email_notification: emailNotificationValue,
      unit: unit === PCI ? 0 : 1,
    }

    if (isSubsStatusChanged) {
      device_info.subs_status = subscriptionStatus;
    }

    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: device_info,
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      console.log(
        "changeDeviceInformation status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update device configuration status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
        return true;
      }
      return response;
    } catch (e) {
      // this.log('update device configuration status failed. error: ' + e + ',' + pushNotiStatus + ',' + emailNotiStatus + ',' + notiThreshold + ',' + unit, true);
    }
  },

  changeLocationInformation: async function (
    email,
    sn,
    mitigationInstallation,
    mitigationDate,
    yearBuilt,
    buildingType,
    structureType,
    testFloor,
    roomType,
    comments
  ) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          device_placement: {
            mitigation_installed: mitigationInstallation,
            mitigation_installed_date: mitigationDate,
            year_built: yearBuilt,
            bld_type: buildingType,
            str_type: structureType,
            location_floor: testFloor,
            room_type: roomType,
            comments: comments,
          },
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      console.log(
        "changeLocationInformation status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update device configuration status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
        return true;
      }
      return response;
    } catch (e) {
      // this.log('update device configuration status failed. error: ' + e + ',' + pushNotiStatus + ',' + emailNotiStatus + ',' + notiThreshold + ',' + unit, true);
    }
  },

  changeDeviceStatus: async function (email, sn, status, mode) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          subs_status: status,
          mv_mode: mode,
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      this.log(
        "update device subscription status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update device subscription status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
      }
      return response;
    } catch (e) {
      this.log(
        "update device subscription status failed. error: " +
          e +
          "," +
          email +
          "," +
          sn +
          "," +
          status,
        true
      );
    }
  },

  changeDeviceSubStatus: async function (email, sn, status, mode) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          subs_status: status,
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      this.log(
        "update device subscription status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update device subscription status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
      }
      return response;
    } catch (e) {
      this.log(
        "update device subscription status failed. error: " +
          e +
          "," +
          email +
          "," +
          sn +
          "," +
          status,
        true
      );
    }
  },

  changeDeviceConfiguration: async function (
    email,
    sn,
    pushNotiStatus,
    emailNotiStatus,
    value,
    unit
  ) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    const pushNotificationValue = pushNotiStatus
      ? pushNotiStatus.toLowerCase()
      : null;
    const emailNotificationValue = emailNotiStatus
      ? emailNotiStatus.toLowerCase()
      : null;
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          unit: unit === PCI ? 0 : 1,
          alarm_value:
            unit === PCI
              ? (value * UNIT_CONVERSION_SCALE).toFixed(1)
              : Number(value).toFixed(1),
          push_notification: pushNotificationValue,
          email_notification: emailNotificationValue,
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      this.log(
        "update device configuration status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update device configuration status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
        return true;
      }
      return response;
    } catch (e) {
      this.log(
        "update device configuration status failed. error: " +
          e +
          "," +
          pushNotiStatus +
          "," +
          emailNotiStatus +
          "," +
          value +
          "," +
          unit,
        true
      );
    }
  },

  changeClientInformation: async function (
    email,
    sn,
    clientName,
    clientEmail,
    deviceName,
    location,
    phone,
    notifyMonthly
  ) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          client_display: clientEmail,
          client_name: clientName,
          device_name: deviceName,
          location: location,
          phone: phone,
          notify_monthly: notifyMonthly,  // @TODO: need to apply a dynamical change, 1 : checked(send emails), 0 : unchecked(no emails)
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      this.log(
        "update client information : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
        "update client information response : " + JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
        return true;
      }
      return response;
    } catch (e) {
      this.log(
        "update client information failed. error: " +
          e +
          "," +
          clientName +
          "," +
          deviceName +
          "," +
          phone,
        true
      );
    }
  },

  changeAdditionInformation: async function (
      email,
      sn,
      auxImages,
      ansiAarst,
      comments
  ) {
    const userEmail = this.getTestEmail()||email;
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      body: JSON.stringify({
        email: userEmail,
        serial_number: sn,
        device_info: {
          aux_images: auxImages,
          "ansi-aarst": ansiAarst,
          comments: comments,
        },
      }),
    };

    try {
      let requestUrl = apiEndPoint + "/subscription/device";
      console.log(
          "changeAdditionInformation status : " +
          requestUrl +
          "," +
          JSON.stringify(options)
      );
      const timeoutId = setTimeout(
          () => controller.abort(),
          CRITICAL_ACTION_TIMEOUT
      );
      let response = await fetch(requestUrl, options);
      this.log(
          "update addition information status response : " +
          JSON.stringify(response)
      );
      if (response.status === 200 || response.status === 201) {
        // In case of SUCCESS
        return true;
      }
      return response;
    } catch (e) {
      this.log('update addition information status failed. error: ' + e + ',' + auxImages + ',' + ansiAarst + ',' + comments, true);
    }
  },

  log: function (msg, flag) {
    if (NETWORK_LOG || flag) console.log(`[SubscriptionInterface] ${msg}`);
  },
};

export default SubscriptionInterface;
