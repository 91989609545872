import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signOut } from "../../models/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import {validEmailRegex, validPwdRegex} from "../../utils/AuthValidation";
import InputBase from "@material-ui/core/InputBase";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

function ResetPwdConfirm() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <div align="center">
        <img width={120} src="/static/img/logo/ecosense-logo3.jpg"></img>
      </div>
      <div className='s20'></div>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Reset password
      </Typography>
      <div className='s20'></div>
      <Typography component="h2" variant="body1" align="center">
        Your password was successfully reset!
      </Typography>
      <Typography component="h2" variant="body1" align="center">
      </Typography>

      <Formik
        initialValues={{
          submit: false,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(
              signOut()
            );
            history.push("/auth/sign-in");
          } catch (error) {
            let message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <InputBase disabled inputProps={{ 'aria-label': 'naked' }}/>
            <InputBase disabled inputProps={{ 'aria-label': 'naked' }}/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              OK
            </Button>

          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default ResetPwdConfirm;
