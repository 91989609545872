import {Auth} from 'aws-amplify';
import {apiEndPoint, awsXApiKey} from '../../../src/env';
import {CRITICAL_ACTION_TIMEOUT} from '../../constants/values';

const headers = {
  "Content-Type": "application/json",
  "x-api-key": awsXApiKey,
};

export async function createPaymentIntentForOrder(info) {
  const POST_options = {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(info.data)
  };

  const options = POST_options;

  // return await fetch(apiEndPoint + '/stripe/paymentintent/invoice', options) // for testing
  return await fetch(apiEndPoint + '/stripe/paymentintent', options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    })
    .catch((error) => {
      throw error;
    });
};

export async function createPaymentIntentForInvoice(info) {
  const POST_options = {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(info.data)
  };

  const options = POST_options;

  return await fetch(apiEndPoint + '/stripe/paymentintent/invoice', options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    })
    .catch((error) => {
      throw error;
    });
};
