import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Typography,
} from "@material-ui/core";
import Space from "../../../components/Space";
import { DeviceTable } from "../Tables/DevicesTable";
import Loading from "../../components/Loading";

const SelectDeviceDialog = ({
  isLoading,
  openDeviceForm,
  handleClose,
  devices,
  handleDeviceSelect,
  deviceSelected,
  handleFirstEligibilityCheck,
}) => {
  return (
    <Dialog
      titleStyle={{ textAlign: "center" }}
      maxWidth="md"
      maxHeight={"sm"}
      open={openDeviceForm}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title1"
    >
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="s20"></div>
        <DialogTitle id="max-width-dialog-title1">
          <Typography variant="h5" align="center">
            Select device to generate report
          </Typography>
        </DialogTitle>
        <div className="s10"></div>
        {isLoading ? (
          <Loading top={"100px"} bottom={"100px"} />
        ) : (
          <>
            <DialogContent>
              <TableContainer>
                <DeviceTable
                  devices={devices}
                  handleDeviceSelect={handleDeviceSelect}
                />
              </TableContainer>
            </DialogContent>
            <div className="s20"></div>
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "flex-start",
                }}
              >
                <Button
                  style={{
                    color: "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handleClose}
                >
                  <u>Cancel</u>
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    background: deviceSelected.isSelected
                      ? "#3DB5E6"
                      : "#E0E0E0",
                    color: deviceSelected.isSelected ? "#FFFFFF" : "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handleFirstEligibilityCheck}
                  color="primary"
                  disabled={!deviceSelected.isSelected}
                >
                  Next
                </Button>
              </Box>
              <Box style={{ width: "8px" }} />
            </DialogActions>
            <Space height={"15px"} />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default SelectDeviceDialog;
