import { Grid, Typography } from "@material-ui/core";
import { styles } from "../PageMain";

const Header = ({ reportDetails, DefaultCompanyLogo, companyInfo }) => {
  const companyAddressReturn = () => {
    if (companyInfo === undefined) {
      return <Typography style={styles.companyAddressText}>N/A</Typography>;
    } else {
      return (
        <>
          {companyInfo.companyName && (
            <Typography style={styles.companyAddressText}>
              {companyInfo.companyName}
            </Typography>
          )}
          {companyInfo.address.street && (
            <Typography style={styles.companyAddressText}>
              {companyInfo.address.street}
              {companyInfo.address.detail
                ? " " + companyInfo.address.detail
                : ""}
            </Typography>
          )}
          <Typography style={styles.companyAddressText}>
            {companyInfo.address.city +
              " " +
              companyInfo.address.state +
              " " +
              companyInfo.address.zipcode}
          </Typography>
        </>
      );
    }
  };

  return (
    <Grid
      style={{
        marginBottom: "30px",
        flexDirection: "row",
      }}
      container
    >
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
        }}
      >
        <img
          style={{
            objectFit: "scale-down",
            width: "160px",
            height: "60px",
          }}
          crossOrigin="anonymous"
          src={
            reportDetails.company_logo_url !== "" && reportDetails.company_logo_url !== "N/A"
              ? reportDetails.company_logo_url
              : DefaultCompanyLogo
          }
          alt=""
        />
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          paddingLeft: "3.5rem",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "160px",
            height: "60px",
          }}
        >
          <Typography style={styles.reportItemCompany}>
            {companyAddressReturn()}
          </Typography>
        </div>
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "end",
          flexDirection: "column",
        }}
      >
        <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
          Radon Measurement {reportDetails.measurement_duration === "48 Hours" ? "Report" : "Review" }
        </Typography>
        <Typography>Prepared by Ecosense Lab</Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
