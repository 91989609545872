export const apiEndPoint = "https://dev-api.cloud.ecosense.io/api/v1";
export const accessUrl = "https://dev.dashboard.ecosense.io";
export const awsXApiKey = "vgIHAUMHvw44Ery0fJlJv6tjNa3OHmB23cbndLcs";
export const stripeKey = "pk_test_51IZMIZI37b7asQ3bVAMOHfxBlFPhUxoBPwlAk4wIsTwpJJO8cAvhsJsA3vq5gYjbWOIJlIxmLmb5dwAXoVteHkRg007UDFsqvQ";
export const awsAmplifyAuth = {
  region: 'us-east-1',
  userPoolId: 'us-east-1_cysufm7cy',
  identityPoolId: 'us-east-1:245c8e9f-637c-4399-ae64-285df3a9e8e4',
  userPoolWebClientId: '38tbptbgmkoqsb6chbq0qduthb',
};
// just comment to make push work
