import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { getCompanyInfo } from "../../../models/services/authService";

import DefaultCompanyLogo from "../../../assets/img/ic_company.png";
import Header from "./Header";
import Details from "./Details";
import Footer from "./Footer";
import HistoricalTrend from "./HistoricalTrend";
import Comments from "./Comments";
import _ from "lodash";

export const styles = {
  reportItemTitle: {
    color: "black",
    fontSize: "11px",
    fontWeight: 500,
    textAlign: "left",
    fontStyle: "italic",
  },

  reportItemCompany: {
    color: "black",
    fontSize: "12px",
    fontWeight: 350,
    textAlign: "left",
    whiteSpace: "pre-line",
  },

  reportItemContent: {
    marginTop: 5,
    color: "black",
    fontSize: "10px",
    fontWeight: 500,
    textAlign: "left",
    whiteSpace: "pre-line",
    wordWrap: "break-word",
  },

  reportItemText: {
    color: "black",
    fontSize: "11px",
    fontWeight: 500,
  },

  companyAddressText: {
    color: "black",
    fontSize: "12px",
  },

  contentText: {
    color: "black",
    fontSize: "10px",
    fontWeight: 500,
    marginLeft: 5,
  },

  titleTopText: {
    color: "black",
    fontSize: "10px",
    fontWeight: 300,
    marginTop: 5,
    textAlign: "left",
  },

  titleText: {
    color: "black",
    fontSize: "11px",
    fontWeight: 300,
    textAlign: "left",
  },

  report_footer: {
    marginTop: "10px",
    fontWeight: 300,
    fontSize: "10px",
    color: "#585858",
  },
};

const PageMain = ({ reportDetails, reportChartRadonValue, isForPDF }) => {
  const [user, setUser] = useState();
  const [companyInfo, setCompanyInfo] = useState();

  useEffect(() => {
    const getUser = async () => {
      try {
        const owner = reportDetails.test_by;
        const user = await Auth.currentAuthenticatedUser();

        if(owner && Object.keys(owner).length > 0) {
          // "ownerDisplay":"ownIt@gmail.com","name":"Jon","certNo":"CRT#123"
          setUser({
            name: owner.name,
            email: owner.ownerDisplay ? owner.ownerDisplay : user.attributes["email"],
            certNo: owner.certNo });
        } else {
          if(user) {
            setUser({
              name: user.attributes.name,
              email: _.get(user.attributes, "custom:ownerDisplay") ?? user.attributes["email"],
              certNo: user.attributes["custom:certNo"] });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUser();

    const getCompany = async () => {
      const trimmedEmail = reportDetails.email.trim().toLowerCase();

      const companyInfo = (await getCompanyInfo(trimmedEmail)).data;
      setCompanyInfo(companyInfo);
    };

    getCompany();
  }, [reportDetails]);

  return (
    <Grid
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
      container
    >
      <Header
        reportDetails={reportDetails}
        DefaultCompanyLogo={DefaultCompanyLogo}
        companyInfo={companyInfo}
      />
      <Details user={user} reportDetails={reportDetails} />
      <HistoricalTrend
        reportDetails={reportDetails}
        reportChartRadonValue={reportChartRadonValue}
        isForPDF={isForPDF}
      />
      <Comments reportDetails={reportDetails} />
      <Footer />
    </Grid>
  );
};

export default PageMain;
