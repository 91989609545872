export const radonMapInfoFromServer = [
  {lat: 53.447438411693334, lng: -113.61323751403668, radonNumber: 66, serialNumber: 'EK11EQ001129'},
  {lat: 53.2485597, lng: -113.5233818, radonNumber: 49, serialNumber: 'EK11EQ001559'},
  {lat: 50.31047860812207, lng: -119.21419069017992, radonNumber: 12, serialNumber: 'EK11EQ001162'},
  {lat: 49.9264706, lng: -119.4617736, radonNumber: 213, serialNumber: 'FB25EQ001764'},
  {lat: 49.23485080439707, lng: -124.01123952055379, radonNumber: 0, serialNumber: 'EI14EQ000049'},
  {lat: 48.5134012, lng: -117.8731999, radonNumber: 61, serialNumber: 'FA20EQ000397'},
  {lat: 47.8089226, lng: -116.8885491, radonNumber: 95, serialNumber: 'EJ23EQ000775'},
  {lat: 47.7302436, lng: -117.4338556, radonNumber: 20, serialNumber: 'EK11EQ001142'},
  {lat: 47.6944948, lng: -117.2602745, radonNumber: 24, serialNumber: 'FA28EQ000607'},
  {lat: 47.621590455917485, lng: -117.09550240732186, radonNumber: 11, serialNumber: 'EJ28EQ000910'},
  {lat: 47.5701741, lng: -117.5392709, radonNumber: 23, serialNumber: 'FA19EQ000022'},
  {lat: 47.1465522004002, lng: -122.56076820928057, radonNumber: 30, serialNumber: 'EJ22EQ000508'},
  {lat: 46.8916721, lng: -114.1582329, radonNumber: 60, serialNumber: 'EJ23EQ000688'},
  {lat: 46.8897967, lng: -114.0716089, radonNumber: 129, serialNumber: 'EJ28EQ000917'},
  {lat: 46.8739187, lng: -113.9759336, radonNumber: 21, serialNumber: 'EJ23EQ000800'},
  {lat: 46.871436, lng: -71.2769335, radonNumber: 27, serialNumber: 'EK11EQ001382'},
  {lat: 46.7779787, lng: -95.7986743, radonNumber: 104, serialNumber: 'FA29EQ000860'},
  {lat: 46.718529, lng: -117.0093895, radonNumber: 44, serialNumber: 'FC23EQ002825'},
  {lat: 46.46544035354226, lng: 6.078604062401551, radonNumber: 276, serialNumber: 'FA29EQ001063'},
  {lat: 46.465438949936505, lng: 6.078612191868092, radonNumber: 299, serialNumber: 'FA29EQ001047'},
  {lat: 46.46543729702254, lng: 6.0786450008414485, radonNumber: 0, serialNumber: 'FA29EQ001061'},
  {lat: 46.465437072820436, lng: 6.078623235766218, radonNumber: 47, serialNumber: 'FA29EQ001054'},
  {lat: 46.3200399, lng: -64.5806758, radonNumber: 740, serialNumber: 'EK11EQ001127'},
  {lat: 45.8301460280966, lng: -108.46009435604745, radonNumber: 18, serialNumber: 'FB25EQ001756'},
  {lat: 45.57834989523476, lng: -122.70260786980946, radonNumber: 107, serialNumber: 'EK11EQ001594'},
  {lat: 45.48484421030491, lng: -75.47889329622627, radonNumber: 23, serialNumber: 'EK11EQ001378'},
  {lat: 45.4559343, lng: -94.437328, radonNumber: 148, serialNumber: 'EK11EQ001480'},
  {lat: 45.445683546219264, lng: -122.2780716069675, radonNumber: 13, serialNumber: 'FA20EQ000372'},
  {lat: 45.43451303661252, lng: -73.6966317227296, radonNumber: 13, serialNumber: 'EK11EQ001115'},
  {lat: 45.15400374150239, lng: -93.47734464572814, radonNumber: 121, serialNumber: 'FA19EQ000079'},
  {lat: 45.080816133825195, lng: -93.21221188810804, radonNumber: 29, serialNumber: 'FA20EQ000246'},
  {lat: 44.984135529999605, lng: -70.74060109450551, radonNumber: 7, serialNumber: 'FB24EQ001593'},
  {lat: 44.9780587, lng: -123.0298153, radonNumber: 17, serialNumber: 'EJ22EQ000491'},
  {lat: 44.9253784, lng: -93.2392913, radonNumber: 17, serialNumber: 'EJ22EQ000525'},
  {lat: 44.8852761, lng: -73.4357427, radonNumber: 95, serialNumber: 'EK11EQ001534'},
  {lat: 44.842167, lng: -93.515784, radonNumber: 19, serialNumber: 'FA29EQ000861'},
  {lat: 44.80062958568813, lng: -93.37461379611699, radonNumber: 134, serialNumber: 'EK11EQ001169'},
  {lat: 44.795898994553625, lng: -72.22584148175505, radonNumber: 22, serialNumber: 'EK11EQ001569'},
  {lat: 44.7047847, lng: -63.4719105, radonNumber: 15, serialNumber: 'EK11EQ001554'},
  {lat: 44.5011685, lng: -92.9032781, radonNumber: 78, serialNumber: 'FA20EQ000302'},
  {lat: 44.45487213000085, lng: -67.87891510204014, radonNumber: 24, serialNumber: 'EK11EQ001575'},
  {lat: 44.27560143227913, lng: -88.34257748492304, radonNumber: 22, serialNumber: 'EK11EQ001464'},
  {lat: 44.01854673670165, lng: -70.6598836592055, radonNumber: 49, serialNumber: 'FA19EQ000036'},
  {lat: 43.86549462301675, lng: -95.11647975189969, radonNumber: 87, serialNumber: 'FA29EQ000851'},
  {lat: 43.73389667017943, lng: -79.39351834787954, radonNumber: 10, serialNumber: 'FB25EQ001769'},
  {lat: 43.73389500197371, lng: -79.39351089306119, radonNumber: 17, serialNumber: 'FA20EQ000339'},
  {lat: 43.73388662913134, lng: -79.39351591727593, radonNumber: 17, serialNumber: 'FA29EQ001060'},
  {lat: 43.7286121213044, lng: -111.08999795370187, radonNumber: 16, serialNumber: 'FB24EQ001633'},
  {lat: 43.64142741714455, lng: -72.24444053529699, radonNumber: 44, serialNumber: 'EJ23EQ000776'},
  {lat: 43.6151404, lng: -79.5163383, radonNumber: 12, serialNumber: 'FA29EQ000940'},
  {lat: 43.54986071159539, lng: -90.89642956887647, radonNumber: 27, serialNumber: 'EK11EQ001461'},
  {lat: 43.5373849, lng: -71.1409924, radonNumber: 53, serialNumber: 'FA19EQ000032'},
  {lat: 43.53516259234728, lng: -110.73556361730279, radonNumber: 52, serialNumber: 'EJ22EQ000563'},
  {lat: 43.50638873080326, lng: -114.31082478272086, radonNumber: 536, serialNumber: 'FB25EQ001744'},
  {lat: 43.4910171, lng: -80.5858864, radonNumber: 20, serialNumber: 'EJ23EQ000723'},
  {lat: 43.4530354, lng: -97.3604139, radonNumber: 134, serialNumber: 'EJ23EQ000782'},
  {lat: 43.43423849956213, lng: -96.15699680718278, radonNumber: 25, serialNumber: 'FA20EQ000310'},
  {lat: 43.3853015, lng: -80.4200063, radonNumber: 22, serialNumber: 'EJ23EQ000735'},
  {lat: 43.13741518896198, lng: -88.40010426682781, radonNumber: 8, serialNumber: 'FA20EQ000556'},
  {lat: 43.0576882, lng: -89.3104957, radonNumber: 10, serialNumber: 'EJ23EQ000833'},
  {lat: 43.0307046, lng: -70.772113, radonNumber: 7, serialNumber: 'FA19EQ000027'},
  {lat: 43.0115155, lng: -89.2917855, radonNumber: 7, serialNumber: 'FB26EQ002002'},
  {lat: 43.011046135419804, lng: -70.8125344297573, radonNumber: 177, serialNumber: 'FA20EQ000322'},
  {lat: 42.9974122, lng: -87.9921911, radonNumber: 118, serialNumber: 'FA29EQ001038'},
  {lat: 42.9696454, lng: -87.9069408, radonNumber: 14, serialNumber: 'FA28EQ000565'},
  {lat: 42.9670545, lng: -90.4281934, radonNumber: 11, serialNumber: 'EJ23EQ000835'},
  {lat: 42.9525767, lng: -88.1524957, radonNumber: 33, serialNumber: 'FA20EQ000444'},
  {lat: 42.93145195974995, lng: -74.62641973808567, radonNumber: 13, serialNumber: 'FB23EQ001276'},
  {lat: 42.9001326, lng: -74.5705099, radonNumber: 22, serialNumber: 'EJ22EQ000667'},
  {lat: 42.7464, lng: -71.4512663, radonNumber: 21, serialNumber: 'EK11EQ001406'},
  {lat: 42.7212017, lng: -71.4815505, radonNumber: 179, serialNumber: 'FB26EQ002076'},
  {lat: 42.67766787516722, lng: -71.90616626695258, radonNumber: 76, serialNumber: 'FB23EQ001231'},
  {lat: 42.5799586, lng: -83.3384402, radonNumber: 51, serialNumber: 'FB23EQ001267'},
  {lat: 42.5424787, lng: -83.088954, radonNumber: 7, serialNumber: 'EJ28EQ000984'},
  {lat: 42.5395561, lng: -83.6249855, radonNumber: 33, serialNumber: 'EK11EQ001540'},
  {lat: 42.52422873997971, lng: -71.43663302346265, radonNumber: 20, serialNumber: 'EJ23EQ000778'},
  {lat: 42.5163922, lng: -73.7543264, radonNumber: 21, serialNumber: 'EK11EQ001502'},
  {lat: 42.45486364238192, lng: -83.49097383216348, radonNumber: 16, serialNumber: 'EJ22EQ000597'},
  {lat: 42.45433539684993, lng: -72.73644047767192, radonNumber: 7, serialNumber: 'FC02EQ002165'},
  {lat: 42.453036268565754, lng: -83.87651701672958, radonNumber: 43, serialNumber: 'FB26EQ002007'},
  {lat: 42.442446, lng: -71.0559999, radonNumber: 154, serialNumber: 'FA20EQ000378'},
  {lat: 42.4021559, lng: -73.7766983, radonNumber: 11, serialNumber: 'FA20EQ000333'},
  {lat: 42.3880406, lng: -83.4259051, radonNumber: 39, serialNumber: 'EK11EQ001458'},
  {lat: 42.379190576887744, lng: -83.47971780760587, radonNumber: 35, serialNumber: 'EK11EQ001547'},
  {lat: 42.37083742448092, lng: -71.45410008435726, radonNumber: 41, serialNumber: 'EJ22EQ000580'},
  {lat: 42.340653571806186, lng: -83.4652834295295, radonNumber: 53, serialNumber: 'FA29EQ000841'},
  {lat: 42.3053599, lng: -83.7908551, radonNumber: 26, serialNumber: 'FB23EQ001342'},
  {lat: 42.3035426, lng: -71.6641379, radonNumber: 16, serialNumber: 'FB23EQ001229'},
  {lat: 42.2995876, lng: -88.1737429, radonNumber: 178, serialNumber: 'EJ23EQ000831'},
  {lat: 42.27239691524634, lng: -71.20666325756491, radonNumber: 18, serialNumber: 'EK11EQ001151'},
  {lat: 42.242902713964305, lng: -87.91405125022557, radonNumber: 26, serialNumber: 'FA29EQ000846'},
  {lat: 42.2176591, lng: -87.9419981, radonNumber: 33, serialNumber: 'FA29EQ001079'},
  {lat: 42.2115725, lng: -87.8213009, radonNumber: 250, serialNumber: 'FA28EQ000839'},
  {lat: 42.2073348, lng: -83.1685809, radonNumber: 84, serialNumber: 'FB26EQ002017'},
  {lat: 42.18420637848414, lng: -88.04168497448207, radonNumber: 40, serialNumber: 'FA28EQ000619'},
  {lat: 42.17774333745436, lng: -88.38723811181924, radonNumber: 7, serialNumber: 'EJ23EQ000830'},
  {lat: 42.168839, lng: -87.8356708, radonNumber: 104, serialNumber: 'EK11EQ001413'},
  {lat: 42.16688709331215, lng: -83.80091725433113, radonNumber: 35, serialNumber: 'FB24EQ001493'},
  {lat: 42.140678561418376, lng: -88.03089725013248, radonNumber: 216, serialNumber: 'FA20EQ000369'},
  {lat: 42.122222, lng: -87.966657, radonNumber: 76, serialNumber: 'FB25EQ001819'},
  {lat: 42.056930425272036, lng: -80.0964640999664, radonNumber: 7, serialNumber: 'FB24EQ001636'},
  {lat: 42.00865793901852, lng: -73.87059669738346, radonNumber: 14, serialNumber: 'FA20EQ000459'},
  {lat: 41.95923718162063, lng: -70.68226782611634, radonNumber: 7, serialNumber: 'FB24EQ001719'},
  {lat: 41.94191011138441, lng: -87.92522784330689, radonNumber: 12, serialNumber: 'EK11EQ001143'},
  {lat: 41.8950755, lng: -87.8097187, radonNumber: 75, serialNumber: 'EJ28EQ000960'},
  {lat: 41.8887484, lng: -88.3047079, radonNumber: 31, serialNumber: 'FB25EQ001837'},
  {lat: 41.8627928, lng: -72.6600361, radonNumber: 36, serialNumber: 'FA20EQ000368'},
  {lat: 41.8549672, lng: -93.9250193, radonNumber: 33, serialNumber: 'EK11EQ001040'},
  {lat: 41.82179363509401, lng: -88.21158129076633, radonNumber: 18, serialNumber: 'FA20EQ000253'},
  {lat: 41.82028781626543, lng: -72.8433764354073, radonNumber: 33, serialNumber: 'FA20EQ000425'},
  {lat: 41.81670895315876, lng: -87.70083306365431, radonNumber: 39, serialNumber: 'FA29EQ001071'},
  {lat: 41.78565360748212, lng: -87.96809700241997, radonNumber: 392, serialNumber: 'EJ23EQ000834'},
  {lat: 41.7772216, lng: -71.4805582, radonNumber: 92, serialNumber: 'EJ23EQ000860'},
  {lat: 41.7328099, lng: -91.1500742, radonNumber: 726, serialNumber: 'FB23EQ001259'},
  {lat: 41.73222940556217, lng: -87.95408368329623, radonNumber: 18, serialNumber: 'FA20EQ000358'},
  {lat: 41.73089020889414, lng: -72.55713083788059, radonNumber: 64, serialNumber: 'FB23EQ001336'},
  {lat: 41.72790308910803, lng: -88.23376782765973, radonNumber: 82, serialNumber: 'FA29EQ001022'},
  {lat: 41.7195218742575, lng: -87.9297936658769, radonNumber: 16, serialNumber: 'FA19EQ000203'},
  {lat: 41.6965271, lng: -88.2223287, radonNumber: 183, serialNumber: 'EJ28EQ000947'},
  {lat: 41.68929390630684, lng: -80.96218671132104, radonNumber: 8, serialNumber: 'FA20EQ000449'},
  {lat: 41.664688444501245, lng: -72.74290496443746, radonNumber: 21, serialNumber: 'FA20EQ000475'},
  {lat: 41.6462028, lng: -93.7322901, radonNumber: 65, serialNumber: 'FA28EQ000777'},
  {lat: 41.6273855, lng: -73.4181493, radonNumber: 173, serialNumber: 'FB23EQ001335'},
  {lat: 41.61140256099538, lng: -73.96934891768898, radonNumber: 22, serialNumber: 'FB24EQ001464'},
  {lat: 41.6107482, lng: -73.9757089, radonNumber: 0, serialNumber: 'FA19EQ000109'},
  {lat: 41.6016788913941, lng: -72.52236343321108, radonNumber: 197, serialNumber: 'EJ28EQ000897'},
  {lat: 41.579842986062545, lng: -87.97953539640555, radonNumber: 36, serialNumber: 'EJ23EQ000814'},
  {lat: 41.566059, lng: -88.1172519, radonNumber: 47, serialNumber: 'EJ28EQ000951'},
  {lat: 41.5308356, lng: -72.7192646, radonNumber: 18, serialNumber: 'EK11EQ001443'},
  {lat: 41.522821119081414, lng: -72.61853078473905, radonNumber: 7, serialNumber: 'FC02EQ002170'},
  {lat: 41.47534544092, lng: -75.6936499309165, radonNumber: 178, serialNumber: 'FC17EQ002318'},
  {lat: 41.42863727948048, lng: -81.48433374328252, radonNumber: 28, serialNumber: 'EJ28EQ000891'},
  {lat: 41.4219555, lng: -87.9978283, radonNumber: 25, serialNumber: 'FB24EQ001698'},
  {lat: 41.362505, lng: -75.2737297, radonNumber: 187, serialNumber: 'EK11EQ001427'},
  {lat: 41.32751737325169, lng: -72.65908616806747, radonNumber: 527, serialNumber: 'FA28EQ000727'},
  {lat: 41.27815152600302, lng: -73.2657625580771, radonNumber: 22, serialNumber: 'FA19EQ000137'},
  {lat: 41.26150322107732, lng: -72.79567914338307, radonNumber: 316, serialNumber: 'FB24EQ001468'},
  {lat: 41.2604375, lng: -80.4723707, radonNumber: 49, serialNumber: 'FA20EQ000511'},
  {lat: 41.25375142896927, lng: -96.24581243192421, radonNumber: 45, serialNumber: 'FA29EQ001031'},
  {lat: 41.2078935, lng: -74.6810286, radonNumber: 18, serialNumber: 'FB25EQ001940'},
  {lat: 41.2059344, lng: -96.1088272, radonNumber: 133, serialNumber: 'FC23EQ002834'},
  {lat: 41.19966732492077, lng: -73.35012098371325, radonNumber: 96, serialNumber: 'FA28EQ000722'},
  {lat: 41.18305543045811, lng: -81.84863904453373, radonNumber: 42, serialNumber: 'EJ28EQ000971'},
  {lat: 41.16879735647453, lng: -73.4540915414871, radonNumber: 16, serialNumber: 'EJ28EQ000988'},
  {lat: 41.16500469820275, lng: -74.0254501666518, radonNumber: 18, serialNumber: 'EJ22EQ000577'},
  {lat: 41.1586143, lng: -73.3216792, radonNumber: 171, serialNumber: 'FB25EQ001774'},
  {lat: 41.0552702, lng: -80.6610392, radonNumber: 20, serialNumber: 'EJ28EQ000963'},
  {lat: 41.0512775, lng: -88.1008592, radonNumber: 164, serialNumber: 'FB24EQ001669'},
  {lat: 40.9904348, lng: -74.1429897, radonNumber: 7, serialNumber: 'EJ23EQ000792'},
  {lat: 40.97843075529853, lng: -74.12647439454196, radonNumber: 13, serialNumber: 'FC23EQ002826'},
  {lat: 40.915745652817996, lng: -73.94956317490387, radonNumber: 63, serialNumber: 'FA20EQ000420'},
  {lat: 40.8970433, lng: -90.2860703, radonNumber: 170, serialNumber: 'EK11EQ001477'},
  {lat: 40.8853279, lng: -81.5516404, radonNumber: 53, serialNumber: 'FB26EQ002008'},
  {lat: 40.8560604, lng: -74.2015551, radonNumber: 17, serialNumber: 'FA20EQ000341'},
  {lat: 40.83732800901943, lng: -73.98710574597624, radonNumber: 35, serialNumber: 'FA20EQ000473'},
  {lat: 40.8043042, lng: -73.3335787, radonNumber: 20, serialNumber: 'FB24EQ001653'},
  {lat: 40.7994384, lng: -89.1982056, radonNumber: 81, serialNumber: 'FA20EQ000515'},
  {lat: 40.798124639025616, lng: -73.18305935326528, radonNumber: 15, serialNumber: 'EJ22EQ000646'},
  {lat: 40.7719601, lng: -96.6470693, radonNumber: 79, serialNumber: 'FA20EQ000321'},
  {lat: 40.6643516, lng: -111.867556, radonNumber: 16, serialNumber: 'EJ22EQ000609'},
  {lat: 40.6639965, lng: -111.5756674, radonNumber: 47, serialNumber: 'EJ23EQ000702'},
  {lat: 40.63844550404757, lng: -74.85597884289442, radonNumber: 39, serialNumber: 'EJ22EQ000604'},
  {lat: 40.60384970407138, lng: -88.98643554526755, radonNumber: 8, serialNumber: 'FA29EQ001080'},
  {lat: 40.56237851929001, lng: -105.05386934155702, radonNumber: 13, serialNumber: 'FA20EQ000356'},
  {lat: 40.557825918781305, lng: -111.99435663891126, radonNumber: 78, serialNumber: 'FA20EQ000392'},
  {lat: 40.55063688852811, lng: -75.49914310369981, radonNumber: 71, serialNumber: 'FA19EQ000185'},
  {lat: 40.5391751, lng: -111.8283032, radonNumber: 72, serialNumber: 'EJ28EQ000924'},
  {lat: 40.515664050229844, lng: -89.01527283451817, radonNumber: 19, serialNumber: 'FB24EQ001573'},
  {lat: 40.47030662109609, lng: -104.91524520930544, radonNumber: 46, serialNumber: 'EK11EQ001090'},
  {lat: 40.43997234953005, lng: -86.89853880361368, radonNumber: 32, serialNumber: 'FB23EQ001243'},
  {lat: 40.4213677, lng: -79.9426647, radonNumber: 108, serialNumber: 'FA19EQ000067'},
  {lat: 40.3547378444255, lng: -79.65000919302558, radonNumber: 41, serialNumber: 'FB25EQ001760'},
  {lat: 40.35470183830374, lng: -79.64994142721892, radonNumber: 44, serialNumber: 'FA29EQ001150'},
  {lat: 40.35470182088419, lng: -79.64994899047454, radonNumber: 183, serialNumber: 'FA29EQ001162'},
  {lat: 40.354701323077094, lng: -79.64995234642139, radonNumber: 373, serialNumber: 'FA29EQ001146'},
  {lat: 40.354673044112396, lng: -79.64973420772294, radonNumber: 10, serialNumber: 'FB25EQ001751'},
  {lat: 40.34479489310986, lng: -74.70510464911168, radonNumber: 10, serialNumber: 'EK11EQ001483'},
  {lat: 40.3393934, lng: -104.9221622, radonNumber: 159, serialNumber: 'EK11EQ001401'},
  {lat: 40.33807884202284, lng: -76.84391684612002, radonNumber: 9, serialNumber: 'FA19EQ000114'},
  {lat: 40.314787, lng: -79.9673267, radonNumber: 9, serialNumber: 'EK11EQ001258'},
  {lat: 40.2886938, lng: -96.827247, radonNumber: 66, serialNumber: 'FA28EQ000760'},
  {lat: 40.274075163891844, lng: -74.88608802056538, radonNumber: 27, serialNumber: 'FB26EQ002035'},
  {lat: 40.2496752, lng: -75.5470296, radonNumber: 199, serialNumber: 'FA29EQ000932'},
  {lat: 40.24823970873696, lng: -74.35666676397041, radonNumber: 116, serialNumber: 'FB24EQ001444'},
  {lat: 40.2026281, lng: -76.0322666, radonNumber: 52, serialNumber: 'FA20EQ000424'},
  {lat: 40.188050993615946, lng: -75.06518845139676, radonNumber: 7, serialNumber: 'FA20EQ000510'},
  {lat: 40.13015909284261, lng: -76.21092246581668, radonNumber: 848, serialNumber: 'FB24EQ001606'},
  {lat: 40.11198925643912, lng: -105.44743804712907, radonNumber: 14, serialNumber: 'EJ23EQ000686'},
  {lat: 40.11027667516141, lng: -76.07455732778857, radonNumber: 16, serialNumber: 'FA19EQ000180'},
  {lat: 40.10877519671134, lng: -82.89256229398167, radonNumber: 118, serialNumber: 'FA20EQ000433'},
  {lat: 40.102245979030926, lng: -75.12210723957203, radonNumber: 47, serialNumber: 'EJ22EQ000605'},
  {lat: 40.1001426, lng: -83.0491919, radonNumber: 41, serialNumber: 'FA20EQ000531'},
  {lat: 40.0674119, lng: -75.4235661, radonNumber: 162, serialNumber: 'EJ22EQ000650'},
  {lat: 40.0536404, lng: -83.0245988, radonNumber: 68, serialNumber: 'FA29EQ000985'},
  {lat: 40.0523373, lng: -82.724767, radonNumber: 12, serialNumber: 'FB26EQ001990'},
  {lat: 40.049099863304306, lng: -105.34410217763491, radonNumber: 21, serialNumber: 'FB23EQ001226'},
  {lat: 40.03399714399319, lng: -75.50974781099698, radonNumber: 509, serialNumber: 'FA29EQ000921'},
  {lat: 40.003754480468466, lng: -83.17726712639016, radonNumber: 533, serialNumber: 'EK11EQ001157'},
  {lat: 39.996119385883425, lng: -105.08893062519618, radonNumber: 0, serialNumber: 'FA20EQ000373'},
  {lat: 39.98210074508461, lng: -105.15652160967136, radonNumber: 11, serialNumber: 'EJ23EQ000704'},
  {lat: 39.97040391070941, lng: -75.12852409062901, radonNumber: 37, serialNumber: 'FA20EQ000436'},
  {lat: 39.968357163876625, lng: -82.91478396984215, radonNumber: 40, serialNumber: 'FB26EQ002001'},
  {lat: 39.9422955, lng: -81.6374394, radonNumber: 93, serialNumber: 'EK11EQ001193'},
  {lat: 39.9317912, lng: -84.0277062, radonNumber: 281, serialNumber: 'EK11EQ001253'},
  {lat: 39.8776595, lng: -105.0494983, radonNumber: 116, serialNumber: 'FC02EQ002159'},
  {lat: 39.8100023, lng: -82.6110645, radonNumber: 43, serialNumber: 'EJ28EQ000996'},
  {lat: 39.80525653839916, lng: -105.22224774826897, radonNumber: 11, serialNumber: 'FA20EQ000382'},
  {lat: 39.79122288087302, lng: -104.89059076748445, radonNumber: 242, serialNumber: 'FB25EQ001758'},
  {lat: 39.7527108, lng: -105.2176691, radonNumber: 8, serialNumber: 'EJ23EQ000790'},
  {lat: 39.7404283, lng: -105.1933841, radonNumber: 133, serialNumber: 'FA28EQ000611'},
  {lat: 39.71733431801174, lng: -77.79439933605286, radonNumber: 9, serialNumber: 'FB24EQ001624'},
  {lat: 39.69180639371247, lng: -75.28445863171973, radonNumber: 92, serialNumber: 'FB25EQ001927'},
  {lat: 39.6890276, lng: -105.0568054, radonNumber: 63, serialNumber: 'FA20EQ000308'},
  {lat: 39.64595685545103, lng: -75.68951143241436, radonNumber: 86, serialNumber: 'FB24EQ001599'},
  {lat: 39.6451862, lng: -77.6011179, radonNumber: 123, serialNumber: 'EJ22EQ000521'},
  {lat: 39.63657871673393, lng: -105.1326813276267, radonNumber: 19, serialNumber: 'FA28EQ000627'},
  {lat: 39.6325211, lng: -104.9876033, radonNumber: 29, serialNumber: 'FA29EQ001048'},
  {lat: 39.6143188, lng: -104.7870143, radonNumber: 36, serialNumber: 'FA28EQ000642'},
  {lat: 39.61401210635863, lng: -104.92661688486568, radonNumber: 13, serialNumber: 'FB25EQ001891'},
  {lat: 39.5941399, lng: -104.9837187, radonNumber: 28, serialNumber: 'FB24EQ001442'},
  {lat: 39.583283001593855, lng: -105.33885053368782, radonNumber: 161, serialNumber: 'FB24EQ001655'},
  {lat: 39.5561172, lng: -75.7716198, radonNumber: 66, serialNumber: 'FB24EQ001629'},
  {lat: 39.52496803245392, lng: -105.01581885312413, radonNumber: 88, serialNumber: 'FB23EQ001241'},
  {lat: 39.49159004553224, lng: -119.87513621947824, radonNumber: 35, serialNumber: 'EJ22EQ000512'},
  {lat: 39.40692147724268, lng: -80.16080623918847, radonNumber: 87, serialNumber: 'FA20EQ000363'},
  {lat: 39.3852459, lng: -77.370095, radonNumber: 10, serialNumber: 'FB24EQ001594'},
  {lat: 39.35230346402552, lng: -84.20299121827965, radonNumber: 60, serialNumber: 'FA19EQ000216'},
  {lat: 39.335839200724955, lng: -84.43167361145231, radonNumber: 269, serialNumber: 'FA20EQ000506'},
  {lat: 39.2980998, lng: -76.8579383, radonNumber: 11, serialNumber: 'EK11EQ001433'},
  {lat: 39.2331214, lng: -76.8892744, radonNumber: 33, serialNumber: 'EJ22EQ000601'},
  {lat: 39.21772068891912, lng: -76.81042186965169, radonNumber: 19, serialNumber: 'EK11EQ001448'},
  {lat: 39.129794, lng: -77.798462, radonNumber: 197, serialNumber: 'EJ13EQ000103'},
  {lat: 39.1069023, lng: -84.3261027, radonNumber: 73, serialNumber: 'EK11EQ001265'},
  {lat: 39.0994587, lng: -77.0121545, radonNumber: 7, serialNumber: 'FB25EQ001942'},
  {lat: 39.0927191, lng: -80.0859345, radonNumber: 35, serialNumber: 'FA20EQ000437'},
  {lat: 39.0759288, lng: -76.6912118, radonNumber: 107, serialNumber: 'FB24EQ001699'},
  {lat: 39.04374381250318, lng: -77.21788739960321, radonNumber: 7, serialNumber: 'FB24EQ001591'},
  {lat: 39.01270834368193, lng: -94.65472640079477, radonNumber: 73, serialNumber: 'EK11EQ001245'},
  {lat: 39.01259161725879, lng: -77.13729440922947, radonNumber: 0, serialNumber: 'FA19EQ000148'},
  {lat: 38.986011, lng: -76.9931366, radonNumber: 15, serialNumber: 'FA19EQ000140'},
  {lat: 38.9826678, lng: -104.7374399, radonNumber: 17, serialNumber: 'EJ22EQ000543'},
  {lat: 38.979307901445765, lng: -104.60465728344761, radonNumber: 105, serialNumber: 'FB25EQ001960'},
  {lat: 38.95524992145501, lng: -77.56019982516538, radonNumber: 63, serialNumber: 'EK11EQ001089'},
  {lat: 38.9475024, lng: -104.8794086, radonNumber: 28, serialNumber: 'FC02EQ002166'},
  {lat: 38.92844798401126, lng: -79.85529279859807, radonNumber: 30, serialNumber: 'FA29EQ000916'},
  {lat: 38.918897377718835, lng: -84.65943032375655, radonNumber: 24, serialNumber: 'FC02EQ002107'},
  {lat: 38.9104122, lng: -77.1418673, radonNumber: 7, serialNumber: 'FA29EQ000886'},
  {lat: 38.89872984397658, lng: -77.14218421300147, radonNumber: 81, serialNumber: 'FA29EQ000994'},
  {lat: 38.89401949816676, lng: -77.17193899773957, radonNumber: 29, serialNumber: 'FC02EQ002100'},
  {lat: 38.87442053696597, lng: -99.2866679079571, radonNumber: 27, serialNumber: 'EK11EQ001228'},
  {lat: 38.8704811202427, lng: -105.25649787601225, radonNumber: 9, serialNumber: 'FA20EQ000348'},
  {lat: 38.7709667, lng: -104.8329926, radonNumber: 360, serialNumber: 'FB25EQ001962'},
  {lat: 38.7709445, lng: -104.832994, radonNumber: 7, serialNumber: 'FB24EQ001495'},
  {lat: 38.76235890205834, lng: -76.65331201634989, radonNumber: 36, serialNumber: 'FB25EQ001899'},
  {lat: 38.6888088, lng: -104.6953699, radonNumber: 343, serialNumber: 'FB25EQ001798'},
  {lat: 38.612562820251135, lng: -90.63903031763782, radonNumber: 18, serialNumber: 'FA20EQ000318'},
  {lat: 38.5786892, lng: -90.6092806, radonNumber: 38, serialNumber: 'EK11EQ001150'},
  {lat: 38.56232046093135, lng: -105.99755650047784, radonNumber: 76, serialNumber: 'EJ22EQ000539'},
  {lat: 38.389392797255134, lng: -85.59036262702902, radonNumber: 51, serialNumber: 'FA28EQ000602'},
  {lat: 38.3478152, lng: -85.8373942, radonNumber: 213, serialNumber: 'FA20EQ000527'},
  {lat: 38.3086141, lng: -104.7667988, radonNumber: 213, serialNumber: 'EJ22EQ000555'},
  {lat: 38.1722793, lng: -85.5713462, radonNumber: 55, serialNumber: 'EK11EQ001236'},
  {lat: 38.13060492365751, lng: -86.9162208342892, radonNumber: 51, serialNumber: 'FB25EQ001816'},
  {lat: 37.8115155, lng: -122.2126194, radonNumber: 12, serialNumber: 'FA28EQ000710'},
  {lat: 37.73536600695471, lng: -121.93185591292432, radonNumber: 0, serialNumber: 'EI14EQ000100'},
  {lat: 37.657609483094646, lng: -77.59748408268587, radonNumber: 56, serialNumber: 'FA29EQ000930'},
  {lat: 37.4847051, lng: -77.6283613, radonNumber: 19, serialNumber: 'EJ23EQ000748'},
  {lat: 37.447721507977086, lng: -122.25778377635284, radonNumber: 7, serialNumber: 'EI14EQ000099'},
  {lat: 37.44771676947942, lng: -122.25775765607378, radonNumber: 0, serialNumber: 'EI14EQ000092'},
  {lat: 37.369772116688424, lng: -107.85348300832123, radonNumber: 26, serialNumber: 'FC23EQ002819'},
  {lat: 37.3516411, lng: 126.9785796, radonNumber: 7, serialNumber: 'EI14EQ000061'},
  {lat: 37.3289384, lng: -121.9601944, radonNumber: 85, serialNumber: 'EJ19EQ000408'},
  {lat: 37.32606739063044, lng: -80.8196165112276, radonNumber: 76, serialNumber: 'FB24EQ001662'},
  {lat: 37.30013656666543, lng: 127.04635627643573, radonNumber: 78, serialNumber: 'EF19EQ000307'},
  {lat: 37.300106151052375, lng: 127.04637948373323, radonNumber: 63, serialNumber: 'FC03EQ000001'},
  {lat: 37.30009685109802, lng: 127.04636170957824, radonNumber: 67, serialNumber: 'FC03EQ000003'},
  {lat: 37.30009614978199, lng: 127.04637761474814, radonNumber: 75, serialNumber: 'EF19EQ000306'},
  {lat: 37.3000601, lng: 127.046724, radonNumber: 11, serialNumber: 'FF14EQ003662'},
  {lat: 37.3000267, lng: 127.0466863, radonNumber: 64, serialNumber: 'EI14EQ000059'},
  {lat: 37.2976904, lng: -121.9582549, radonNumber: 112, serialNumber: 'EI17EQ000150'},
  {lat: 37.2946427, lng: -121.9172012, radonNumber: 0, serialNumber: 'EI17EQ000160'},
  {lat: 37.2945472497468, lng: -121.91713755827502, radonNumber: 7, serialNumber: 'FG05ES005320'},
  {lat: 37.2944294, lng: -121.9986384, radonNumber: 0, serialNumber: 'EI17EQ000158'},
  {lat: 37.2602148, lng: -76.7280708, radonNumber: 16, serialNumber: 'FB24EQ001625'},
  {lat: 37.2509023, lng: 127.0602842, radonNumber: 0, serialNumber: 'EI14EQ000053'},
  {lat: 37.2196677, lng: -107.7993741, radonNumber: 10, serialNumber: 'EJ22EQ000558'},
  {lat: 36.481208031059346, lng: -82.47989282416901, radonNumber: 159, serialNumber: 'EJ23EQ000873'},
  {lat: 36.4454009, lng: -94.2182724, radonNumber: 49, serialNumber: 'EK11EQ001396'},
  {lat: 36.3122697, lng: -78.4339891, radonNumber: 197, serialNumber: 'EK11EQ001504'},
  {lat: 36.1758739, lng: -79.8714074, radonNumber: 81, serialNumber: 'EK11EQ001432'},
  {lat: 36.1249591, lng: -85.5176541, radonNumber: 73, serialNumber: 'EK11EQ001498'},
  {lat: 36.0866106, lng: -82.865737, radonNumber: 35, serialNumber: 'FA28EQ000721'},
  {lat: 36.072739, lng: -86.7450541, radonNumber: 195, serialNumber: 'EK11EQ001462'},
  {lat: 36.0591904, lng: -81.9211145, radonNumber: 66, serialNumber: 'FA20EQ000542'},
  {lat: 36.015372097063974, lng: -115.31411324801354, radonNumber: 31, serialNumber: 'EJ28EQ000941'},
  {lat: 35.97591791095135, lng: -79.03713371191992, radonNumber: 204, serialNumber: 'FA19EQ000126'},
  {lat: 35.668466692052895, lng: -105.94821855142484, radonNumber: 8, serialNumber: 'EJ14EQ000237'},
  {lat: 35.63816342192262, lng: -87.02488808958823, radonNumber: 99, serialNumber: 'FB24EQ001410'},
  {lat: 35.5135566, lng: -84.2299307, radonNumber: 224, serialNumber: 'FA28EQ000765'},
  {lat: 35.304036737040455, lng: -97.50080652274634, radonNumber: 17, serialNumber: 'EK11EQ001028'},
  {lat: 35.1560508, lng: -106.5237084, radonNumber: 15, serialNumber: 'EK11EQ001197'},
  {lat: 35.152856807401086, lng: -80.83981430099917, radonNumber: 74, serialNumber: 'FA19EQ000040'},
  {lat: 34.9562282, lng: -82.2235897, radonNumber: 7, serialNumber: 'FB24EQ001696'},
  {lat: 34.86648650896556, lng: -111.78222601698984, radonNumber: 20, serialNumber: 'FB23EQ001238'},
  {lat: 34.42172769633877, lng: -119.59769038003142, radonNumber: 7, serialNumber: 'FB25EQ001817'},
  {lat: 34.2528125, lng: -84.3313495, radonNumber: 76, serialNumber: 'FA20EQ000283'},
  {lat: 34.21539800710437, lng: -118.84774074900787, radonNumber: 8, serialNumber: 'FA28EQ000720'},
  {lat: 34.151785266215654, lng: -118.60851284030517, radonNumber: 79, serialNumber: 'EJ22EQ000581'},
  {lat: 34.116379579365876, lng: -118.58245423244716, radonNumber: 16, serialNumber: 'FA20EQ000480'},
  {lat: 34.053491, lng: -84.2110996, radonNumber: 24, serialNumber: 'FA20EQ000299'},
  {lat: 34.049090130540804, lng: -84.18747715066282, radonNumber: 56, serialNumber: 'FB23EQ001234'},
  {lat: 34.031547127670876, lng: -84.41541611035947, radonNumber: 97, serialNumber: 'FA29EQ001173'},
  {lat: 33.9564046, lng: -84.6817592, radonNumber: 561, serialNumber: 'FA28EQ000726'},
  {lat: 33.73515565363347, lng: -117.74006230786085, radonNumber: 45, serialNumber: 'EJ19EQ000418'},
  {lat: 33.7342939, lng: -117.7455692, radonNumber: 64, serialNumber: 'EI17EQ000152'},
  {lat: 33.6129934, lng: -111.941669, radonNumber: 209, serialNumber: 'EJ28EQ000998'},
  {lat: 33.5579457, lng: -117.702835, radonNumber: 18, serialNumber: 'EJ28EQ000925'},
  {lat: 33.4244169, lng: -84.6088469, radonNumber: 79, serialNumber: 'FA28EQ000732'},
  {lat: 33.33323251034109, lng: -84.51213215569385, radonNumber: 62, serialNumber: 'EK11EQ001187'},
  {lat: 32.8770875, lng: -96.6440626, radonNumber: 0, serialNumber: 'EG23EQ000069'},
  {lat: 28.151127688134967, lng: -80.59287405796938, radonNumber: 8, serialNumber: 'EJ23EQ000684'},
  {lat: 26.532957235159127, lng: -81.81710185069073, radonNumber: 66, serialNumber: 'EK11EQ001515'},
  {lat: 26.49166098332637, lng: -81.89021565027711, radonNumber: 61, serialNumber: 'EK11EQ001200'},
  {lat: 26.36538392489092, lng: -80.19695632194214, radonNumber: 208, serialNumber: 'FA29EQ001138'},
  {lat: 26.2548137, lng: -80.2630258, radonNumber: 17, serialNumber: 'EK11EQ001035'},
  {lat: 26.248394966961662, lng: -81.80827992824521, radonNumber: 132, serialNumber: 'EK11EQ001233'},
  {lat: 26.194776949847427, lng: -80.27362570949603, radonNumber: 213, serialNumber: 'FA20EQ000398'},
  {lat: 26.153193757842885, lng: -81.80458980086829, radonNumber: 67, serialNumber: 'FA20EQ000393'}
]

