import {
  Card,
  CardContent,
  Grid,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";

import React from "react";
import { withStyles } from "@material-ui/core/styles";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const FAQ = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">Frequently Asked Questions</Typography>
          </Grid>
          <Grid item style={{ width: "1000px" }}>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Can my client view the radon data? </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  It is your decision. If you would like to share your radon
                  data and trends with your client, you can invite clients
                  through the device users menu in the mobile app. Clients
                  receive an invitation and download an app to check their radon
                  levels.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>
                  Can I change the radon monitoring mode to a 24-hour average?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, please go to{" "}
                  <a href="https://www.dashboard.ecosense.io/">
                    https://www.dashboard.ecosense.io/
                  </a>{" "}
                  and click on the device you want to change and select “24hrs
                  mode” under the Radon menu.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>
                  I installed the device but I cannot see the radon reading,
                  what should I do?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  In order to see the radon reading, please activate the
                  subscription service first. You can change the subscription
                  status to “Activate” in the Dashboard > Select Device > Radon
                  menu. You will be able to see first reading 48 hours after
                  subscription is activated (48-hour monitoring mode is
                  default). If you select the 24-hour monitoring mode, the first
                  data is available after 24 data points have been collected.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Typography>
                  I would like to send a radon report to my client, how do I
                  generate a report?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A monthly radon report is automatically available 30 days
                  after each device is activated. You can also generate the
                  report from the “Radon report” menu by selecting the device
                  and measurement period. Once the report is generated, you can
                  download the report as a PDF and email it.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Typography>
                  I would like to get a push notification for the radon level,
                  how do I set it up?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To receive push notifications on your phone, turn on push
                  notifications in the device settings on your dashboard. Also,
                  make sure to turn on push notifications in the EcoQube app.
                  You can enable push notifications in settings.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <Typography>
                  I would like to get an email notification for the radon level,
                  how do I set it up?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To receive email notifications, turn on email notifications
                  and set the alarm threshold in the device settings on the
                  dashboard.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <Typography>
                  I want to move the device to another client’s house, what
                  should I do?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You can move the device to another location and connect to
                  Wi-Fi. Unregister and register the device from your account to
                  start a new measurement. It is recommended to export and save
                  all previous data before unregistering the device to
                  permanently delete it.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
              >
                <Typography>
                  The device has lost its Wi-Fi connection at the client's home.
                  What should I do?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If a device fails to connect to the Wi-Fi network or has
                  issues, customers can scan the QR code to submit an inquiry
                  and you will receive an email notification. More detailed
                  information and instructions for how to reconnect Wi-Fi
                  networks remotely.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel9d-content"
                id="panel9d-header"
              >
                <Typography>EcoGuard Support form via QR code </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <a href="https://cdn.shopify.com/s/files/1/0106/9911/2511/files/EcoGuard_Support_Form_and_Wi-Fi_Remote_Settings_Instruction_1.pdf?v=1698440129">
                    More detailed information and instructions for how to use
                    EcoGuard QR code
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FAQ;
