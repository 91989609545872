//import React, {PropTypes, Component} from 'react/addons';
//import shouldPureComponentUpdate from 'react-pure-render/function';
import React, {Component} from 'react';
import GoogleMap from 'google-map-react';
import Marker from './Marker.js';
import GoogleMapReact from "google-map-react";
import {Helmet} from "react-helmet-async";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {radonMapInfoFromServer} from "./DataDump";

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true
  };
}

export default class RadonMap extends Component {
  state = {
    radonMapInfo: [],
  };

  static propTypes = {
    // center: PropTypes.array,
    // zoom: PropTypes.number,
    // greatPlaceCoords: PropTypes.any
  };

  static defaultProps = {
    center: [37.3382082, -121.8863286], // San Jose
    zoom: 9,
  };

  //shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);

    if (!localStorage.getItem('0xaccountId')) {
      this.props.history.push("/auth/sign-in");
    }

    //this.state.radonMapInfo = [];

    // Geocode.setApiKey("AIzaSyCQD7JleFDpcheqFxBq3qwfW1IUF3JcpII");
    // Geocode.setLanguage("en");
    // Geocode.setRegion("us");
    // Geocode.setLocationType("ROOFTOP");
    // Geocode.enableDebug();

    this.state.radonMapInfo = radonMapInfoFromServer;
  }

  render() {
    return (
      <>
        <Helmet title="Radon Map"/>
        <Typography variant="h3">
          Radon Map
        </Typography>
        {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
        {/*  <Link component={NavLink} exact to="/">*/}
        {/*    Dashboard*/}
        {/*  </Link>*/}
        {/*  <Link component={NavLink} exact to="/">*/}
        {/*    Pages*/}
        {/*  </Link>*/}
        {/*  <Typography>Settings</Typography>*/}
        {/*</Breadcrumbs>*/}
        <div className="s25"></div>
        <Divider my={6}/>
        <div className="s20"></div>
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMap
            bootstrapURLKeys={{ key: 'AIzaSyCQD7JleFDpcheqFxBq3qwfW1IUF3JcpII' }}
            center={this.props.center}
            zoom={this.props.zoom}
            options={createMapOptions}>
            {
              this.state.radonMapInfo.map(function(data){
                return <Marker lat={data.lat} lng={data.lng} radonNumber={data.radonNumber} />
              })
            }
          </GoogleMap>
        </div>
      </>
    );
  }
}
