import * as types from "../../constants";
import {
  signIn as authSignIn,
  signOut as authSignOut,
  signUpAsMitigator as authSignUpAsMitigator,
  changePassword as authChangePassword,
  updateUserAttribute as authUpdateUserAttribute,
  updateUserAttributes as authUpdateUserAttributes,
  resendSignUp as authResendSignUp,
  createCompanyInfo as serviceCreateCompanyInfo,
  updateCompanyInfo as serviceUpdateCompanyInfo,
  getCompanyInfo as serviceGetCompanyInfo,
  verifyCode as authVerifyCode,
  forgotPassword as authForgotPassword,
  resetPassword as authResetPassword,
} from "../services/authService";
import { TEST_EMAIL } from "../../constants/testAccount";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          id: "",
          email: credentials.email,
          name: response.attributes.name,
        });
        localStorage.setItem("0xuserId", response.attributes.name);
        if (TEST_EMAIL) {
          localStorage.setItem("0xaccountId", TEST_EMAIL);
        } else {
          localStorage.setItem("0xaccountId", credentials.email);
        }
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function verifyCode(code) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_VERIFY_CODE_REQUEST });

    return authVerifyCode(code)
      .then((response) => {
        //alert(JSON.stringify(response));
        dispatch({
          type: types.AUTH_VERIFY_CODE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_VERIFY_CODE_FAILURE });
        throw error;
      });
  };
}

export function changePassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PWD_REQUEST });

    return authChangePassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_CHANGE_PWD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_CHANGE_PWD_FAILURE });
        throw error;
      });
  };
}

export function updateUserAttribute(key, value) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_UPDATE_ATTRIBUTE_REQUEST });

    return authUpdateUserAttribute(key, value)
      .then((response) => {
        dispatch({
          type: types.AUTH_UPDATE_ATTRIBUTE_SUCCESS,
        });
        if (key == "name") {
          localStorage.setItem("0xuserId", value);
        } else if (key == "custom:certNo") {
          localStorage.setItem("0xcertNo", value);
        }
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_UPDATE_ATTRIBUTE_FAILURE });
        throw error;
      });
  };
}

export function updateUserAttributes(keys, values) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_UPDATE_ATTRIBUTES_REQUEST });

    return authUpdateUserAttributes(keys, values)
      .then((response) => {
        dispatch({
          type: types.AUTH_UPDATE_ATTRIBUTES_SUCCESS,
        });
        for(let i=0; i<keys.length; i++) {
          if (keys[i] == "name") {
            localStorage.setItem("0xuserId", values[i]);
          } else if (keys[i] == "custom:certNo") {
            localStorage.setItem("0xcertNo", values[i]);
          } else if(keys[i] == "custom:ownerDisplay") {
            localStorage.setItem("0xownerDisplay", values[i]);
          }
        }

      })
      .catch((error) => {
        dispatch({ type: types.AUTH_UPDATE_ATTRIBUTES_FAILURE });
        throw error;
      });
  };
}

export function signUpAsMitigator(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUpAsMitigator(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function resendSignUp(email) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESEND_SIGNUP_REQUEST });

    return authResendSignUp(email)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESEND_SIGNUP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESEND_SIGNUP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_OUT_REQUEST });

    return authSignOut()
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_OUT_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_OUT_FAILURE });
        throw error;
      });
  };
}

export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_FORGOT_PASSWORD_REQUEST });

    return authForgotPassword(email)
      .then((response) => {
        dispatch({
          type: types.AUTH_FORGOT_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_FORGOT_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function resetPassword(email, passcode, password) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(email, passcode, password)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function createCompanyInfo(info) {
  return async (dispatch) => {
    dispatch({ type: types.COMPANY_INFO_CREATE_REQUEST });
    return serviceCreateCompanyInfo(info)
      .then((response) => {
        dispatch({
          type: types.COMPANY_INFO_CREATE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.COMPANY_INFO_CREATE_FAILURE });
        //throw error;
      });
  };
}

export function updateCompanyInfo(info) {
  return async (dispatch) => {
    dispatch({ type: types.COMPANY_INFO_UPDATE_REQUEST });
    return serviceUpdateCompanyInfo(info)
      .then((response) => {
        dispatch({
          type: types.COMPANY_INFO_UPDATE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.COMPANY_INFO_UPDATE_FAILURE });
        //throw error;
      });
  };
}

export function getCompanyInfo(email) {
  return async (dispatch) => {
    dispatch({ type: types.COMPANY_INFO_GET_REQUEST });
    return serviceGetCompanyInfo(email)
      .then((response) => {
        dispatch({
          type: types.COMPANY_INFO_GET_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.COMPANY_INFO_GET_FAILURE });
        //throw error;
      });
  };
}
