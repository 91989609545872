import { Redirect, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// For routes that can be accessed without authentication

function getCategory(path, args, key=null) {
  console.log(path + ':' + args + ':' + args.slice(1))
  if( path=="/eu/report" ) {

    return {accessPath:path, params:atob(args.slice(1))}
  } else {
    console.log('what is wrong?');
  }
}

function NoAuthGuard({ children }) {
  const user = localStorage.getItem("0xuserId");

  const prevLocation = useLocation();
  // console.log('Yay:' + prevLocation.pathname);
  // const {accessPath, params} = getCategory(prevLocation.pathname, prevLocation.search)
  // console.log(`access=${accessPath}, params=${params}`)

  // return (
  //   <Link
  //     to={prevLocation.pathname}
  //     params={params}
  //   />
  // );

  return children;
}

export default NoAuthGuard;
