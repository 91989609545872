import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const user = localStorage.getItem("0xuserId");

  const prevLocation = useLocation();

  if (!user) {
    return (
      <Redirect
        to={`/auth/sign-in?redirect_to=${encodeURIComponent(
          prevLocation.pathname
        )}${encodeURIComponent(prevLocation.search)}`}
      />
    );
  }

  return children;
}

export default AuthGuard;
