export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);
export const validSignupEmailRegex = RegExp(/^[a-zA-Z0-9]*([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,}$|^[a-zA-Z0-9]*([\w\.\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+)\-([a-zA-Z0-9]+)\.[a-zA-Z0-9]{2,}$/i);
export const validCompanyEmailRegex = RegExp(/^[a-zA-Z0-9]*([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,}$|^[a-zA-Z0-9]*([\w\.\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+)\-([a-zA-Z0-9]+)\.[a-zA-Z0-9]{2,}$/i);
export const validPwdRegex = RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
);
export const validZipcodeRegex = RegExp(
  /(^\d{5}$)|(^\d{5}-\d{4}$)/
);


// false if the name has nothing or has numbers or special characters
export const validNameRegex = RegExp(
  // "^[a-zA-Z\\\\s]+"
  /^[a-zA-Z ]*$/
);

export const validCertNoRegex = RegExp(
  ""
);

export const validQuantityRegex = RegExp(
  //TODO
);

export const validPhoneNumberRegex = RegExp(/(?:\+?(\d{1,3}))?[-. ]?(\d{2,3})[-. ]?(\d{3})[-. ]?(\d{4})/);

export const validateEmail = (email) => {
  if (!validEmailRegex.test(email)) {
    return 'Please enter a valid email address.';
  }
  return null;
};

export const validateZipcode = (email) => {
  if (!validZipcodeRegex.test(email)) {
    return 'Please enter a valid Zipcode';
  }
  return null;
};

export const validatePassword = (pwd) => {
  if ((pwd.length < 8)||!pwd.match(validPwdRegex)) {
    return 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
  }
  return null;
};

export const validateWiFiPassword = (pwd) => {
  return null;
};

export const validateCode = (code) => {
  if (code.length !== 6 || isNaN(code)) {
    return 'Passcode must be 6 digits';
  }
  return null;
};
