import { savePDF } from '@progress/kendo-react-pdf';

class OrderDocService {
  createPdf = (html, orderId) => {
    savePDF(html, {
      scale: 0.8,
      paperSize: 'A4',
      fileName: 'order-' + orderId+ '.pdf',
      margin: 0
    })
  }
}

const Doc = new OrderDocService();
export default Doc;
