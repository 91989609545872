import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {createPaymentIntentForInvoice} from "../../models/services/stripeService";
import * as types from "../../constants";
import {Button, CircularProgress, Typography} from "@material-ui/core";
import "../../assets/css/payment.css";
import {createOrderAction, getOrderDetailAction} from "../../models/actions/orderActions";
import {useDispatch} from "react-redux";
import _ from 'lodash';

export default function CheckoutFormForSubscription(props) {
  // Setup state to track client secret, errors and checkout status
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [paidAmount, setPaidAmount] = useState(0);
  // Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
  }, []);

  const handleChangeForCreditCard = async (event) => {
    if (props.checked) {
      if (Object.keys(props.validateBillingInformation(props.values)).length > 0) {
        props.refreshPopup();
        return;
      }
    }

    setError(event.error ? event.error.message : "");
  };

  const handleSubmitForCreditCard = async (ev) => {
    if (Object.keys(props.validateBillingInformation(props.values)).length > 0) {
      props.refreshPopup();
      return;
    }

    if (error) {
      return;
    }

    ev.preventDefault();
    setProcessing(true);

    // Payment Intent Data
    const paymentIntentParam = {
      data: {
        email: localStorage.getItem('0xaccountId'),
        payment_method_type: 'card',
        currency: 'usd',
        amount: props.subscription.totalDue * 100,
        order: props.subscription,
        invoiceNo: props.subscription.invoiceNo,
      }
    }
    console.log('props.subscription = ' + props.subscription);
    try {
      // Create Payment Intent
      let res = await createPaymentIntentForInvoice(paymentIntentParam);
      const clientSecret = _.get(res, 'clientSecret');
      const invoiceNo = _.get(res, 'invoiceNo');

      if (!res || !clientSecret || clientSecret.length <= 0 || !invoiceNo ){
        throw new Error('Payment Intent is not created successfully');
      }

      // Confirm Card Payment.
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              city: props.values.bill_city,
              country: props.values.bill_country,
              line1: props.values.bill_street,
              line2: props.values.bill_detail,
              postal_code: props.values.bill_zipcode,
              state: props.values.bill_state
            },
            email: props.values.bill_email,
            name: props.values.bill_firstname + ' ' + props.values.bill_lastname,
            phone: props.values.bill_phone
          },
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);

        setPaymentIntentId(payload.paymentIntent.id);
        setPaidAmount(payload.paymentIntent.amount);

        props.showSubscriptionConfirmation(res.orderId, payload);
      }
    } catch (error) {
      let message = error.message || "Something went wrong";
      setError(error.message);
      setProcessing(false);
    }
  };

  // Construct UI.
  return (
    <form id="payment-form" onSubmit={handleSubmitForCreditCard}>
      {/* Title */}
      <Typography variant="h7" gutterBottom>
        <b>Credit card information</b>
      </Typography>
      <div className="s20"></div>

      {/* Credit Card Information */}
      <CardElement
        id="card-element"
         // Specify styles here
        options={{}}
        onChange={handleChangeForCreditCard}
      />
      <div className="s20"></div>

      {/* Pay now button */}
      <Button
        onClick={handleSubmitForCreditCard}
        // disabled={processing || disabled || succeeded || (error && error.length > 0)}
        disabled={processing || disabled || succeeded}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        classes={{root: 'button', label: 'button-label'}}
      >
        { processing ? <CircularProgress size={20}/> : <span>Pay now</span> }
      </Button>
      <div className="s20"></div>
      {/* Show a success message upon completion */}
      {!error && succeeded && (
        /*<div className="card-success" role="alert">Payment succeeded! <br/>Order ID: {orderId} (${(paidAmount/100).toFixed(2)})</div>*/
        <div className="card-success" role="alert">Payment succeeded!</div>
      )}

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">{error}</div>
      )}
    </form>
  );
}
