import {GREEN, ORANGE, RED} from "../../constants/colors";

const K_WIDTH = 40;
const K_HEIGHT = 40;

const markerStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '5px solid',
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  textAlign: 'center',
  color: 'black',
  fontSize: 13,
  fontWeight: 'bold',
  paddingLeft: 3,
  paddingRight: 5,
  paddingTop: 7,
  // paddingBottom: 4,
};

const markerGreenStyle = {
  ...markerStyle,
  borderColor: GREEN,
};

const markerRedStyle = {
  ...markerStyle,
  borderColor: RED,
};

const markerOrangeStyle = {
  ...markerStyle,
  borderColor: ORANGE,
};

export {markerGreenStyle, markerRedStyle, markerOrangeStyle};
