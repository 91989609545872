import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { createCompanyInfo, signUpAsMitigator } from "../../models/actions/authActions";
import {
  Button, Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
  FormControl as MuiFormControl, Box, MenuItem as MuiMenuItem, CircularProgress, DialogActions,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import "../../assets/css/auth.css";
import {validNameRegex, validSignupEmailRegex} from "../../utils/AuthValidation";
import Select from '@material-ui/core/Select';
import { ISS_WIFI, ISS_OTHERS, states } from '../../constants/values';
import {labelForTitle} from '../dashboards/Devices/CardLayout';
import {reportIssueAction} from '../../models/actions/reportIssueActions';
import {rgba} from 'polished';
import {withStyles} from '@material-ui/core/styles';
import {apiEndPoint, awsXApiKey} from '../../env';


const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(0)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(0)}px;
    padding-right: ${(props) => props.theme.spacing(0)}px;
  }
`;

const MenuItem = withStyles({
  root: {
    justifyContent: 'center',
  }
})(MuiMenuItem);

function EcoGuardGuide() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [companyName, setCompanyName] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [issueType, setIssueType] = useState(ISS_WIFI);
  const [description, setDescription] = useState('');
  const [issueTypeChanged, setIssueTypeChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mitigatorEmail = queryParams.get('email');
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    console.log('location is : ' + queryParams.get('email'));
    getInfoToAutoFill();
  },[]);

  const getInfoToAutoFill = async () => {
    let urlToFetch = apiEndPoint + '/mitigator?email=' + queryParams.get('email');

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': awsXApiKey
      },
      credentials: 'include',
    };

    try {
      let response = await fetch(urlToFetch, options);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      let result = await response.json();
      setCompanyName(result.data.companyName);
      setCompanyPhoneNumber(result.data.phoneNumber);
      setCompanyEmail(result.data.companyEmail)
      console.log('response to call mitigator info:' + JSON.stringify(result));
    } catch(e) {
      console.error('response to call mitigator error:' + e);
    }

  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
  };

  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleZipcodeChange = (event) => {
    setZipcode(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const validate = (values) => {
    const errors = {};

    if (!email) {
      errors.email = 'Required';
    // } else if (!validEmailRegex.test(email)) {
    } else if (!validSignupEmailRegex.test(email)) {
      errors.email = 'Please enter a valid email address.\nAlphanumeric, periods(.), hyphens(-), and underscores(_) are allowed.';
    }

    if (!name) {
      errors.name = 'Required';
    } else if (!validNameRegex.test(name)) {
      errors.name = 'It must not contain numbers or special characters.';
    }

    if (!phoneNumber) {
      errors.phoneNumber = 'Required';
    }

    if (!issueType) {
      errors.issueType = 'Required';
    }

    return errors;
  };

  const issueTypeSelectionChanged = (event) => {
    const selectedIssueType = event.target.value;
    console.log('issueTypeChanged -> changed to type:' + selectedIssueType);
    setIssueType(selectedIssueType);
    if (!issueType || issueType != selectedIssueType) {
      setIssueTypeChanged(true);
    } else {
      setIssueTypeChanged(false);
    }
  };

  return (
    <Wrapper>

      <Helmet title="EcoGuard Service" />
      <div>
        <Box display='flex' style={{height: 70, backgroundColor:'#00ADB4'}} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="h6" align={'center'} style={{
            fontSize: '20px',
            fontWeight: '700',
            fontStyle: 'normal',
            lineHeight: '20px',
            letter: '0.06px',
            color: rgba(255, 255, 255, 0.87),
          }}>
            Support Request Form
          </Typography>
        </Box>
        <Box style={{width:'100%', height:34}}></Box>
      </div>

      <div style={{paddingLeft:'33px', paddingRight:'38px'}}>
      <Formik
        initialValues={{
          email: "",
          name: "",
          phoneNumber: "",
          serialNumber: "",
          description: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={
          async (values, {setErrors, setStatus, setSubmitting}) => {
            setShowSpinner(true)
            setSubmitting(true)
            let res = await dispatch(
              reportIssueAction({
                company_name: companyName,
                customer_email: email,
                customer_name: name,
                customer_phone: phoneNumber,
                description: description,
                issue_type: issueType,
                mitigator_company_email: companyEmail? companyEmail: mitigatorEmail,
                mitigator_phone: companyPhoneNumber,
                mitigator_email: mitigatorEmail,
                serial_number: serialNumber,
                address1: address1,
                address2: address2,
                city: city,
                zipcode: zipcode,
                state: state
              })
            ).then(res => {
              // show a success message
              // console.log('successfully report issues');
              history.push("/qr/success");
            }).catch(e => {
              // show an error message
              setStatus({success: false})
              // setErrors({submit: 'temporary error message. to be replaced'})
              console.log('report issues failed:' + e)
            });
            setShowSpinner(false)
            setSubmitting(false)
          }
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
                <div className="s10"></div>
              </>
            )}
            <Typography variant="h6" gutterBottom>
              Service provider information
            </Typography>
            <TextField
              type="text"
              name="companyName"
              label="Company name"
              value={companyName}
              fullWidth
              disabled
              my={2}
            />
            <TextField
              type="text"
              name="phone"
              label="Phone number"
              value={companyPhoneNumber}
              fullWidth
              disabled
              my={2}
            />
            <TextField
              type="text"
              name="companyEmailAddress"
              label="Company email address"
              value={companyEmail}
              fullWidth
              disabled
              my={2}
            />
            <div className='s20'></div>
            <Typography variant="h6" gutterBottom>
              Fill out your information
            </Typography>
            <TextField
              type="text"
              name="name"
              label="Full name"
              required
              value={name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              // onBlur={handleBlur}
              onChange={handleNameChange}
              my={2}
            />
            <TextField
              type="email"
              name="email"
              label="Email address"
              required
              value={email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={handleEmailChange}
              my={2}
            />
            <TextField
              type="text"
              name="address1"
              label="Address1"
              value={address1}
              error={Boolean(touched.address1 && errors.address1)}
              fullWidth
              helperText={touched.address1 && errors.address1}
              // onBlur={handleBlur}
              onChange={handleAddress1Change}
              my={2}
            />
            <TextField
              type="text"
              name="address2"
              label="Address2"
              value={address2}
              error={Boolean(touched.address2 && errors.address2)}
              fullWidth
              helperText={touched.address2 && errors.address2}
              // onBlur={handleBlur}
              onChange={handleAddress2Change}
              my={2}
            />
            <TextField
              type="text"
              name="city"
              label="City"
              value={city}
              error={Boolean(touched.city && errors.city)}
              fullWidth
              helperText={touched.city && errors.city}
              // onBlur={handleBlur}
              onChange={handleCityChange}
              my={2}
            />
            <Box display='flex' flexDirection='row'>
              <TextField display='flex' style={{width:'40%', justifyContent:'flex-start'}}
                type="text"
                name="zipcode"
                label="Zipcode"
                value={zipcode}
                error={Boolean(touched.zipcode && errors.zipcode)}
                fullWidth
                helperText={touched.zipcode && errors.zipcode}
                // onBlur={handleBlur}
                onChange={handleZipcodeChange}
                my={3}
              />
              <Box style={{width:'20%', backgroundColor:'white'}}></Box>
              <Box my={3} display='flex' flexDirection='column' style={{width:'40%', justifyContent:'flex-end'}}>
                <Typography style={labelForTitle}>State</Typography>
                <Grid item>
                  <FormControl>
                    <Select
                      className="state-select"
                      id="state-select"
                      value={state}
                      onChange={handleStateChange}

                    >
                      <MenuItem className="state-select-menuitem" value={states.AK}>{states.AK}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.AL}>{states.AL}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.AR}>{states.AR}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.AZ}>{states.AZ}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.CA}>{states.CA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.CO}>{states.CO}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.CT}>{states.CT}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.DC}>{states.DC}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.DE}>{states.DE}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.FL}>{states.FL}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.GA}>{states.GA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.HI}>{states.HI}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.IA}>{states.IA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.ID}>{states.ID}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.IL}>{states.IL}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.IN}>{states.IN}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.KS}>{states.KS}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.KY}>{states.KY}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.LA}>{states.LA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MA}>{states.MA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MD}>{states.MD}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.ME}>{states.ME}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MI}>{states.MI}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MN}>{states.MN}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MO}>{states.MO}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MS}>{states.MS}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.MT}>{states.MT}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NC}>{states.NC}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.ND}>{states.ND}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NE}>{states.NE}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NH}>{states.NH}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NJ}>{states.NJ}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NM}>{states.NM}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NV}>{states.NV}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.NY}>{states.NY}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.OH}>{states.OH}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.OK}>{states.OK}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.OR}>{states.OR}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.PA}>{states.PA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.RI}>{states.RI}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.SC}>{states.SC}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.SD}>{states.SD}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.TN}>{states.TN}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.TX}>{states.TX}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.UT}>{states.UT}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.VA}>{states.VA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.VT}>{states.VT}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.WA}>{states.WA}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.WI}>{states.WI}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.WV}>{states.WV}</MenuItem>
                      <MenuItem className="state-select-menuitem" value={states.WY}>{states.WY}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </Box>
            <TextField
              type="text"
              name="phone"
              label="Phone number"
              required
              value={phoneNumber}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              fullWidth
              helperText={touched.phoneNumber && errors.phoneNumber}
              // onBlur={handleBlur}
              onChange={handlePhoneNumberChange}
              my={2}
            />
            <TextField
              type="text"
              name="serialNumber"
              label="Device serial number"
              value={serialNumber}
              error={Boolean(touched.serialNumber && errors.serialNumber)}
              fullWidth
              helperText={touched.serialNumber && errors.serialNumber}
              // onBlur={handleBlur}
              onChange={handleSerialNumberChange}
              my={2}
            />
            <Box my={2}>
              <Typography style={labelForTitle}>Type of issues</Typography>
            </Box>
            <Box display='flex' flexDirection='row'>
              <Grid item>
                <FormControl>
                  <Select
                    className="issue-type-select"
                    id="issue-type-select"
                    value={issueType}
                    onChange={issueTypeSelectionChanged}
                    error={Boolean(touched.issueType && errors.issueType)}
                    helpertext={touched.issueType && errors.issueType}
                  >
                    <MenuItem className="issue-type-menuitem" value={ISS_WIFI}>{ISS_WIFI}</MenuItem>
                    <MenuItem className="issue-type-menuitem" value={ISS_OTHERS}>{ISS_OTHERS}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <TextField
              type="text"
              name="description"
              label="Please describe your issue"
              value={description}
              error={Boolean(touched.description && errors.description)}
              fullWidth
              helperText={touched.description && errors.description}
              // onBlur={handleBlur}
              onChange={handleDescriptionChange}
              my={2}
            />
            <div className="s30"></div>
            {!showSpinner?
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{backgroundColor:"#00ADB4", color: '#FFFFFF'}}
                classes={{ root: 'button', label: 'button-label' }}
              >
                Submit
              </Button>:
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{backgroundColor:"#E0E0E0", color: '#FFFFFF'}}
                disabled
                classes={{ root: 'button', label: 'button-label' }}
              >
                <div className='save-changes-spinner'>
                  <CircularProgress size={20}/>
                </div>
              </Button>}
          </form>
        )}
      </Formik>
      </div>
      <div className='s20'></div>
      <div align="center">
          <span className='signup-terms-text'>
            By submitting request, you're agreeing to the <br/><a className='signup-terms-link' href='https://ecosense.io/policies/terms-of-service' target='_self'>Terms of Service and Privacy Policy</a>
          </span>
      </div>
    </Wrapper>
  );
}

export default EcoGuardGuide;
