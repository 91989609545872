import {apiEndPoint, awsXApiKey} from '../../env'
import {CRITICAL_ACTION_TIMEOUT} from '../../constants/values';

export async function reportIssue(issue) {
  const controller = new AbortController();

  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'X-Api-Key': awsXApiKey},
    signal: controller.signal,
    credentials: 'include',
    body : JSON.stringify(issue)
  };
  let requestUrl = apiEndPoint + '/mitigator/issue';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  let result;
  try {
    let response = await fetch(requestUrl, options);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    result = await response.json();
  } catch(e) {
    console.error('[reportIssue]result error is:' + e);
    throw e;
  }

  return result;
  // return await fetch(requestUrl, options)
  //   .then((res) => {
  //     if (res.status === 200 || res.status === 201) {
  //       console.log('success, reportIssue in reportIssueService: ' + JSON.stringify(res));
  //       // resolve(res.data);
  //     }
  //     // reject(res.data);
  //     // return res.text().then(text => {
  //     //   const data = text && JSON.parse(text);
  //     //   return data;
  //     // })
  //   })
  //   .catch((error) => {
  //     console.log('reportIssue in reportIssueService: ' + JSON.stringify(error));
  //     throw error;
  //   });
};


