import React from "react";
import { useDispatch } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {signUp, createCompanyInfo, signUpAsMitigator} from "../../models/actions/authActions";
import {
  Button, CardContent, Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
  FormControl as MuiFormControl,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import "../../assets/css/auth.css";
import {validEmailRegex, validPwdRegex, validNameRegex, validSignupEmailRegex} from "../../utils/AuthValidation";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";

const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [pwdValues, setPwdValues] = React.useState({
    currentPassword: "",
    confirmPassword: "",
    showCurrentPassword: false,
    showConfirmPassword: false
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
  };

  const handleClickShowCurrentPassword = () => {
    setPwdValues({ ...pwdValues, showCurrentPassword: !pwdValues.showCurrentPassword });
  };

  const handleClickShowNewPassword = () => {
    setPwdValues({ ...pwdValues, showNewPassword: !pwdValues.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setPwdValues({ ...pwdValues, showConfirmPassword: !pwdValues.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};

    if (!email) {
      errors.email = 'Required';
    // } else if (!validEmailRegex.test(email)) {
    } else if (!validSignupEmailRegex.test(email)) {
      errors.email = 'Please enter a valid email address.\nAlphanumeric, periods(.), hyphens(-), and underscores(_) are allowed.';
    }

    if (!name) {
      errors.name = 'Required';
    } else if (!validNameRegex.test(name)) {
      errors.name = 'It must not contain numbers or special characters.';
    }

    if (!pwdValues.currentPassword) {
      errors.currentPassword = 'Required';
    } else if (!validPwdRegex.test(pwdValues.currentPassword)) {
      errors.currentPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    }

    if (!pwdValues.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (!validPwdRegex.test(pwdValues.confirmPassword)) {
      errors.confirmPassword = 'It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.';
    } else if (!errors.currentPassword && (pwdValues.currentPassword !== pwdValues.confirmPassword)) {
      errors.confirmPassword = 'Your new password and confirmation password do not match.';
    }

    if (!values.companyName) {
      errors.companyName = 'Required';
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = 'Required';
    }

    return errors;
  };

  return (
    <Wrapper>
      <Helmet title="Sign Up" />
      <div>
        <div align="center">
          <img width={120} src="/static/img/logo/ecosense-logo3.jpg"></img>
        </div>
        <div className='s30'></div>
        <div align="center">
          <span className='account-title-text'>Create your account</span>
        </div>
        <div className='s20'></div>
        <div align="center">
          <span className='account-check-text'>
            Have an account? <Link className='account-check-link' to='/auth/sign-in'>SIGN IN</Link>
          </span>
        </div>
        <div className='s30'></div>
      </div>

      <Formik
        initialValues={{
          email: "",
          name: "",
          companyName: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          zipcode: "",
          submit: false,
        }}
        validate={validate}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const trimmedEmail = email.trim().toLowerCase();
          try {
            //Register Subscription Information for Mitigator
            await dispatch(
              signUpAsMitigator({
                email: trimmedEmail,
                name: name,
                password: pwdValues.currentPassword,
              })
            ).then(async response => {
              await dispatch(
                createCompanyInfo({
                  email: trimmedEmail,
                  name: name,
                  companyName: values.companyName,
                  phoneNumber: values.phoneNumber,
                  address: {
                    street: values.address,
                    detail: '',
                    city: values.city,
                    state: values.state,
                    zipcode: values.zipcode,
                  }
                })
              );
            }).then(async response => {
              await history.push({
                pathname: '/auth/verify-code',
                state: { email: trimmedEmail }
              });
            });
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === 'UserNotConfirmedException') {
              message = 'Account not verified yet';
            } else if (error.code === 'PasswordResetRequiredException') {
              message = 'Existing user found. Please reset your password';
            } else if (error.code === 'NotAuthorizedException') {
              message = error.message;
            } else if (error.code === 'UserNotFoundException') {
              message = 'User does not exist!';
            } else if (error.code === 'UsernameExistsException') {
              message = 'User already exists!';
            } else if(error.code === 'InvalidParameterException') {
              message = 'Trailing spaces are not allowed in your password \n or one of parameters is invalid';
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
                <div className="s10"></div>
              </>
            )}
            <Typography variant="h6" gutterBottom>
              Account information
            </Typography>
            <TextField
              type="email"
              name="email"
              label="Email Address *"
              value={email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={handleEmailChange}
              my={2}
            />
            <TextField
              type="text"
              name="name"
              label="Full name *"
              value={name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              // onBlur={handleBlur}
              onChange={handleNameChange}
              my={2}
            />
            <FormControl
              fullWidth
              my={2}
            >
              <InputLabel htmlFor="standard-current-password" error={(errors.currentPassword) ? true : false}>Password *</InputLabel>
              <Input
                id="standard-current-password"
                autoComplete="new-password"
                aria-describedby="current-password-helper"
                type={pwdValues.showCurrentPassword ? "text" : "password"}
                value={pwdValues.currentPassword}
                onChange={handlePwdChange("currentPassword")}
                // onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {pwdValues.showCurrentPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.currentPassword && <FormHelperText id="current-password-helper" error={true}>{errors.currentPassword}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              my={2}
            >
              <InputLabel htmlFor="standard-confirm-password" error={(errors.confirmPassword) ? true : false}>Confirm Password *</InputLabel>
              <Input
                id="standard-confirm-password"
                autoComplete="new-password"
                aria-describedby="confirm-password-helper"
                type={pwdValues.showConfirmPassword ? "text" : "password"}
                value={pwdValues.confirmPassword}
                onChange={handlePwdChange("confirmPassword")}
                // onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {pwdValues.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && <FormHelperText id="confirm-password-helper" error={true}>{errors.confirmPassword}</FormHelperText>}
            </FormControl>
            <div className="s30"></div>
            <Typography variant="h6" gutterBottom>
              Company information
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  name="companyName"
                  label="Company name *"
                  value={values.companyName}
                  fullWidth
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="phoneNumber"
                  label="Phone number *"
                  value={values.phoneNumber}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
            </Grid>
            <TextField
              name="address"
              label="Address"
              value={values.address}
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              // onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  name="city"
                  label="City"
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  name="state"
                  label="State"
                  value={values.state}
                  error={Boolean(touched.state && errors.state)}
                  fullWidth
                  helperText={touched.state && errors.state}
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  type="number"
                  name="zipcode"
                  label="Zipcode"
                  value={values.zipcode}
                  error={Boolean(touched.zipcode && errors.zipcode)}
                  fullWidth
                  helperText={touched.zipcode && errors.zipcode}
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </Grid>
            </Grid>
            <div className="s30"></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              classes={{ root: 'button', label: 'button-label' }}
            >
              Sign up
            </Button>
          </form>
        )}
      </Formik>
      <div className='s20'></div>
      <div align="center">
          <span className='signup-terms-text'>
            By continuing, you're agreeing to the <br/><a className='signup-terms-link' href='https://ecosense.io/policies/terms-of-service' target='_self'>Ecosense Terms of Service and Privacy Policy</a>
          </span>
      </div>
    </Wrapper>
  );
}

export default SignUp;
