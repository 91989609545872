import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {Box, Paper, Typography} from "@material-ui/core";
import {rgba} from 'polished';
import {Image} from '@material-ui/icons';

import success from '../../assets/img/success.png';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(0)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(0)}px;
    padding-right: ${(props) => props.theme.spacing(0)}px;
  }
`;

function Success() {
  return (
    <Wrapper>
      <Helmet title="Submit success" />
      <div style={{width:'100%'}}>
        <Box display='flex' style={{height: 70, backgroundColor:'#00ADB4'}} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="h6" align={'center'} style={{
            fontSize: '20px',
            fontWeight: '700',
            fontStyle: 'normal',
            lineHeight: '20px',
            letter: '0.06px',
            color: rgba(255, 255, 255, 0.87),
          }}>
            Support Request Form
          </Typography>
        </Box>
        <Box style={{width:'100%', height:265}}></Box>
      </div>
      <div style={{paddingLeft:'33px', paddingRight:'38px'}}>
        <div align={'center'}>
          <img src={success} style={{width:78, height:78}}></img>
        </div>
        <Typography variant="h6" align={'center'} style={{
          fontSize: '17px',
          fontWeight: '600',
          fontStyle: 'normal',
          lineHeight: '48px',
          color: '#1F2738',
        }}>
          Your email is successfully sent!
        </Typography>
      </div>
      <Box style={{height:200}}></Box>
    </Wrapper>
  );
}

export default Success;
