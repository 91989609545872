import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import "../../assets/css/common.css";
import "../../assets/css/dashboard.css";
import { spacing } from "@material-ui/system";
import {
  Typography as MuiTypography,
} from "@material-ui/core";

const Typography = styled(MuiTypography)(spacing);

function NoSubscriptions() {
  return (
    <React.Fragment>
      <Typography mb={'228px'}/>
      <div className='no-data-container'>
        <Typography variant={'h6'} align={'center'} mb={'19px'}>
          No subscription invoice available yet
        </Typography>
        <span className='no-data-text'>
          Register your EcoGuard with Mobile app or <NavLink to='/orders'> Order</NavLink> new EcoQube
        </span>
      </div>
    </React.Fragment>
  );
}

export default NoSubscriptions;
