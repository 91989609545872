import React, { PureComponent } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../../../assets/css/line-chart.css";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import Button from "@material-ui/core/Button";
import { UNIT_CONVERSION_SCALE } from "../../../constants/values";
import moment from "moment";
import _ from "lodash";
import { CardContent, Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Space from "../../../components/Space";
import { RadonUtil } from "../../components/RadonUtil";
import { GREEN, ORANGE, RED } from "../../../constants/colors";

const initialState = {
  data: [],
  type: "MONTH",
  left: "dataMin",
  right: "dataMax",
  refAreaLeft: "",
  refAreaRight: "",
  top: "dataMax",
  bottom: "dataMin",
  animation: true,
  unit: 0,
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload, data } = this.props;
    if (data.type == "DAY" || data.type == "WEEK" || data.type == "MONTH") {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fill="#666">
            <tspan textAnchor="middle" x="0">
              {moment.unix(payload.value).format("MM/DD/YY")}
            </tspan>
            <tspan
              textAnchor="middle"
              x="0"
              dy="11"
              fontSize={10}
              fill="#C0C0C0"
            >
              {moment.unix(payload.value).format("hh:mm A")}
            </tspan>
          </text>
        </g>
      );
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fill="#666">
            <tspan textAnchor="middle" x="0">
              {moment.unix(payload.value).format("MM/DD/YY")}
            </tspan>
          </text>
        </g>
      );
    }
  }
}

export default class RadonLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.updateChart();
  }

  updateChart() {
    this.state.unit = this.props.unit;
    let data = _.cloneDeep(this.props.radon);
    const formattedData = this.reformatData(data, this.props);
    let sortedDataArray = this.sortDate(
      formattedData,
      this.getComparator("desc", "datetime")
    );
    this.state.data = sortedDataArray.reverse();
    initialState.data = sortedDataArray;
    if (this.props.successLoadedChartData)
      this.props.successLoadedChartData(sortedDataArray);
  }

  componentDidUpdate() {}

  getAxisYDomain = (from, to, ref, offset) => {
    const refData = this.state.data.slice(from, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
    if (top > 0) {
      top = top * 1.2;
    }
    return [0, top | 0];
  };

  reformatData(data, props) {
    data = data.filter(
      (v, i, a) => a.findIndex((t) => t.datetime === v.datetime) === i
    );
    let formattedDataArray = data.map(function (data) {
      let radonLevelText;
      let radonLevelForNumber;
      if (props.unit === 1) {
        radonLevelText = data.radon_level;
        radonLevelForNumber = radonLevelText;
      } else {
        radonLevelText = (data.radon_level / UNIT_CONVERSION_SCALE).toFixed(1);
        radonLevelForNumber = Math.round(radonLevelText * 100) / 100;
      }
      let newObj;
      if (
        props.type == "DAY" ||
        props.type == "WEEK" ||
        props.type == "MONTH"
      ) {
        newObj = {
          datetime: data.datetime,
          radon_level: radonLevelForNumber,
        };
      } else {
        const year = data.datetime.substring(0, 4);
        const month = data.datetime.substring(4, 6);
        const day = data.datetime.substring(6, 8);

        let timestamp = new Date(
          year + "-" + month + "-" + day + "T00:00:01"
        ).getTime();
        timestamp = timestamp.toString().substring(0, 10);

        newObj = {
          datetime: timestamp,
          radon_level: radonLevelForNumber,
        };
      }

      return newObj;
    });

    let startDateTime;
    let endDateTime;

    startDateTime = this.props.originMoment.startMoment.unix().toString();
    endDateTime = this.props.originMoment.endMoment.unix().toString();

    if (!formattedDataArray.some((data) => data.datetime === startDateTime)) {
      formattedDataArray.push({
        datetime: startDateTime,
        radon_level: null,
      });
    }

    if (!formattedDataArray.some((data) => data.datetime === endDateTime)) {
      formattedDataArray.push({
        datetime: endDateTime,
        radon_level: null,
      });
    }

    return formattedDataArray;
  }

  componentDidMount() {
    this.zoomOut();
  }
  //
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.radon !== this.props.radon) {
  //     if (this.props.successLoadedChartData) this.props.successLoadedChartData(this.props.radon);
  //   }
  // }

  sortDate(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => this.descendingComparator(a, b, orderBy);
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  zoom() {
    let { refAreaLeft, refAreaRight } = this.state;
    const { data } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      this.setState(() => ({
        refAreaLeft: "",
        refAreaRight: "",
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // Get index of refAreaLeft and refArearight
    let indexAreaLeft = this.getIndexOfArray(data, "datetime", refAreaLeft);
    let indexAreaRight = this.getIndexOfArray(data, "datetime", refAreaRight);

    if (indexAreaLeft > indexAreaRight)
      [indexAreaLeft, indexAreaRight] = [indexAreaRight, indexAreaLeft];
    indexAreaRight = indexAreaRight + 1;

    if (this.props.setStartMoment)
      this.props.setStartMoment(moment.unix(refAreaLeft));
    if (this.props.setEndMoment)
      this.props.setEndMoment(moment.unix(refAreaRight));

    // yAxis domain
    const [bottom, top] = this.getAxisYDomain(
      indexAreaLeft,
      indexAreaRight,
      "radon_level",
      1
    );
    this.setState(() => ({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(indexAreaLeft, indexAreaRight),
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
    }));

    if (this.props.successLoadedChartData)
      this.props.successLoadedChartData(
        data.slice(indexAreaLeft, indexAreaRight)
      );
  }

  zoomOut() {
    if (this.props.setStartMoment)
      this.props.setStartMoment(this.props.originMoment.startMoment);
    if (this.props.setEndMoment)
      this.props.setEndMoment(this.props.originMoment.endMoment);

    this.setState(() => ({
      data: initialState.data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax",
      top: "dataMax",
      bottom: 0,
    }));

    if (this.props.successLoadedChartData)
      this.props.successLoadedChartData(initialState.data.slice());
  }

  getIndexOfArray(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return 0;
  }

  // Input: "1630918182"
  formatXAxisDateTimeWithHourAndMin(dateTime) {
    return moment.unix(dateTime).format("MM/DD/YY HH:MM");

    // return moment.unix(dateTime).format("MM/DD/YY");
  }

  // Input: "20210831"
  formatXAxisDateTimeWithoutHourAndMin(dateString) {
    return RadonUtil.changeDateFormat(dateString);
  }

  tickFormatter(props) {
    if (props.type == "DAY" || props.type == "WEEK" || props.type == "MONTH") {
      return this.formatXAxisDateTimeWithHourAndMin;
    }
    return this.formatXAxisDateTimeWithoutHourAndMin;
  }

  getStats() {
    const { data } = this.state;
    let max = -1,
      min = 99999,
      sum = 0,
      dataSize = 0;
    let timeAtMax, timeAtMin;

    for (let i = 0; i < data.length; i++) {
      sum += data[i].radon_level;
      if (data[i].radon_level) dataSize += 1;
      else continue;
      if (min > data[i].radon_level) {
        min = data[i].radon_level;
        timeAtMin = data[i].datetime;
      }
      if (max < data[i].radon_level) {
        max = data[i].radon_level;
        timeAtMax = data[i].datetime;
      }
    }
    if (dataSize > 0) {
      return {
        avg: (sum / data.length).toFixed(1),
        min: min,
        max: max,
        timeAtMin: timeAtMin,
        timeAtMax: timeAtMax,
      };
    }
    return {
      avg: null,
      min: null,
      max: null,
      timeAtMin: null,
      timeAtMax: null,
    };
  }

  render() {
    const {
      data,
      barIndex,
      left,
      right,
      refAreaLeft,
      refAreaRight,
      top,
      bottom,
    } = this.state;
    return (
      <>
        {!this.props.isForPDF ? (
          <>
            <Box display="flex" p={1}>
              <Box p={1} flexGrow={1}>
                {!this.props.isForDashboard ? (
                  <Typography variant={"subtitle1"} mb={3}>
                    <Box fontWeight="fontWeightBold">Radon Trends</Box>
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
              <Box p={1}>
                {this.props.isForDashboard ? (
                  <Button
                    classes={{
                      root: "zoom-button",
                      label: "zoom-button-label",
                    }}
                    onClick={this.zoomOut.bind(this)}
                  >
                    <ZoomOutIcon />
                    {/*Zoom out*/}
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {!this.props.isForDashboard ? <Space height={"20px"} /> : <></>}
          </>
        ) : null}
        {/*<Box display='flex' alignItems={'center'} justifyContent={'flex-end'} style={{paddingRight:30}}>*/}
        {/*  <Typography>Avg: {this.getStats().avg}</Typography>*/}
        {/*  <Box style={{width:10}}/>*/}
        {/*  /!*<Typography>Min: {this.getStats().min} at {moment.unix(this.getStats().timeAtMin).format("MM/DD/YY HH:mm")}</Typography>*!/*/}
        {/*  <Typography>Min: {this.getStats().min}</Typography>*/}
        {/*  <Box style={{width:10}}/>*/}
        {/*  <Typography>Max: {this.getStats().max}</Typography>*/}
        {/*</Box>*/}
        {/*<div className={'s20'}></div>*/}
        <div style={styles.chart_root}>
          <div style={styles.line_chart_root}>
            <ResponsiveContainer
              width={this.props.width ? this.props.width * 0.9 : "100%"}
              height={this.props.isForDashboard ? 200 : 400}
              // aspect={7 / 3}
            >
              <LineChart
                data={this.state.data}
                onMouseDown={(e) => {
                  e &&
                    e.activeLabel &&
                    this.props.isForDashboard &&
                    this.setState({ refAreaLeft: e.activeLabel });
                }}
                onMouseMove={(e) => {
                  e &&
                    e.activeLabel &&
                    this.props.isForDashboard &&
                    this.state.refAreaLeft &&
                    this.setState({ refAreaRight: e.activeLabel });
                }}
                onMouseUp={this.props.isForDashboard && this.zoom.bind(this)}
              >
                <CartesianGrid
                  strokeDasharray={this.props.isForPDF ? "0.1 0.1" : "0.3 0.3"}
                  // vertical={false}
                />
                <XAxis
                  scale="time"
                  allowDataOverflow
                  dataKey="datetime"
                  domain={[left, right]}
                  type="number"
                  tickCount={3}
                  // tick={{ fill: 'gray', fontSize: 12,  angle: -30 }}
                  // tickFormatter={this.tickFormatter(this.props)}
                  tick={<CustomizedAxisTick data={this.props} />}
                />
                <YAxis
                  allowDataOverflow
                  // domain={[0, dataMax => this.props.unit === 0 ? (dataMax*1.2).toFixed(1) : (dataMax*1.2).toFixed(0)]}
                  domain={[0, (dataMax) => (dataMax * 1.2).toFixed(1)]}
                  type="number"
                  yAxisId="1"
                />
                {
                  <Tooltip
                    formatter={function (value, name) {
                      return `${value}`;
                    }}
                    labelFormatter={function (value) {
                      return `${moment.unix(value).format("MM/DD/YY hh:mm A")}`;
                    }}
                  />
                }
                <Line
                  yAxisId="1"
                  // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter'
                  type={this.props.line ? this.props.line : "monotoneX"}
                  dataKey="radon_level"
                  stroke="#4983DA"
                  strokeWidth={this.props.isForDashboard ? 2.7 : 2}
                  animationDuration={1500}
                  connectNulls={false}
                  strokeDashArray="3"
                  dot={false}
                />
                <ReferenceLine
                  yAxisId="1"
                  y={this.props.unit == 0 ? 2.7 : 100}
                  // label={{
                  //   position: "center",
                  //   value: "Consider Fixing Home"
                  // }}
                  strokeDasharray="6 4"
                  stroke={ORANGE}
                  strokeWidth={0.3}
                />
                <ReferenceLine
                  yAxisId="1"
                  y={this.props.unit == 0 ? 4 : 150}
                  strokeDasharray="6 4"
                  stroke={RED}
                  strokeWidth={0.3}
                />
                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
          {
            // <Typography variant="body2" align={'center'} gutterBottom style={styles.labelForTitle}>
            //   {this.getTextForChartType()}{' of Radon Trend'}
            // </Typography>
          }
          <div className={"s20"}></div>
        </div>
      </>
    );
  }
}

const styles = {
  labelForTitle: {
    fontSize: "12px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#000000",
    opacity: 0.6,
  },
  valueForText: {
    fontSize: "15px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#000000",
    minWidth: "120px",
  },
  chart_root: {
    userSelect: "none",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  line_chart_root: {
    userSelect: "none",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "45px",
  },
};
