import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import CheckIcon from "@material-ui/icons/Check";
import { useState } from "react";

const Wifi = () => {
  const [copied, setCopied] = useState("");

  const CopyToClipboard = ({ os }) => {
    let link;

    switch (os) {
      case "ios":
        link = "https://apps.apple.com/us/app/eg-wifi-config/id1671668222";

        break;
      case "android":
        link =
          "https://play.google.com/store/apps/details?id=io.ecosense.ecoqube_setting&hl=en_US&gl=US";
        break;

      default:
        break;
    }

    const handleCopy = (e) => {
      setCopied(e.currentTarget.id);
      navigator.clipboard.writeText(link);
    };

    return (
      <TextField
        disabled
        fullWidth
        id="outlined-start-adornment"
        defaultValue={link}
        InputProps={{
          style: {
            color: "black",
          },
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <Button
                style={{ backgroundColor: copied === os ? "#C4C4C4" : "" }}
                size="small"
                id={os}
                onClick={(e) => handleCopy(e)}
                classes={{ root: "button", label: "button-label" }}
                variant="contained"
                color="primary"
              >
                {copied === os ? "Copied!" : "Copy"}
              </Button>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    );
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">
              Connect Wi-Fi Network Remotely Using EG WiFi Config App
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              If a customer is having Wi-Fi connectivity issues (e.g. changing
              network router or changing Wi-Fi password) and needs to reconnect
              <br />
              EcoGuard to a Wi-Fi network, provide the app links below to your
              customer. Customers can connect EcoGuard to their Wi-Fi network
              <br />
              without logging into the app or seeing their data. If you want to
              send EcoGuard to your customer after registering the device to
              your
              <br />
              account, customers can connect EcoGuard to their home Wi-Fi
              network using this app without you visiting the site.
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                marginTop: "15px",
                textDecoration: "underline",
              }}
            >
              EG WiFi Config by Ecosense
            </Typography>
            <Grid
              container
              direction="column"
              style={{ marginTop: "15px" }}
              spacing={3}
            >
              <Grid item style={{ width: "800px" }}>
                <Typography style={{ marginBottom: "5px" }}>iOS</Typography>
                <CopyToClipboard os="ios" />
              </Grid>
              <Grid item style={{ width: "800px" }}>
                <Typography style={{ marginBottom: "5px" }}>Android</Typography>
                <CopyToClipboard os="android" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Wifi;
