import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import Loading from "../../components/Loading";

const Confirm48HrDialog = ({
  isLoading,
  open48HrConfirmPopup,
  handleClose,
  handle48HrConfirm,
  handle48HrPrevious,
}) => {
  return (
    <Dialog
      titleStyle={{ textAlign: "center" }}
      maxWidth="md"
      maxHeight={"sm"}
      open={open48HrConfirmPopup}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title2"
    >
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="s20"></div>
        <DialogTitle id="max-width-dialog-title2">
          <Typography variant="h5" align="center">
            Confirm Report Generation
          </Typography>
        </DialogTitle>
        {isLoading ? (
          <Loading top={"100px"} bottom={"100px"} />
        ) : (
          <>
            <DialogContent>
              <Container
                style={{
                  width: "700px",
                  height: "400px",
                }}
              >
                <Grid
                  container
                  style={{
                    paddingTop: "20px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color: "#B00020",
                        marginBottom: "20px",
                      }}
                    >
                      Are you sure you want to generate the 48-hour clearance radon report?
                      Once generated, this report cannot be regenerated,
                      so please ensure all information is accurate and up-to-date.
                    </Typography>
                    <Typography
                      style={{ marginBottom: "20px" }}
                    >
                      We provide two complimentary 48-hour clearance radon reports per quarter.
                      To generate the report,
                      the device must be activated and connected to a Wi-Fi network for at least 72 hours.
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      Please confirm that you have entered all the required information for your radon report:
                      <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
                        <li>Device information</li>
                        <li>Client information</li>
                        <li>Location information</li>
                      </ul>
                    </Typography>
                    <Typography>
                      If the information is not complete, close this window, go to your dashboard,
                      select the device, update all necessary information, and then try generating the report again.
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </DialogContent>
            <div className="s20"></div>
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    color: "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handleClose}
                >
                  <u>Cancel</u>
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    background: "#E0E0E0",
                    color: "#000000",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handle48HrPrevious}
                  color="primary"
                >
                  Previous
                </Button>
                <Box style={{ width: "10px" }} />
                <Button
                  style={{
                    background: "#3DB5E6",
                    color: "#FFFFFF",
                    width: "150px",
                    height: "36px",
                  }}
                  onClick={handle48HrConfirm}
                  color="primary"
                >
                  Preview
                </Button>
              </Box>
            </DialogActions>
            <div className="s20"></div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Confirm48HrDialog;
