import * as types from "../../constants";
import {
  reportIssue
} from "../services/reportIssueService";

export function reportIssueAction(issue) {
  return async (dispatch) => {
    console.log('reportIssueAction is called:' + JSON.stringify(issue));
    dispatch({ type: types.REPORT_ISSUE_REQUEST });

    return reportIssue(issue)
      .then((response) => {
        dispatch({
          type: types.REPORT_ISSUE_SUCCESS,
        })
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.REPORT_ISSUE_FAILURE });
        throw error;
      });
  };
}
