import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {apiEndPoint} from '../../env'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody, TableCell as MuiTableCell,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@material-ui/core";

import "../../assets/css/common.css";
import "../../assets/css/order.css";
import {Alert as MuiAlert} from "@material-ui/lab";

import {Add as AddIcon, RemoveRedEye as RemoveRedEyeIcon,} from "@material-ui/icons";

import {spacing} from "@material-ui/system";
import _ from "lodash";
import SubscriptionInterface from '../../integration/internal/SubscriptionInterface';
import {customAlert} from '../../utils/AlertWrapper';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportInterface from '../../integration/internal/ReportInterface';
import moment from 'moment';
import Loading from "../components/Loading";
import {Auth} from 'aws-amplify';
import {drawDOM, exportPDF} from '@progress/kendo-drawing';
import {MAIN} from '../../constants/colors';
import Space from '../../components/Space';
import {UNIT_CONVERSION_SCALE} from '../../constants/values';
import CustomHr from '../../components/CustomHr';
import AutoPdfGenerationContainer from '../../common/pdf/AutoPdfGenerationContainer';
import RadonReportChart from '../charts/Chartjs/RadonReportChart';
import {TEST_REPORT} from '../../constants/envConstant';
import DialogContentText from '@material-ui/core/DialogContentText';
import NoData from "../invoices/NoData";
import NoReports from "../invoices/NoReports";
import rgba from "polished/lib/color/rgba";

const ChartWrapper = styled.div`
  height: 300px;
`;

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Chip = styled(MuiChip)`
  ${spacing};
  width: ${(props) => '120px'};
  background: ${(props) => props.invoiceSent && '#F8B814'};
  background: ${(props) => props.paid && '#A3A3A3'};
  background: ${(props) => props.orderRequested && '#4CAF50'};
  background: ${(props) => props.cancelled && '#D65B52'};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const useStyles = makeStyles({
  customTableContainer: {
    overflow: "auto"
  }
});

let EnhancedTableToolbar = (props) => {
  const {numSelected} = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {/*{numSelected} selected*/}
          </Typography>
        ) : (
          <Typography variant="h5" id="tableTitle">
            Radon Reports
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer/>
      {/*<div>*/}
      {/*  {numSelected > 0 ? (*/}
      {/*    <Tooltip title="Delete">*/}
      {/*      <IconButton aria-label="Delete">*/}
      {/*        <ArchiveIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  ) : (*/}
      {/*    <Tooltip title="Filter list">*/}
      {/*      <IconButton aria-label="Filter list">*/}
      {/*        <FilterListIcon />*/}
      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  )}*/}
      {/*</div>*/}
    </Toolbar>
  );
};

function descendingComparator(a, b, orderBy) {
  if(orderBy==='generated_date') {
    let d1 = moment(a[orderBy], "MM/DD/YYYY");
    let d2 = moment(b[orderBy], "MM/DD/YYYY");
    return d2.utc() - d1.utc();
  } else if(orderBy==='measurement_start'||orderBy==='measurement_end') {
    let d1 = moment(a[orderBy], "MMDDYY");
    let d2 = moment(b[orderBy], "MMDDYY");
    return d2.utc() - d1.utc();
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!array || array.length === undefined) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {id: "device_name", alignment: "left", label: "Device Name"},
  {id: "device_name", alignment: "left", label: "Device"},
  {id: "serial_number", alignment: "left", label: "S/N"},
  {id: "client_name", alignment: "left", label: "Client"},
  {id: "measurement_start", alignment: "left", label: "Started"},
  {id: "measurement_end", alignment: "left", label: "Ended"},
  {id: "generated_date", alignment: "left", label: "Generated"},
  // {id: "pdf_url", alignment: "right", label: "Actions"},
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const deviceSelectionTableHeadCells = [
  // {id: "radio_button", alignment: "center", label: ""},
  // {id: "device_name", alignment: "left", label: "Device Name"},
  {id: "device_name", alignment: "left", label: "Device"},
  {id: "serial_number", alignment: "left", label: "S/N"},
  // {id: "client_name", alignment: "left", label: "Client"},
  // {id: "installed", alignment: "left", label: "Installed"},
];

function DeviceSelectionTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {deviceSelectionTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const durationHeadCells = [
  {id: "radio_button", alignment: "center", label: ""},
  {id: "duration", alignment: "left", label: "Measurement Duration"},
];

function EnhancedDurationTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {durationHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

function GeneratedReportsTable() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("generated_date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orders, setOrders] = useState([]);
  const [reports, setReports] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openViewClicked, setOpenViewClicked] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [reportDuration, setReportDuration] = useState(null);
  const location = useLocation();
  const reportType = '30 days';
  const bodyRef = React.createRef();
  let res;
  location.state.email = localStorage.getItem('0xaccountId');
  location.state.name = localStorage.getItem('0xuserId');
  // const fixedRow = {
  //   "measurement_start_d": "2021-02-15T12:00:00",
  //   "types": "hr_btn",
  //   "created": "2021-08-17T20:56:19.341685",
  //   "sn_report_start": "EG23EQ000012-021721-081721",
  //   "client_name": "User_12",
  //   // "pdf_url": "https://s3-us-west-2.amazonaws.com/dev-mitigatorreport/test1.pdf",
  //   "device_name": "EG12_In_SanRamon",
  //   "email": "choi2incommon@gmail.com",
  //   "serial_number": "EG23EQ000012",
  //   "filename": "test1.pdf",
  //   "measurement_start": "021521",
  //   "date": "2021-08-17",
  //   "measurement_end": "081721",
  //   "updated": "2021-08-17T21:56:19.341685",
  //   "generated_date": "08-17-2021",
  //   "measurement_end_d": "2021-08-17T12:00:00"
  // };

  const fixedRow = {
    "measurement_start_d": "2021-02-15T12:00:00",
    "types": "hr_btn",
    "created": "2021-08-19T20:56:19.341685",
    "sn_report_start": "EG23EQ000012-072021-081921",
    "client_name": "User_12",
    // "pdf_url": "https://s3-us-west-2.amazonaws.com/dev-mitigatorreport/test1.pdf",
    "device_name": "EG12_In_SanRamon",
    "email": "choi2incommon@gmail.com",
    "serial_number": "EG23EQ000012",
    "filename": "choi2incommon@gmail.com_EG23EQ000012_User_12_072021_081921",
    "measurement_start": "072021",
    "date": "2021-08-19",
    "measurement_end": "081921",
    "updated": "2021-08-19T21:56:19.341685",
    "generated_date": "08-19-2021",
    "measurement_end_d": "2021-08-19T12:00:00"
  };

  const getGeneratedReportsList = async () => {
    if (!location.state) return;
    try {
      setLoaded(false);
      setNoData(false);
      res = await ReportInterface.retrieveReportsList(location.state.email);

      if (res && (!res.data || (res.data && res.data.length === 0))) res.data = [];
      else if (!_.isEqual(reports, changeDate(TEST_REPORT ? [...res.data, fixedRow] : res.data))) {
        setReports(changeDate(TEST_REPORT ? [...res.data, fixedRow] : res.data));
      }
    } catch (e) {
      alert(e);
    }

    setLoaded(true);
    if (res && res.resultcode === 404) {
      setNoData(true);
    }
  };

  useEffect(() => {
    getGeneratedReportsList();
  }, [reportDetails, reportDuration]);

  const convert2DurationSet = (duration) => {
    let current = moment();
    let startMillis;
    if (duration.type === 'MONTH') {
      startMillis = current.clone().add(-30, 'days');
    } else if (duration.type === 'QUARTER') {
      startMillis = current.clone().add(-3, 'months');
    } else if (duration.type === 'HALF_YEAR') {
      startMillis = current.clone().add(-6, 'months');
    } else if (duration.type === 'YEAR') {
      startMillis = current.clone().add(-1, 'years');
    } else {
      startMillis = current.clone().add(-30, 'days');
    }
    let startTime;
    let endTime;
    startTime = getDate(startMillis, 1);
    endTime = getDate(current, 1);
    if (duration.type === 'MONTH') {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: 'hr_btn'
      }
    } else if (duration.type === 'QUARTER') {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: 'dy_btn'
      }
    } else if (duration.type === 'HALF_YEAR') {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: 'dy_btn'
      }
    } else if (duration.type === 'YEAR') {
      return {
        measurement_start: startTime,
        measurement_end: endTime,
        types: 'wk_btn'
      }
    }
  };

  const getDate = (moment, type) => {
    /*
    * type1 : MMDDYY
    * type2 : MM/DD/YYYY
    * type3 : MM-DD-YYYY
    * */

    if (type === 1) {
      let month = (moment.month() < 9) ? '0' + (moment.month() + 1) : (moment.month() + 1);
      return month + moment.date() + (moment.year() - 2000);
    } else if (type === 2) {
      let month = (moment.month() < 9) ? '0' + (moment.month() + 1) : (moment.month() + 1);
      return month + '/' + moment.date() + '/' + moment.year();
    } else if (type === 3) {
      let month = (moment.month() < 9) ? '0' + (moment.month() + 1) : (moment.month() + 1);
      return month + '-' + moment.date() + '-' + moment.year();
    }
  };
  let created = getDate(moment(), 3);

  const changeDate = (data) => {
    let tmp = data.map((r) => {
      // return {...r, generated_date: moment.parseZone(r.generated_date).utc().format('MM/DD/yyyy')}
      return {...r, generated_date: r.generated_date.replace(/-/g,'/')}
    });
    // alert(JSON.stringify(tmp));
    return tmp;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertDate = (d) => {
    return d.substring(0, 2) + '/' + d.substring(2, 4) + '/20' + d.substring(4);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);
  const history = useHistory();
  const downloadPDF = (event) => {
    try {
      ReportInterface.downloadFile(event.target.value);
    } catch (e) {
      alert(e);
    }
  };

  const updatePDFUrl = (url) => {
    getGeneratedReportsList();
  };

  const openRealtimeView = (row) => {
    let startUtc = moment.parseZone(row.measurement_start_d).utc();
    let endUtc = moment.parseZone(row.measurement_end_d).utc();
    let timeDiff = endUtc - startUtc;
    let duration;
    let btnType;
    const oneDay = 1000 * 60 * 60 * 24;
    if (timeDiff < oneDay * 32) {
      duration = 'MONTH';
      btnType = 'hr_btn';
    } else if (timeDiff < oneDay * 31 * 3) {
      duration = 'QUARTER';
      btnType = 'dy_btn';
    } else if (timeDiff < oneDay * 31 * 6) {
      duration = 'HALF_YEAR';
      btnType = 'dy_btn';
    } else {
      duration = 'YEAR';
      btnType = 'wk_btn';
    }
    history.push({
      pathname: '/reports/details',
      state: {
        email: localStorage.getItem('0xaccountId'),
        serial_number: [row.serial_number],
        actionType: 'VIEW',
        duration: {
          start: row.measurement_start,
          end: row.measurement_end,
          type: duration,
          btnType: btnType,
        },
        originalGenerated: row.generated_date
      },
    });
  };

  const useStyles = makeStyles((theme) => ({
    hover: {
      "&:hover": {
        // backgroundColor: "#f0fcff !important",
        cursor: 'pointer',
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {
        !loaded ? (
          <Loading></Loading>
        ) : (
          noData ? (
              <NoReports></NoReports>
            ) :
          <Wrapper>
            {/*<EnhancedTableToolbar/>*/}
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={reports.length}
                  />
                  <TableBody>
                    {stableSort(reports, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const date = new Date(row.created);

                        return (
                          <TableRow
                            hover
                            classes={{hover: classes.hover,}}
                            onClick={(event) => openRealtimeView(row)}
                            role="checkbox"
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                          >
                            <TableCell align="left">{row.device_name}</TableCell>
                            <TableCell align="left">{row.serial_number}</TableCell>
                            <TableCell align="left">{row.client_name}</TableCell>
                            <TableCell align="left">{convertDate(row.measurement_start)}</TableCell>
                            <TableCell align="left">{convertDate(row.measurement_end)}</TableCell>
                            <TableCell
                              align="left">{moment.parseZone(row.generated_date).utc().format('MM/DD/yyyy')}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={reports.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Wrapper>
        )
      }
    </>
  );
};

/*
TODO : 1. some of data doesn't show up in the table(resolved),
2. Stick header doesn't work
3. rerendering issue
*/
function DeviceTable(props) {
  const {callback, devices} = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("serial_number");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [devices, setDevices] = useState([]);
  const [skip, setSkip] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  let res;
  location.state.email = localStorage.getItem('0xaccountId');
  location.state.name = localStorage.getItem('0xuserId');
  const getDeviceList = async () => {
    if (!location.state) return;
    if (skip) return;
    setLoaded(true);
  };

  useEffect(() => {
    getDeviceList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = devices.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    // alert('handleClick for ' + id);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex >= 0) return;
    else {
      newSelected = [id];
    }

    setSelected(newSelected);
    setSkip(!skip);
    callback(true, newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, devices.length - page * rowsPerPage);
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {
        !loaded ? (
          <Loading top={'110px'} bottom={'110px'}></Loading>
        ) : devices.devices && devices.devices.length === 0 ? <></> : (
          <div>
            <Paper>
              {/*  <TableContainer style={{maxHeight:'1300px'}}>*/}
              {/*TODO: stickyHeader does not work*/}
              <TableContainer style={{maxHeight: '600px'}}>
                <Table stickyHeader aria-label="sticky table"
                  // aria-labelledby="tableTitle"
                       size={"medium"}
                >
                  <DeviceSelectionTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={devices.length}
                  />
                  <TableBody>
                    {stableSort(devices.devices, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.serial_number);

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.serial_number)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`${row.serial_number}-${index}`}
                            selected={isItemSelected}
                          >
                            {/*<TableCell align='center'>*/}
                            {/*  <FormControl component="fieldset">*/}
                            {/*    <RadioGroup aria-label="gender" name="gender1">*/}
                            {/*      <FormControlLabel value="selected" control={<Radio/>} checked={isItemSelected}/>*/}
                            {/*    </RadioGroup>*/}
                            {/*  </FormControl>*/}
                            {/*</TableCell>*/}
                            <TableCell align="left">{row.device_name}</TableCell>
                            <TableCell align="left">{row.serial_number}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {/*<Typography variant={'body2'} align={'center'} gutterBottom style={styles.report_client_bold}>*/}
                  {/*Generating Report...*/}
                  {/*</Typography></>}*/}
                </Table>
              </TableContainer>
            </Paper>
          </div>
        )
      }
    </>
  );
};

function DurationTable(props) {
  const {callback} = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("serial_number");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [devices, setDevices] = useState([]);
  const durations = [
    {duration: 'Last 7 days', type: 'WEEK'},
    {duration: 'Last 30 days', type: 'MONTH'},
    // {duration: 'Last 3 months', type: 'QUARTER'},
    {duration: 'Last 6 months', type: 'HALF_YEAR'},
    {duration: 'Last 1 year', type: 'YEAR'},
  ];
  const location = useLocation();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = devices.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, clickedRow) => {
    if (clickedRow.duration !== 'Last 7 days') return;

    const selectedIndex = selected.indexOf(clickedRow.duration);
    let newSelected = [];
    if (selectedIndex >= 0) return;
    else {
      newSelected = [clickedRow.duration];
    }

    setSelected(newSelected);
    callback(true, clickedRow);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, durations.length - page * rowsPerPage);
  const history = useHistory();

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table"
                 size={"medium"}
          >
            {/*<EnhancedDurationTableHead*/}
            {/*  numSelected={selected.length}*/}
            {/*  order={order}*/}
            {/*  orderBy={orderBy}*/}
            {/*  onSelectAllClick={handleSelectAllClick}*/}
            {/*  onRequestSort={handleRequestSort}*/}
            {/*  rowCount={durations.length}*/}
            {/*/>*/}
            <TableBody>
              {stableSort(durations, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.duration);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, {duration: row.duration, type: row.type})}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.duration}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align='center'>
                        <FormControl component="fieldset">
                          <RadioGroup aria-label="gender" name="gender1">
                            {/*<FormControlLabel value="selected" disabled={index!==0 ? true: false} control={<Radio/>} checked={isItemSelected}/>*/}
                            <FormControlLabel value="selected" control={<Radio/>} checked={isItemSelected}/>
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell align="left">{row.duration}</TableCell>
                    </TableRow>
                  );
                })}
              {/*{emptyRows > 0 && (*/}
              {/*  <TableRow style={{height: 53 * emptyRows}}>*/}
              {/*    <TableCell colSpan={8}/>*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

function ReportListForApp() {
  const history = useHistory();
  const location = useLocation();
  const [deviceSelected, setDeviceSelected] = useState({isSelected: false, serial: null});
  const [durationSelected, setDurationSelected] = useState({isSelected: false, duration: null});
  location.state = {
    ...location.state,
    email: localStorage.getItem('0xaccountId'),
    name: localStorage.getItem('0xuserId')
  };

  const [openDeviceForm, setOpenDeviceForm] = React.useState(false);
  const [openDurationForm, setOpenDurationForm] = React.useState(false);
  const [openNoDataMsg, setOpenNoDataMsg] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [devices, setDevices] = React.useState([]);

  const checkAvailableDevices = async () => {
    // let res = await SubscriptionInterface.retrieveDevicesWithoutSummary(location.state.email);
    let res = await SubscriptionInterface.retrieveDevicesWithoutSummaryForApp(location.state.email);
    if (res && res.data && res.data.devices && res.data.devices.length > 0) {
      setDevices(res.data);
      return true;
    } else return false;
  };

  const handleClickOpen = async () => {
    // alert('handleClickOpen() is called');
    let haveDevices = await checkAvailableDevices();
    if (haveDevices) setOpenDeviceForm(true);
    else {
      setOpenNoDataMsg(true);
    }
  };

  const handleClose = () => {
    setOpenDeviceForm(false);
    setDeviceSelected({...deviceSelected, isSelected: false});
  };

  const handleNext = () => {
    handleClose();
    setOpenDurationForm(true);
  };

  const handleNextClose = () => {
    setOpenDurationForm(false);
    setDurationSelected({isSelected: false, duration: null});
  };

  const handleFinal = () => {
    console.log('handleFinal :  ' + JSON.stringify(durationSelected.duration));
    handleNextClose();
    setOpenDurationForm(false);
    history.push({
      pathname: '/reports/advanced-details',
      state: {
        email: localStorage.getItem('0xaccountId'),
        sn: deviceSelected.serial[0],
        duration: durationSelected.duration,
      },
    });
  };

  const notifyDeviceSelected = (isSelected, serial) => {
    setDeviceSelected({isSelected: isSelected, serial: serial});
  };

  const notifyDurationSelected = (isSelected, duration) => {
    setDurationSelected({isSelected: isSelected, duration: duration});
  };

  const handleGoPrevious = () => {
    setDeviceSelected({...deviceSelected, isSelected: false});
    setOpenDurationForm(false);
    setOpenDeviceForm(true);
  };

  const handleNoDataClose = () => {
    setOpenNoDataMsg(false);
  };

  useEffect(() => {
    // alert('useEffect@ location.state.email = ' + location.state.email);
    if (location.state && location.state.sn) {
      setDeviceSelected({isSelected: true, serial: [location.state.sn]});
      setOpenDurationForm(true);
    }

    handleClickOpen();
  }, []);

  const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
  `;

  return (
    // <React.Fragment>
    <>
      <Helmet title="Reports"/>
      <Grid justify="space-between" container spacing={24}>
        {/*<Grid item>*/}
        {/*  <Typography variant="h3" gutterBottom display="inline">*/}
        {/*    Radon Reports*/}
        {/*  </Typography>*/}

        {/*  <Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
        {/*    <Link component={NavLink} exact to="/reports/list">*/}
        {/*      Radon Reports*/}
        {/*    </Link>*/}
        {/*    <Typography>Overview</Typography>*/}
        {/*  </Breadcrumbs>*/}
        {/*</Grid>*/}
        <Grid item>
          <div>
            {/*<Button variant="contained"*/}
            {/*        color="primary"*/}
            {/*        onClick={handleClickOpen}*/}
            {/*        classes={{root: 'button', label: 'button-label'}}>*/}
            {/*  <AddIcon/>*/}
            {/*  New Report*/}
            {/*</Button>*/}
            <Dialog
              // fullWidth={fullWidth}
              titleStyle={{textAlign: "center"}}
              maxWidth="md"
              maxHeight={'sm'}
              open={openDeviceForm}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title1"
              style={{backgroundColor: rgba(0,0,0,0.7)}}
              overlayStyle={{backgroundColor: 'black'}}
            >
              <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
                <div className="s20"></div>
                <DialogTitle id="max-width-dialog-title1">
                  <Typography variant="h5" align="center">Select device</Typography>
                </DialogTitle>
                <div className="s10"></div>
                <DialogContent>
                  <TableContainer>
                    <DeviceTable devices={devices} callback={notifyDeviceSelected}/>
                  </TableContainer>
                </DialogContent>
                <div className="s20"></div>
                <DialogActions>
                  {/*<Box style={{display: 'flex', flex: 1, alignItems: 'flex-start'}}>*/}
                  {/*  <Button style={{color: '#000000', width: '150px', height: '36px'}}*/}
                  {/*          onClick={handleClose}>*/}
                  {/*    <u>Cancel</u>*/}
                  {/*  </Button>*/}
                  {/*</Box>*/}
                  <Box style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                    <Button style={{
                      background: deviceSelected.isSelected ? '#3DB5E6' : '#E0E0E0',
                      color: deviceSelected.isSelected ? '#FFFFFF' : '#000000',
                      width: '150px',
                      height: '36px'
                    }} onClick={handleNext} color="primary" disabled={!deviceSelected.isSelected}>
                      Next
                    </Button>
                  </Box>
                  <Box style={{width: '8px'}}/>
                </DialogActions>
                <Space height={'15px'}/>
              </div>

            </Dialog>
            <Dialog
              // fullWidth={fullWidth}
              titleStyle={{textAlign: "center"}}
              maxWidth="md"
              maxHeight={'sm'}
              open={openDurationForm}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title2"
              style={{backgroundColor: rgba(0,0,0,0.7)}}
            >
              <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
                <div className="s20"></div>
                <DialogTitle id="max-width-dialog-title2">
                  <Typography variant="h5" align="center">Select measurement time</Typography>
                </DialogTitle>
                <DialogContent>
                  <TableContainer>
                    <DurationTable callback={notifyDurationSelected}/>
                  </TableContainer>
                </DialogContent>
                <div className="s20"></div>
                <DialogActions>
                  <Box style={{display: 'flex', flex: 1, justifyContent: 'flex-start'}}>
                    <Button style={{color: '#000000', width: '150px', height: '36px'}}
                            onClick={handleNextClose}>
                      <u>Cancel</u>
                    </Button>
                  </Box>
                  <Box style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                    <Button style={{
                      background: '#3DB5E6',
                      color: '#FFFFFF',
                      width: '150px',
                      height: '36px'
                    }} onClick={handleGoPrevious} color="primary">
                      Previous
                    </Button>
                    <Box style={{width: '10px'}}/>
                    <Button style={{
                      background: durationSelected.isSelected ? '#3DB5E6' : '#E0E0E0',
                      color: durationSelected.isSelected ? '#FFFFFF' : '#000000',
                      width: '150px',
                      height: '36px'
                    }} onClick={handleFinal} color="primary" disabled={!durationSelected.isSelected}>
                      Create
                    </Button>
                  </Box>
                </DialogActions>
                <div className="s20"></div>
              </div>
            </Dialog>
            <Dialog
              titleStyle={{textAlign: "center"}}
              maxWidth="md"
              maxHeight={'sm'}
              open={openNoDataMsg}
              onClose={handleNoDataClose}
              aria-labelledby="alert-dialog-title"
            >
              <div className="s5"></div>
              <DialogTitle id="alert-dialog-title">
                Information Message
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">There is no devices to generate
                  reports</DialogContentText>
              </DialogContent>
              <div className="s10"></div>
              <DialogActions style={{paddingRight: '20px'}}>
                <Button style={{background: '#E0E0E0', color: '#000000', width: '100px', height: '36px'}}
                        onClick={handleClose}>
                  OK
                </Button>
              </DialogActions>
              <div className="s10"></div>
            </Dialog>
          </div>
        </Grid>
      </Grid>

      {/*<Divider my={6}/>*/}

      {/*<Grid container spacing={6}>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <GeneratedReportsTable/>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Grid container spacing={6}>*/}
      {/*  <Grid item xs={12} md={6}>*/}
      {/*    <LineChart />*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*</React.Fragment>*/}
    </>
  );
}

const styles = {
  report_client_bold: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  report_label: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
  },
  report_title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#FFFFFF',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  report_title_box: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: MAIN,
    justifyContent: 'center',
    alignItems: 'center'
  },
  report_footer: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '48px',
    color: '#585858',
  }
};

export default ReportListForApp;
