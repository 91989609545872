import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {createPaymentIntentForOrder} from "../../models/services/stripeService";
import * as types from "../../constants";
import {Button, CircularProgress, Typography} from "@material-ui/core";
import "../../assets/css/payment.css";
import {createOrderAction, getOrderDetailAction} from "../../models/actions/orderActions";
import {useDispatch} from "react-redux";
import _ from 'lodash';

export default function CheckoutFormForOrder(props) {
  // Setup state to track client secret, errors and checkout status
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [paidAmount, setPaidAmount] = useState(0);
  // Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
  }, []);

  const handleChangeForCreditCard = async (event) => {
    if (props.checked) {
      if (Object.keys(props.validateBillingInformation(props.values)).length > 0) {
        props.refreshPopup();
        return;
      }
    }

    setError(event.error ? event.error.message : "");
  };

  const handleSubmitForCreditCard = async (ev) => {
    if (Object.keys(props.validateBillingInformation(props.values)).length > 0) {
      props.refreshPopup();
      return;
    }

    if (error) {
      return;
    }

    if (!props.checked) {
      props.order.bill_firstname = props.order.ship_firstname;
      props.order.bill_lastname = props.order.ship_lastname;
      props.order.bill_street = props.order.ship_street;
      props.order.bill_detail = props.order.ship_detail;
      props.order.bill_email = props.order.ship_email;
      props.order.bill_phone = props.order.ship_phone;
      props.order.bill_city = props.order.ship_city;
      props.order.bill_state = props.order.ship_state;
      props.order.bill_zipcode = props.order.ship_zipcode;
      props.order.bill_country = props.order.ship_country;
    }

    ev.preventDefault();
    setProcessing(true);

    // Payment Intent Data
    const paymentIntentParam = {
      data: {
        email: localStorage.getItem('0xaccountId'),
        payment_method_type: 'card',
        currency: 'usd',
        amount: props.order.totalDue * 100,
        order: props.order,
        product_id: 'EQS',
        refer_id: props.order.refer_id,
        order_type: props.order.order_type,
        // just for testing
        //invoiceNo: '500000050'
      }
    }

    try {
      // Create Payment Intent
      let res = await createPaymentIntentForOrder(paymentIntentParam);
      const clientSecret = _.get(res, 'clientSecret');
      // const invoiceId = _.get(res, 'invoiceNo');  // just for testing
      const orderId = _.get(res, 'orderId');
      // if (!res || !clientSecret || clientSecret.length <= 0 || !invoiceId ){
      if (!res || !clientSecret || clientSecret.length <= 0 || !orderId ){
        throw new Error('Payment Intent is not created successfully');
      }

      // Confirm Card Payment.
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              city: props.order.bill_city,
              country: props.order.bill_country,
              line1: props.order.bill_street,
              line2: props.order.bill_detail,
              postal_code: props.order.bill_zipcode,
              state: props.order.bill_state
            },
            email: props.order.bill_email,
            name: props.order.bill_firstname + ' ' + props.order.bill_lastname,
            phone: props.order.bill_phone
          },
        },
        shipping: {
          address: {
            city: props.order.ship_city,
            country: props.order.ship_country,
            line1: props.order.ship_street,
            line2: props.order.ship_detail,
            postal_code: props.order.ship_zipcode,
            state: props.order.ship_state
          },
          // email: props.order.ship_email,
          name: props.order.ship_firstname + ' ' + props.order.ship_lastname,
          phone: props.order.ship_phone
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);

        setOrderId(payload.paymentIntent.id);
        setPaidAmount(payload.paymentIntent.amount);

        props.showOrderConfirmation(res.orderId, payload);
      }
    } catch (error) {
      let message = error.message || "Something went wrong";
      setError(error.message);
      setProcessing(false);
    }
  };

  // Construct UI.
  return (
    <form id="payment-form" onSubmit={handleSubmitForCreditCard}>
      {/* Title */}
      <Typography variant="h7" gutterBottom>
        <b>Credit card information</b>
      </Typography>
      <div className="s20"></div>

      {/* Credit Card Information */}
      <CardElement
        id="card-element"
         // Specify styles here
        options={{}}
        onChange={handleChangeForCreditCard}
      />
      <div className="s20"></div>

      {/* Pay now button */}
      <Button
        onClick={handleSubmitForCreditCard}
        // disabled={processing || disabled || succeeded || (error && error.length > 0)}
        disabled={processing || disabled || succeeded}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        classes={{root: 'button', label: 'button-label'}}
      >
        { processing ? <CircularProgress size={20}/> : <span>Pay now</span> }
      </Button>
      <div className="s20"></div>
      {/* Show a success message upon completion */}
      {!error && succeeded && (
        /*<div className="card-success" role="alert">Payment succeeded! <br/>Order ID: {orderId} (${(paidAmount/100).toFixed(2)})</div>*/
        <div className="card-success" role="alert">Payment succeeded!</div>
      )}

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">{error}</div>
      )}
    </form>
  );
}
