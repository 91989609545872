// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT_REQUEST = "AUTH_SIGN_OUT_REQUEST";
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_SUCCESS";
export const AUTH_SIGN_OUT_FAILURE = "AUTH_SIGN_OUT_FAILURE";
export const AUTH_VERIFY_CODE_REQUEST = "AUTH_VERIFY_CODE_REQUEST";
export const AUTH_VERIFY_CODE_SUCCESS = "AUTH_VERIFY_CODE_SUCCESS";
export const AUTH_VERIFY_CODE_FAILURE = "AUTH_VERIFY_CODE_FAILURE";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_CHANGE_PWD_REQUEST = "AUTH_CHANGE_PWD_REQUEST";
export const AUTH_CHANGE_PWD_SUCCESS = "AUTH_CHANGE_PWD_SUCCESS";
export const AUTH_CHANGE_PWD_FAILURE = "AUTH_CHANGE_PWD_FAILURE";
export const AUTH_UPDATE_ATTRIBUTE_REQUEST = "AUTH_UPDATE_ATTRIBUTE_REQUEST";
export const AUTH_UPDATE_ATTRIBUTE_SUCCESS = "AUTH_UPDATE_ATTRIBUTE_SUCCESS";
export const AUTH_UPDATE_ATTRIBUTE_FAILURE = "AUTH_UPDATE_ATTRIBUTE_FAILURE";
export const AUTH_UPDATE_ATTRIBUTES_REQUEST = "AUTH_UPDATE_ATTRIBUTES_REQUEST";
export const AUTH_UPDATE_ATTRIBUTES_SUCCESS = "AUTH_UPDATE_ATTRIBUTES_SUCCESS";
export const AUTH_UPDATE_ATTRIBUTES_FAILURE = "AUTH_UPDATE_ATTRIBUTES_FAILURE";
export const AUTH_RESEND_SIGNUP_REQUEST = "AUTH_RESEND_SIGNUP_REQUEST";
export const AUTH_RESEND_SIGNUP_SUCCESS = "AUTH_RESEND_SIGNUP_SUCCESS";
export const AUTH_RESEND_SIGNUP_FAILURE = "AUTH_RESEND_SIGNUP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const AUTH_FORGOT_PASSWORD_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAILURE = "AUTH_FORGOT_PASSWORD_FAILURE";

// Company (mitigator)
export const COMPANY_INFO_CREATE_REQUEST = "COMPANY_INFO_CREATE_REQUEST";
export const COMPANY_INFO_CREATE_SUCCESS = "COMPANY_INFO_CREATE_SUCCESS";
export const COMPANY_INFO_CREATE_FAILURE = "COMPANY_INFO_CREATE_FAILURE";
export const COMPANY_INFO_UPDATE_REQUEST = "COMPANY_INFO_UPDATE_REQUEST";
export const COMPANY_INFO_UPDATE_SUCCESS = "COMPANY_INFO_UPDATE_SUCCESS";
export const COMPANY_INFO_UPDATE_FAILURE = "COMPANY_INFO_UPDATE_FAILURE";
export const COMPANY_INFO_GET_REQUEST = "COMPANY_INFO_GET_REQUEST";
export const COMPANY_INFO_GET_SUCCESS = "COMPANY_INFO_GET_SUCCESS";
export const COMPANY_INFO_GET_FAILURE = "COMPANY_INFO_GET_FAILURE";

// Order
export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAILURE = "ORDER_CREATE_FAILURE";
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAILURE = "ORDER_DETAIL_FAILURE";
export const ORDER_DETAIL_BY_QTY_REQUEST = "ORDER_DETAIL_BY_QTY_REQUEST";
export const ORDER_DETAIL_BY_QTY_SUCCESS = "ORDER_DETAIL_BY_QTY_SUCCESS";
export const ORDER_DETAIL_BY_QTY_FAILURE = "ORDER_DETAIL_BY_QTY_FAILURE";
export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILURE = "ORDER_LIST_FAILURE";
export const REPORT_ISSUE_REQUEST = "REPORT_ISSUE_REQUEST";
export const REPORT_ISSUE_SUCCESS = "REPORT_ISSUE_SUCCESS";
export const REPORT_ISSUE_FAILURE = "REPORT_ISSUE_FAILURE";

// SUBSCRIPTION
export const SUBSCRIPTION_CREATE_REQUEST = "SUBSCRIPTION_CREATE_REQUEST";
export const SUBSCRIPTION_CREATE_SUCCESS = "SUBSCRIPTION_CREATE_SUCCESS";
export const SUBSCRIPTION_CREATE_FAILURE = "SUBSCRIPTION_CREATE_FAILURE";
export const SUBSCRIPTION_DETAIL_REQUEST = "SUBSCRIPTION_DETAIL_REQUEST";
export const SUBSCRIPTION_DETAIL_SUCCESS = "SUBSCRIPTION_DETAIL_SUCCESS";
export const SUBSCRIPTION_DETAIL_FAILURE = "SUBSCRIPTION_DETAIL_FAILURE";
export const SUBSCRIPTION_LIST_REQUEST = "SUBSCRIPTION_LIST_REQUEST";
export const SUBSCRIPTION_LIST_SUCCESS = "SUBSCRIPTION_LIST_SUCCESS";
export const SUBSCRIPTION_LIST_FAILURE = "SUBSCRIPTION_LIST_FAILURE";

// Payment
export const PAYMENT_INTENT_CREATE_REQUEST = "PAYMENT_INTENT_CREATE_REQUEST";
export const PAYMENT_INTENT_CREATE_SUCCESS = "PAYMENT_INTENT_CREATE_SUCCESS";
export const PAYMENT_INTENT_CREATE_FAILURE = "PAYMENT_INTENT_CREATE_FAILURE";

// Cognito attributes
export const CUSTOM_COMPANY_EMAIL = 'custom:companyEmail'
export const CUSTOM_GROUP = 'custom:group'

// local storage(LS) keys
export const LS_EMAIL = '0xaccountId'
export const LS_NAME = '0xuserId'
export const LS_ACCESS_SOURCE = 'accessSource'
export const LS_ACCESS_TOKEN = 'accessToken'
