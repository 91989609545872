import React from 'react';

function CustomHr({color, height}) {
  return (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        borderColor: color,
        height: height,
      }}
    />
  );
};

export default CustomHr;
