export const fromBase64 = async (base64Files) => {
  let decodedFiles = [];
  const mimeType = {
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    pdf: "application/pdf",
  };

  base64Files.forEach(async (encodedFile) => {
    const bstr = atob(encodedFile.image);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], encodedFile.name, {
      type: mimeType[encodedFile.name.split(".")[1]],
    });

    decodedFiles.push(file);
  });

  return decodedFiles;
};
