import React, {useEffect} from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import "../../assets/css/auth.css";
import "../../assets/css/common.css";
import {
  Typography, Box, CircularProgress,
} from "@material-ui/core";


function IntegrateWithApp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    init(params);
  }, []);

  const init = async (params) => {
    const email = decodeURIComponent(params.get('email'));
    const userId = decodeURIComponent(params.get('userId'));
    const accessToken = decodeURIComponent(params.get('accessToken'));

    await localStorage.setItem("0xuserId", userId);
    await localStorage.setItem('0xaccountId', email);
    await localStorage.setItem('accessToken', accessToken);
    await localStorage.setItem('accessSource', 'app');

    if (accessToken) {
      history.replace('/support');
      // history.replace('/devices');
      // history.replace('/reports/list');
    }
  }

  return (
      (loading) ? (
          <>
            <Box display='flex' flexDirection={'column'}>
              <div className='progress'>
                <CircularProgress />
              </div>
              <div className='s20'></div>
              <Typography variant={'body1'}>Loading ..</Typography>
            </Box>
          </>
        ) : (
          <></>
        )
  );
}

export default IntegrateWithApp;
